import React, { useCallback, useEffect, useState } from "react";
import { observer } from "mobx-react-lite";
import { Box, Button, EditSelect, ICommand, Input, RelayCommand } from "Application";
import { ModalGenericWithHeader } from "Components/ModalGenericWithHeader/ModalGenericWithHeader";
import tick from "Assets/Icons/SmallTickCircleIcon.svg";
import NoteIcon from "Assets/Icons/editButtonIcon.svg";
import { useDropzone } from "react-dropzone";
import FileIcon from "Assets/Icons/FileIcon.svg";
import { P } from "Components/Primitives/TextElements/TextElements";
import DocLogo from "Assets/Icons/DocLogo.svg";
import CloseIcon from "Assets/close_icon_black.svg";
import { DeleteButton, DocumentToUploadBox, NoteBox, UploadImageBox } from "Components/CommonPageComponents";
import { ModalType } from "Application/Models/Domain/AddEdditModalType";
import { SubmitType } from "Application/Models/Domain/Forms";
import { IDocumentType } from "Models/Documents/DocumentType";
import { DownloadButton } from "Components/Primitives/Buttons/DownloadButton";
import { INotesViewModel } from "./IAddNoteViewModel";
import { checkIsAcceptedFile } from "Utils/File";
import { PreviewButtonImg } from "Components/DocumentUploader/DocumentUploader.styles";
import { truncateString } from "Utils/Formats";
import spyScope from "Assets/Icons/magnifyingGlass.svg";
import { PreviewView } from "Components/DocumentUploader/PreviewView/PreviewView";
import { ViewDocumentPreviewButton } from "Components/Primitives/Buttons/ViewDocumentPreviewButton";

interface IAddNoteModalProps {
    isOpen: boolean;
    closeModal: ICommand<any>;
    customerId: string;
    viewModel: INotesViewModel;
    AddOrEdit?: ModalType;
    documentTypes?: IDocumentType[];
}

export const AddNoteModal: React.FC<IAddNoteModalProps> = observer((props) => {
    const [isPreviewPanelVisible, setIsPreviewPanelVisible] = useState<boolean>(false);
    const [previewFileIndex, setPreviewFileIndex] = useState<number>(0);

    useEffect(() => {
        if (props.documentTypes !== undefined) {
            props.viewModel.documentTypes.replace(props.documentTypes);
        }
        return () => {
            // Clean up after yourself
            //viewModel.clear();
        };
    }, []);

    // Previewing document

    const showPreviewPanel = (documentIndex: number) => {
        setPreviewFileIndex(documentIndex);
        setIsPreviewPanelVisible(true);
    };

    const navBackToDocumentUploader: ICommand = new RelayCommand(() => {
        setIsPreviewPanelVisible(false);
    });

    // ***************

    function MyDropzone() {
        const onDrop = useCallback((acceptedFiles: File[]) => {
            // Do something with the files
            props.viewModel.addFiles(acceptedFiles);
        }, []);
        const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop, noDragEventsBubbling: true });

        return (
            <UploadImageBox>
                <div {...getRootProps()}>
                    <img src={FileIcon} />
                    <input {...getInputProps()} />
                    {isDragActive ? <p>Drop the files here ...</p> : <p>Click or drag files to attach (.doc .pdf .xls .csv .jpg .png etc.)</p>}
                </div>
            </UploadImageBox>
        );
    }

    const submitNote: ICommand = new RelayCommand(async (submitType: SubmitType) => {
        let areViewModelsValid: boolean = true;
        if (props.viewModel.documentsToUpload.length > 0) {
            for (let i = 0; i < props.viewModel.documentsToUpload.length; i++) {
                if (props.viewModel.documentsToUpload[i].isModelValid() === false) {
                    areViewModelsValid = false;
                }
            }
            if (areViewModelsValid === true && props.viewModel.addDocumentFileAndNote) {
                props.viewModel.addDocumentFileAndNote(submitType);
            }
        } else {
            props.viewModel.submitNote(submitType);
        }
    });

    return (
        <>
            <ModalGenericWithHeader
                isOpen={props.isOpen}
                headerText={props.AddOrEdit === ModalType.Edit ? " Edit note" : " Add note"}
                headerIcon={NoteIcon}
                stickyBar={
                    <>
                        <Button
                            displayName={props.AddOrEdit === ModalType.Edit ? " Save note & exit" : " Add note & exit"}
                            paletteColor="ButtonGreen"
                            value={SubmitType.SaveAndExit}
                            command={submitNote}
                            icon={<img src={tick} />}
                            mr="10px"
                            boxShadow={true}
                        />
                        {props.viewModel.showDocumentUploader === false && (
                            <Button
                                displayName={" Add note & continue"}
                                paletteColor="ButtonGreen"
                                value={SubmitType.SaveAndContinue}
                                command={submitNote}
                                icon={<img src={tick} />}
                                mr="10px"
                                boxShadow={true}
                            />
                        )}
                        <Button displayName=" Cancel" paletteColor="ButtonCancel" command={props.closeModal} icon={<img src={tick} />} boxShadow={true} />
                    </>
                }
                closeModalCommand={props.closeModal}
            >
                {isPreviewPanelVisible && (
                    <PreviewView documentList={props.viewModel.documentsToUpload} previewFileIndex={previewFileIndex} navBackToDocumentUploader={navBackToDocumentUploader} />
                )}

                {!isPreviewPanelVisible && (
                    <Box minWidth="658px" p="12px 0px">
                        <Input
                            multiline
                            autoFill={false}
                            command={props.viewModel.setNote}
                            displayName="Note *"
                            placeholder="Note"
                            value={() => props.viewModel.getValue("note")}
                            validationMessage={() => props.viewModel.getError("note")}
                            style={{ whiteSpace: "normal", overflow: "visible", minHeight: "160px" }}
                        ></Input>
                    </Box>
                )}
                {props.viewModel.showDocumentUploader === true && !isPreviewPanelVisible && (
                    <>
                        <Box p="10px 0px">
                            <P pb="15px">
                                <strong>Documents added</strong>
                            </P>
                            {props.viewModel.uploadedDocuments.length < 1 && <P pb="15px">There are currently no documents added to this contact</P>}
                            {props.viewModel.uploadedDocuments.map((document) => {
                                return (
                                    <>
                                        <NoteBox key={document.id}>
                                            <Box flexBox>
                                                <img src={FileIcon} height="15.3px"></img>
                                                <P pl="10px">{document.fileName}</P>
                                            </Box>

                                            <Box flexBox>
                                                {/* <ViewDocumentPreviewButton
                                                    id={document.id}
                                                    fileName={document.fileName}
                                                    blobName={document.blobName}
                                                    fileType={document.fileType}
                                                    docType={document.docType}
                                                    onClick={viewModel.downloadDocumentCommand}
                                                /> */}
                                                <DownloadButton id={String(document.id)} onClick={props.viewModel.downloadDocumentCommand} />

                                                <DeleteButton
                                                    command={props.viewModel.deleteDocumentByIdCommand}
                                                    value={String(document.id)}
                                                    style={{ cursor: "pointer" }}
                                                    icon={<img src={CloseIcon} />}
                                                />
                                            </Box>
                                        </NoteBox>
                                    </>
                                );
                            })}
                        </Box>

                        <Box minWidth="658px" p="12px 0px">
                            <MyDropzone />
                        </Box>

                        {props.viewModel.documentsToUpload.map((document, index) => {
                            return (
                                <>
                                    <DocumentToUploadBox key={document.model.KEY + index}>
                                        <Box flexBox gap={0} style={{ alignItems: "center", justifyContent: "space-between" }}>
                                            <Box flexBox gap={0} style={{ alignItems: "center" }}>
                                                <img src={DocLogo} height="18px" />
                                                <P pl="10px">{truncateString(document.getValue("filename"))}</P>
                                            </Box>

                                            {checkIsAcceptedFile(document.model.file) && (
                                                <Box pr={"10px"}>
                                                    <PreviewButtonImg src={spyScope} onClick={() => showPreviewPanel(index)} />
                                                </Box>
                                            )}
                                        </Box>
                                        <Box flexBox style={{ justifyContent: "space-between", alignItems: "center" }}>
                                            <EditSelect
                                                command={document.setDocumentType}
                                                options={props.viewModel.getDocTypesForDropdown}
                                                value={() => document.getValue("documentCategoryId")}
                                                validationMessage={() => document.getError("documentCategoryId")}
                                                errorIconRight={true}
                                                noHeader={true}
                                                menuPosition="fixed"
                                            />
                                            <DeleteButton
                                                command={props.viewModel.removeFileFromModalList}
                                                value={index}
                                                style={{ cursor: "pointer" }}
                                                icon={<img src={CloseIcon} />}
                                            />
                                        </Box>
                                    </DocumentToUploadBox>
                                </>
                            );
                        })}
                        {/* {props.viewModel.documentsToUpload.length > 0 && (
                            <Box flexBox justifyContent="right">
                                <Button displayName="Attach files" boxShadow={true} paletteColor="ButtonGreen" command={props.viewModel.submitDocuments} />
                            </Box>
                        )} */}
                    </>
                )}
            </ModalGenericWithHeader>
        </>
    );
});
