import React, { useEffect, useState } from "react";
import { observer } from "mobx-react-lite";
import { Box } from "Application";
import { ComplaintDetailsViewModel } from "./ComplaintDetailsViewModel";
import { P } from "Components/Primitives/TextElements/TextElements";
import { formatDate, hyphenIfNoValue } from "Utils/Formats";
import { ComplaintsViewModel } from "../ComplaintsViewModel";
import { BooleanTickOrCrossCell } from "Utils/CellFormatComponents";
import { PageTabWrapper } from "Styles/BaseStyles";

export interface ComplaintDetailsViewProps {
    parentViewModel: ComplaintsViewModel;
}

export const ComplaintDetailsView: React.FC<ComplaintDetailsViewProps> = observer((props) => {
    const [viewModel] = useState(() => new ComplaintDetailsViewModel());
    useEffect(() => {
        return () => {
            // Clean up after yourself
            viewModel.clear();
        };
    }, []);

    return (
        <PageTabWrapper>
            <Box grid dc="1fr 1fr" pt="30px">
                <P>
                    <strong>Opened on: </strong> {hyphenIfNoValue(formatDate(new Date(props.parentViewModel.getValue("createdDate"))))}
                </P>
                <P>
                    <strong>Installation company: </strong> {hyphenIfNoValue(props.parentViewModel.getValue("installationCompany"))}
                </P>
                <P>
                    <strong>Project: </strong> {hyphenIfNoValue(props.parentViewModel.getValue("projectName"))}
                </P>
                <P>
                    <strong>Assigned to: </strong> {hyphenIfNoValue(props.parentViewModel.getValue("complaintAssignee"))}
                </P>
                <P>
                    <strong>Reported by: </strong> {hyphenIfNoValue(props.parentViewModel.getValue("reportedBy"))}
                </P>
                <P>
                    <strong>Nature of complaint: </strong> {hyphenIfNoValue(props.parentViewModel.getValue("nature"))}
                </P>
                <P>
                    <strong>Company who reported: </strong> {hyphenIfNoValue(props.parentViewModel.getValue("companyReportedBy"))}
                </P>
                <P>
                    <strong>Category of complaint: </strong> {hyphenIfNoValue(props.parentViewModel.getValue("category"))}
                </P>
                <P style={{ gridColumn: "span 2" }}>
                    <strong>Complaint Details: </strong> {hyphenIfNoValue(props.parentViewModel.getValue("details"))}
                </P>
                <P style={{ gridColumn: "span 2" }}>
                    <strong>Assessment: </strong> {hyphenIfNoValue(props.parentViewModel.getValue("assessment"))}
                </P>
                <P style={{ gridColumn: "span 2", display: "flex" }}>
                    <strong>Resolved: </strong>
                    <Box style={{ paddingLeft: "5px" }}>
                        <BooleanTickOrCrossCell value={props.parentViewModel.getValue("isResolved")} alignLeft={true} />
                    </Box>
                </P>
            </Box>
        </PageTabWrapper>
    );
});
