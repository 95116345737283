import { makeObservable, action, observable, runInAction, IObservableArray, computed } from "mobx";
import { KeyValuePair, ViewModelBase } from "@shoothill/core";
import { APIClient, ICommand, RelayCommand } from "Application";
import { ErrorStore } from "Stores/Domain/ErrorStore";
import { container } from "tsyringe";
import { ComplaintTaskListItem, TasksModel, TasksModelValidator } from "./TasksModel";
import { ComplaintTasksUpsertEndpoint } from "./Endpoints/ComplaintTasksUpsertEndpoint";
import { GetComplaintTaskDetailsByIdEndpoint } from "./Endpoints/GetComplaintTaskDetailsByIdEndpoint";
import { GetComplaintTaskListEndpoint } from "./Endpoints/GetComplaintTaskListEndpoint";
import { SharmanTaskTypeEnum } from "Models/Tasks/TaskTypeEnum";
import { SortOrderDirection } from "Components/Primitives/DataTable/SharmansTable";
import { DefaultPageSize } from "Globals/GlobalSettings";

export class TasksViewModel extends ViewModelBase<TasksModel> {
    public apiClient = new APIClient();
    errorStore = container.resolve(ErrorStore);

    public complaintId: string = "";
    public filterByCompleted: string[] = [];
    public isProcessing: boolean = false;
    public showViewTaskModal: boolean = false;

    private taskCountCallBack: (newCount: number) => void;

    public tasksList: IObservableArray<ComplaintTaskListItem> = observable([]);

    // Table ordering

    public orderBy: SortOrderDirection = SortOrderDirection.ASC;
    public columnName: string = "displayName";

    // Pagination
    public pageSize: number = DefaultPageSize;
    public pageCount: number = 0;
    public pageNumber: number = 1;
    public totalCount: number = 0;

    constructor(complaintId: string, taskCountCallBack: (newCount: number) => void) {
        super(new TasksModel());
        this.complaintId = complaintId;
        this.taskCountCallBack = taskCountCallBack;
        this.setValidator(new TasksModelValidator());
        this.loadNoCompletedSelected();
        makeObservable(this, {
            pageSize: observable,
            pageNumber: observable,
            totalCount: observable,
            isProcessing: observable,
            showViewTaskModal: observable,
            filterByCompleted: observable,
            openViewTasksModal: action,
            getComplaintTaskDetailsById: action,
            getComplaintTaskListAsync: action,
            updateCompletedFiltering: action,
            completedSelectAll: action,
            completedSelectNone: action,
            getCompletedFilter: computed,
        });
    }

    public clear = () => {
        this.isProcessing = false;
        this.showViewTaskModal = false;
        this.orderBy = SortOrderDirection.ASC;
        this.columnName = "displayName";
        this.pageSize = 10;
        this.pageNumber = 1;
        this.totalCount = 0;
        this.tasksList.clear();
    };

    public openViewTasksModal = (Id: any) => {
        this.getComplaintTaskDetailsById(Id!);
        this.showViewTaskModal = true;
    };

    public get IsBusy(): boolean {
        return this.apiClient.IsBusy;
    }

    public closeViewTaskModal: ICommand = new RelayCommand(() => {
        this.showViewTaskModal = false;
    });

    public updateIsCompleteCommand: ICommand = new RelayCommand((value: boolean) => {
        this.model.isComplete = value;
    });

    public addEditCommand: ICommand = new RelayCommand(() => {
        let promise = this.upsertAsync();
        promise.then((kv) => {
            this.getComplaintTaskListAsync();
        });
    });

    //completed

    public get getCompletedFilter(): string[] {
        return this.filterByCompleted.slice();
    }

    public get getCompletedDropdown() {
        let retVal: KeyValuePair[] = [
            { key: true, text: "Completed" },
            { key: false, text: "Not Completed" },
        ];

        return retVal;
    }

    public updateCompletedFiltering = (values: string[]) => {
        this.filterByCompleted = values;
    };

    public completedSelectAll = () => {
        this.filterByCompleted = this.getCompletedDropdown.map((item) => {
            return item.key;
        });
    };

    public completedSelectNone = () => {
        this.filterByCompleted = [];
    };

    public loadNoCompletedSelected = () => {
        const noCompletedSelected: string[] = this.getCompletedDropdown.filter((item) => item.text === "Not Completed").map((item) => item.key);
        this.filterByCompleted = noCompletedSelected;
    };

    // End completed

    public upsertAsync = async (): Promise<any> => {
        let retVal: TasksModel = this.model;

        if (this.isProcessing === false && this.apiClient.IsBusy === false) {
            {
                let endpoint: ComplaintTasksUpsertEndpoint = new ComplaintTasksUpsertEndpoint();
                this.isProcessing = true;
                this.setValue("sourceId", this.complaintId);
                this.setValue("taskTypeId", SharmanTaskTypeEnum.ProjectQuoteComplaint);
                let _ = await this.apiClient.sendAsync(endpoint, this.model);
                if (this.apiClient.IsRequestSuccessful) {
                    runInAction(() => {
                        this.isProcessing = false;
                        let response: TasksModel = this.apiClient.Response();
                        this.model.fromResponse(response);
                        this.showViewTaskModal = false;
                        this.getComplaintTaskListAsync();
                    });
                } else {
                    runInAction(() => {
                        this.isProcessing = false;
                    });
                    this.errorStore.setHeaderText("Tasks Complaint");
                    this.errorStore.setButtonText("Close");
                    this.errorStore.setErrorMessageOne("Failed to upsert complaint tasks.  Please try again later.");
                    this.errorStore.setErrorMessageTwo(this.apiClient.ValidationMessage);
                    this.errorStore.setErrorModalOpen(true);
                }
            }
        }
        return retVal;
    };

    public async getComplaintTaskDetailsById(Id: Guid): Promise<void> {
        this.isProcessing = true;
        if (Id !== null) {
            await this.apiClient.sendAsync(new GetComplaintTaskDetailsByIdEndpoint(Id));
            if (this.apiClient.IsRequestSuccessful) {
                runInAction(() => {
                    let response = this.apiClient.Response();
                    this.model.fromResponse(response.sharmanTask);
                    this.isProcessing = false;
                });
            } else {
                runInAction(() => {
                    this.isProcessing = false;
                });
                this.errorStore.setHeaderText("Tasks Details");
                this.errorStore.setButtonText("Close");
                this.errorStore.setErrorMessageOne("Failed to get task details.  Please try again later.");
                this.errorStore.setErrorMessageTwo(this.apiClient.ValidationMessage);
                this.errorStore.setErrorModalOpen(true);
            }
        }
    }

    public async getComplaintTaskListAsync(): Promise<void> {
        if (this.apiClient.IsBusy === false && this.isProcessing === false) {
            this.isProcessing = true;
            const endpoint = new GetComplaintTaskListEndpoint();
            let _ = await this.apiClient.sendAsync(endpoint, this);
            if (this.apiClient.IsRequestSuccessful) {
                runInAction(() => {
                    let response = this.apiClient.Response<ComplaintTaskListItem[]>();
                    this.tasksList.replace(response.taskList);
                    this.taskCountCallBack(response.taskList.length);
                    this.isProcessing = false;
                    this.totalCount = response.taskCount;
                    this.pageCount = Math.ceil(this.totalCount / this.pageSize);
                });
            } else {
                runInAction(() => {
                    this.isProcessing = false;
                });
                this.errorStore.setHeaderText("Tasks List");
                this.errorStore.setButtonText("Close");
                this.errorStore.setErrorMessageOne("Failed to get the task list.  Please try again later.");
                this.errorStore.setErrorMessageTwo(this.apiClient.ValidationMessage);
                this.errorStore.setErrorModalOpen(true);
            }
        }
    }

    public changePageNumber = (pageNumber: number) => {
        this.pageNumber = pageNumber;
        this.getComplaintTaskListAsync();
    };
}
