import React, { useEffect, useState } from "react";
import { observer } from "mobx-react-lite";
import { Box, Button, ICommand, RelayCommand, formatDate } from "Application";
import { ModalGenericWithHeader } from "Components/ModalGenericWithHeader/ModalGenericWithHeader";
import { HRDashedGrey } from "Components/HorizontalRules";
import tick from "Assets/Icons/SmallTickCircleIcon.svg";
import ContactIcon from "Assets/Icons/ContactIcon.svg";
import { P } from "Components/Primitives/TextElements/TextElements";
import { CenteredTextCell, EmailWithIconCell, PhoneNumberWithIconCell } from "Utils/CellFormatComponents";
import { formatDateTime } from "Utils/Formats";
import FileIcon from "Assets/Icons/FileIcon.svg";
import { NoteBox } from "Components/CommonPageComponents";
import { ViewContactModalViewModel } from "./ViewContactModalViewModel";
import { ContactStatusTableBox } from "Models/Contacts/ContactStatus.style";
import { ContactStatusTypeHelper } from "Models/Contacts/ContactStatusTypeEnun";
import GreenTick from "Assets/Icons/TickGreen.svg";

interface IViewcontactModal {
    isOpen: boolean;
    closeModal: ICommand<any>;
    contactId?: string;
}

export const ViewContactModal: React.FC<IViewcontactModal> = observer((props) => {
    const [viewModel] = useState(new ViewContactModalViewModel());
    const tempCommand: ICommand = new RelayCommand(() => {});

    useEffect(() => {
        if (props.contactId) {
            viewModel.setContactId(props.contactId);
        }
        return () => {
            // Clean up after yourself
            //viewModel.clear();
        };
    }, []);

    return (
        <>
            <ModalGenericWithHeader
                isOpen={props.isOpen}
                headerText={viewModel.getValue("firstName") + " " + viewModel.getValue("lastName")}
                headerIcon={ContactIcon}
                stickyBar={
                    <>
                        <Button displayName=" Close" paletteColor="ButtonCancel" command={props.closeModal} icon={<img src={tick} />} boxShadow={true} />
                    </>
                }
                closeModalCommand={props.closeModal}
            >
                <Box width="658px" p="12px 0px">
                    <Box style={{ alignItems: "center", display: "flex", columnGap: "25px" }}>
                        <Box flexBox style={{ alignItems: "center" }}>
                            <P pr="10px">
                                <strong>Status: </strong>
                            </P>
                            <Box minWidth="100px">
                                <ContactStatusTableBox color={ContactStatusTypeHelper.GetColor(viewModel.getValue("status"))}>
                                    {ContactStatusTypeHelper.GetText(viewModel.getValue("status"))}
                                </ContactStatusTableBox>
                            </Box>
                        </Box>
                        <Box flexBox style={{ alignItems: "center" }}>
                            <P pr="10px">
                                <strong>Email: </strong>
                            </P>
                            <EmailWithIconCell value={viewModel.getValue("email")} />
                        </Box>
                    </Box>
                    <Box style={{ alignItems: "center", display: "flex", columnGap: "25px", marginTop: "10px" }}>
                        <Box flexBox style={{ alignItems: "center" }}>
                            <P pr="10px">
                                <strong>Phone: </strong>
                            </P>
                            <PhoneNumberWithIconCell value={viewModel.getValue("phone")} />
                        </Box>
                        <Box flexBox style={{ alignItems: "center" }}>
                            <P pr="10px">
                                <strong>Phone Additional: </strong>
                            </P>
                            <PhoneNumberWithIconCell value={viewModel.getValue("phone2")} />
                        </Box>
                    </Box>
                    <HRDashedGrey />
                    <Box style={{ alignItems: "center", display: "flex", columnGap: "25px" }}>
                        <Box flexBox style={{ alignItems: "center" }}>
                            <P pr="10px">
                                <strong>Plygene Trained: </strong>
                            </P>
                            {viewModel.getValue("datePlygeneTrained") === null && viewModel.getValue("plygeneTrained") === true ? (
                                <img src={GreenTick} />
                            ) : (
                                <CenteredTextCell
                                    greenText={true}
                                    value={viewModel.getValue("datePlygeneTrained") !== null ? formatDate(new Date(viewModel.getValue("datePlygeneTrained"))) : ""}
                                />
                            )}
                        </Box>
                        <Box flexBox style={{ alignItems: "center" }}>
                            <P pr="10px">
                                <strong>Coating Trained: </strong>
                            </P>
                            {viewModel.getValue("dateCoatingsTrained") === null && viewModel.getValue("coatingsTrained") === true ? (
                                <img src={GreenTick} />
                            ) : (
                                <CenteredTextCell
                                    greenText={true}
                                    value={viewModel.getValue("dateCoatingsTrained") !== null ? formatDate(new Date(viewModel.getValue("dateCoatingsTrained"))) : ""}
                                />
                            )}
                        </Box>
                        <Box flexBox style={{ alignItems: "center" }}>
                            <P pr="10px">
                                <strong>Plygene CPD: </strong>
                            </P>
                            {viewModel.getValue("datePlygeneCPD") === null && viewModel.getValue("plygeneCPD") === true ? (
                                <img src={GreenTick} />
                            ) : (
                                <CenteredTextCell
                                    greenText={true}
                                    value={viewModel.getValue("datePlygeneCPD") !== null ? formatDate(new Date(viewModel.getValue("datePlygeneCPD"))) : ""}
                                />
                            )}
                        </Box>
                        <Box flexBox style={{ alignItems: "center" }}>
                            <P pr="10px">
                                <strong>Coating CPD: </strong>
                            </P>
                            {viewModel.getValue("dateCoatingsCPD") === null && viewModel.getValue("coatingsCPD") === true ? (
                                <img src={GreenTick} />
                            ) : (
                                <CenteredTextCell
                                    greenText={true}
                                    value={viewModel.getValue("dateCoatingsCPD") !== null ? formatDate(new Date(viewModel.getValue("dateCoatingsCPD"))) : ""}
                                />
                            )}
                        </Box>
                    </Box>
                    <HRDashedGrey />
                    <P pb="15px">
                        <strong>Note </strong>
                    </P>
                    <P>{viewModel.getValue("note")}</P>
                    <P p="15px 0px">
                        <strong>Documents </strong>
                    </P>
                    {viewModel.uploadedDocuments.length === 0 && <P>There are currently no documents for this contact</P>}
                    <Box grid dc="1fr" columnGap="15px">
                        {viewModel.uploadedDocuments.map((document) => {
                            return (
                                <NoteBox key={document.id}>
                                    <Box flexBox>
                                        <img src={FileIcon} height="15.3px"></img>
                                        <P pl="10px">{document.fileName}</P>
                                    </Box>
                                    <Box className="documentType">
                                        <P>{document.docType}</P>
                                    </Box>
                                </NoteBox>
                            );
                        })}
                    </Box>
                    <HRDashedGrey />
                    <Box flexBox>
                        <P pr="10px">
                            <strong>Created:</strong>
                        </P>
                        <P>{viewModel.getValue("createdDate") !== null ? formatDateTime(new Date(viewModel.getValue("createdDate"))) : ""}</P>
                    </Box>
                </Box>
            </ModalGenericWithHeader>
        </>
    );
});
