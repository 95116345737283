import React, { useEffect, useState } from "react";
import { observer } from "mobx-react-lite";
import { ICommand, RelayCommand } from "Application";
import { createColumnHelper } from "@tanstack/react-table";
import { CenteredCell, CenteredTextCell } from "Utils/CellFormatComponents";
import { formatDate } from "Utils/Formats";
import { PaginationDirection, SharmansTable, SortOrderDirection } from "Components/Primitives/DataTable/SharmansTable";
import { ProjectStatusBox } from "Views/Work/Common/ProjectsList.styles";
import { AreYouSureModal } from "Components/AreYouSureModal/AreYouSure";
import { MultiEditSelect } from "Components/Primitives/Selects/MultiEditSelect";
import { KeyValuePair, formatCurrency } from "@shoothill/core";
import { useNavigate } from "react-router";
import { AppUrlIndex, AppUrls } from "AppUrls";
import { FilteringBottomSectionBox } from "Components/FilteringBottomSectionBox";
import { H2 } from "Components/Primitives/TextElements/TextElements";
import { DeleteButton } from "Components/Primitives/Buttons/DeleteButton";
import { MultiSiteProjectViewModel } from "./MultiSiteProjectViewModel";

export interface IMultiSiteProjectsTableProps {
    propertyId: string;
    multiSiteProjectCountCallBack: (newCount: number) => void;
}

export const MultiSiteProjectsTable: React.FC<IMultiSiteProjectsTableProps> = observer((props) => {
    const [viewModel] = useState(() => new MultiSiteProjectViewModel(props.propertyId!, props.multiSiteProjectCountCallBack));
    const [isFiltering, setIsFiltering] = useState(false);
    let navigate = useNavigate();
    let debounceTimer: NodeJS.Timeout | null = null;
    const columnHelper = createColumnHelper<any>();

    useEffect(() => {
        if (props?.propertyId) {
            viewModel.getPropertyLinkedMultiSite();
        }
        return () => {
            // Clean up after yourself
            viewModel.clear();
        };
    }, []);

    useEffect(() => {
        if (isFiltering) {
            debounceFilter();
        }
    }, [isFiltering, viewModel]);

    const columns = [
        columnHelper.accessor("number", {
            header: () => "Prj no.",
            cell: (info) => info.renderValue(),
        }),

        columnHelper.accessor("projectName", {
            size: 600,
            header: () => "Project name",
            cell: (info) => info.renderValue(),
        }),

        columnHelper.accessor("createdDate", {
            header: () => "Created date",
            cell: (info) => <CenteredTextCell value={formatDate(new Date(info.renderValue()))} />,
        }),

        columnHelper.accessor("noOfMasters", {
            header: () => "No. of masters",
            cell: (info) => info.renderValue(),
        }),

        columnHelper.accessor("netValue", {
            header: () => "Total net value",
            cell: (info) => {
                return formatCurrency(info.renderValue());
            },
        }),

        columnHelper.accessor("wonValue", {
            header: () => "Total won value",
            cell: (info) => {
                return formatCurrency(info.renderValue());
            },
        }),

        columnHelper.accessor("openValue", {
            header: () => "Total open value",
            cell: (info) => {
                return formatCurrency(info.renderValue());
            },
        }),

        columnHelper.accessor("lostValue", {
            header: () => "Total lost value",
            cell: (info) => {
                return formatCurrency(info.renderValue());
            },
        }),

        columnHelper.accessor("status", {
            header: () => "Status",
            cell: (info) => {
                const status = viewModel.statusDropdownItems.find((a: any) => a.statusId === info.getValue());
                if (status !== undefined) {
                    return (
                        <ProjectStatusBox className={status.className}>
                            <div>{status.displayName}</div>
                        </ProjectStatusBox>
                    );
                } else {
                    return "";
                }
            },
        }),

        columnHelper.accessor("canDelete", {
            header: () => "Actions",
            cell: (info) => {
                if (info.getValue() === true) {
                    return (
                        <CenteredCell>
                            <DeleteButton id={info.row.original.id.toString()} onClick={viewModel.deletePropertyCheck} />
                        </CenteredCell>
                    );
                } else {
                    return "";
                }
            },
            enableSorting: false,
        }),
    ];

    const updateSorting = (columnName: string, orderBy: SortOrderDirection) => {
        viewModel.columnName = columnName;
        viewModel.orderBy = orderBy;
        viewModel.getPropertyLinkedMultiSite();
    };

    const onChangePage = (change: PaginationDirection) => {
        switch (change) {
            case PaginationDirection.NEXT:
                viewModel.pageNumber < viewModel.pageCount ? viewModel.pageNumber++ : null;
                viewModel.getPropertyLinkedMultiSite();
                break;
            case PaginationDirection.BACK:
                viewModel.pageNumber > 1 ? viewModel.pageNumber-- : null;
                viewModel.getPropertyLinkedMultiSite();
                break;
            case PaginationDirection.START:
                viewModel.pageNumber = 1;
                viewModel.getPropertyLinkedMultiSite();
                break;
            case PaginationDirection.END:
                viewModel.pageNumber = viewModel.pageCount;
                viewModel.getPropertyLinkedMultiSite();
                break;
            default:
        }
    };

    const onChangeRowPerPage = (rowsPerPage: number) => {
        viewModel.pageSize = rowsPerPage;
        viewModel.pageNumber = 1;
        viewModel.getPropertyLinkedMultiSite();
    };

    const debounceFilter = () => {
        if (debounceTimer) {
            clearTimeout(debounceTimer);
        }
        debounceTimer = setTimeout(() => {
            viewModel.getPropertyLinkedMultiSite();
            setIsFiltering(false);
        }, 1500);
    };

    const updateStatusFiltering: ICommand = new RelayCommand((values: KeyValuePair[]) => {
        viewModel.updateStatusFiltering(
            values.map((item) => {
                return item.key;
            }),
        );
        viewModel.pageNumber = 1;
        debounceFilter();
    });

    const statusSelectAll = (): void => {
        viewModel.statusSelectAll();
        viewModel.getPropertyLinkedMultiSite();
    };

    const statusSelectNone = (): void => {
        viewModel.statusSelectNone();
        viewModel.getPropertyLinkedMultiSite();
    };

    const FilteringBottomSection = (
        <FilteringBottomSectionBox grid dc={"1fr 1fr 1fr 1fr 1fr"}>
            <MultiEditSelect
                command={updateStatusFiltering}
                displayName="Filter by status"
                options={viewModel.getStatuses}
                value={() => viewModel.getStatusFilter}
                showSelectAllNone={true}
                selectAll={statusSelectAll}
                selectNone={statusSelectNone}
                isDisabled={viewModel.isProcessing}
            />
        </FilteringBottomSectionBox>
    );

    function onRowClick(rowId: string | number): void {
        const path: string = AppUrls.Client.Work.Multi.View[AppUrlIndex.Long].replace(":multisiteprojectid", rowId.toString());
        navigate(path);
    }

    return (
        <>
            {viewModel.areYouSureModelOpen && (
                <AreYouSureModal
                    title="Delete Multi Site Project"
                    message="Are you sure you want to delete this multi site project?"
                    isOpen={viewModel.areYouSureModelOpen}
                    onCancel={viewModel.closeAreYouSureModal}
                    onConfirm={viewModel.confirmDeleteProperty}
                />
            )}

            <H2 pt="25px" p={"28px 0 10px 0"}>
                Multi site projects linked to this property
            </H2>

            <SharmansTable
                columns={columns}
                data={viewModel.getLinkedMultiSiteList}
                tableHeaderColour="#F1D6D6"
                tableFilteringColour="#F9EEEE"
                filteringElementsBottomSection={FilteringBottomSection}
                showPagination={true}
                onSortChange={updateSorting}
                totalRowCount={viewModel.totalCount}
                onChangePage={onChangePage}
                onChangePageNumber={viewModel.changePageNumber}
                onChangeRowPerPage={onChangeRowPerPage}
                totalPageCount={viewModel.pageCount}
                pageNumber={viewModel.pageNumber}
                pageSize={viewModel.pageSize}
                clearFilteringCommand={viewModel.clearFiltering}
                onRowClick={onRowClick}
            />
        </>
    );
});
