import React, { useEffect, useState } from "react";
import { observer } from "mobx-react-lite";
import { Box, Button, CheckBox, EditSelect, ICommand, Input, RelayCommand, ThemedLoader } from "Application";
import { BaseWrapper } from "Components/BaseWrapper";
import { SubHeaderView, subHeadingButton } from "Views/Header/SubHeader";
import { useNavigate, useParams, useMatch } from "react-router";
import { AppUrlIndex, AppUrls } from "AppUrls";
import { H2 } from "Components/Primitives/TextElements/TextElements";
import { CreateCustomerFooter } from "./NewCustomer.styles";
import { NewCustomerViewModel } from "./NewCustomerViewModel";
import { SpanFourBox, SpanTwoBox } from "Components/SpanBoxes";
import { HRDashedGrey, HRSolidGrey } from "Components/HorizontalRules";
import { AddressGeneric } from "Components/AddressGeneric/AddressGeneric";

import tick from "Assets/Icons/SmallTickCircleIcon.svg";
import { CustomerNotesView } from "./CustomerNotes/CustomerNotes";
import { CustomerDocumentsView } from "./CustomerDocuments/CustomerDocumentsView";
import { CustomerContactsView } from "./CustomerContacts/CustomerContactsView";
import { GridWrapper } from "Components/CommonPageComponents";
import { ModalType } from "Application/Models/Domain/AddEdditModalType";
import { SubmitType } from "Application/Models/Domain/Forms";
import { IReturnButtonDetails } from "Models/Routing/IReturnButtonDetails";
import { container } from "tsyringe";
import { CustomerStore } from "Stores/Domain";
import { CustomerOptionMatrixModel } from "Models/Customer/CustomerOptionMatrix";
import { BackButtonNavigation } from "Components/BackButtonNavigation";

interface ICustomer {
    addOrEdit: ModalType;
}

export const NewEditCustomerView: React.FC<ICustomer> = observer(({ addOrEdit }) => {
    const [viewModel] = useState(() => new NewCustomerViewModel(addOrEdit));
    const customerStore = container.resolve(CustomerStore);
    const [customerMatrix, setCustomerMatrix] = useState<CustomerOptionMatrixModel | undefined>(undefined);

    const [backToTitle, setBackToTitle] = useState<IReturnButtonDetails>({ text: "Back to customers", url: AppUrls.Client.Directory.Default[AppUrlIndex.Long] });
    const navigate = useNavigate();
    let params = useParams();

    const matches = useMatch(AppUrls.Client.Directory.CustomersView[AppUrlIndex.Long] + "/*");
    let matchedPath = matches == null ? useMatch(AppUrls.Client.Directory.CustomersViewEdit[AppUrlIndex.Long] + "/*") : matches;
    matchedPath = matchedPath == null ? useMatch(AppUrls.Client.Work.Quotes.CustomersViewEdit[AppUrlIndex.Long] + "/*") : matches;
    // Can't use this for more than two paths..  too long winded but I'm going to do it anyway!

    useEffect(() => {
        let promise = viewModel.getRelated();

        promise.then(() => {
            if (addOrEdit === ModalType.Edit) {
                viewModel.getCustomerDetails(params.customerId);
                viewModel.setShowAttachmentsSectionTrue();
            }

            if (customerStore.matrixOptions !== undefined) {
                setCustomerMatrix(customerStore.matrixOptions.find((x) => x.id === viewModel.model.customerTypeId));
            }
        });

        if (matchedPath !== null) {
            switch (matchedPath?.pattern.path) {
                case AppUrls.Client.Directory.ContactCustomerView[AppUrlIndex.Long] + "/*": {
                    setBackToTitle({ text: "Back to contacts", url: AppUrls.Client.Directory.Contacts[AppUrlIndex.Long] });
                    break;
                }
                case AppUrls.Client.Work.Quotes.CustomersViewEdit[AppUrlIndex.Long] + "/*": {
                    const projectQuoteId: string | undefined = matchedPath.params.projectquoteid;
                    const customerId: string | undefined = matchedPath.params.customerId;
                    if (projectQuoteId !== undefined && customerId !== undefined) {
                        setBackToTitle({
                            text: "Back to customer",
                            url:
                                AppUrls.Client.Work.Quotes.CustomersView[AppUrlIndex.Long].replace(":projectquoteid", projectQuoteId).replace(":customerId", params.customerId!) +
                                "/details",
                        });
                    }
                    break;
                }

                case AppUrls.Client.Directory.CustomersViewEdit[AppUrlIndex.Long] + "/*":
                default: {
                    setBackToTitle({
                        text: "Back to customer",
                        url: AppUrls.Client.Directory.CustomersView[AppUrlIndex.Long].replace(":customerId", params.customerId!) + "/details",
                    });
                    break;
                }
            }
        }

        return () => {
            // Clean up after yourself
            //    viewModel.clear();
        };
    }, []);

    useEffect(() => {
        if (customerStore.matrixOptions !== undefined) {
            setCustomerMatrix(customerStore.matrixOptions.find((x) => x.id === viewModel.model.customerTypeId));
        }
    }, [customerStore.matrixOptions, viewModel.model.customerTypeId]);

    const handleSubHeadingButtonClicks: ICommand = new RelayCommand((value: subHeadingButton) => {
        switch (value) {
            case subHeadingButton.Back:
                // navigate(backToTitle.url);
                navigate(BackButtonNavigation(AppUrls.Client.Directory.Default[AppUrlIndex.Long]));
                break;

            default:
            // code block
        }
    });

    const documentCountCallBack = (newCount: number): void => {
        // Nothing to update viewModel.setValue("documentCount", newCount);
    };

    const noteCountCallBack = (newCount: number): void => {
        // Nothing to update viewModel.setValue("noteCount", newCount);
    };

    const contactCountCallBack = (newCount: number): void => {
        // Nothing to update viewModel.setValue("contactCount", newCount);
    };

    return (
        <>
            <SubHeaderView buttonClick={handleSubHeadingButtonClicks} backButtonText={backToTitle.text} />

            <BaseWrapper>
                {viewModel.isProcessing ? (
                    <ThemedLoader isOpen={true} />
                ) : (
                    <>
                        <H2 pt="40px" pb="10px">
                            {addOrEdit === "edit" ? "Edit customer" : "New customer"}
                        </H2>
                        <GridWrapper mb="12px">
                            <SpanFourBox>
                                <Input
                                    displayName="Company name *"
                                    placeholder="Name of company"
                                    command={viewModel.setCompanyName}
                                    value={() => viewModel.getValue("name")}
                                    validationMessage={() => viewModel.getError("name")}
                                    autoFill={false}
                                />
                            </SpanFourBox>

                            <SpanTwoBox>
                                <EditSelect
                                    displayName="Type *"
                                    command={viewModel.setCustomerType}
                                    options={viewModel.getTypesForDropdown}
                                    value={() => viewModel.getValue("customerTypeId")}
                                    isDisabled={addOrEdit === ModalType.Edit || viewModel.showAttachmentsSection}
                                />
                            </SpanTwoBox>

                            {customerMatrix !== undefined && customerMatrix.hasSageRef === true && (
                                <>
                                    <Input
                                        displayName="Sage ref"
                                        validationMessage={() => viewModel.getError("sageRef")}
                                        placeholder="Sage ref"
                                        command={viewModel.setSageRef}
                                        value={() => viewModel.getSageRef}
                                        autoFill={false}
                                    />
                                    <Box></Box>
                                </>
                            )}

                            {customerMatrix !== undefined && customerMatrix.hasTSM === true && (
                                <SpanTwoBox>
                                    <EditSelect
                                        displayName="Lead TSM *"
                                        command={viewModel.setLeadTSM}
                                        options={viewModel.getTsmsForDropdown}
                                        value={() => viewModel.getValue("leadTsmId")}
                                        validationMessage={() => viewModel.getError("leadTsmId")}
                                        errorIconRight={false}
                                    />
                                </SpanTwoBox>
                            )}

                            {customerMatrix !== undefined && customerMatrix.hasBDM === true && (
                                <SpanTwoBox>
                                    <EditSelect
                                        displayName="BDM *"
                                        command={viewModel.setBDM}
                                        options={viewModel.getBdmsForDropdown}
                                        value={() => viewModel.getValue("bdmId")}
                                        validationMessage={() => viewModel.getError("bdmId")}
                                        errorIconRight={false}
                                    />
                                </SpanTwoBox>
                            )}

                            {customerMatrix !== undefined && customerMatrix.hasEORINumber === true && (
                                <Input
                                    displayName="EORI number"
                                    validationMessage={() => viewModel.getError("eORINumber")}
                                    placeholder="EORI number"
                                    command={viewModel.setEORINumber}
                                    value={() => viewModel.getEORINumber}
                                    autoFill={false}
                                />
                            )}

                            {customerMatrix !== undefined && customerMatrix.hasTSM === true && (
                                <Input
                                    displayName="TSS number"
                                    validationMessage={() => viewModel.getError("tSSNumber")}
                                    placeholder="TSS number"
                                    command={viewModel.setTSSNumber}
                                    value={() => viewModel.getTssNumber}
                                    autoFill={false}
                                />
                            )}
                            {customerMatrix !== undefined && customerMatrix.hasReferral === true && (
                                <div style={{ marginTop: "33px" }}>
                                    <CheckBox
                                        displayName={"Referral"}
                                        command={viewModel.setReferral}
                                        value={() => viewModel.getValue("referral")}
                                        paletteColor={"secondary"}
                                        validationMessage={() => viewModel.getError("referral")}
                                    />
                                </div>
                            )}
                        </GridWrapper>

                        <HRDashedGrey />

                        <AddressGeneric addressViewModel={viewModel.addressViewModel} showAddressFields={addOrEdit == "edit"} />

                        <HRDashedGrey />
                        <GridWrapper p="12px 0px">
                            <SpanTwoBox>
                                <Input
                                    displayName="Company contact phone number *"
                                    validationMessage={() => viewModel.getError("phone")}
                                    command={viewModel.setCompanyContactPhone}
                                    value={() => viewModel.getValue("phone")}
                                    autoFill={false}
                                />
                            </SpanTwoBox>
                            <SpanTwoBox>
                                <Input
                                    displayName="Additional phone number information"
                                    validationMessage={() => viewModel.getError("phone2")}
                                    command={viewModel.setCompanyContactPhone2}
                                    value={() => viewModel.getValue("phone2")}
                                    autoFill={false}
                                />
                            </SpanTwoBox>
                        </GridWrapper>
                        <GridWrapper p="12px 0px">
                            <SpanTwoBox>
                                <Input
                                    displayName="Company contact email address *"
                                    validationMessage={() => viewModel.getError("email")}
                                    command={viewModel.setCompanyContactEmail}
                                    value={() => viewModel.getValue("email")}
                                    autoFill={false}
                                />
                            </SpanTwoBox>
                            <SpanTwoBox>
                                <Input
                                    displayName="Company website address"
                                    command={viewModel.setCompanyWebsiteAddress}
                                    value={() => viewModel.getValue("websiteAddress")}
                                    autoFill={false}
                                />
                            </SpanTwoBox>
                        </GridWrapper>

                        <HRSolidGrey />

                        {viewModel.showAttachmentsSection && (
                            <>
                                <CustomerNotesView
                                    notes={viewModel.customerNotes}
                                    customerId={viewModel.getValue("id")}
                                    documentTypes={viewModel.documentTypes}
                                    noteCountCallBack={noteCountCallBack}
                                />

                                <HRSolidGrey />

                                <CustomerDocumentsView
                                    uploadedDocuments={viewModel.uploadedDocuments}
                                    customerId={viewModel.getValue("id")}
                                    documentTypes={viewModel.documentTypes}
                                    documentCountCallBack={documentCountCallBack}
                                />

                                <HRSolidGrey />

                                <CustomerContactsView customerId={viewModel.getValue("id")} parentViewModel={viewModel} contactCountCallBack={contactCountCallBack} />
                            </>
                        )}
                    </>
                )}
            </BaseWrapper>

            <CreateCustomerFooter>
                <Button
                    displayName=" Save & Exit"
                    style={{ marginRight: "10px" }}
                    command={viewModel.submitNewCustomer}
                    value={SubmitType.SaveAndExit}
                    icon={<img src={tick} />}
                    paletteColor="ButtonGreen"
                    boxShadow={true}
                />
                {addOrEdit === ModalType.Add && (
                    <Button
                        displayName=" Save & Continue"
                        command={viewModel.submitNewCustomer}
                        value={SubmitType.SaveAndContinue}
                        paletteColor="ButtonGreen"
                        icon={<img src={tick} />}
                        boxShadow={true}
                    />
                )}
            </CreateCustomerFooter>
        </>
    );
});
