import React, { useEffect, useState } from "react";
import { observer } from "mobx-react-lite";
import { Box, ICommand, RelayCommand, ThemedLoader } from "Application";
import { ComplaintsViewModel } from "./ComplaintsViewModel";
import { H3, H5, P } from "Components/Primitives/TextElements/TextElements";
import { BaseWrapperNoMargin, BaseWrapperNoMarginTop } from "Components/BaseWrapper";
import { SubHeaderView, subHeadingButton } from "Views/Header/SubHeader";
import { AppUrlIndex, AppUrls } from "AppUrls";
import { Route, Routes, useNavigate, useParams } from "react-router";
import { TabsWrapper } from "Components/TabsWrapper";
import { NavLink } from "react-router-dom";
import clsx from "clsx";
import { ComplaintDetailsView } from "./Details/ComplaintDetailsView";
import { Heading, ViewTopSection } from "../ComplaintsList.styles";
import { ButtonNextPrev } from "Components/Primitives/Buttons/ButtonNextPrev";
import { CorrectiveActionView } from "./CorrectiveAction/CorrectiveActionView";
import { PreventativeActionView } from "./PreventativeAction/PreventativeActionView";
import { TasksView } from "./Tasks/TasksView";
import { DocumentsView } from "./Documents/DocumentsView";
import { ComplaintTypeEnum, ComplaintTypeEnumHelpers } from "Models/Complaints/ComplaintTypeEnum";

export const ComplaintsView: React.FC = observer(() => {
    const [viewModel] = useState(() => new ComplaintsViewModel());
    const navigate = useNavigate();
    const params = useParams();

    useEffect(() => {
        if (params.complaintid) {
            viewModel.loadComplaint(params.complaintid);
        }
        return () => {
            // Clean up after yourself
            viewModel.clear();
        };
    }, []);

    const handleSubHeadingButtonClicks: ICommand = new RelayCommand((value: subHeadingButton) => {
        switch (value) {
            case subHeadingButton.Back:
                navigate(AppUrls.Client.Aftersales.Complaints[AppUrlIndex.Long] + "/list/complaints");
                break;
            case subHeadingButton.Edit:
                if (viewModel.getValue("complaintType") === ComplaintTypeEnum.ProjectQuote) {
                    navigate(
                        AppUrls.Client.Aftersales.AddEdit[AppUrlIndex.Long]
                            .replace(":complainttype", ComplaintTypeEnumHelpers.geturlComponent(ComplaintTypeEnum.ProjectQuote))
                            .replace(":projectid", viewModel.getValue("projectId"))
                            .replace(":complaintid", params.complaintid!) + "/aftersales",
                    );
                } else if (viewModel.getValue("complaintType") === ComplaintTypeEnum.MasterProject) {
                    navigate(
                        AppUrls.Client.Aftersales.AddEdit[AppUrlIndex.Long]
                            .replace(":complainttype", ComplaintTypeEnumHelpers.geturlComponent(viewModel.getValue("complaintType")))
                            .replace(":projectid", viewModel.getValue("projectId"))
                            .replace(":complaintid", params.complaintid!) + "/aftersales",
                    );
                } else {
                }

                break;

            default:
        }
    });

    const correctiveActionCountCallBack = (newCount: number): void => {
        viewModel.setValue("correctiveActionCount", newCount);
    };

    const preventativeActionCountCallBack = (newCount: number): void => {
        viewModel.setValue("preventativeActionCount", newCount);
    };

    const taskCountCallBack = (newCount: number): void => {
        viewModel.setValue("taskCount", newCount);
    };

    const documentCountCallBack = (newCount: number): void => {
        viewModel.setValue("documentCount", newCount);
    };

    return (
        <>
            {viewModel.isProcessing ? (
                <ThemedLoader isOpen={true} spinnerText="Searching..." delayBeforeOpen={200} />
            ) : (
                <>
                    <SubHeaderView buttonClick={handleSubHeadingButtonClicks} backButtonText="Back to Complaint List" editButtonText="Edit complaint" />

                    <BaseWrapperNoMargin>
                        <ViewTopSection p="50px 10px 0px 10px">
                            <Box>
                                <Heading display={"flex"} alignItems={"center"} gap={"20px 40px"} style={{ gap: "20px 40px" }}>
                                    <H3 p="0" style={{ maxWidth: "1300px" }}>
                                        <strong>Site address: </strong> {viewModel.getValue("siteAddress")}
                                    </H3>
                                </Heading>
                                <Box flexBox mt={1}>
                                    <P>
                                        <strong>Reference id: </strong> {viewModel.getValue("referenceId")}
                                    </P>
                                    <P pl="20px">
                                        <strong>Order number: </strong> {viewModel.getValue("orderNumber")}
                                    </P>
                                    <P pl="20px">
                                        <strong>Order Value: </strong> {viewModel.getValue("orderValue")}
                                    </P>
                                </Box>
                            </Box>
                            <Box flexBox style={{ alignItems: "flex-end" }}>
                                <Box pt="10px">
                                    <ButtonNextPrev nextCommand={viewModel.nextComplaintCommand} prevCommand={viewModel.prevComplaintCommand} />
                                    <P pt="20px" style={{ textAlign: "center" }}>
                                        {viewModel.allComplaints.indexOf(viewModel.model.id) + 1} of {viewModel.allComplaints.length} Selected
                                    </P>
                                </Box>
                            </Box>
                        </ViewTopSection>
                        <TabsWrapper>
                            <NavLink to={"details"} className={(navData) => clsx("root", navData.isActive ? "current" : "default")}>
                                <H5>Details</H5>
                            </NavLink>
                            <NavLink to={"correctiveaction"} className={(navData) => clsx("root", navData.isActive ? "current" : "default long")}>
                                <H5>Corrective Action ({viewModel.getValue("correctiveActionCount")})</H5>
                            </NavLink>
                            <NavLink to={"preventativeaction"} className={(navData) => clsx("root", navData.isActive ? "current" : "default long")}>
                                <H5>Preventative Action ({viewModel.getValue("preventativeActionCount")})</H5>
                            </NavLink>
                            <NavLink to={"tasks"} className={(navData) => clsx("root", navData.isActive ? "current" : "default")}>
                                <H5>Tasks ({viewModel.getValue("taskCount")})</H5>
                            </NavLink>
                            <NavLink to={"documents"} className={(navData) => clsx("root", navData.isActive ? "current" : "default")}>
                                <H5>Documents ({viewModel.getValue("documentCount")})</H5>
                            </NavLink>
                        </TabsWrapper>

                        <BaseWrapperNoMarginTop>
                            <Routes>
                                <Route path={"details"} element={<ComplaintDetailsView parentViewModel={viewModel} />} />
                                <Route path={"correctiveaction"} element={<CorrectiveActionView correctiveActionCountCallBack={correctiveActionCountCallBack} />} />
                                <Route path={"preventativeaction"} element={<PreventativeActionView preventativeActionCountCallBack={preventativeActionCountCallBack} />} />
                                <Route path={"tasks"} element={<TasksView complaintId={params.complaintid!} taskCountCallBack={taskCountCallBack} />} />
                                <Route
                                    path={"documents"}
                                    element={
                                        <DocumentsView
                                            documentCountCallBack={documentCountCallBack}
                                            complaintId={viewModel.getValue("id")}
                                            complaintType={viewModel.getValue("complaintType")}
                                            documentTypes={viewModel.getValue("documentTypes")}
                                        />
                                    }
                                />
                            </Routes>
                        </BaseWrapperNoMarginTop>
                    </BaseWrapperNoMargin>
                </>
            )}
        </>
    );
});
