import { getHistory } from "@shoothill/core";
import { DomainStore } from "Stores/Domain/DomainStore";
import { useNavigate, useLocation } from "react-router-dom";
import { container } from "tsyringe";
const domainStore = container.resolve(DomainStore);

export const BackButtonNavigation = (defaultPath: string) => {
    let retVal = "";

    if (domainStore.routeHistory.length > 1) {
        domainStore.popLastHistoryRoute();
        retVal = domainStore.routeHistory[domainStore.routeHistory.length - 1];
    } else {
        retVal = defaultPath;
    }
    return retVal;
};

export const GetBackButtonText = (defaultText: string) => {
    let retVal = "";

    if (domainStore.routeHistory.length > 1) {
        let previousPath = domainStore.routeHistory[domainStore.routeHistory.length - 2];

        if (previousPath.includes("/directory/customers")) {
            retVal = GetCustomersButtonText(previousPath);
        } else if (previousPath.includes("/directory/properties")) {
            retVal = GetPropertiesButtonText(previousPath);
        } else if (previousPath.includes("/work/master")) {
            retVal = GetMasterButtonText(previousPath);
        } else if (previousPath.includes("/work/multi")) {
            retVal = GetMultiButtonText(previousPath);
        } else if (previousPath.includes("/work/quotes")) {
            retVal = GetProjectQuoteButtonText(previousPath);
        } else if (previousPath.includes("/tsm")) {
            retVal = " to TSM";
        } else if (previousPath.includes("/directory/contacts")) {
            retVal = " to contacts";
        }
    } else {
        retVal = defaultText;
    }

    return retVal;
};

const GetCustomersButtonText = (path: string) => {
    let retVal = " to customers";

    if (path.includes("/directory/customers/view")) {
        retVal = " to customer";
    }

    return retVal;
};

// Contacts? Probably don't need one of these functions

const GetPropertiesButtonText = (path: string) => {
    let retVal = " to properties";

    if (path.includes("/directory/properties/view")) {
        retVal = " to property";
    }

    return retVal;
};

const GetMultiButtonText = (path: string) => {
    let retVal = " to multi site projects";

    if (path.includes("/work/multi/view")) {
        retVal = " to multi site project";
    }

    return retVal;
};

const GetMasterButtonText = (path: string) => {
    let retVal = " to master projects";

    if (path.includes("/work/master/view")) {
        retVal = " to master project";
    }

    return retVal;
};

const GetProjectQuoteButtonText = (path: string) => {
    let retVal = " to project quotes";

    if (path.includes("/work/quotes/view")) {
        retVal = " to project quote";
    }

    return retVal;
};
