import React, { useEffect, useState } from "react";
import { observer } from "mobx-react-lite";
import { Box, Button, EditDate, EditSelect, ICommand, Input, RelayCommand } from "Application";
import { TSMListViewModel } from "Views/TSM/TSMListViewModel";
import { ModalGenericWithHeader } from "Components/ModalGenericWithHeader/ModalGenericWithHeader";
import { DateWrapperBox, TSMModalWrapper } from "../TSMModal.styles";
import Tick from "Assets/Icons/SmallTickCircleIcon.svg";
import Close from "Assets/Icons/RoundCloseIcon.svg";
import SiteVisitIcon from "Assets/Icons/SiteVisitIcon.svg";
import { TSMSiteVisitViewModel } from "./TSMSiteVisitModalViewModel";
import { P } from "Components/Primitives/TextElements/TextElements";
import { CoreStoreInstance } from "@shoothill/core";
import { DatePickerBox } from "Components/Primitives/DatePickerBox";

export interface IModalProps {
    closeModalCommand: ICommand<any>;
    isOpen: boolean;
    parentViewModel: TSMListViewModel;
}

export const TsmSiteVisitModal: React.FC<IModalProps> = observer((props) => {
    const [viewModel] = useState(() => new TSMSiteVisitViewModel(props.parentViewModel));
    useEffect(() => {
        if (props.parentViewModel.selectedRowId) {
            viewModel.loadDataFromParentViewModel(props.parentViewModel.selectedRowId);
            viewModel.LoadRelated(props.parentViewModel.selectedRowId);
        }
        return () => {
            // Clean up after yourself
            //    viewModel.clear();
        };
    }, []);

    // Close the modal after upserting the Site Visit Request

    const upsertSiteVisitRequest: ICommand = new RelayCommand(() => {
        if (viewModel.isModelValid()) {
            let promise = viewModel.upsertSiteVisitRequest();
            promise.then(() => {
                props.closeModalCommand.execute();
                CoreStoreInstance.HideInfoBar();
            });
        }
    });

    return (
        <>
            <ModalGenericWithHeader
                isOpen={props.isOpen}
                closeModalCommand={props.closeModalCommand}
                headerIcon={SiteVisitIcon}
                headerText={"Request Site Visit"}
                stickyBar={
                    <>
                        <Button displayName=" Request site visit" command={upsertSiteVisitRequest} icon={<img src={Tick} />} paletteColor="ButtonGreen" boxShadow={true} />
                        <Button displayName=" Close" icon={<img src={Close} />} command={props.closeModalCommand} />{" "}
                    </>
                }
                noBodyMargin={true}
            >
                <TSMModalWrapper p="15px 9px">
                    <DateWrapperBox p="15px 0px" maxWidth="300px" grid dc="1fr 1fr" style={{ alignItems: "end" }}>
                        <DatePickerBox>
                            <EditDate command={viewModel.setSiteVisitDate} value={viewModel.getValue("visitDateTime")} displayName="Site visit date" />
                        </DatePickerBox>
                        <P pb="12px">at 9:00 AM</P>
                    </DateWrapperBox>

                    <Box p="15px 0px">
                        <EditSelect
                            displayName="Type of visit*:"
                            command={viewModel.setTypeOfVisit}
                            options={viewModel.getVisitTypesForDropdown}
                            value={() => viewModel.getValue("visitTypeId")}
                            validationMessage={() => viewModel.getError("visitTypeId")}
                            errorIconRight={false}
                        />
                    </Box>

                    <Box p="15px 0px">
                        <EditSelect
                            displayName="Contact*:"
                            command={viewModel.setContact}
                            options={viewModel.getContactsForDropdown}
                            value={() => viewModel.getValue("contactId")}
                            validationMessage={() => viewModel.getError("contactId")}
                            errorIconRight={false}
                        />
                    </Box>

                    <Box p="15px 0px">
                        <Input displayName="Additional note:" command={viewModel.setNote} value={() => viewModel.getValue("note")} multiline={true} rows={6} />
                    </Box>
                </TSMModalWrapper>
            </ModalGenericWithHeader>
        </>
    );
});
