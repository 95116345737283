import { Tasks } from "./../../../../Tasks/Tasks";
import { IDocumentType } from "./../../../../../Models/Documents/DocumentType";
import { Logger } from "index";
import { Endpoint, Http } from "Application/Helpers/BaseEndpoint";
import { AppUrls } from "AppUrls";
import { ComplaintTypeEnum } from "Models/Complaints/ComplaintTypeEnum";

class GetDetailsForViewRequest {
    public id: Guid = "";
}

export class GetDetailsForViewResponse {
    id: Guid = "";
    siteAddress: string = "";
    referenceId: string = "";
    orderNumber: string = "";
    createdDate: string = "";
    installationCompany: string = "";
    projectName: string = "";
    complaintAssignee: string = "";
    reportedBy: string = "";
    nature: string = "";
    companyReportedBy: string = "";
    category: string = "";
    details: string = "";
    assessment: string = "";
    isResolved: boolean = false;

    correctiveActionCount: number = 0;
    preventativeActionCount: number = 0;
    taskCount: number = 0;
    documentCount: number = 0;

    allComplaintGuids: Guid[] = [];
    projectId: Guid = "";

    complaintType: ComplaintTypeEnum = ComplaintTypeEnum.ProjectQuote;
    documentTypes: IDocumentType[] = [];
}

export class GetDetailsForViewEndpoint extends Endpoint<GetDetailsForViewRequest, GetDetailsForViewResponse> {
    constructor(id: Guid) {
        super();
        this.verb(Http.Get);
        this.path(AppUrls.Server.AfterSales.Complaints.GetDetailsForView + `/${id}`);
    }

    public async HandleRequestAsync(model: any): Promise<any> {
        let request = new GetDetailsForViewRequest();
        return request;
    }

    public async HandleResponseAsync(response: GetDetailsForViewResponse): Promise<any> {
        Logger.logInformation("Response", response);

        //Return your model
        return response;
    }
}
