import styled from "@emotion/styled";
import { Box } from "Application";
import { BaseWrapper } from "Components/BaseWrapper";

export const ReportListWrapper = styled(BaseWrapper)``;

export const ReportWrapper = styled(Box)`
    padding: 0 15px 15px 15px;
    display: flex;
    flex: 1 0 0;
    flex-direction: column;
    justify-content: flex-start;
`;

export const ReportSection = styled(Box)`
    margin-top: -40px;
    min-height: 40px;
`;

export const ReportRow = styled(Box)`
    display: flex;
    flex: 1 0 0;
    flex-direction: row;
    justify-content: flex-start;
`;

export const ReportRadio = styled(Box)`
    max-width: 320px;
    label {
        font-size: 10px !important;
        display: flex !important;
        align-items: center;
    }
`;

export const ReportFilterWrapper = styled(Box)`
    display: flex;
    flex: 1 0 0;
    flex-direction: column;
    padding-top: 15px;
`;

export const ReportFilterRow = styled(Box)`
    display: flex;
    flex: 1 0 0;
    flex-direction: row;
    background-color: #ededed;
    padding: 15px;
`;

export const ReportFilterSearchRow = styled(Box)`
    display: grid;
    grid-template-columns: 3fr 3fr;
    margin-bottom: 20px;
`;

export const ReportDateWrapper = styled(Box)`
    max-height: 45px;
    display: grid;
    grid-template-columns: 3fr 3fr;
    column-gap: 15px;
    row-gap: 12px;
    margin-right: 30px;
`;

export const ReportTableDateWrapper = styled(Box)`
    margin-left: 30px;
    /* max-height: 45px; */
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
    column-gap: 15px;
    row-gap: 12px;
`;

export const ViewTopSection = styled(Box)`
    display: flex;
    justify-content: space-between;
`;

export const LeftDatePicker = styled(Box)`
    .drop-to-the-right .rdtPicker {
        right: auto;
        left: 0;
    }
`;

export const FilteringSectionBox = styled(Box)`
    width: 100%;
`;
