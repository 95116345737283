import React, { useEffect, useRef, useState } from "react";
// import mammoth from "mammoth";
import { renderAsync } from "docx-preview";
import styled from "@emotion/styled";
import { Box } from "Application";

interface DocxViewerProps {
    docxFile: File;
}

const DocxViewer: React.FC<DocxViewerProps> = ({ docxFile }) => {
    const [htmlContent, setHtmlContent] = useState<string | null>(null);

    // useEffect(() => {
    //     const convertDocxToHtml = async () => {
    //         try {
    //             const arrayBuffer = await fileToArrayBuffer(docxFile);
    //             const result = await mammoth.convertToHtml({ arrayBuffer });
    //             setHtmlContent(result.value);
    //         } catch (error) {
    //             console.error("Error converting DOCX to HTML:", error);
    //         }
    //     };

    //     convertDocxToHtml();
    // }, [docxFile]);

    // const fileToArrayBuffer = (file: File): Promise<ArrayBuffer> => {
    //     return new Promise((resolve, reject) => {
    //         const reader = new FileReader();
    //         reader.onload = () => {
    //             if (reader.result instanceof ArrayBuffer) {
    //                 resolve(reader.result);
    //             } else {
    //                 reject(new Error("Failed to read file as ArrayBuffer"));
    //             }
    //         };
    //         reader.onerror = reject;
    //         reader.readAsArrayBuffer(file);
    //     });
    // };

    const containerRef = useRef(null);

    useEffect(() => {
        // Create a sample Blob object (replace this with your actual Blob)
        const docData = new Blob([docxFile], { type: "text/html" });

        // Render the document asynchronously when the component mounts
        if (containerRef.current) {
            renderAsync(docData, containerRef.current)
                .then(() => console.log("docx: finished"))
                .catch((error) => console.error("docx: error", error));
        }
        // Clean up the Blob object when the component unmounts
        return () => URL.revokeObjectURL("");
    }, []); // Run this effect only once when the component mounts

    return (
        <>
            <div>
                <WordDocWrapper ref={containerRef}></WordDocWrapper>
            </div>
        </>
    );
    // <div>{htmlContent && <div dangerouslySetInnerHTML={{ __html: htmlContent }} />}</div>;
};

export default DocxViewer;

const WordDocWrapper = styled.div`
    * {
        z-index: 0 !important;
    }
`;
