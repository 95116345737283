import React, { useEffect, useState } from "react";
import { observer } from "mobx-react-lite";
import { Box, Button, CheckBox, EditSelect, ICommand, Input, RelayCommand } from "Application";
import { ModalGenericWithHeader } from "Components/ModalGenericWithHeader/ModalGenericWithHeader";
import tick from "Assets/Icons/SmallTickCircleIcon.svg";
import NewIcon from "Assets/Icons/newRoundButtonIcon.svg";
import { GridWrapper } from "Components/CommonPageComponents";
import { SpanFourBox, SpanTwoBox } from "Components/SpanBoxes";
import { HRDashedGrey } from "Components/HorizontalRules";
import { AddressGeneric } from "Components/AddressGeneric/AddressGeneric";
import { AddCustomerViewModel } from "./AddCustomerViewModel";
import { IComplaintAddEditViewModel } from "../AddEdit/IComplaintAddEditViewModel";
import { CoreStoreInstance } from "@shoothill/core";
import { useParams } from "react-router";
import { container } from "tsyringe";
import { CustomerStore } from "Stores/Domain";
import { CustomerOptionMatrixModel } from "Models/Customer/CustomerOptionMatrix";

interface IAddCustomerModalProps {
    isOpen: boolean;
    closeModal: ICommand<any>;
    viewModel: IComplaintAddEditViewModel;
}

export const AddCustomerModal: React.FC<IAddCustomerModalProps> = observer((props) => {
    const [viewModel] = useState(() => new AddCustomerViewModel());
    const customerStore = container.resolve(CustomerStore);
    const [customerMatrix, setCustomerMatrix] = useState<CustomerOptionMatrixModel | undefined>(undefined);

    let params = useParams();

    useEffect(() => {
        let promise = viewModel.getRelated();

        promise.then(() => {
            if (customerStore.matrixOptions !== undefined) {
                setCustomerMatrix(customerStore.matrixOptions.find((x) => x.id === viewModel.model.customerTypeId));
            }
        });
        return () => {};
    }, []);

    useEffect(() => {
        if (customerStore.matrixOptions !== undefined) {
            setCustomerMatrix(customerStore.matrixOptions.find((x) => x.id === viewModel.model.customerTypeId));
        }
    }, [customerStore.matrixOptions, viewModel.model.customerTypeId]);

    const submitComplaint: ICommand = new RelayCommand(() => {
        viewModel.addressViewModel.turnOnAddressFieldsValidation();

        const thisModelValid = viewModel.isModelValid();
        const addressModelValid = viewModel.addressViewModel.isModelValid();

        if (thisModelValid && addressModelValid) {
            viewModel.model.address = {
                postcode: viewModel.addressViewModel.getValue("postcode"),
                latitude: viewModel.addressViewModel.getValue("latitude"),
                longitude: viewModel.addressViewModel.getValue("longitude"),
                addressLineOne: viewModel.addressViewModel.getValue("addressLineOne"),
                addressLineTwo: viewModel.addressViewModel.getValue("addressLineTwo"),
                townCity: viewModel.addressViewModel.getValue("townCity"),
                county: viewModel.addressViewModel.getValue("county"),
                country: viewModel.addressViewModel.getValue("country"),
                countryId: viewModel.addressViewModel.getValue("countryId"),
            };

            let promise = viewModel.upsertCustomer();
            promise.then(() => {
                props.closeModal.execute();
                props.viewModel.setValue("reportingCompanyId", viewModel.model.id);
                props.viewModel.getComplaintRelatedForProject(params.projectid!);
                CoreStoreInstance.HideInfoBar();
            });
        } else if (addressModelValid == false) {
            viewModel.addressViewModel.setAddressFieldsPanelOpen();
        }
    });

    return (
        <>
            <ModalGenericWithHeader
                isOpen={props.isOpen}
                headerText={"Add Customer"}
                headerIcon={NewIcon}
                stickyBar={
                    <>
                        <Button displayName={"Save & exit"} paletteColor="ButtonGreen" icon={<img src={tick} />} mr="10px" boxShadow={true} command={submitComplaint} />

                        <Button displayName=" Cancel" paletteColor="ButtonCancel" command={props.closeModal} icon={<img src={tick} />} boxShadow={true} />
                    </>
                }
                closeModalCommand={props.closeModal}
            >
                <GridWrapper>
                    <SpanFourBox>
                        <Input
                            displayName="Company name *"
                            placeholder="Name of company"
                            command={viewModel.setCompanyName}
                            value={() => viewModel.getValue("name")}
                            validationMessage={() => viewModel.getError("name")}
                            autoFill={false}
                        />
                    </SpanFourBox>

                    <SpanTwoBox>
                        <EditSelect
                            displayName="Type *"
                            command={viewModel.setCustomerType}
                            options={viewModel.getTypesForDropdown}
                            value={() => viewModel.getValue("customerTypeId")}
                        />
                    </SpanTwoBox>

                    {customerMatrix !== undefined && customerMatrix.hasSageRef === true && (
                        <>
                            <Input
                                displayName="Sage ref"
                                validationMessage={() => viewModel.getError("sageRef")}
                                placeholder="Sage ref"
                                command={viewModel.setSageRef}
                                value={() => viewModel.getSageRef}
                                autoFill={false}
                            />

                            {/* Filler Box! */}
                            <Box />
                        </>
                    )}
                    {customerMatrix !== undefined && customerMatrix.hasTSM === true && (
                        <SpanTwoBox>
                            <EditSelect
                                displayName="Lead TSM *"
                                command={viewModel.setLeadTSM}
                                options={viewModel.getTsmsForDropdown}
                                value={() => viewModel.getValue("leadTsmId")}
                                validationMessage={() => viewModel.getError("leadTsmId")}
                            />
                        </SpanTwoBox>
                    )}
                    {customerMatrix !== undefined && customerMatrix.hasBDM === true && (
                        <SpanTwoBox>
                            <EditSelect
                                displayName="BDM *"
                                command={viewModel.setBDM}
                                options={viewModel.getBdmsForDropdown}
                                value={() => viewModel.getValue("bdmId")}
                                validationMessage={() => viewModel.getError("bdmId")}
                            />
                        </SpanTwoBox>
                    )}
                    {customerMatrix !== undefined && customerMatrix.hasEORINumber === true && (
                        <Input
                            displayName="EORI number"
                            validationMessage={() => viewModel.getError("eORINumber")}
                            placeholder="EORI number"
                            command={viewModel.setEORINumber}
                            value={() => viewModel.getEORINumber}
                            autoFill={false}
                        />
                    )}
                    {customerMatrix !== undefined && customerMatrix.hasTSSNumber === true && (
                        <Input
                            displayName="TSS number"
                            validationMessage={() => viewModel.getError("tSSNumber")}
                            placeholder="TSS number"
                            command={viewModel.setTSSNumber}
                            value={() => viewModel.getTssNumber}
                            autoFill={false}
                        />
                    )}
                    {customerMatrix !== undefined && customerMatrix.hasReferral === true && (
                        <div style={{ marginTop: "33px" }}>
                            <CheckBox
                                displayName={"Referral"}
                                command={viewModel.setReferral}
                                value={() => viewModel.getValue("referral")}
                                paletteColor={"secondary"}
                                validationMessage={() => viewModel.getError("referral")}
                            />
                        </div>
                    )}
                    {/* Filler Box! */}
                    <Box />
                </GridWrapper>

                <HRDashedGrey />

                <AddressGeneric addressViewModel={viewModel.addressViewModel} showAddressFields={false} />

                <HRDashedGrey />
                <GridWrapper p="12px 0px">
                    <SpanTwoBox>
                        <Input
                            displayName="Company contact phone number *"
                            validationMessage={() => viewModel.getError("phone")}
                            command={viewModel.setCompanyContactPhone}
                            value={() => viewModel.getValue("phone")}
                            autoFill={false}
                        />
                    </SpanTwoBox>
                    <SpanTwoBox>
                        <Input
                            displayName="Additional phone number or information"
                            validationMessage={() => viewModel.getError("phone2")}
                            command={viewModel.setCompanyContactPhone2}
                            value={() => viewModel.getValue("phone2")}
                            autoFill={false}
                        />
                    </SpanTwoBox>
                </GridWrapper>

                <GridWrapper p="12px 0px">
                    <SpanTwoBox>
                        <Input
                            displayName="Company contact email address *"
                            validationMessage={() => viewModel.getError("email")}
                            command={viewModel.setCompanyContactEmail}
                            value={() => viewModel.getValue("email")}
                            autoFill={false}
                        />
                    </SpanTwoBox>
                    <SpanTwoBox>
                        <Input
                            displayName="Company website address"
                            command={viewModel.setCompanyWebsiteAddress}
                            value={() => viewModel.getValue("websiteAddress")}
                            autoFill={false}
                        />
                    </SpanTwoBox>
                </GridWrapper>
            </ModalGenericWithHeader>
        </>
    );
});
