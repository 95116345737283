import styled from "@emotion/styled";
import { Box, theme } from "Application";

export const DatePickerBox = styled(Box)`
    td {
        border: 1px solid #d4d4d4;
        font-size: ${theme.fontStyles.tableRow.fontSize};
    }

    //Spacing between thead and tbody
    tbody:before {
        content: "@";
        display: block;
        line-height: 14px;
        text-indent: -99999px;
        border: none;
    }

    table,
    .divTable {
        width: fit-content;
        border-collapse: collapse;
        width: 100%;
        table-layout: auto;
    }

    table {
        width: 100% !important;
    }

    tbody > tr:nth-of-type(1) {
        border-top: 1px solid #d4d4d4;
    }

    .t-head {
        height: 40px;
    }

    .tr {
        display: flex;
    }

    .td {
    }

    tr,
    .tr {
        width: fit-content;
        height: 30px;
        cursor: pointer;
    }

    th,
    .th,
    td,
    .td {
        /* box-shadow: inset 0 0 0 1px lightgray; */
        padding: 6px;
    }

    th,
    .th {
        padding: 2px 8px;
        /* position: relative; */
        font-weight: 600;
        font-size: ${theme.fontStyles.datepickerTableHeader.fontSize};
        text-align: left;
        height: 30px;
        border-right: 1px solid white;
    }

    td,
    .td {
        height: 30px;
    }

    th {
        cursor: pointer;
        -webkit-user-select: none; /* Safari */
        -ms-user-select: none; /* IE 10 and IE 11 */
        user-select: none; /* Standard syntax */
    }

    .resizer {
        position: absolute;
        right: -1px;
        top: 0;
        height: 100%;
        width: 3px;
        background: transparent;
        cursor: col-resize;
        user-select: none;
        touch-action: none;
    }

    .resizer.isResizing {
        background: blue;
        opacity: 1;
    }

    @media (hover: hover) {
        .resizer {
            opacity: 0;
        }

        *:hover > .resizer {
            opacity: 1;
        }
    }

    .arrow-box {
        min-width: 15px;
        padding-left: 4px;
    }

    .title-wrapper {
        display: flex;
    }

    .action-cell {
        position: relative;
        display: flex;
        flex-direction: row;
        justify-content: space-evenly;
    }
`;
