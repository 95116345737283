import React, { useEffect, useState } from "react";
import { observer } from "mobx-react-lite";
import { MasterProjectComplaintAddEditViewModel } from "./MasterProjectComplaintAddEditViewModel";
import { ProjectQuoteComplaintAddEditViewModel } from "./ProjectQuoteComplaintAddEditViewModel";
import { ComplaintsAddEditWrapper } from "./ComplaintAddEdit.style";
import { useLocation, useNavigate, useParams } from "react-router";
import { Box, Button, CheckBox, EditDate, EditSelect, ICommand, Input, Label, RelayCommand, ThemedLoader, formatDate } from "Application";
import { SubHeaderView, subHeadingButton } from "Views/Header/SubHeader";
import { ComplaintTypeEnum, ComplaintTypeEnumHelpers } from "Models/Complaints/ComplaintTypeEnum";
import { AppUrlIndex, AppUrls } from "AppUrls";
import { ErrorStore } from "Stores/Domain/ErrorStore";
import { container } from "tsyringe";
import { IComplaintAddEditViewModel } from "./IComplaintAddEditViewModel";
import { H2 } from "Components/Primitives/TextElements/TextElements";
import { CreateCustomFooter, GridWrapper } from "Components/CommonPageComponents";
import { SpanFourBox, SpanTwoBox } from "Components/SpanBoxes";
import { SubmitType } from "Application/Models/Domain/Forms";
import tick from "Assets/Icons/SmallTickCircleIcon.svg";
import { AddCustomerModal } from "../Modals/AddCustomerModalView";
import { CoreStoreInstance } from "@shoothill/core";
import { DatePickerBox } from "Components/Primitives/DatePickerBox";
import { CenteredTextBox, PAddress } from "Components/AddressGeneric/AddressGeneric.style";
import { BackButtonNavigation } from "Components/BackButtonNavigation";
import styled from "@emotion/styled";

export const TextAreaBox = styled(Box)`
    textarea {
        width: calc(100% - 24px) !important;
        white-space: normal !important;
        overflow: auto;
    }
`;

export const CategoryTextBox = styled(Box)`
    .editselect__option {
        padding-left: 30px;
        position: relative;
        border-bottom: 1px solid #ccc;
        &:before {
            content: "";
            position: absolute;
            left: 15px;
            top: 16px;
            width: 5px;
            height: 1px;
            background-color: #000;
        }
        &.editselect__option--is-disabled {
            padding-left: 12px;
            background-color: rgb(237, 237, 237);
            &:before {
                content: unset;
            }
            p {
                color: #000;
            }
        }
    }
`;

export const ComplaintAddEditView: React.FC = observer(() => {
    const navigate = useNavigate();
    let params = useParams();
    let location = useLocation();
    let errorStore = container.resolve(ErrorStore);

    const [viewModel, setViewModel] = useState<IComplaintAddEditViewModel | undefined>(undefined);
    const [isEdit, setIsEdit] = useState(false);

    useEffect(() => {
        if (params.complainttype === undefined || params.projectid === undefined || params.complaintid === undefined) {
            errorStore.setHeaderText("Add / Edit Complaint");
            errorStore.setButtonText("Close");
            errorStore.setErrorMessageOne("Invalid add / edit complaint route. Please go back and try again.");
            errorStore.setErrorModalOpen(true);

            navigate(AppUrls.Client.Aftersales.Default[AppUrlIndex.Long]);
        }

        if (ComplaintTypeEnumHelpers.getFromUrlComponent(params.complainttype!) === ComplaintTypeEnum.MasterProject) {
            setViewModel(new MasterProjectComplaintAddEditViewModel());
        } else {
            setViewModel(new ProjectQuoteComplaintAddEditViewModel());
        }

        return () => {
            // Clean up after yourself
            if (viewModel !== undefined) {
                viewModel.clear();
            }
        };
    }, []);

    useEffect(() => {
        if (viewModel !== undefined) {
            if (params.complaintid === "new") {
                let promise = viewModel.getComplaintRelatedForProject(params.projectid!);

                promise.then(() => {
                    setIsEdit(false);
                });
            } else {
                let promise = viewModel.getComplaint(params.complaintid!);
                promise.then((kv) => {
                    setIsEdit(true);
                });
            }
        }
    }, [viewModel]);

    const handleSubHeadingButtonClicks: ICommand = new RelayCommand((value: subHeadingButton) => {
        if (params.complainttype !== undefined && params.projectid !== undefined) {
            switch (value) {
                case subHeadingButton.Back:
                    if (location.pathname.endsWith("aftersales")) {
                        navigate(AppUrls.Client.Aftersales.ComplaintsView[AppUrlIndex.Long].replace(":complaintid", params.complaintid!));
                    } else {
                        if (ComplaintTypeEnumHelpers.getFromUrlComponent(params.complainttype!) === ComplaintTypeEnum.ProjectQuote) {
                            navigate(AppUrls.Client.Work.Quotes.View[AppUrlIndex.Long].replace(":projectquoteid", params.projectid!) + "/complaints");
                        } else {
                            navigate(
                                BackButtonNavigation(
                                    AppUrls.Client.Work.Master.View[AppUrlIndex.Long]
                                        .replace(":masterprojectid/details", params.projectid!)
                                        .replace(":masterprojectid", params.projectid!) + "/complaints",
                                ),
                            );
                        }
                    }
                    break;

                default:
                // code block
            }
        }
    });

    const getBackButtonText = () => {
        let retVal = "Back to ";
        if (params.complainttype !== undefined && params.projectid !== undefined) {
            if (location.pathname.endsWith("/aftersales")) {
                retVal = "Back to Complaint " + viewModel?.getValue("referenceId");
            } else {
                if (ComplaintTypeEnumHelpers.getFromUrlComponent(params.complainttype!) === ComplaintTypeEnum.ProjectQuote) {
                    retVal = "Back to Project Quote";
                } else {
                    retVal = "Back to Master Project";
                }
            }
        }
        return retVal;
    };

    const submitComplaint: ICommand = new RelayCommand((submitType: SubmitType) => {
        if (viewModel !== undefined && viewModel.isModelValid() === true) {
            let promise = viewModel.upsertAsync(params.projectid!);

            promise.then(() => {
                if (submitType === SubmitType.SaveAndExit) {
                    if (location.pathname.endsWith("/aftersales")) {
                        navigate(AppUrls.Client.Aftersales.ComplaintsView[AppUrlIndex.Long].replace(":complaintid", params.complaintid!));
                    } else {
                        if (ComplaintTypeEnumHelpers.getFromUrlComponent(params.complainttype!) === ComplaintTypeEnum.ProjectQuote) {
                            navigate(AppUrls.Client.Work.Quotes.View[AppUrlIndex.Long].replace(":projectquoteid", params.projectid!) + "/complaints");
                        } else {
                            // Master Project
                            navigate(BackButtonNavigation(AppUrls.Client.Work.Master.View[AppUrlIndex.Long].replace(":masterprojectid", params.projectid!) + "/complaints"));
                        }
                    }
                } else if (submitType === SubmitType.SaveAndContinue) {
                    viewModel.setShowActionSection(true);
                    setIsEdit(true);
                    CoreStoreInstance.HideInfoBar();
                }
            });
        }
    });

    const formatOptionLabel = ({ value, label }: any) => <p className={getClassName(value)}>{label}</p>;

    const getClassName = (value: number) => {
        let retVal = "";
        if (viewModel?.getComplaintCategoryDropdown!) {
            let type = viewModel?.getComplaintCategoryDropdown!.find((productType: any) => productType.id === value);
            if (type?.parentId === null) {
                retVal = "parent";
            } else if (type?.parentId !== 0) {
                retVal = "child";
            }
            return retVal;
        }
    };

    return (
        <>
            <ComplaintsAddEditWrapper>
                {viewModel === undefined || viewModel.getIsProcessing ? (
                    <ThemedLoader isOpen={true} />
                ) : (
                    <>
                        <SubHeaderView buttonClick={handleSubHeadingButtonClicks} backButtonText={String(getBackButtonText())} />

                        <H2 pt="40px" pb="10px">
                            {isEdit === true ? <div>Edit Complaint</div> : <div>New Complaint</div>}
                        </H2>

                        {viewModel.getShowNewCustomerModel === true && (
                            <AddCustomerModal isOpen={viewModel.getShowNewCustomerModel} closeModal={viewModel.closeModelCommand!} viewModel={viewModel} />
                        )}

                        {isEdit && (
                            <>
                                <GridWrapper mb="20px">
                                    <SpanTwoBox>
                                        <Label>
                                            <strong>Opened:</strong>
                                        </Label>
                                        <Label>
                                            {formatDate(viewModel.getValue("createdDate"))} &nbsp;by {viewModel.getValue("createdByName")}
                                        </Label>
                                    </SpanTwoBox>
                                </GridWrapper>
                                <GridWrapper mb="20px">
                                    <SpanTwoBox>
                                        <Label>
                                            <strong>ReferenceId:</strong>
                                        </Label>
                                        <Label>{viewModel.getValue("referenceId")}</Label>
                                    </SpanTwoBox>
                                    <SpanTwoBox>
                                        <Label>
                                            <strong>Order Number:</strong>
                                        </Label>
                                        <Label>{viewModel.getValue("orderNumber")}</Label>
                                    </SpanTwoBox>
                                </GridWrapper>
                            </>
                        )}

                        <GridWrapper mb="20px">
                            <SpanTwoBox>
                                <Input
                                    command={viewModel.setReportedBy}
                                    displayName="Reported by:"
                                    placeholder="Who reported this complaint?"
                                    value={() => viewModel.getValue("reportedBy")}
                                    validationMessage={() => viewModel.getError("reportedBy")}
                                    autoFill={false}
                                />
                            </SpanTwoBox>
                            <SpanTwoBox>
                                <EditSelect
                                    displayName="Company Who Reported:*"
                                    command={viewModel.setCompanyWhoReported!}
                                    options={viewModel.getCompanyWhoReportedDropdownItems!}
                                    value={() => viewModel.getValue("reportingCompanyId")}
                                    validationMessage={() => viewModel.getError("reportingCompanyId")}
                                    isDisabled={viewModel.getValue("isResolved")}
                                    errorIconRight={false}
                                />
                            </SpanTwoBox>
                            <SpanTwoBox style={{ display: "flex", flexWrap: "wrap", alignContent: "end" }}>
                                <CenteredTextBox>
                                    <PAddress>
                                        Customer not found? &nbsp; &nbsp;
                                        <span className="link" onClick={() => viewModel.openShowNewCustomerModelCommand?.execute()}>
                                            Add a Customer
                                        </span>
                                    </PAddress>
                                </CenteredTextBox>
                            </SpanTwoBox>
                        </GridWrapper>

                        <GridWrapper mb="20px">
                            <SpanTwoBox>
                                <EditSelect
                                    displayName="Installation Company:*"
                                    command={viewModel.setInstallationCompany}
                                    options={viewModel.getInstallationCompaniesDropdownItems}
                                    value={() => viewModel.getValue("installationContractorId")}
                                    validationMessage={() => viewModel.getError("installationContractorId")}
                                    isDisabled={viewModel.getValue("isResolved")}
                                    errorIconRight={false}
                                />
                            </SpanTwoBox>
                            <SpanTwoBox>
                                <Input
                                    command={viewModel.setOrderValue}
                                    displayName="Order Value:*"
                                    placeholder="Order Value"
                                    value={() => viewModel.getValue("orderValue")}
                                    validationMessage={() => viewModel.getError("orderValue")}
                                />
                            </SpanTwoBox>
                        </GridWrapper>
                        <GridWrapper mb="20px">
                            <SpanFourBox>
                                <TextAreaBox>
                                    <Input
                                        command={viewModel.setSiteAddressdBy}
                                        displayName="Site Address: *"
                                        placeholder="Site Address?"
                                        value={() => viewModel.getValue("siteAddress")}
                                        validationMessage={() => viewModel.getError("siteAddress")}
                                        multiline={true}
                                        rows={5}
                                        autoFill={false}
                                    />
                                </TextAreaBox>
                            </SpanFourBox>
                        </GridWrapper>
                        <GridWrapper mb="20px">
                            <SpanTwoBox>
                                <EditSelect
                                    displayName="Nature:*"
                                    command={viewModel.setNature}
                                    options={viewModel.getComplaintNaturesDropdownItems}
                                    value={() => viewModel.getValue("natureId")}
                                    validationMessage={() => viewModel.getError("natureId")}
                                    isDisabled={viewModel.getValue("isResolved")}
                                    errorIconRight={false}
                                />
                            </SpanTwoBox>
                            <Box />
                        </GridWrapper>
                        <GridWrapper mb="20px">
                            <SpanFourBox>
                                <TextAreaBox>
                                    <Input
                                        command={viewModel.setDetails}
                                        displayName="Complaint Details:*"
                                        placeholder="Add details"
                                        value={() => viewModel.getValue("details")}
                                        validationMessage={() => viewModel.getError("details")}
                                        multiline={true}
                                        rows={5}
                                        autoFill={false}
                                    />
                                </TextAreaBox>
                            </SpanFourBox>
                        </GridWrapper>
                        <GridWrapper mb="20px">
                            <SpanTwoBox>
                                <EditSelect
                                    displayName="Assigned User:*"
                                    command={viewModel.setComplaintAssignee}
                                    options={viewModel.getComplaintAssigneesDropdownItems}
                                    value={() => viewModel.getValue("assignedUserId")}
                                    validationMessage={() => viewModel.getError("assignedUserId")}
                                    isDisabled={viewModel.getValue("isResolved")}
                                    errorIconRight={false}
                                />
                            </SpanTwoBox>
                        </GridWrapper>
                        <GridWrapper mb="20px">
                            <SpanFourBox>
                                <TextAreaBox>
                                    <Input
                                        command={viewModel.setAssessment}
                                        displayName="Assessment:"
                                        placeholder="Add assessment"
                                        value={() => viewModel.getValue("assessment")}
                                        validationMessage={() => viewModel.getError("assessment")}
                                        multiline={true}
                                        rows={5}
                                        autoFill={false}
                                    />
                                </TextAreaBox>
                            </SpanFourBox>
                        </GridWrapper>

                        <GridWrapper mb="20px">
                            <SpanTwoBox>
                                <CategoryTextBox>
                                    <EditSelect
                                        displayName="Category:*"
                                        command={viewModel.setCategory}
                                        options={viewModel.getComplaintCategoriesDropdownItems}
                                        value={() => viewModel.getValue("categoryId")}
                                        validationMessage={() => viewModel.getError("categoryId")}
                                        isDisabled={viewModel.getValue("isResolved")}
                                        errorIconRight={false}
                                        formatOptionLabel={formatOptionLabel}
                                        isOptionDisabled={(option) => getClassName(option.value) == "parent"}
                                    />
                                </CategoryTextBox>
                            </SpanTwoBox>
                        </GridWrapper>

                        {isEdit && (
                            <>
                                <GridWrapper mb="20px" mt="30px">
                                    <SpanTwoBox>
                                        <CheckBox displayName="Resolved" command={viewModel.setIsResolved} value={() => viewModel.getValue("isResolved")} />
                                    </SpanTwoBox>
                                </GridWrapper>
                                <GridWrapper mb="20px" pb="40px" showIf={viewModel.getValue("isResolved")}>
                                    <SpanTwoBox>
                                        <CategoryTextBox>
                                            <EditSelect
                                                displayName="Confirm complaint type:*"
                                                command={viewModel.setConfirmComplaintType}
                                                options={viewModel.getComplaintCategoriesDropdownItems}
                                                value={() => viewModel.getValue("resolvedCategoryId")}
                                                validationMessage={() => viewModel.getError("resolvedCategoryId")}
                                                formatOptionLabel={formatOptionLabel}
                                                isOptionDisabled={(option) => getClassName(option.value) == "parent"}
                                            />
                                        </CategoryTextBox>
                                    </SpanTwoBox>

                                    <Box>
                                        <DatePickerBox>
                                            <EditDate command={viewModel.setDateResolved} value={viewModel.getDateResolved} displayName="Date resolved *: " />
                                        </DatePickerBox>
                                    </Box>
                                </GridWrapper>
                            </>
                        )}
                    </>
                )}
            </ComplaintsAddEditWrapper>

            <CreateCustomFooter>
                <Button
                    displayName=" Save & Exit"
                    style={{ marginRight: "10px" }}
                    command={submitComplaint}
                    value={SubmitType.SaveAndExit}
                    icon={<img src={tick} />}
                    paletteColor="ButtonGreen"
                    boxShadow={true}
                />

                <Button
                    displayName=" Save & Continue"
                    command={submitComplaint}
                    value={SubmitType.SaveAndContinue}
                    paletteColor="ButtonGreen"
                    icon={<img src={tick} />}
                    boxShadow={true}
                />
            </CreateCustomFooter>
        </>
    );
});
