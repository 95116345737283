import styled from "@emotion/styled";
import { Box, theme } from "Application";
import { P } from "Components/Primitives/TextElements/TextElements";

export const TSMModalWrapper = styled(Box)`
    width: 676px;
`;

export const TSMModalGreyBox = styled(Box)`
    background-color: #f3f3f3;
    padding: 15px 8px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .editinput {
        resize: none !important;
    }
`;

export const TSMModalLargerText = styled(P)`
    font-size: ${theme.fontStyles.labelLarge.fontSize};
    font-weight: ${theme.fontStyles.labelLarge.fontWeight};
`;

export const TSMModalTableGrid = styled(Box)`
    display: grid;
    grid-template-columns: 1fr 2fr 1fr 0.5fr;
    margin-bottom: 50px;
`;

export const TSMTableSectionWrapper = styled(Box)`
    padding: 15px 8px;
`;

export const TSMModalListText = styled(P)`
    padding: 16px 8px 16px 0px;
`;

export const TSMListHorizontalLine = styled.hr`
    grid-column: span 4;
    margin-top: 8px;
    border-top: 1px solid #d4d4d4;
`;

export const TSMListHorizontalLineDashed = styled.hr`
    grid-column: span 4;
    border-top: 1px dashed #d4d4d4;
`;

export const DocumentModalGreyBox = styled(Box)`
    padding: 9px;
    text-align: right;
`;

export const DateWrapperBox = styled(Box)`
    .rdtPicker {
        left: 0px !important;
    }
`;
