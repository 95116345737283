import { makeObservable, action, computed, observable, runInAction } from "mobx";
import { ViewModelBase } from "@shoothill/core";
import { APIClient, ICommand, RelayCommand } from "Application";
import { ErrorStore } from "Stores/Domain/ErrorStore";
import { container } from "tsyringe";
import { PreviewUploadedDocumentItemModel, PreviewUploadedDocumentItemModelValidator } from "./PreviewUploadedDocumentItemModel";
import { IAddedDocumentViewModel } from "Models/Documents/IAddedDocumentViewModel";

export class PreviewUploadedDocumentItemViewModel extends ViewModelBase<PreviewUploadedDocumentItemModel> implements IAddedDocumentViewModel<PreviewUploadedDocumentItemModel> {
    public apiClient = new APIClient();
    errorStore = container.resolve(ErrorStore);

    constructor() {
        super(new PreviewUploadedDocumentItemModel());
        this.setValidator(new PreviewUploadedDocumentItemModelValidator());
        makeObservable(this, {
            clear: action,
        });
    }

    public clear = () => {
        this.model.clear();
    };

    public setDocumentType: ICommand = new RelayCommand(() => {});
}
