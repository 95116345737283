import { ModelBase } from "@shoothill/core";
import { makeObservable, observable } from "mobx";
import { Validator } from "Application/Validation";
import { IAddress } from "Components/AddressGeneric/AddressGenericModel";
import { CustomerStatus } from "Application/Models/Domain/Customer/CustomerStatusEnum";
import { container } from "tsyringe";
import { CustomerStore } from "Stores/Domain";

export class AddCustomerModel extends ModelBase<AddCustomerModel> {
    private customerStore = container.resolve(CustomerStore);
    public id: Guid | null = null;
    public customerTypeId: number = this.customerStore.DefaultCustomerTypeId; // Contractor
    public name: string = "";
    public address: IAddress = { postcode: "", latitude: 0, longitude: 0, addressLineOne: "", addressLineTwo: "", townCity: "", county: "", country: "", countryId: -1 };
    public email: string = "";
    public phone: string = "";
    public phone2: string = "";
    public websiteAddress: string = "";
    public customerStatusId: CustomerStatus = CustomerStatus.Active;

    // Contractor
    public contractorId: Guid | null = null;
    public customerId: Guid | null = null;
    public leadTsmId: number | null = null;
    public sageRef: string | null = "";
    public eORINumber: string | null = "";
    public tSSNumber: string | null = "";
    public referral: boolean | null = null;

    // Contractor & Specifier

    public bdmId: number | null = 0;

    // Specifiers

    public specifierId: Guid | null = null;

    // Occupiers

    public occupierId: Guid | null = null;

    // FacilityManager

    public facilityManagerId: Guid | null = null;

    public rowVersion: string = "";

    constructor() {
        super();
        makeObservable(this, {
            customerTypeId: observable,
            name: observable,
            address: observable,
            email: observable,
            phone: observable,
            phone2: observable,
            websiteAddress: observable,

            customerStatusId: observable,

            contractorId: observable,
            customerId: observable,
            leadTsmId: observable,
            bdmId: observable,
            sageRef: observable,
            eORINumber: observable,
            tSSNumber: observable,

            occupierId: observable,

            facilityManagerId: observable,
        });
    }

    public clear = () => {
        this.id = "";
        this.customerTypeId = this.customerStore.DefaultCustomerTypeId; // Contractor
        this.name = "";
        this.address = { postcode: "", latitude: 0, longitude: 0, addressLineOne: "", addressLineTwo: "", townCity: "", county: "", country: "", countryId: -1 };
        this.email = "";
        this.phone = "";
        this.phone2 = "";
        this.websiteAddress = "";
        this.customerStatusId = CustomerStatus.Active;
        this.rowVersion = "";
        // Contractor
        this.contractorId = null;
        this.customerId = null;
        this.leadTsmId = null;
        this.sageRef = "";
        this.eORINumber = "";
        this.tSSNumber = "";
        this.referral = null;

        // Contractor & Specifier
        this.bdmId = null;

        // Specifier
        this.specifierId = null;

        // Occupiers
        this.occupierId = null;

        // FacilityManager
        this.facilityManagerId = null;
    };
}

export class AddCustomerModelValidator extends Validator<AddCustomerModel> {
    constructor(customerStore: CustomerStore) {
        super();
        this.ruleFor("name")
            .notEmpty()
            .withMessage("Please enter a name")
            .notNull()
            .withMessage("Please enter a name")
            .maxLength(256)
            .withMessage("Enter a name less than 256 characters");

        this.ruleFor("email")
            .notEmpty()
            .withMessage("Please enter an email")
            .notNull()
            .withMessage("Please enter an email")
            .emailAddress()
            .withMessage("Please enter a valid email Address")
            .maxLength(256)
            .withMessage("Enter an email less than 256 characters");

        this.ruleFor("phone")
            .notEmpty()
            .withMessage("Please enter a phone number")
            .notNull()
            .withMessage("Please enter a phone number")
            .maxLength(32)
            .withMessage("Enter a phone number less than 16 characters for Big Change");

        this.ruleFor("phone2")
            .matches(/^[+\d\s\/]{0,32}$/) // Allow numbers, "+", "/" and spaces, with max length of 32 characters
            .withMessage("Please enter a valid phone number")
            .maxLength(32)
            .withMessage("Enter a phone additional number less than 32 characters");

        this.ruleFor("address").notNull().withMessage("Please enter an Address");

        this.ruleFor("leadTsmId")
            .notNull()
            .greaterThan(0)
            .withMessage("Please select Lead TSM")
            .when(
                (model) =>
                    customerStore.getMatrixOptionsForCustomerType(model.customerTypeId) !== null &&
                    customerStore.getMatrixOptionsForCustomerType(model.customerTypeId)?.hasTSM === true,
            );

        this.ruleFor("bdmId")
            .notNull()
            .greaterThan(0)
            .withMessage("Please select BDM")
            .when(
                (model) =>
                    customerStore.getMatrixOptionsForCustomerType(model.customerTypeId) !== null &&
                    customerStore.getMatrixOptionsForCustomerType(model.customerTypeId)?.hasBDM === true,
            );

        this.ruleFor("customerTypeId").greaterThan(0).withMessage("Please select a customer type");

        this.ruleFor("sageRef")
            .maxLength(50)
            .withMessage("Enter an sageRef less than 50 characters")
            .when(
                (model) =>
                    customerStore.getMatrixOptionsForCustomerType(model.customerTypeId) !== null &&
                    customerStore.getMatrixOptionsForCustomerType(model.customerTypeId)?.hasSageRef === true,
            );
        this.ruleFor("eORINumber")
            .maxLength(50)
            .withMessage("Enter an eORINumber less than 50 characters")
            .when(
                (model) =>
                    customerStore.getMatrixOptionsForCustomerType(model.customerTypeId) !== null &&
                    customerStore.getMatrixOptionsForCustomerType(model.customerTypeId)?.hasEORINumber === true,
            );
        this.ruleFor("tSSNumber")
            .maxLength(50)
            .withMessage("Enter an tSSNumber less than 50 characters")
            .when(
                (model) =>
                    customerStore.getMatrixOptionsForCustomerType(model.customerTypeId) !== null &&
                    customerStore.getMatrixOptionsForCustomerType(model.customerTypeId)?.hasTSSNumber === true,
            );
    }
}
