import { makeObservable, action, computed, observable, runInAction, IObservableArray } from "mobx";
import { KeyValuePair, ViewModelBase } from "@shoothill/core";
import { APIClient, ICommand, RelayCommand } from "Application";
import { ErrorStore } from "Stores/Domain/ErrorStore";
import { container } from "tsyringe";
import { TSMSiteVisitModalModel, TSMSiteVisitModalModelValidator } from "./TSMSiteVisitModalModel";
import { GetSiteVisitModalRelatedEndpoint, GetSiteVisitModalRelatedResponse } from "./Endpoints/GetSiteVisitModalRelatedEndpoint";
import { DropdownItem } from "Models/DropdownItem";
import { UpsertRequestSiteVisitEndpoint, UpsertRequestSiteVisitResponse } from "./Endpoints/UpsertRequestSiteVisitEndpoint";
import { TSMListViewModel } from "Views/TSM/TSMListViewModel";

export class TSMSiteVisitViewModel extends ViewModelBase<TSMSiteVisitModalModel> {
    public apiClient = new APIClient();
    errorStore = container.resolve(ErrorStore);
    public visitTypes: IObservableArray<DropdownItem> = observable([]);
    public contacts: IObservableArray<DropdownItem> = observable([]);
    public parentViewModel: TSMListViewModel = new TSMListViewModel();

    constructor(parentViewModel: TSMListViewModel) {
        super(new TSMSiteVisitModalModel());
        this.parentViewModel = parentViewModel;
        this.setValidator(new TSMSiteVisitModalModelValidator());
        makeObservable(this, {
            clear: action,
        });
    }

    public clear = () => {
        this.model.clear();
    };

    public loadDataFromParentViewModel = (projectQuoteId: Guid) => {
        let clickedROwData = this.parentViewModel.model.tsmList.find((item) => {
            return item.id == projectQuoteId;
        });
        this.setValue("projectId", clickedROwData?.id);
        this.setValue("propertyId", clickedROwData?.propertyId);
        this.setValue("tsmId", this.parentViewModel.tsmId);
    };

    setSiteVisitDate: ICommand = new RelayCommand((date: Date) => {
        this.setValue("visitDateTime", date);
    });

    setTypeOfVisit: ICommand = new RelayCommand((type: KeyValuePair) => {
        this.setValue("visitTypeId", Number(type.key));
    });

    setContact: ICommand = new RelayCommand((contact: KeyValuePair) => {
        if (contact.key == "null") {
            this.setValue("contactId", null);
        } else {
            this.setValue("contactId", contact.key);
        }
    });

    setNote: ICommand = new RelayCommand((note: string) => {
        this.setValue("note", note);
    });

    public get getVisitTypesForDropdown(): KeyValuePair[] {
        return this.visitTypes.map((item) => {
            return { key: item.id, text: item.displayName };
        });
    }

    public get getContactsForDropdown(): KeyValuePair[] {
        let otherOption = [{ key: "null", text: "Other" }];
        let contact = this.contacts.map((item) => {
            return { key: item.id, text: item.displayName };
        });
        return [...otherOption, ...contact];
    }

    public LoadRelated = async (projectId: Guid): Promise<GetSiteVisitModalRelatedResponse> => {
        const endpoint: GetSiteVisitModalRelatedEndpoint = new GetSiteVisitModalRelatedEndpoint(projectId);
        let retVal: GetSiteVisitModalRelatedResponse = new GetSiteVisitModalRelatedResponse();

        if (this.apiClient.IsBusy === false) {
            await this.apiClient.sendAsync(endpoint);

            if (this.apiClient.IsRequestSuccessful) {
                runInAction(() => {
                    let response: GetSiteVisitModalRelatedResponse = this.apiClient.Response();
                    this.visitTypes.replace(response.visitTypes);
                    this.contacts.replace(response.contacts);
                });
            } else {
                runInAction(() => {
                    // this.isProcessing = false;
                });
                this.errorStore.setHeaderText("TSM Notes");
                this.errorStore.setButtonText("Close");
                this.errorStore.setErrorMessageOne("Failed to get the tsm site visit data.  Please try again later.");
                this.errorStore.setErrorMessageTwo(this.apiClient.ValidationMessage);
                this.errorStore.setErrorModalOpen(true);
            }
        }
        return retVal;
    };

    public upsertSiteVisitRequest = async (): Promise<UpsertRequestSiteVisitResponse> => {
        const endpoint: UpsertRequestSiteVisitEndpoint = new UpsertRequestSiteVisitEndpoint();
        let retVal: UpsertRequestSiteVisitResponse = new UpsertRequestSiteVisitResponse();

        if (this.apiClient.IsBusy === false && this.isModelValid() === true) {
            await this.apiClient.sendAsync(endpoint, this.model);

            if (this.apiClient.IsRequestSuccessful) {
                runInAction(() => {
                    let response: UpsertRequestSiteVisitResponse = this.apiClient.Response();
                });
            } else {
                runInAction(() => {
                    // this.isProcessing = false;
                });
                this.errorStore.setHeaderText("Request Site Visit");
                this.errorStore.setButtonText("Close");
                this.errorStore.setErrorMessageOne("Failed to add the site visit request.  Please try again later.");
                this.errorStore.setErrorMessageTwo(this.apiClient.ValidationMessage);
                this.errorStore.setErrorModalOpen(true);
            }
        }
        return retVal;
    };
}
