import * as React from "react";
import clsx from "clsx";
import { isEmptyOrWhitespace, isNullOrUndefined } from "@shoothill/core";
import { Box, ErrorExclamationView, ICommand } from "Application";
import Select, { components, FormatOptionLabelMeta, Options } from "react-select";
import { useEffect, useState } from "react";
import { FieldLabel } from "../CommonStyles";
import { observer } from "mobx-react-lite";
import DownArrow from "Assets/Icons/EditSelectArrow.svg";
import { AllNoneButton, SelectContainer } from "./Select.style";
import { KeyValuePairExtended } from "Models/KeyValuePairExtended";
// #region View Props

interface IProps {
    canClear?: boolean;
    command: ICommand;
    className?: string;
    displayName?: string;
    optionRenderer?: (item: any) => void;
    options: KeyValuePairExtended[];
    placeholder?: string;
    style?: any;
    value: () => string | string[] | number | number[];
    isMulti?: boolean;
    validationMessage?: () => string;
    noHeader?: boolean;
    ref?: React.Ref<any>;
    cy?: string;
    formatOptionLabel?: ((data: any, formatOptionLabelMeta: FormatOptionLabelMeta<any>) => React.ReactNode) | undefined;
    isOptionDisabled?: ((option: any, selectValue: Options<any>) => boolean) | undefined;
    errorIconRight?: boolean;
    isDisabled?: boolean;
    isPreSticky?: boolean;

    selectAll?: () => void;
    selectNone?: () => void;
    showSelectAllNone?: boolean;
    hasParentChildren?: boolean;
    onInputChange?: (newValue: any, actionMeta: any) => void;
    showClearButton?: boolean;
    clearDropdownList?: () => void;
}

// #endregion View Props

// NOTE CMR Have to put these outside the function to get the blur to work.  Makes no sense, but is explained in the link below
// https://github.com/JedWatson/react-select/issues/5489

const DropdownIndicator = (props: any) => {
    return (
        <components.DropdownIndicator {...props}>
            <img src={DownArrow} style={{ transform: "rotate(180deg)" }} />
        </components.DropdownIndicator>
    );
};

const ValueContainerDefault = (props: any) => {
    let [values, input] = props.children;
    let total: number = props.options.length;

    if (Array.isArray(values)) {
        if (values.length === 0) {
            values = "None selected";
        } else if (values.length === total) {
            values = "All";
        } else {
            values = `${values.length} of ${total}`;
        }
    }

    return (
        <components.ValueContainer {...props}>
            {values}
            {input}
        </components.ValueContainer>
    );
};

// For Parent/Child EditSelects
const ValueContainerParentChild = (props: any) => {
    let [values, input] = props.children;
    let total: number = props.options.length;
    let allChildOptions = props.options.filter((option: KeyValuePairExtended) => option.class == "child");

    if (Array.isArray(values)) {
        if (values.length === 0) {
            values = "None selected";
        } else if (values.length === total) {
            values = "All";
        } else {
            values = `${values.length} of ${allChildOptions.length}`;
        }
    }

    return (
        <components.ValueContainer {...props}>
            {values}
            {input}
        </components.ValueContainer>
    );
};

const CheckboxOptions = (props: any) => {
    const [isActive, setIsActive] = useState(false);
    const onMouseDown = () => setIsActive(true);
    const onMouseUp = () => setIsActive(false);
    const onMouseLeave = () => setIsActive(false);

    // styles
    let bg = "transparent";
    if (props.isFocused) {
        bg = "#eee";
    }
    if (isActive) {
        bg = "#B2D4FF";
    }

    const style = {
        alignItems: "center",
        backgroundColor: bg,
        color: "inherit",
        display: "flex ",
    };

    // prop assignment
    const propsAssignment = {
        ...props.innerProps,
        onMouseDown,
        onMouseUp,
        onMouseLeave,
        style,
    };

    return (
        <components.Option {...props} innerProps={propsAssignment}>
            <div className={"checkboxrow " + props.data.class}>
                <div className={"select-label " + props.data.class}>{props.children}</div>
                <div className={"checkbox " + props.data.class}>
                    <input readOnly type="checkbox" checked={props.isSelected} />
                </div>
            </div>
        </components.Option>
    );
};

export const MultiEditSelect: React.FC<IProps> = observer((props) => {
    const [selectedOption, setSelectedOption] = useState<any>(null);
    const haveError = !isEmptyOrWhitespace(props.validationMessage?.() as string);
    let debounceTimer: NodeJS.Timeout | null = null;

    // #region Code Behind
    useEffect(() => {
        //EN: Have to do this because react-select stores the entire key value pair in the value property
        if (!props.isMulti) {
            const initValue = props.options.find((option: KeyValuePairExtended) => option.key === props.value());
            if (initValue) {
                setSelectedOption({ value: initValue.key, label: initValue.text, class: initValue.class });
            }
        } else {
            if (props.value()) {
                const values = props.value() as any as string[];
                let arr = [];
                for (const item of values) {
                    const initValue: KeyValuePairExtended<any> | undefined = props.options.find((option: KeyValuePairExtended) => option.key === item);
                    if (initValue !== undefined) {
                        arr.push({ value: initValue.key, label: initValue.text, class: initValue.class });
                    }
                }
                setSelectedOption(arr);
            }
        }
    }, []);

    useEffect(() => {
        if (!props.isMulti) {
            const initValue = props.options.find((option: KeyValuePairExtended) => option.key === props.value()) as KeyValuePairExtended;
            if (initValue) {
                setSelectedOption({ value: initValue.key, label: initValue.text });
            }
        } else {
            const values = props.value() as any as string[];
            let arr: any[] = [];
            for (const item of values) {
                const initValue: KeyValuePairExtended<any> | undefined = props.options.slice().find((option: KeyValuePairExtended) => option.key === item);
                if (initValue !== undefined) {
                    arr.push({ value: initValue.key, label: initValue.text });
                }
            }
            setSelectedOption(arr);
        }
    }, [props.value()]);

    const getCanClear = (): boolean => {
        return isNullOrUndefined(props.canClear) ? false : props.canClear!;
    };

    const getClassName = (): string => {
        return clsx({
            editselect: true,
            [`editselect-${props.className}`]: !isEmptyOrWhitespace(props.className),
            "editselect-emptylabel": props.displayName === "EMPTY_LABEL",
        });
    };

    const getPlaceholder = (): string => {
        return isEmptyOrWhitespace(props.placeholder) ? "Select or type" : props.placeholder!;
    };

    const onChange = (option: any): void => {
        setSelectedOption(option);
        if (!props.isMulti) {
            props.command.execute({ key: option.value, text: option.label, class: option.class } as KeyValuePairExtended);
        } else {
            const selected = option.map((a: any) => {
                return { key: a.value, text: a.label, class: a.class } as KeyValuePairExtended;
            });

            props.command.execute(selected);
        }
    };
    const isDisabled = (): boolean => {
        return isNullOrUndefined(props.command.canExecute) ? false : !props.command.canExecute;
    };
    const getValidationMessage = (): string => {
        return isEmptyOrWhitespace(props.validationMessage?.() as string) ? "" : (props.validationMessage?.() as string);
    };

    const getOptions = (): any => {
        return props.options.map((item: KeyValuePairExtended) => {
            return {
                label: item.text,
                value: item.key,
                class: item.class,
            };
        });
    };
    const getDataCY = (): string => {
        let regex = /[^a-z]/gi;
        let result = (props.displayName ?? "").replace(regex, "");
        return props.cy ?? result;
    };

    const MenuList = (menuProps: any) => {
        let selectedItems = props.value();
        let selectedItemsCount: number = 0;
        if (Array.isArray(selectedItems)) {
            selectedItemsCount = selectedItems.length;
        }

        return (
            <components.MenuList {...menuProps}>
                {props.showSelectAllNone !== undefined &&
                    props.showSelectAllNone === true &&
                    props.options.length > 0 &&
                    props.selectAll !== undefined &&
                    props.selectNone !== undefined && (
                        <div className="multiselect-btn-row">
                            <AllNoneButton
                                className={selectedItemsCount === props.options.length ? "selected" : "not-selected"}
                                onClick={(e) => {
                                    props.selectAll!();
                                }}
                            >
                                Select all
                            </AllNoneButton>
                            <AllNoneButton
                                className={selectedItemsCount === 0 ? "selected" : "not-selected"}
                                onClick={(e) => {
                                    props.selectNone!();
                                }}
                            >
                                Select none
                            </AllNoneButton>
                        </div>
                    )}

                {props.showClearButton === true && (
                    <Box paddingBottom="5px" style={{ display: "flex", justifyContent: "center" }}>
                        <AllNoneButton
                            className={"selected"}
                            onClick={(e) => {
                                props.clearDropdownList!();
                            }}
                        >
                            Clear contractors
                        </AllNoneButton>
                    </Box>
                )}
                {menuProps.children}
                {props.showSelectAllNone !== undefined &&
                    props.showSelectAllNone === true &&
                    props.options.length > 10 &&
                    props.selectAll !== undefined &&
                    props.selectNone !== undefined && (
                        <div className="multiselect-btn-row">
                            <AllNoneButton
                                className={selectedItemsCount === props.options.length ? "selected" : "not-selected"}
                                onClick={(e) => {
                                    props.selectAll!();
                                }}
                            >
                                Select all
                            </AllNoneButton>
                            <AllNoneButton
                                className={selectedItemsCount === 0 ? "selected" : "not-selected"}
                                onClick={(e) => {
                                    props.selectNone!();
                                }}
                            >
                                Select none
                            </AllNoneButton>
                        </div>
                    )}
            </components.MenuList>
        );
    };

    const ValueContainer = props.hasParentChildren ? ValueContainerParentChild : ValueContainerDefault;

    let classList: string = props.isPreSticky === undefined || props.isPreSticky === false ? "virtualized-select-validation" : "virtualized-select-validation isPreSticky";
    // #endregion Code Behind
    return (
        <SelectContainer className={getClassName()}>
            {!props.noHeader && (
                <Box flexBox>
                    <FieldLabel className="placeholder" style={{ color: haveError ? "red" : "" }}>
                        {props.displayName}
                    </FieldLabel>
                    {props.errorIconRight === false && <ErrorExclamationView haveError={haveError} validationMessage={() => getValidationMessage()} />}
                </Box>
            )}
            <Box flexBox style={{ ...props.style }}>
                <Select
                    ref={props.ref}
                    isClearable={getCanClear()}
                    className={classList}
                    classNamePrefix={"editselect"}
                    isDisabled={props.isDisabled}
                    onChange={onChange}
                    isMulti={props.isMulti}
                    options={getOptions()}
                    placeholder={getPlaceholder()}
                    value={selectedOption}
                    formatOptionLabel={props.formatOptionLabel}
                    data-cy={getDataCY()}
                    components={{ DropdownIndicator, ValueContainer, Option: CheckboxOptions, MenuList }}
                    hideSelectedOptions={false}
                    closeMenuOnSelect={false}
                    isOptionDisabled={props.isOptionDisabled}
                    onInputChange={props.onInputChange}
                />
                {props.errorIconRight === true && (
                    <Box flexBox style={{ alignItems: "center" }}>
                        <ErrorExclamationView haveError={haveError} validationMessage={() => getValidationMessage()} iconRight={props.errorIconRight} />
                    </Box>
                )}
            </Box>
        </SelectContainer>
    );
    // #endregion Public Interface
});

MultiEditSelect.defaultProps = {
    displayName: "",
    isMulti: true,
};
