import React from "react";
import { observer } from "mobx-react-lite";
import { Box, ThemedLoader } from "Application";
import { ViewPropertyViewModel } from "../ViewPropertyViewModel";
import { P } from "Components/Primitives/TextElements/TextElements";
import { BooleanTickOrCrossCell } from "Utils/CellFormatComponents";
import { hyphenIfNoValue } from "Utils/Formats";
import { MapContainer, Marker, TileLayer } from "react-leaflet";
import { MapPlaceholder } from "../ViewPropertyView.style";
import { getDefaultZoomLevel, getSharmansPin } from "Components/Maps/Helpers";

interface IPropertyDetailsView {
    ParentViewModel: ViewPropertyViewModel;
}

export const PropertyDetailsView: React.FC<IPropertyDetailsView> = observer((props) => {
    return (
        <>
            {props.ParentViewModel.isProcessing === true ? (
                <ThemedLoader spinnerText="Loading..." isOpen={true} />
            ) : (
                <>
                    <Box grid dc="1fr 1fr 1fr">
                        <Box grid dc="1fr 1fr" p="10px 0px 20px 0px" style={{ gap: "0 10px" }}>
                            <p
                                style={{
                                    display: "flex",
                                    alignItems: "center",
                                    gap: "5px",
                                    font: "normal normal normal 10px/25px Inter",
                                    letterSpacing: "-0.15px",
                                }}
                            >
                                <strong>Is Surveyed by Tsm: </strong> <BooleanTickOrCrossCell value={props.ParentViewModel.getValue("isSurveyedByTsm")} alignLeft={true} />
                            </p>
                            <p
                                style={{
                                    display: "flex",
                                    alignItems: "center",
                                    gap: "5px",
                                    font: "normal normal normal 10px/25px Inter",
                                    letterSpacing: "-0.15px",
                                    color: "#171716",
                                }}
                            >
                                <strong style={{ minWidth: "90px", display: "block" }}>Roof substrate: </strong> {props.ParentViewModel.getRoofSubstrate}
                            </p>
                            <p
                                style={{
                                    display: "flex",
                                    alignItems: "center",
                                    gap: "5px",
                                    font: "normal normal normal 10px/25px Inter",
                                    letterSpacing: "-0.15px",
                                    color: "#171716",
                                }}
                            >
                                <strong>Site inspection: </strong> <BooleanTickOrCrossCell value={props.ParentViewModel.getValue("siteInspection")} alignLeft={true} />
                            </p>
                            <p
                                style={{
                                    display: "flex",
                                    alignItems: "center",
                                    gap: "5px",
                                    font: "normal normal normal 10px/25px Inter",
                                    letterSpacing: "-0.15px",
                                    color: "#171716",
                                }}
                            >
                                <strong style={{ minWidth: "90px", display: "block" }}>End of lease: </strong> {hyphenIfNoValue(props.ParentViewModel.getLeaseMonthAndYear)}
                            </p>
                        </Box>
                    </Box>

                    {props.ParentViewModel.getValue("locationLatitude") !== null && props.ParentViewModel.getValue("locationLongitude") !== null ? (
                        <MapContainer
                            style={{ width: "100%", height: "433px", zIndex: 0 }}
                            center={[props.ParentViewModel.getValue("locationLatitude"), props.ParentViewModel.getValue("locationLongitude")]}
                            zoom={getDefaultZoomLevel()}
                            scrollWheelZoom={false}
                        >
                            <TileLayer
                                attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                            />
                            <Marker
                                icon={getSharmansPin()}
                                position={[props.ParentViewModel.getValue("locationLatitude"), props.ParentViewModel.getValue("locationLongitude")]}
                            ></Marker>
                        </MapContainer>
                    ) : (
                        <MapPlaceholder height="433px">
                            <P>No location has been set for this property.</P>
                        </MapPlaceholder>
                    )}
                </>
            )}
        </>
    );
});
