import { IObservableArray, action, computed, makeObservable, observable, runInAction } from "mobx";
import { CoreStoreInstance, KeyValuePair, ViewModelBase } from "@shoothill/core";
import { APIClient, ICommand, RelayCommand } from "Application";
import { TSMListModel, TSMListModelValidator } from "./TSMListModel";
import { Timescale } from "Models/Timescale";
import { SortOrderDirection } from "Components/Primitives/DataTable/SharmansTable";
import { GetTSMListAndRelatedEndpoint, GetTSMListAndRelatedResponse } from "./Endpoints/GetTSMListAndRelatedEndpoint";
import { container } from "tsyringe";
import { ErrorStore } from "Stores/Domain/ErrorStore";
import { KeyValuePairExtended } from "Models/KeyValuePairExtended";
import { DropdownItem, ProjectStatusDropdownItem } from "Models/DropdownItem";
import { ProjectStatusEnum } from "Models/Project/ProjectStatusEnum";
import { DefaultPageSize } from "Globals/GlobalSettings";
import { ToggleShowOnTsmTabEndpoint, ToggleShowOnTsmTabResponse } from "./Endpoints/ToggleshowOnTsmTabEndpoint";
import { ToggleSiteVisitedEndpoint, ToggleSiteVisitedResponse } from "./Endpoints/ToggleSiteVisitedEndpoint";
import { GetContractorsForDropdownEndpoint, GetContractorsResponse } from "./Endpoints/GetContractorsForDropdownEndpoint";
import { GetTsmsForAdminDropdownEndpoint } from "./Endpoints/GetTsmsForAdminDropdownEndpoint";

export class TSMListViewModel extends ViewModelBase<TSMListModel> {
    // Singleton Patten - Uses instance and a private static variable to ensure only one instance is created and a private Constructor
    private static _instance: TSMListViewModel;
    public static get Instance() {
        return this._instance || (this._instance = new this());
    }

    public apiClient = new APIClient();
    public isProcessing: boolean = false;
    public isVisibleProcessing: boolean = false;
    public isVisitedProcessing: boolean = false;
    public visitedSiteIdProcessing: Guid = "";
    errorStore = container.resolve(ErrorStore);
    public initialLoad: boolean = true;

    // Admin only
    public TSMs: DropdownItem[] = []; // Dropdown TSM list for admins

    public products: IObservableArray<KeyValuePairExtended> = observable([]);
    public statuses: IObservableArray<KeyValuePairExtended> = observable([]);
    public projectStatuses: ProjectStatusDropdownItem[] = [];
    public contractors: IObservableArray<DropdownItem> = observable([]);

    // Filtering top section
    public timescale: Timescale = Timescale.All;

    public filterByProduct: string[] = [];
    public filterByContractors: string[] = [];
    public filterByStatus: string[] = [];

    public filterBySurveyed: string[] = [];
    public filterByVisited: string[] = [];
    public filterByVisible: string[] = ["true"];

    public contractorDebounceTimer: NodeJS.Timeout | null = null;

    // Table ordering
    public orderBy: SortOrderDirection = SortOrderDirection.DESC;
    public columnName: string = "number";

    // Pagination
    public customerCount: number = 0;
    public pageSize: number = DefaultPageSize;
    public pageCount: number = 0;
    public pageNumber: number = 1;

    // Search String
    public searchString: string = "";
    public contractorSearchString: string = ""; // For editselect read ahead

    public totalCount: number = 0;

    public tsmId: number = -1; // TODO: REMOVE HARDCODED ID
    public searchStringLimit: number = 1; // Adjust to change the number of characters that can be typed into the search box before a search is performed.

    // Modals
    public selectedRowId: Guid = "";
    public showNoteModal: boolean = false;
    public showDocumentModal: boolean = false;
    public showRequestSiteVisitModal: boolean = false;

    constructor() {
        super(new TSMListModel());
        this.setValidator(new TSMListModelValidator());
        makeObservable(this, {
            contractorSearchString: observable,
            contractorDebounceTimer: observable,
            isProcessing: observable,
            isVisibleProcessing: observable,
            isVisitedProcessing: observable,
            initialLoad: observable,
            filterByContractors: observable,
            filterByProduct: observable,
            filterByStatus: observable,
            filterBySurveyed: observable,
            filterByVisible: observable,
            filterByVisited: observable,
            searchString: observable,
            timescale: observable,
            tsmId: observable,
            TSMs: observable,
            orderBy: observable,
            columnName: observable,
            pageSize: observable,
            pageNumber: observable,
            projectStatuses: observable,
            selectedRowId: observable,
            showNoteModal: observable,
            showDocumentModal: observable,
            showRequestSiteVisitModal: observable,
            visitedSiteIdProcessing: observable,

            clearDropdownList: action,
            changePageNumber: action,
            getContractorsBySearchString: action,
            loadTSM: action,
            onChangeRowPerPage: action,
            openDocumentModal: action,
            openNoteModal: action,
            productSelectAll: action,
            productSelectNone: action,
            setContractors: action,
            setLoggedInTsm: action,
            statusSelectAll: action,
            statusSelectNone: action,
            surveyedSelectNone: action,
            surveyedSelectAll: action,
            toggleShowOnTsmTab: action,
            updateProductFiltering: action,
            updateStatusFiltering: action,
            updateVisitedFiltering: action,
            updateSurveyedFiltering: action,
            updateContractorFiltering: action,
            visitedSelectNone: action,
            visitedSelectAll: action,
            visibleSelectNone: action,
            visibleSelectAll: action,

            getCanExecute: computed,
            getBooleanOptionsForDropdown: computed,
            getContractorsForDropdown: computed,
            getContractorFilter: computed,
            getProducts: computed,
            getProductFilter: computed,
            getStatuses: computed,
            getStatusFilter: computed,
            getSurveyedFilter: computed,
            getTSMsForAdminDropdown: computed,
            getSelectedTsm: computed,
            getVisitedFilter: computed,
            // clear: action,
            // downloadCSVAsync: action,
            // updatePageNumber: action,
        });
    }

    public reset = () => {
        this.searchString = "";
        this.timescale = Timescale.All;
        // this.showFrom = new Date(StartOfTime);
        // this.showTo = new Date();
        this.productSelectAll();
        this.statusSelectAll();
    };

    public get getCanExecute(): boolean {
        return !this.isProcessing;
    }

    public setLoggedInTsm = (id: number) => {
        this.tsmId = id;
    };

    public loadTsmsForAdminDropdown = async (): Promise<DropdownItem[]> => {
        const endpoint: GetTsmsForAdminDropdownEndpoint = new GetTsmsForAdminDropdownEndpoint();
        let retVal: DropdownItem[] = [];

        if (this.isVisibleProcessing === false && this.apiClient.IsBusy === false) {
            this.isVisibleProcessing = true;
            await this.apiClient.sendAsync(endpoint, this);

            if (this.apiClient.IsRequestSuccessful) {
                runInAction(() => {
                    let response: DropdownItem[] = this.apiClient.Response();
                    this.TSMs = response;

                    this.isVisibleProcessing = false;
                });
            } else {
                runInAction(() => {
                    this.isVisibleProcessing = false;
                });
                this.errorStore.setHeaderText("TSM");
                this.errorStore.setButtonText("Close");
                this.errorStore.setErrorMessageOne("Failed to load the TSMs.  Please try again later.");
                this.errorStore.setErrorMessageTwo(this.apiClient.ValidationMessage);
                this.errorStore.setErrorModalOpen(true);
            }
        }
        return retVal;
    };

    public get getTSMsForAdminDropdown(): KeyValuePair[] {
        return this.TSMs.map((tsm) => ({ key: tsm.id, text: tsm.displayName }));
    }

    public get getSelectedTsm(): string {
        console.log("Getting selected TSM: " + this.tsmId);
        return String(this.tsmId);
    }

    public chooseTsmToDisplay: ICommand = new RelayCommand(
        (tsm: KeyValuePair) => {
            this.tsmId = tsm.key;
            this.loadTSM();
        },
        () => {
            return this.getCanExecute;
        },
    );

    public updateKeywordSearch: ICommand = new RelayCommand(
        (keyword: string) => {
            this.searchString = keyword;
            this.loadTSM();
        },
        () => {
            return this.getCanExecute;
        },
    );

    public get IsBusy(): boolean {
        return this.apiClient.IsBusy;
    }

    public updateFiltering: ICommand = new RelayCommand(
        () => {},
        () => {
            return this.getCanExecute;
        },
    );

    public loadTSM = async (): Promise<GetTSMListAndRelatedResponse> => {
        const endpoint: GetTSMListAndRelatedEndpoint = new GetTSMListAndRelatedEndpoint();
        let retVal: GetTSMListAndRelatedResponse = new GetTSMListAndRelatedResponse();

        if (this.isProcessing === false && this.apiClient.IsBusy === false) {
            this.isProcessing = true;
            await this.apiClient.sendAsync(endpoint, this);

            if (this.apiClient.IsRequestSuccessful) {
                runInAction(() => {
                    this.model.tsmList.clear();
                    let response: GetTSMListAndRelatedResponse = this.apiClient.Response();
                    this.model.tsmList.replace(response.tsmList);

                    this.totalCount = response.count;
                    this.pageCount = Math.ceil(this.totalCount / this.pageSize);

                    if (this.initialLoad === true) {
                        this.products.clear();
                        this.statuses.clear();

                        this.products.replace(
                            response.productDropdown.map((item) => {
                                if (item.parentId === 0) {
                                    return { key: item.id.toString(), text: item.displayName, class: "parent" };
                                } else {
                                    return { key: item.id.toString(), text: item.displayName, class: "child" };
                                }
                            }),
                        );

                        let amdendedStatuses: ProjectStatusDropdownItem[] = response.statusDropdown.filter(
                            (a) => a.statusId !== ProjectStatusEnum.Unknown && a.statusId !== ProjectStatusEnum.MasterComplete && a.statusId !== ProjectStatusEnum.Master,
                        );

                        this.statuses.replace(
                            amdendedStatuses.map((item) => {
                                return { key: item.statusId.toString(), text: item.displayName, class: item.className + " status-option" };
                            }),
                        );

                        this.projectStatuses = response.statusDropdown;

                        const tempBdm: string[] = response.productDropdown.map((item) => {
                            return item.id.toString();
                        });

                        this.filterByProduct = tempBdm;

                        const tempStatus: string[] = amdendedStatuses.map((item) => {
                            // Use Status Id not id here....
                            return item.statusId.toString();
                        });
                        this.filterByStatus = tempStatus;
                        this.visitedSelectAll();
                        this.surveyedSelectAll();
                        this.productSelectAll();
                        // this.visibleSelectAll();

                        this.initialLoad = false;
                    }

                    this.isProcessing = false;
                });
            } else {
                runInAction(() => {
                    this.isProcessing = false;
                });
                this.errorStore.setHeaderText("TSM");
                this.errorStore.setButtonText("Close");
                this.errorStore.setErrorMessageOne("Failed to get the TSM list.  Please try again later.");
                this.errorStore.setErrorMessageTwo(this.apiClient.ValidationMessage);
                this.errorStore.setErrorModalOpen(true);
            }
        }
        return retVal;
    };

    public updateSorting = (columnName: string, orderBy: SortOrderDirection) => {
        this.columnName = columnName;
        this.orderBy = orderBy;
        this.loadTSM();
    };

    public dummyCommand: ICommand = new RelayCommand(() => {});

    public toggleVisited = (e: any, id: Guid, siteVisited: boolean) => {
        e.stopPropagation();
        if (id) {
            this.visitedSiteIdProcessing = id;
            this.toggleSiteVisited(id, !siteVisited);
        }
    };

    public get getProducts(): KeyValuePairExtended[] {
        return this.products.slice();
    }

    public get getProductFilter(): string | string[] {
        return this.filterByProduct.slice();
    }

    public updateProductFiltering = (values: string[]) => {
        this.filterByProduct = values;
    };

    public productSelectNone = () => {
        this.filterByProduct = [];
    };

    public productSelectAll = () => {
        this.filterByProduct = this.products.map((item) => {
            return item.key.toString();
        });
    };

    public updateStatusFiltering = (values: string[]) => {
        this.filterByStatus = values;
    };

    public get getStatuses(): KeyValuePairExtended[] {
        return this.statuses.slice();
    }

    public get getStatusFilter(): string | string[] {
        return this.filterByStatus.slice();
    }

    public get getContractorFilter(): string | string[] {
        return this.filterByContractors.slice();
    }

    public statusSelectNone = () => {
        this.filterByStatus = [];
    };

    public statusSelectAll = () => {
        this.filterByStatus = this.statuses.map((item) => {
            return item.key.toString();
        });
    };

    public get getBooleanOptionsForDropdown() {
        let retVal: KeyValuePair[] = [
            // { key: null, text: "All" },
            { key: "true", text: "Yes" },
            { key: "false", text: "No" },
        ];

        return retVal;
    }

    public updateContractorFiltering = (values: string[]) => {
        this.filterByContractors = values;
    };

    public get getSurveyedFilter(): string | string[] {
        return this.filterBySurveyed.slice();
    }

    public updateSurveyedFiltering = (values: string[]) => {
        this.filterBySurveyed = values;
    };

    public surveyedSelectNone = () => {
        this.filterBySurveyed = [];
        this.loadTSM();
    };

    public surveyedSelectAll = () => {
        this.filterBySurveyed = this.getBooleanOptionsForDropdown.map((item) => {
            return item.key;
        });
        this.loadTSM();
    };

    public get getVisitedFilter(): string | string[] {
        return this.filterByVisited.slice();
    }

    public updateVisitedFiltering = (values: string[]) => {
        this.filterByVisited = values;
    };

    public visitedSelectNone = () => {
        this.filterByVisited = [];
        this.loadTSM();
    };

    public visitedSelectAll = () => {
        this.filterByVisited = this.getBooleanOptionsForDropdown.map((item) => {
            return item.key;
        });
        this.loadTSM();
    };

    public toggleIsVisible: ICommand = new RelayCommand(
        (id: Guid, showOnTsmTab: boolean) => {
            this.toggleShowOnTsmTab(id, !showOnTsmTab);
        },
        () => {
            return this.getCanExecute;
        },
    );

    public get getVisibleFilter(): string[] {
        return this.filterByVisible.slice();
    }

    public visibleSelectNone = () => {
        this.filterByVisible = [];
        this.loadTSM();
    };

    public visibleSelectAll = () => {
        this.filterByVisible = this.getBooleanOptionsForDropdown.map((item) => {
            return item.key;
        });
        this.loadTSM();
    };

    public updateVisibleFiltering = (values: string[]) => {
        this.filterByVisible = values;
    };

    // Modals

    public openNoteModal = (id: Guid) => {
        this.selectedRowId = id;
        this.showNoteModal = true;
    };

    public closeNoteModal: ICommand = new RelayCommand(
        () => {
            this.showNoteModal = false;

            CoreStoreInstance.HideInfoBar();
            //Refresh the list to get latest Doc and Note counts
            this.loadTSM();
        },
        () => {
            return this.getCanExecute;
        },
    );

    public openDocumentModal = (id: Guid) => {
        this.selectedRowId = id;
        this.showDocumentModal = true;
    };

    public closeDocumentModal: ICommand = new RelayCommand(
        () => {
            this.showDocumentModal = false;

            CoreStoreInstance.HideInfoBar();
            //Refresh the list to get latest Doc and Note counts
            this.loadTSM();
        },
        () => {
            return this.getCanExecute;
        },
    );

    public openSiteVisitModal = (e: any, id: Guid) => {
        e.preventDefault();
        this.selectedRowId = id;
        this.showRequestSiteVisitModal = true;
    };

    public closeSiteVisitModal: ICommand = new RelayCommand(() => {
        CoreStoreInstance.HideInfoBar();
        this.showRequestSiteVisitModal = false;
    });

    public onChangeRowPerPage = (rowsPerPage: number) => {
        this.pageSize = rowsPerPage;
        this.pageNumber = 1;

        this.loadTSM();
    };

    public toggleShowOnTsmTab = async (id: Guid, showOnTsmTab: boolean): Promise<ToggleShowOnTsmTabResponse> => {
        const endpoint: ToggleShowOnTsmTabEndpoint = new ToggleShowOnTsmTabEndpoint(id, showOnTsmTab);
        let retVal: ToggleShowOnTsmTabResponse = new ToggleShowOnTsmTabResponse();

        if (this.isVisibleProcessing === false && this.apiClient.IsBusy === false) {
            this.isVisibleProcessing = true;
            await this.apiClient.sendAsync(endpoint, this);

            if (this.apiClient.IsRequestSuccessful) {
                runInAction(() => {
                    let response: ToggleShowOnTsmTabResponse = this.apiClient.Response();
                    this.model.tsmList.replace(response.tsmList);

                    this.totalCount = response.count;
                    this.pageCount = Math.ceil(this.totalCount / this.pageSize);

                    this.isVisibleProcessing = false;
                });
            } else {
                runInAction(() => {
                    this.isVisibleProcessing = false;
                });
                this.errorStore.setHeaderText("TSM");
                this.errorStore.setButtonText("Close");
                this.errorStore.setErrorMessageOne("Failed to toggle the show on table.  Please try again later.");
                this.errorStore.setErrorMessageTwo(this.apiClient.ValidationMessage);
                this.errorStore.setErrorModalOpen(true);
            }
        }
        return retVal;
    };

    public toggleSiteVisited = async (id: Guid, siteVisited: boolean): Promise<ToggleSiteVisitedResponse> => {
        const endpoint: ToggleSiteVisitedEndpoint = new ToggleSiteVisitedEndpoint(id, siteVisited);
        let retVal: ToggleSiteVisitedResponse = new ToggleSiteVisitedResponse();

        if (this.isVisitedProcessing === false && this.apiClient.IsBusy === false) {
            this.isVisitedProcessing = true;
            await this.apiClient.sendAsync(endpoint, this);

            if (this.apiClient.IsRequestSuccessful) {
                runInAction(() => {
                    let response: ToggleSiteVisitedResponse = this.apiClient.Response();
                    this.model.tsmList.replace(response.tsmList);

                    this.totalCount = response.count;
                    this.pageCount = Math.ceil(this.totalCount / this.pageSize);

                    this.isVisitedProcessing = false;
                    this.visitedSiteIdProcessing = "";
                });
            } else {
                runInAction(() => {
                    this.isVisitedProcessing = false;
                });
                this.errorStore.setHeaderText("TSM");
                this.errorStore.setButtonText("Close");
                this.errorStore.setErrorMessageOne("Failed to toggle site inspected.  Please try again later.");
                this.errorStore.setErrorMessageTwo(this.apiClient.ValidationMessage);
                this.errorStore.setErrorModalOpen(true);
            }
        }
        return retVal;
    };

    public get getContractorsForDropdown() {
        let retVal: KeyValuePair[] = [];

        retVal = this.contractors.map((contractor: DropdownItem) => {
            return { key: contractor.id, text: contractor.displayName };
        });

        return retVal;
    }

    public getContractorsBySearchString(e: string) {
        this.contractorSearchString = e;
        if (this.contractorDebounceTimer) {
            clearTimeout(this.contractorDebounceTimer);
        }
        this.contractorDebounceTimer = setTimeout(() => {
            if (this.contractorSearchString.length > this.searchStringLimit) {
                this.getContractors();
            } else if (this.contractorSearchString.length <= this.searchStringLimit) {
                if (this.filterByContractors.length < this.contractors.length) {
                    let tempContractorList = this.contractors.slice().filter((item) => this.filterByContractors.includes(item.id.toString()));
                    this.setContractors(tempContractorList);
                }
            }
        }, 500);
    }

    public setContractors(contractors: DropdownItem[]) {
        this.contractors.replace(contractors);
    }

    public async getContractors() {
        if (this.isProcessing === false && this.apiClient.IsBusy === false) {
            const endpoint: GetContractorsForDropdownEndpoint = new GetContractorsForDropdownEndpoint(this.contractorSearchString);
            await this.apiClient.sendAsync(endpoint, this);
            // this.setIsProcessing(true);

            if (this.apiClient.IsRequestSuccessful) {
                runInAction(() => {
                    let response: GetContractorsResponse = this.apiClient.Response();
                    // Remove any duplicates...
                    let contractorsNoDuplicates = response.contractors.filter((item) => !this.contractors.some((existingItem) => existingItem.id === item.id));
                    this.contractors.replace([...this.contractors, ...contractorsNoDuplicates]);

                    // this.setIsProcessing(false);
                });
            } else {
                runInAction(() => {
                    // this.setIsProcessing(false);
                });
                this.errorStore.setHeaderText("TSM");
                this.errorStore.setButtonText("Close");
                this.errorStore.setErrorMessageOne("Failed to get the contractors.  Please try again later.");
                this.errorStore.setErrorMessageTwo(this.apiClient.ValidationMessage);
                this.errorStore.setErrorModalOpen(true);
            }
        }
    }

    public clearDropdownList = () => {
        this.contractors.replace([]);
        this.filterByContractors = [];

        this.loadTSM();
    };

    public clearFiltering: ICommand = new RelayCommand(
        () => {
            this.filterByProduct = [];
            this.filterByContractors = [];
            this.filterByStatus = [];
            this.filterBySurveyed = [];
            this.filterByVisited = [];
            this.initialLoad = true;
            this.loadTSM();
        },
        () => {
            return this.getCanExecute;
        },
    );

    public changePageNumber = (pageNumber: number) => {
        this.pageNumber = pageNumber;
        this.loadTSM();
    };
}
