import { makeObservable, action, runInAction } from "mobx";
import { APIClient } from "Application";
import { ErrorStore } from "Stores/Domain/ErrorStore";
import { container } from "tsyringe";
import { GetComplaintRelatedForProjectQuoteEndpoint } from "../Endpoints/GetComplaintRelatedForProjectQuote";
import { IComplaintRelatedResponse } from "Models/Complaints/IComplaintRelatedResponse";
import { AddEditBaseViewModel } from "./AddEditBaseViewModel";
import { ProjectQuoteComplaintGetWithRelatedEndpoint } from "../Endpoints/ProjectQuoteComplaintGetWithRelated";
import { IComplaintWithRelatedResponse } from "Models/Complaints/ComplaintWithRelatedResponse";
import { ComplaintModel } from "Models/Complaints/ComplaintModel";
import { ProjectQuoteComplaintUpsertEndpoint } from "../Endpoints/ProjectQuoteComplaintUpsert";

export class ProjectQuoteComplaintAddEditViewModel extends AddEditBaseViewModel {
    public apiClient = new APIClient();
    errorStore = container.resolve(ErrorStore);

    constructor() {
        super();

        makeObservable(this, {
            getComplaint: action,
            getComplaintRelatedForProject: action,
            upsertAsync: action,
        });
    }

    public upsertAsync = async (projectId: string): Promise<any> => {
        let retVal: ComplaintModel = this.model;

        if (this.isProcessing === false && this.apiClient.IsBusy === false) {
            {
                let endpoint: ProjectQuoteComplaintUpsertEndpoint = new ProjectQuoteComplaintUpsertEndpoint();
                this.isProcessing = true;
                this.setValue("projectId", projectId);
                let _ = await this.apiClient.sendAsync(endpoint, this.model);
                if (this.apiClient.IsRequestSuccessful) {
                    runInAction(() => {
                        this.isProcessing = false;
                        let response: ComplaintModel = this.apiClient.Response();
                        this.model.fromResponse(response);
                    });
                } else {
                    runInAction(() => {
                        this.isProcessing = false;
                    });
                    this.errorStore.setHeaderText("Complaint");
                    this.errorStore.setButtonText("Close");
                    this.errorStore.setErrorMessageOne("Failed to upsert complaint.  Please try again later.");
                    this.errorStore.setErrorMessageTwo(this.apiClient.ValidationMessage);
                    this.errorStore.setErrorModalOpen(true);
                }
            }
        }
        return retVal;
    };

    public getComplaint = async (complaintId: string): Promise<IComplaintWithRelatedResponse> => {
        let retVal: IComplaintWithRelatedResponse = {
            complaint: new ComplaintModel(),
            reportingCompanies: [],
            installationCompanies: [],
            complaintAssignees: [],
            complaintNatures: [],
            complaintCategories: [],
        };

        if (this.isProcessing === false && this.apiClient.IsBusy === false) {
            {
                let endpoint: ProjectQuoteComplaintGetWithRelatedEndpoint = new ProjectQuoteComplaintGetWithRelatedEndpoint(complaintId);
                this.isProcessing = true;

                let _ = await this.apiClient.sendAsync(endpoint);
                if (this.apiClient.IsRequestSuccessful) {
                    runInAction(() => {
                        this.isProcessing = false;
                        let response: IComplaintWithRelatedResponse = this.apiClient.Response();
                        this.processComplaintWithRelatedResponse(response);
                    });
                } else {
                    runInAction(() => {
                        this.isProcessing = false;
                    });
                    this.errorStore.setHeaderText("Complaint");
                    this.errorStore.setButtonText("Close");
                    this.errorStore.setErrorMessageOne("Failed to get complaint and related data.  Please try again later.");
                    this.errorStore.setErrorMessageTwo(this.apiClient.ValidationMessage);
                    this.errorStore.setErrorModalOpen(true);
                }
            }
        }
        return retVal;
    };

    public getComplaintRelatedForProject = async (projectId: string): Promise<IComplaintRelatedResponse> => {
        let retVal: IComplaintRelatedResponse = { installationCompanies: [], complaintAssignees: [], complaintNatures: [], complaintCategories: [], reportingCompanies: [] };

        if (this.isProcessing === false && this.apiClient.IsBusy === false) {
            {
                let endpoint: GetComplaintRelatedForProjectQuoteEndpoint = new GetComplaintRelatedForProjectQuoteEndpoint(projectId);
                this.isProcessing = true;

                let _ = await this.apiClient.sendAsync(endpoint);
                if (this.apiClient.IsRequestSuccessful) {
                    runInAction(() => {
                        this.isProcessing = false;
                        let response: IComplaintRelatedResponse = this.apiClient.Response();
                        this.processRelatedResponse(response);
                    });
                } else {
                    runInAction(() => {
                        this.isProcessing = false;
                    });
                    this.errorStore.setHeaderText("Complaint");
                    this.errorStore.setButtonText("Close");
                    this.errorStore.setErrorMessageOne("Failed to get complaint related data.  Please try again later.");
                    this.errorStore.setErrorMessageTwo(this.apiClient.ValidationMessage);
                    this.errorStore.setErrorModalOpen(true);
                }
            }
        }
        return retVal;
    };
}
