import { ModelBase } from "@shoothill/core";
import { IObservableArray, action, computed, makeObservable, observable } from "mobx";
import { Validator } from "Application/Validation";
import { RoofSubstrateEnum } from "Models/Property/RoofSubstrateEnum";

export class NewEditPropertyModel extends ModelBase<NewEditPropertyModel> {
    // public id: string = "";
    public occupiers: IObservableArray<Guid> = observable([]);
    public isSurveyedByTsm: boolean = false;
    public siteInspection: boolean = false;
    public roofSubstrate: RoofSubstrateEnum = RoofSubstrateEnum.Unknown;
    public endOfLeaseMonth: number | null = null;
    public endOfLeaseYear: number | null = null;

    constructor() {
        super();
        makeObservable(this, {
            // id: observable
            //occupiers: observable,
            isSurveyedByTsm: observable,
            siteInspection: observable,
            roofSubstrate: observable,
            endOfLeaseMonth: observable,
            endOfLeaseYear: observable,

            clear: action,

            getOccupiers: computed,
        });
    }

    public get getOccupiers() {
        return this.occupiers.slice();
    }

    public allowAddingOcupier(occupierId: Guid, propertyId: Guid): boolean {
        let retVal: boolean = false;

        if (this.occupiers.indexOf(occupierId) == -1) {
            retVal = true;
        }

        return retVal;
    }

    public clear = () => {
        this.occupiers.clear();
        this.isSurveyedByTsm = false;
        this.siteInspection = false;
        this.roofSubstrate = RoofSubstrateEnum.Unknown;
        this.endOfLeaseMonth = null;
        this.endOfLeaseYear = null;
    };
}

export class NewEditPropertyModelValidator extends Validator<NewEditPropertyModel> {
    constructor() {
        super();
        //this.ruleFor("id").notEmpty();
    }
}
