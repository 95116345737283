import React, { useState, useEffect } from "react";
import { observer } from "mobx-react-lite";
import { Box, ICommand, RelayCommand } from "Application";
import { createColumnHelper } from "@tanstack/react-table";
import { PaginationDirection, SharmansTable, SortOrderDirection } from "Components/Primitives/DataTable/SharmansTable";
import { useNavigate } from "react-router";
import { AppUrlIndex, AppUrls } from "AppUrls";
import { ButtonUnder } from "Components/Primitives/Buttons/ButtonUnder";
import { ConditionReportListViewModel } from "./ConditionReportListViewModel";
import { formatDate, formatDateTime } from "Utils/Formats";
import { BaseWrapper } from "Components/BaseWrapper";
import spyScope from "Assets/Icons/magnifyingGlass.svg";
import { DeleteButton } from "Components/Primitives/Buttons/DeleteButton";
import { AreYouSureModal } from "Components/AreYouSureModal/AreYouSure";
import { FilteringTopSectionGenericView } from "Components/FilteringTopSectionGeneric";
import { FilteringBottomSectionBox } from "Components/FilteringBottomSectionBox";
import { MultiEditSelect } from "Components/Primitives/Selects/MultiEditSelect";
import { KeyValuePair } from "@shoothill/core";
import { CenteredCell } from "Utils/CellFormatComponents";

export const ConditionReportListView: React.FC = observer(() => {
    const [viewModel] = useState(() => new ConditionReportListViewModel());
    const [isDeleteSectionOpen, setIsDeleteSectionOpen] = useState<boolean>(false);
    const [deleteSelectionId, setDeleteSelection] = useState<string>("");

    let debounceTimer: NodeJS.Timeout | null = null;
    const [isFiltering, setIsFiltering] = useState(false);

    let navigate = useNavigate();

    useEffect(() => {
        let promise = viewModel.loadFilterData();
        promise.then(() => {
            viewModel.loadConditionReportsAsync();
        });

        return () => {
            // Clean up after yourself
            viewModel.clear();
        };
    }, []);

    useEffect(() => {
        if (isFiltering) {
            debounceFilter();
        }
    }, [isFiltering, viewModel]);

    const debounceFilter = () => {
        if (debounceTimer) {
            clearTimeout(debounceTimer);
        }
        debounceTimer = setTimeout(() => {
            viewModel.loadConditionReportsAsync();
            setIsFiltering(false);
        }, 1500);
    };

    const columnHelper = createColumnHelper<any>();

    const columns = [
        columnHelper.accessor("createdDate", {
            header: () => "Created",
            cell: (info) => formatDateTime(new Date(info.renderValue())),
        }),
        columnHelper.accessor("createdBy", {
            header: () => "Editor",
            cell: (info) => info.getValue(),
        }),
        columnHelper.accessor("dateOfReport", {
            header: () => "Report Date",
            cell: (info) => formatDate(new Date(info.renderValue())),
        }),
        columnHelper.accessor("name", {
            header: () => "Name",
            cell: (info) => info.getValue(),
        }),

        columnHelper.accessor("referenceNumber", {
            header: () => "Reference Number",
            cell: (info) => info.getValue(),
        }),

        columnHelper.accessor("sharmansRep", {
            header: () => "Reported by",
            cell: (info) => info.getValue(),
        }),

        columnHelper.accessor("companyName", {
            header: () => "Company",
            cell: (info) => info.getValue(),
        }),

        columnHelper.accessor("siteAddress", {
            header: () => "Site Address",
            cell: (info) => info.getValue(),
        }),
        columnHelper.accessor("id", {
            header: () => <div style={{ maxWidth: "50px" }}></div>,
            cell: (info) => {
                return (
                    <CenteredCell style={{ display: "flex", flexDirection: "row", justifyContent: "space-evenly" }}>
                        <div
                            style={{ maxWidth: "50px" }}
                            onClick={(e: any) => {
                                //e.stopPropagation() stops the ROW onclick firing!!!
                                e.stopPropagation();
                                const path: string = AppUrls.Client.ConditionReport.ViewRaw[AppUrlIndex.Long].replace(":reportId", info.getValue().toString());
                                navigate(path);
                            }}
                        >
                            <img src={spyScope} style={{ cursor: "pointer" }} />
                        </div>

                        <DeleteButton id={info.getValue().toString()} onClick={deleteCRClick} />
                    </CenteredCell>
                );
            },
        }),
    ];

    const deleteCRClick = (e: any, id: string): void => {
        setDeleteSelection(id);
        setIsDeleteSectionOpen(true);
    };

    function onRowClick(rowId: string | number): void {
        const path: string = AppUrls.Client.ConditionReport.PdfList[AppUrlIndex.Long].replace(":reportId", rowId.toString());
        navigate(path);
    }

    const updateSorting = (columnName: string, orderBy: SortOrderDirection) => {
        viewModel.columnName = columnName;
        viewModel.orderBy = orderBy;
        viewModel.loadConditionReportsAsync();
    };

    const onChangeRowPerPage = (rowsPerPage: number) => {
        viewModel.pageSize = rowsPerPage;
        viewModel.pageNumber = 1;

        viewModel.loadConditionReportsAsync();
    };

    const onChangePage = (change: PaginationDirection) => {
        switch (change) {
            case PaginationDirection.NEXT:
                viewModel.pageNumber < viewModel.pageCount ? viewModel.pageNumber++ : null;
                viewModel.loadConditionReportsAsync();
                break;
            case PaginationDirection.BACK:
                viewModel.pageNumber > 1 ? viewModel.pageNumber-- : null;
                viewModel.loadConditionReportsAsync();

                break;
            case PaginationDirection.START:
                viewModel.pageNumber = 1;
                viewModel.loadConditionReportsAsync();

                break;
            case PaginationDirection.END:
                viewModel.pageNumber = viewModel.pageCount;
                viewModel.loadConditionReportsAsync();

                break;
            default:
            // code block
        }

        // }
    };

    const cancelSectionDelete = () => {
        setIsDeleteSectionOpen(false);
    };

    const confirmSectionDelete = () => {
        let promise = viewModel.deleteCondtionReport(deleteSelectionId);

        promise.then(() => {
            setIsDeleteSectionOpen(false);
            setDeleteSelection("");
        });
    };

    const updateFilteringByKeywordSearch: ICommand = new RelayCommand((searchString: string) => {
        if (searchString == "") {
            viewModel.setSearchString(searchString);
            viewModel.loadConditionReportsAsync();
        } else {
            viewModel.setSearchString(searchString);
            if (searchString.length >= 3) {
                viewModel.loadConditionReportsAsync();
            }
        }
    });

    const FilteringTopSection = (
        <FilteringTopSectionGenericView
            searchValue={viewModel.searchString}
            searchExecute={() => viewModel.executeSearch()}
            searchCommand={updateFilteringByKeywordSearch}
            timescaleValue={""}
            isDisabled={!viewModel.getCanExecute}
        />
    );

    const updateFilteringByEditor: ICommand = new RelayCommand(
        (values: KeyValuePair[]) => {
            viewModel.updateFilteringByEditor(values);
            viewModel.pageNumber = 1;
            debounceFilter();
        },
        () => {
            return viewModel.getCanExecute;
        },
    );

    const FilteringBottomSection = (
        <FilteringBottomSectionBox grid dc={"1fr 1fr 1fr 1fr 1fr"}>
            <MultiEditSelect
                command={updateFilteringByEditor}
                isMulti={true}
                displayName="Editor"
                options={viewModel.getEditors}
                value={() => viewModel.editorsForFiltering}
                showSelectAllNone={true}
                selectAll={viewModel.editorsSelectAll}
                selectNone={viewModel.editorsSelectNone}
                isDisabled={!viewModel.getCanExecute}
            />
        </FilteringBottomSectionBox>
    );

    return (
        <BaseWrapper>
            {isDeleteSectionOpen === true && (
                <AreYouSureModal
                    title="Delete Condition Report"
                    message="Are you sure you want to delete this condition report?"
                    isOpen={isDeleteSectionOpen}
                    onCancel={cancelSectionDelete}
                    onConfirm={confirmSectionDelete}
                />
            )}
            <Box id="tablecontainer" p={3}>
                <Box flexGrow={1} textAlign="right" pb="20px">
                    <ButtonUnder
                        paletteColor={viewModel.isProcessing === true ? "ButtonInactive" : "ButtonBlue"}
                        boxShadow={true}
                        command={viewModel.newUserCommand}
                        displayName={"New Condition Report"}
                    />
                </Box>
                <SharmansTable
                    columns={columns}
                    data={viewModel.getConditionReports}
                    showFooter={false}
                    onRowClick={onRowClick}
                    onSortChange={updateSorting}
                    initialSortColumn={viewModel.columnName}
                    initialSortDirection={viewModel.orderBy}
                    showPagination={true}
                    totalRowCount={viewModel.getPageItemCount}
                    onChangePage={onChangePage}
                    onChangeRowPerPage={onChangeRowPerPage}
                    totalPageCount={viewModel.pageCount}
                    pageNumber={viewModel.pageNumber}
                    pageSize={viewModel.pageSize}
                    filteringElementsTopSection={FilteringTopSection}
                    filteringElementsBottomSection={FilteringBottomSection}
                    isProcessing={viewModel.isProcessing}
                />
            </Box>
        </BaseWrapper>
    );
});
