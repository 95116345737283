import styled from "@emotion/styled";
import { theme } from "Styles";

interface InputBoxProps {
    shape?: string;
}
export const InputWrapper = styled.div<InputBoxProps>`
    display: flex;
    flex-direction: column;
    align-self: end;
    .inputbox {
        display: flex;
        flex-direction: row;
        align-items: center;
        background-color: white;
        //height: 40px;
        box-sizing: content-box;
        border-radius: ${(p) => p.shape ?? 0};
        border: 1px solid ${theme.palette.field.main};
        transition: 0.5s ease all;
        //check for focus on the child input
        &:hover {
            border: 1px solid ${theme.palette.field.dark};
        }
        &:focus-within {
            border: 1px solid ${theme.palette.common.focus};
        }
        //react-datetime
        .rdt {
            width: 100%;
        }
    }
    input,
    textarea {
        /*  Theme properties*/
        color: ${theme.palette.field.text};
        font-family: ${theme.fontStyles.field.fontFamily ?? theme.defaultFontStyle.fontFamily};
        font-size: ${theme.fontStyles.field.fontSize ?? theme.defaultFontStyle.fontSize};
        font-weight: ${theme.fontStyles.field.fontWeight ?? theme.defaultFontStyle.fontWeight};
        letter-spacing: ${theme.fontStyles.field.letterSpacing ?? theme.defaultFontStyle.letterSpacing};
        line-height: ${theme.fontStyles.field.lineHeight ?? theme.defaultFontStyle.lineHeight};
        text-transform: ${theme.fontStyles.field.textTransform ?? theme.defaultFontStyle.textTransform};
        padding: 10px;
        /*End theme properties*/
        /* Resets */
        width: calc(100% - 24px);
        outline: 0;
        border: 0;
        -webkit-appearance: none;
        -ms-appearance: none;
        -moz-appearance: none;
        appearance: none;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        transition: 0.5s ease all;
        /* End Resets */

        ::placeholder {
            color: #8d8d8d;
        }
    }
    .rdtOpen.drop-to-the-right .rdtPicker {
        right: -25px;
    }
`;

export const FieldLabel = styled.label`
    font-family: ${theme.fontStyles.label.fontFamily ?? theme.defaultFontStyle.fontFamily};
    font-size: ${theme.fontStyles.label.fontSize ?? theme.defaultFontStyle.fontSize};
    font-weight: ${theme.fontStyles.label.fontWeight ?? theme.defaultFontStyle.fontWeight};
    letter-spacing: ${theme.fontStyles.label.letterSpacing ?? theme.defaultFontStyle.letterSpacing};
    line-height: ${theme.fontStyles.label.lineHeight ?? theme.defaultFontStyle.lineHeight};
    margin-top: 0px;
    margin-bottom: 8px;
    display: block;
    /* min-height: 20px; */
`;
