import React, { useEffect, useMemo, useRef, useState } from "react";
import { observer } from "mobx-react-lite";
import { Box, Button, EditSelect, ICommand, Input, RelayCommand } from "Application";
import { RoundedInputSuffix } from "../RoundedInputSuffix";
import { SpanEightBox, SpanFourBox, SpanTwoBox } from "../SpanBoxes";
import { AddressGenericViewModel } from "./AddressGenericViewModel";
import { CenteredTextBox, GridWrapper, PAddress, RoundedInputStyle } from "./AddressGeneric.style";
import { getDefaultZoomLevel, getSharmansPin } from "Components/Maps/Helpers";
import { MapContainer, Marker, TileLayer } from "react-leaflet";
import { MapPlaceholder } from "Views/Directory/Properties/ViewProperty/ViewPropertyView.style";
import { P } from "Components/Primitives/TextElements/TextElements";
import { LatLng } from "leaflet";
import { LatLongEditor } from "./LatLongEditor";

interface IAddressGeneric {
    addressViewModel: AddressGenericViewModel;
    showAddressFields: boolean;
}

export interface ILocation {
    lat: number;
    lng: number;
}

export const AddressGeneric: React.FC<IAddressGeneric> = observer(({ addressViewModel, showAddressFields }) => {
    const [modalState, setModalState] = useState<boolean>(false);
    const [location, setLocation] = useState<ILocation>({ lat: 0, lng: 0 });
    const markerRef = useRef(null);

    useEffect(() => {
        if (showAddressFields === true) {
            addressViewModel.setAddressFieldsPanelOpen();
        }

        let promise = addressViewModel.getCountries();

        return () => {
            // Clean up after yourself
            //    viewModel.clear();
            setModalState(false);
            setLocation({ lat: 0, lng: 0 });
        };
    }, []);

    useEffect(() => {
        if (
            (addressViewModel.model.latitude !== 0 || addressViewModel.model.longitude !== 0) &&
            addressViewModel.model.latitude !== null &&
            addressViewModel.model.longitude !== null &&
            isNaN(addressViewModel.model.latitude) === false &&
            isNaN(addressViewModel.model.longitude) === false
        ) {
            setLocation({ lat: addressViewModel.model.latitude, lng: addressViewModel.model.longitude });
        }
    }, [addressViewModel.model.latitude, addressViewModel.model.longitude]);

    const showLocationModal: ICommand = new RelayCommand(
        () => {
            if (addressViewModel.canShowMapPin === false) {
                let promise = addressViewModel.populateLatLongFromPostcode();

                promise.then(() => {
                    if (addressViewModel.canShowMapPin === false) {
                        // error been shown so cancel
                    } else {
                        setModalState(!modalState);
                    }
                });
            } else {
                setModalState(!modalState);
            }
        },
        () => {
            return addressViewModel.canShowLocationModal;
        },
    );

    const eventHandlers = useMemo(
        () => ({
            dragend() {
                const marker: any = markerRef.current;
                if (marker != null) {
                    const position: LatLng = marker.getLatLng();
                    setLocation(position);
                    addressViewModel.model.latitude = position.lat;
                    addressViewModel.model.longitude = position.lng;
                }
            },
        }),
        [],
    );

    return (
        <>
            <Box grid dc="1fr 4fr 1fr" pb="15px">
                <Input
                    displayName="Address search"
                    placeholder="Postcode"
                    command={addressViewModel.setPostcodeCommand}
                    validationMessage={() => addressViewModel.getError("postcode")}
                    style={RoundedInputStyle}
                    value={() => addressViewModel.getValue("postcode")}
                    suffix={<RoundedInputSuffix text="Find address" onClick={addressViewModel.findAddressesCommand} />}
                    autoFill={false}
                />
                <EditSelect
                    displayName="Select an address"
                    command={addressViewModel.setAddress}
                    options={addressViewModel.addresses}
                    value={() => addressViewModel.model.selectedAddress.key}
                />
                <CenteredTextBox>
                    {addressViewModel.showAddressFieldsPanel === true ? (
                        <PAddress>
                            <span className="link" onClick={addressViewModel.setAddressFieldsPanelClosed}>
                                Hide address fields
                            </span>
                        </PAddress>
                    ) : (
                        <PAddress>
                            Address not found? &nbsp; &nbsp;
                            <span className="link" onClick={addressViewModel.setAddressFieldsPanelOpen}>
                                Add address manually
                            </span>
                        </PAddress>
                    )}
                </CenteredTextBox>
            </Box>
            <GridWrapper showIf={addressViewModel.showAddressFieldsPanel}>
                <SpanTwoBox>
                    <Input
                        displayName="Address line 1 *"
                        placeholder="Enter address line 1"
                        validationMessage={() => addressViewModel.getError("addressLineOne")}
                        command={addressViewModel.setAddressOne}
                        value={() => addressViewModel.getValue("addressLineOne")}
                        onBlur={addressViewModel.onBlurCommand}
                        autoFill={false}
                    />
                </SpanTwoBox>
                <SpanTwoBox>
                    <Input
                        displayName="Address line 2"
                        placeholder="Enter address line 2"
                        validationMessage={() => addressViewModel.getError("addressLineTwo")}
                        command={addressViewModel.setAddressTwo}
                        value={() => addressViewModel.getValue("addressLineTwo")}
                        onBlur={addressViewModel.onBlurCommand}
                        autoFill={false}
                    />
                </SpanTwoBox>
                <SpanTwoBox>
                    <Input
                        displayName="Town/City *"
                        placeholder="Enter town/city"
                        command={addressViewModel.setTownCity}
                        validationMessage={() => addressViewModel.getError("townCity")}
                        value={() => addressViewModel.getValue("townCity")}
                        onBlur={addressViewModel.onBlurCommand}
                        autoFill={false}
                    />
                </SpanTwoBox>
                <SpanTwoBox>
                    <Input
                        displayName="County"
                        placeholder="Enter county"
                        validationMessage={() => addressViewModel.getError("county")}
                        command={addressViewModel.setCounty}
                        value={() => addressViewModel.getValue("county")}
                        autoFill={false}
                    />
                </SpanTwoBox>
                <SpanTwoBox>
                    <EditSelect
                        command={addressViewModel.updateCountryId}
                        displayName="Country *"
                        placeholder="Enter country"
                        options={addressViewModel.countries}
                        validationMessage={() => addressViewModel.getError("countryId")}
                        value={() => (addressViewModel.model.countryId === null ? "-1" : addressViewModel.model.countryId.toString())}
                    />
                </SpanTwoBox>
                <SpanTwoBox>
                    <Input
                        displayName="Postcode *"
                        placeholder="Enter postcode"
                        command={addressViewModel.setPostcodeCommand}
                        validationMessage={() => addressViewModel.getError("postcode")}
                        value={() => addressViewModel.getValue("postcode")}
                        onBlur={addressViewModel.onBlurCommand}
                        autoFill={false}
                    />
                </SpanTwoBox>

                <SpanTwoBox mt={26}>
                    <Button
                        mr={2}
                        command={showLocationModal}
                        displayName={"Refine Location"}
                        style={{ padding: "5px 20px" }}
                        paletteColor={addressViewModel.isProcessing === true || addressViewModel.canShowLocationModal === false ? "ButtonInactive" : "ButtonBlue"}
                    />
                </SpanTwoBox>
            </GridWrapper>

            {modalState === true && addressViewModel.isProcessing === false && location.lat !== null && location.lng !== null && (location.lat !== 0 || location.lng !== 0) && (
                <>
                    <PAddress mt="20px" mb="20px">
                        <span className="link" onClick={addressViewModel.setLatLongPanelState}>
                            Show Latitude & Longitude
                        </span>
                    </PAddress>

                    {addressViewModel.showLatLongPanel === true && (
                        <GridWrapper mt="20px" mb="20px">
                            <LatLongEditor addressViewModel={addressViewModel} />
                        </GridWrapper>
                    )}

                    <SpanEightBox>
                        {addressViewModel.getValue("latitude") !== null && addressViewModel.getValue("longitude") !== null ? (
                            <MapContainer
                                style={{ width: "100%", height: "433px", zIndex: 0 }}
                                center={[location.lat, location.lng]}
                                zoom={getDefaultZoomLevel()}
                                scrollWheelZoom={false}
                            >
                                <TileLayer
                                    attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                                    url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                                />
                                <Marker eventHandlers={eventHandlers} ref={markerRef} draggable={true} icon={getSharmansPin()} position={[location.lat, location.lng]}></Marker>
                            </MapContainer>
                        ) : (
                            <MapPlaceholder height="433px">
                                <P>No location has been set for this property.</P>
                            </MapPlaceholder>
                        )}
                    </SpanEightBox>
                </>
            )}
        </>
    );
});
