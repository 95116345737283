import React, { useEffect, useState } from "react";
import { observer } from "mobx-react-lite";

import { useNavigate, useParams } from "react-router";
import { Box, Button, CheckBox, EditDate, EditSelect, ICommand, Input, Label, RelayCommand, ThemedLoader } from "Application";
import { SubHeaderView, subHeadingButton } from "Views/Header/SubHeader";

import { H2 } from "Components/Primitives/TextElements/TextElements";
import { CreateCustomFooter, GridWrapper } from "Components/CommonPageComponents";
import { SpanFourBox, SpanTwoBox, RightDateBlock } from "Components/SpanBoxes";
import { SubmitType } from "Application/Models/Domain/Forms";
import { TasksAddEditWrapper } from "./TasksAddEdit.style";
import { ITasksAddEditViewModel } from "./ITasksAddEditViewModel";
import { AppUrlIndex, AppUrls } from "AppUrls";
import { SharmanTaskTypeEnum, TasksTypeEnumHelpers } from "Models/Tasks/TaskTypeEnum";
import { MasterProjectTasksAddEditViewModel } from "./MasterProjectTasksAddEditViewModel";
import { Header } from "Views/Header/Header";
import { ProjectQuoteTasksAddEditViewModel } from "./ProjectQuoteTasksAddEditViewModel";
import { ComplaintTasksAddEditViewModel } from "./ComplaintTasksAddEditViewModel";
import { CustomerTasksAddEditViewModel } from "./CustomerTasksAddEditViewModel";
import { TasksListAddEditViewModel } from "./TasksListAddEditViewModel";
import tick from "Assets/Icons/SmallTickCircleIcon.svg";
import { DatePickerBox } from "Components/Primitives/DatePickerBox";
import { BackButtonNavigation } from "Components/BackButtonNavigation";
import styled from "@emotion/styled";

export const TextAreaBox = styled(Box)`
    textarea {
        width: calc(100% - 24px) !important;
        white-space: normal !important;
        overflow: auto;
    }
`;

export const TasksAddEditView: React.FC = observer(() => {
    const navigate = useNavigate();
    let params = useParams();

    const [viewModel, setViewModel] = useState<ITasksAddEditViewModel | undefined>(undefined);
    const [isEdit, setIsEdit] = useState(false);

    useEffect(() => {
        if (TasksTypeEnumHelpers.getFromUrlComponent(params.tasktype!) === SharmanTaskTypeEnum.MasterProject) {
            setViewModel(new MasterProjectTasksAddEditViewModel());
        } else if (TasksTypeEnumHelpers.getFromUrlComponent(params.tasktype!) === SharmanTaskTypeEnum.ProjectQuote) {
            setViewModel(new ProjectQuoteTasksAddEditViewModel());
        } else if (TasksTypeEnumHelpers.getFromUrlComponent(params.tasktype!) === SharmanTaskTypeEnum.Complaints) {
            setViewModel(new ComplaintTasksAddEditViewModel());
        } else if (TasksTypeEnumHelpers.getFromUrlComponent(params.tasktype!) === SharmanTaskTypeEnum.Customer) {
            setViewModel(new CustomerTasksAddEditViewModel());
        } else {
            setViewModel(new TasksListAddEditViewModel());
        }

        return () => {
            // Clean up after yourself
            if (viewModel !== undefined) {
                viewModel.clear();
            }
        };
    }, []);

    useEffect(() => {
        if (viewModel !== undefined) {
            if (params.taskid === "new") {
                let id =
                    TasksTypeEnumHelpers.getFromUrlComponent(params.tasktype!) === SharmanTaskTypeEnum.MasterProject
                        ? params.masterprojectid!
                        : TasksTypeEnumHelpers.getFromUrlComponent(params.tasktype!) === SharmanTaskTypeEnum.ProjectQuote
                        ? params.masterprojectid!
                        : TasksTypeEnumHelpers.getFromUrlComponent(params.tasktype!) === SharmanTaskTypeEnum.Complaints
                        ? params.complaintid!
                        : TasksTypeEnumHelpers.getFromUrlComponent(params.tasktype!) === SharmanTaskTypeEnum.Customer
                        ? params.customerId!
                        : params.taskid!;

                let promise = viewModel.getTasksRelatedForProject(id!);

                promise.then(() => {
                    setIsEdit(false);
                });
            } else {
                let promise = viewModel.getTasks(params.taskid!);
                promise.then((kv) => {
                    setIsEdit(true);
                });
            }
        }
    }, [viewModel]);

    const handleSubHeadingButtonClicks: ICommand = new RelayCommand((value: subHeadingButton) => {
        if (
            params.tasktype !== undefined ||
            params.masterprojectid !== undefined ||
            params.complaintid !== undefined ||
            params.customerId !== undefined ||
            params.taskid !== undefined
        ) {
            switch (value) {
                case subHeadingButton.Back:
                    if (TasksTypeEnumHelpers.getFromUrlComponent(params.tasktype!) === SharmanTaskTypeEnum.ProjectQuote) {
                        navigate(BackButtonNavigation(AppUrls.Client.Work.Quotes.View[AppUrlIndex.Long].replace(":projectquoteid", params.masterprojectid!) + "/tasks"));
                        break;
                    } else if (TasksTypeEnumHelpers.getFromUrlComponent(params.tasktype!) === SharmanTaskTypeEnum.MasterProject) {
                        navigate(BackButtonNavigation(AppUrls.Client.Work.Master.View[AppUrlIndex.Long].replace(":masterprojectid/details", params.masterprojectid!) + "/tasks"));
                        break;
                    } else if (TasksTypeEnumHelpers.getFromUrlComponent(params.tasktype!) === SharmanTaskTypeEnum.Customer) {
                        navigate(BackButtonNavigation(AppUrls.Client.Directory.CustomersView[AppUrlIndex.Long].replace(":customerId", params.customerId!) + "/tasks"));
                        break;
                    } else if (TasksTypeEnumHelpers.getFromUrlComponent(params.tasktype!) === SharmanTaskTypeEnum.Complaints) {
                        navigate(BackButtonNavigation(AppUrls.Client.Aftersales.ComplaintsView[AppUrlIndex.Long].replace(":complaintid/details", params.complaintid!) + "/tasks"));
                        break;
                    } else {
                        navigate("/dashboard/tasks/list");
                    }
                    break;
                default:
            }
        }
    });

    const getBackButtonText = () => {
        let retVal = "Back to ";
        if (params.tasktype !== undefined || params.masterprojectid !== undefined || params.customerId !== undefined || params.complaintid !== undefined) {
            if (TasksTypeEnumHelpers.getFromUrlComponent(params.tasktype!) === SharmanTaskTypeEnum.ProjectQuote) {
                retVal = "Back to Project Quote";
            } else if (TasksTypeEnumHelpers.getFromUrlComponent(params.tasktype!) === SharmanTaskTypeEnum.MasterProject) {
                retVal = "Back to Master Project";
            } else if (TasksTypeEnumHelpers.getFromUrlComponent(params.tasktype!) === SharmanTaskTypeEnum.Customer) {
                retVal = "Back to customers";
            } else if (TasksTypeEnumHelpers.getFromUrlComponent(params.tasktype!) === SharmanTaskTypeEnum.Complaints) {
                retVal = "Back to complaint List";
            } else {
                retVal = "Back to Tasks List";
            }
        }
        return retVal;
    };

    const navigateToList = () => {
        if (TasksTypeEnumHelpers.getFromUrlComponent(params.tasktype!) === SharmanTaskTypeEnum.ProjectQuote) {
            navigate(BackButtonNavigation(AppUrls.Client.Work.Quotes.View[AppUrlIndex.Long].replace(":projectquoteid", params.masterprojectid!) + "/tasks"));
        } else if (TasksTypeEnumHelpers.getFromUrlComponent(params.tasktype!) === SharmanTaskTypeEnum.MasterProject) {
            navigate(BackButtonNavigation(AppUrls.Client.Work.Master.View[AppUrlIndex.Long].replace(":masterprojectid/details", params.masterprojectid!) + "/tasks"));
        } else if (TasksTypeEnumHelpers.getFromUrlComponent(params.tasktype!) === SharmanTaskTypeEnum.Customer) {
            navigate(BackButtonNavigation(AppUrls.Client.Directory.CustomersView[AppUrlIndex.Long].replace(":customerId", params.customerId!) + "/tasks"));
        } else if (TasksTypeEnumHelpers.getFromUrlComponent(params.tasktype!) === SharmanTaskTypeEnum.Complaints) {
            navigate(BackButtonNavigation(AppUrls.Client.Aftersales.ComplaintsView[AppUrlIndex.Long].replace(":complaintid/details", params.complaintid!) + "/tasks"));
        } else {
            navigate("/dashboard/tasks/list");
        }
    };

    const submitTask: ICommand = new RelayCommand((submitType: SubmitType) => {
        if (viewModel !== undefined) {
            if (submitType === SubmitType.SaveAndExit) {
                navigateToList();
            } else if (submitType === SubmitType.SaveAndContinue && viewModel.isModelValid() === true) {
                let id =
                    TasksTypeEnumHelpers.getFromUrlComponent(params.tasktype!) === SharmanTaskTypeEnum.MasterProject
                        ? params.masterprojectid!
                        : TasksTypeEnumHelpers.getFromUrlComponent(params.tasktype!) === SharmanTaskTypeEnum.ProjectQuote
                        ? params.masterprojectid!
                        : TasksTypeEnumHelpers.getFromUrlComponent(params.tasktype!) === SharmanTaskTypeEnum.Complaints
                        ? params.complaintid!
                        : TasksTypeEnumHelpers.getFromUrlComponent(params.tasktype!) === SharmanTaskTypeEnum.Customer
                        ? params.customerId!
                        : params.taskid;

                let promise = viewModel.upsertAsync(id!);
                promise.then(() => {
                    navigateToList();
                    viewModel.setShowActionSection(true);
                    setIsEdit(true);
                });
            }
        }
    });

    return (
        <>
            <Header />
            <SubHeaderView buttonClick={handleSubHeadingButtonClicks} backButtonText={String(getBackButtonText())} />

            <TasksAddEditWrapper>
                {viewModel === undefined || viewModel.getIsProcessing ? (
                    <ThemedLoader isOpen={true} />
                ) : (
                    <>
                        <H2 pt="40px" pb="10px" mt={"30px"}>
                            {isEdit === true ? <div>Edit Tasks</div> : <div>New Tasks</div>}
                        </H2>

                        <GridWrapper mb="20px">
                            <SpanTwoBox>
                                <EditSelect
                                    displayName="Task type*:"
                                    command={viewModel.setTaskType}
                                    options={viewModel.getTaskTypeDropdownItems}
                                    value={() => viewModel.getValue("subTaskTypeId")}
                                    validationMessage={() => viewModel.getError("subTaskTypeId")}
                                />
                            </SpanTwoBox>
                            <SpanTwoBox>
                                <EditSelect
                                    displayName="Assign this task to*:"
                                    command={viewModel.setSubTaskType}
                                    options={viewModel.getSubTaskTypeDropdownItems}
                                    value={() => viewModel.getValue("assignedUserId")}
                                    validationMessage={() => viewModel.getError("assignedUserId")}
                                />
                            </SpanTwoBox>
                        </GridWrapper>
                        <GridWrapper mb="20px">
                            <SpanFourBox>
                                <TextAreaBox>
                                    <Input
                                        command={viewModel.updatedDescriptionCommand}
                                        displayName="Task detail:"
                                        placeholder="Detail"
                                        value={() => viewModel.getValue("description")}
                                        multiline={true}
                                        rows={5}
                                        autoFill={false}
                                    />
                                </TextAreaBox>
                            </SpanFourBox>
                        </GridWrapper>
                        <GridWrapper mb="20px">
                            <RightDateBlock>
                                <DatePickerBox>
                                    <EditDate
                                        dateFormat={"DD/MM/YYYY HH:mm"}
                                        command={viewModel.updateStartDateCommand}
                                        value={viewModel.getValue("startDateTime")}
                                        displayName="Date set: "
                                        style={{ marginRight: "10px" }}
                                    />
                                </DatePickerBox>
                            </RightDateBlock>
                            <RightDateBlock>
                                <DatePickerBox>
                                    <EditDate
                                        command={viewModel.updateDueDateCommand}
                                        dateFormat={"DD/MM/YYYY HH:mm"}
                                        value={viewModel.getValue("dueDateTime")}
                                        displayName="Due date:"
                                        style={{ marginRight: "10px" }}
                                    />
                                </DatePickerBox>
                            </RightDateBlock>
                        </GridWrapper>
                        <GridWrapper mb="20px">
                            <CheckBox
                                displayName={"Is this task urgent?:"}
                                command={viewModel.updateIsUrgentCommand}
                                value={() => viewModel.getValue("isUrgent")}
                                paletteColor={"secondary"}
                                validationMessage={() => viewModel.getError("isUrgent")}
                            />
                        </GridWrapper>
                        {isEdit && (
                            <GridWrapper mb="20px">
                                <CheckBox
                                    displayName={"Is this task completed?:"}
                                    command={viewModel.updateIsCompleteCommand}
                                    value={() => viewModel.getValue("isComplete")}
                                    paletteColor={"secondary"}
                                    validationMessage={() => viewModel.getError("isComplete")}
                                />
                            </GridWrapper>
                        )}
                    </>
                )}
            </TasksAddEditWrapper>
            <CreateCustomFooter>
                <Button
                    displayName="Save"
                    icon={<img src={tick} />}
                    value={SubmitType.SaveAndContinue}
                    style={{ marginRight: "10px" }}
                    command={submitTask}
                    paletteColor="ButtonGreen"
                    boxShadow={true}
                />
                <Button
                    displayName="Cancel"
                    icon={<img src={tick} />}
                    value={SubmitType.SaveAndExit}
                    style={{ marginRight: "10px" }}
                    command={submitTask}
                    paletteColor="ButtonInactive"
                    boxShadow={true}
                />
            </CreateCustomFooter>
        </>
    );
});
