import React, { useEffect, useState } from "react";
import { observer } from "mobx-react-lite";
import { Box, Button, ICommand } from "Application";
import { PreviewDocumentModalWrapper } from "./PreviewUploadedDocumentModal.styles";
import tick from "Assets/Icons/SmallTickCircleIcon.svg";
import DocumentIcon from "Assets/Icons/DocumentIcon.svg";
import { PreviewView } from "Components/DocumentUploader/PreviewView/PreviewView";
import { PreviewUploadedDocumentViewModel } from "./PreviewUploadedDocumentModalViewModel";
import { ThemedLoaderSmaller } from "Components/Primitives/Loaders/ThemedLoaderSmaller";

interface IProps {
    isOpen: boolean;
    closeModal: ICommand<any>;
    id: string;
    fileName: string;
    blobName: string;
    fileType: string;
    docType: string;
}

export const PreviewUploadedDocumentModal: React.FC<IProps> = observer((props) => {
    const [viewModel] = useState(new PreviewUploadedDocumentViewModel());
    useEffect(() => {
        viewModel.downloadDocumentForPreviewCommand(props.id, props.fileName, props.blobName, props.fileType, props.docType);
        return () => {
            // Clean up after yourself
            //    viewModel.clear();
        };
    }, []);

    return (
        <>
            <PreviewDocumentModalWrapper
                isOpen={props.isOpen}
                headerText="Document Preview"
                headerIcon={DocumentIcon}
                stickyBar={
                    <>
                        <Button displayName=" Close" paletteColor="ButtonCancel" command={props.closeModal} icon={<img src={tick} />} boxShadow={true} />
                    </>
                }
                closeModalCommand={props.closeModal}
            >
                {viewModel.isProcessing ? (
                    <ThemedLoaderSmaller isOpen={false} />
                ) : (
                    <>{viewModel.documentToPreview.length > 0 && <PreviewView documentList={viewModel.documentToPreview} previewFileIndex={0} />}</>
                )}
            </PreviewDocumentModalWrapper>
        </>
    );
});
