import styled from "@emotion/styled";
import { Box } from "Components";
import { P } from "../TextElements/TextElements";
import { theme } from "Application";

export interface ITableProps {
    stickyTop: number;
}

export const TableWrapper = styled(Box)<ITableProps>`
    * {
        box-sizing: border-box;
    }

    html {
        font-family: sans-serif;
        font-size: ${theme.fontStyles.tableGeneral.fontSize};
    }

    td {
        border: 1px solid #d4d4d4;
        font-size: ${theme.fontStyles.tableRow.fontSize};
    }

    //Spacing between thead and tbody
    tbody:before {
        content: "@";
        display: block;
        line-height: 14px;
        text-indent: -99999px;
        border: none;
    }

    table,
    .divTable {
        width: fit-content;
        border-collapse: collapse;
        width: 100%;
        table-layout: auto;
    }

    table {
        width: 100% !important;
    }

    tbody > tr:nth-of-type(1) {
        border-top: 1px solid #d4d4d4;
        z-index: 1;
    }

    thead {
        position: sticky;
        top: ${(props) => props.stickyTop + "px"};
    }

    .t-head {
        height: 40px;
        z-index: 2;
    }

    .tr {
        display: flex;
    }

    .td {
        box-sizing: border-box;
    }

    tr,
    .tr {
        width: fit-content;
        height: 30px;
        cursor: pointer;
    }

    tr .pastdue {
        border: 1px solid #ce0e2d !important;
    }

    .pastdue {
        td {
            border: 1px solid #ce0e2d !important;
            box-sizing: border-box;
        }

        td:first-child {
            border-inline-start: 10px solid #ce0e2d !important;
            writing-mode: horizontal-tb;
            padding-left: 10px;
            box-sizing: border-box; /* Ensures the border is included in the cell's width */
        }
    }

    .pastduenext {
        td {
            border-bottom: 1px solid #ce0e2d !important;
        }
    }

    th,
    .th,
    td,
    .td {
        padding: 6px;
    }

    th,
    .th {
        padding: 2px 8px;
        font-weight: 600;
        font-size: ${theme.fontStyles.tableRow.fontSize};
        text-align: left;
        height: 30px;
        border-right: 1px solid white;
    }

    td,
    .td {
        height: 30px;
    }

    th {
        cursor: pointer;
        -webkit-user-select: none; /* Safari */
        -ms-user-select: none; /* IE 10 and IE 11 */
        user-select: none; /* Standard syntax */
    }

    .resizer {
        position: absolute;
        right: -1px;
        top: 0;
        height: 100%;
        width: 3px;
        background: transparent;
        cursor: col-resize;
        user-select: none;
        touch-action: none;
    }

    .resizer.isResizing {
        background: blue;
        opacity: 1;
    }

    @media (hover: hover) {
        .resizer {
            opacity: 0;
        }

        *:hover > .resizer {
            opacity: 1;
        }
    }

    .arrow-box {
        min-width: 15px;
        padding-left: 4px;
    }

    .title-wrapper {
        display: flex;
    }

    .action-cell {
        position: relative;
        display: flex;
        flex-direction: row;
        justify-content: space-evenly;
    }
`;

export const PaginationWrapper = styled(Box)`
    margin-top: 5px;
    display: flex;
    justify-content: space-between;
`;

export interface IPagButton {
    isStartOrEnd: boolean;
}

export const PaginationButton = styled.button<IPagButton>`
    background-color: transparent;
    border: none;
    padding: 10px;
    color: black;
    font-size: ${theme.fontStyles.tablePaginationButton.fontSize};
    font-weight: 300;
    cursor: ${(props) => (props.isStartOrEnd ? "default" : "pointer")};
`;

export const ButtonsWrapper = styled(Box)`
    display: flex;
    align-items: center;
    padding-top: 8px;

    .PaginationInput {
        border: 1px solid #dedede;
        padding: 3px 8px;
        min-height: 37px;
        font-size: ${theme.fontStyles.tablePaginationInput.fontSize};
    }
`;

export const ItemsPerPageWrapper = styled(Box)`
    display: flex;
    align-items: center;
    gap: 20px;
`;

export const FilteringSectionTop = styled(Box)`
    background-color: #fcfcfc;
    padding: 0px 0px 15px 0px;

    position: sticky;
    top: 29px;
    z-index: 2;
`;

export const FilteringSectionBottom = styled(Box)`
    background-color: #ededed;
    min-height: 98px;
    top: 90px;
    padding: 15px 10px 25px 10px;
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    z-index: 3;
`;

export interface INextPrevText {
    isStartOrEnd: boolean;
}

export const NextPrevText = styled(P)<INextPrevText>`
    font-weight: bold;
    cursor: ${(props) => (props.isStartOrEnd ? "default" : "pointer")};
    opacity: ${(props) => (props.isStartOrEnd ? 0.5 : 1)};
    user-select: none;
`;

export interface IImg {
    isStartOrEnd: boolean;
}

export const ButtonImg = styled.img<IImg>`
    opacity: ${(props) => (props.isStartOrEnd ? 0.5 : 1)};
`;
