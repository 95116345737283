import React, { useEffect, useState } from "react";
import { observer } from "mobx-react-lite";
import { Box, ICommand, RelayCommand } from "Application";
import styled from "@emotion/styled";
import spyScope from "Assets/Icons/magnifyingGlass.svg";
import { PreviewUploadedDocumentModal } from "Components/Modals/PreviewUploadedDocumentModal/PreviewUploadedDocumentModal";
import { checkIsAcceptedFileByFileName } from "Utils/File";

export const BlueButtonBox = styled(Box)`
    width: 20px;
    height: 20px;
    max-width: 20px;
    max-height: 20px;
    /* UI Properties */
    /* background: #337ab7 0% 0% no-repeat padding-box; */
    background: transparent;
    /* box-shadow: 0px 3px 3px #00000029; */
    border-radius: 5px;
    opacity: 1;
    display: flex;
    flex-direction: column;
    :hover {
        cursor: pointer;
    }

    img {
        width: 20px;
        height: 20px;
        /*         background: transparent url("img/sort.png") 0% 0% no-repeat padding-box; */
        opacity: 1;
        /* margin-top: 4px;
        margin-left: 5px; */
    }

    .dash {
        margin-top: 1px;
        margin-left: 5px;

        width: 10px;
        height: 2px;
        /* UI Properties */
        background: #ffffff 0% 0% no-repeat padding-box;
        opacity: 1;
    }
`;

export interface IProps {
    id: string;
    onClick: (e: any, id: string) => void;
    fileName: string;
    blobName: string;
    fileType: string;
    docType: string;
}

export const ViewDocumentPreviewButton: React.FC<IProps> = observer((props) => {
    const [isPreviewModalOpen, setIsPreviewModalOpen] = useState(false);
    useEffect(() => {
        return () => {
            // Clean up after yourself
            //viewModel.clear();
        };
    }, []);

    const localClick = (e: any) => {
        // e.stopPropagation();
        // onClick(e, id);
        setIsPreviewModalOpen(true);
    };

    const closeModal: ICommand = new RelayCommand(() => {
        setIsPreviewModalOpen(false);
    });

    return (
        <>
            {isPreviewModalOpen && (
                <PreviewUploadedDocumentModal
                    isOpen={isPreviewModalOpen}
                    closeModal={closeModal}
                    id={props.id}
                    fileName={props.fileName}
                    blobName={props.blobName}
                    fileType={props.fileType}
                    docType={props.docType}
                />
            )}
            {checkIsAcceptedFileByFileName(props.fileName) ? (
                <BlueButtonBox onClick={localClick}>
                    <img src={spyScope} style={{ cursor: "pointer" }} />
                </BlueButtonBox>
            ) : (
                <BlueButtonBox></BlueButtonBox>
            )}
        </>
    );
});
