import { ModelBase } from "@shoothill/core";
import { makeObservable, observable } from "mobx";
import { Validator } from "Application/Validation";
import { ICustomerNote } from "../../Common/ICustomerNote";
import { IDocumentUploaded } from "Models/Documents/IDocumentUploaded";
import { ContactListItem } from "../../NewCustomer/CustomerContacts/CustomerContactsModel";

export class DetailsModel extends ModelBase<DetailsModel> {
    // public id: string = "";
    public addressLine1: string = "";
    public addressLine2: string = "";
    public townCity: string = "";
    public county: string = "";
    public country: string = "";
    public countryId: number = -1;
    public postCode: string = "";
    public locationLatitude: number | null = null;
    public locationLongitude: number | null = null;
    public phone: string = "";
    public phone2: string = "";
    public email: string = "";
    public websiteAddress: string = "";
    public customerTypeId: number = 1;
    public leadTsm: number | null = null;
    public eori: string | null = null;
    public sageRef: string | null = null;
    public bdm: number | null = null;
    public tss: string | null = null;

    public referral: boolean | null = null;

    public dateCoatingsTrained: string | null = null;
    public datePlygeneTrained: string | null = null;
    public coatingsTrained: boolean = false;
    public plygeneTrained: boolean = false;

    public coatingsCPD: boolean = false;
    public plygeneCPD: boolean = false;
    public dateCoatingsCPD: Date | null = null;
    public datePlygeneCPD: Date | null = null;

    constructor() {
        super();
        makeObservable(this, {
            // id: observable
            addressLine1: observable,
            addressLine2: observable,
            dateCoatingsTrained: observable,
            datePlygeneTrained: observable,
            townCity: observable,
            county: observable,
            country: observable,
            countryId: observable,
            postCode: observable,
            locationLatitude: observable,
            locationLongitude: observable,
            phone: observable,
            phone2: observable,
            email: observable,
            websiteAddress: observable,
            customerTypeId: observable,
            leadTsm: observable,
            eori: observable,
            sageRef: observable,
            bdm: observable,
            tss: observable,
            referral: observable,

            coatingsCPD: observable,
            plygeneCPD: observable,
            dateCoatingsCPD: observable,
            datePlygeneCPD: observable,
        });
    }

    public clear = () => {
        this.id = "";
        this.addressLine1 = "";
        this.addressLine2 = "";
        this.dateCoatingsTrained = null;
        this.datePlygeneTrained = null;
        this.townCity = "";
        this.county = "";
        this.country = "";
        this.countryId = -1;
        this.postCode = "";
        this.locationLatitude = null;
        this.locationLongitude = null;
        this.phone = "";
        this.phone2 = "";
        this.email = "";
        this.websiteAddress = "";
        this.customerTypeId = 1;
        this.leadTsm = null;
        this.eori = null;
        this.sageRef = null;
        this.bdm = null;
        this.tss = null;
        this.referral = null;

        this.plygeneCPD = false;
        this.coatingsCPD = false;
        this.datePlygeneCPD = null;
        this.dateCoatingsCPD = null;
    };
}

export class DetailsModelValidator extends Validator<DetailsModel> {
    constructor() {
        super();
        //this.ruleFor("id").notEmpty();
    }
}

export interface CustomerDetailsForEdit extends CustomerDetails {
    notes: ICustomerNote[];
    documents: IDocumentUploaded[];
    contacts: ContactListItem[];
}

export interface CustomerDetails {
    id: string | null;
    addressLine1: string;
    addressLine2: string;
    townCity: string;
    county: string;
    country: string;
    countryId: number;
    postCode: string;
    locationLatitude: number | null;
    locationLongitude: number | null;
    phone: string;
    phone2: string;
    email: string;
    websiteAddress: string;
    customerTypeId: number;
    name: string;
    rowVersion: string;
    dateCoatingsTrained: string | null;
    datePlygeneTrained: string | null;

    coatingsTrained: boolean;
    plygebeTrained: boolean;

    dateCoatingsCPD: string | null;
    datePlygeneCPD: string | null;

    coatingsCPD: boolean;
    plygeneCPD: boolean;

    // Options
    optionsId: number;
    leadTsm: number | null;
    leadTsmId: number | null;
    eori: string | null;
    sageRef: string | null;
    bdm: number | null;
    bdmId: number | null;
    tss: string | null;
    approved: boolean;
    referral: boolean | null;
}
