import { IObservableArray, action, computed, makeObservable, observable, runInAction } from "mobx";
import { CoreStoreInstance, KeyValuePair, ViewModelBase } from "@shoothill/core";
import { APIClient, ICommand, RelayCommand } from "Application";
import { ErrorStore } from "Stores/Domain/ErrorStore";
import { container } from "tsyringe";
import { DeleteCustomerNoteByIdEndpoint } from "../../Endpoints/DeleteCustomerNoteById";
import { GetCustomerNotesFromCustomerIdEndpoint } from "../../Endpoints/GetCustomerNotesFromCustomerId";
import { UpsertCustomerNoteEndpoint } from "../../Endpoints/UpsertCustomerNote";
import { CustomerNoteDocumentItemViewModel } from "./CustomerNoteDocumentItemViewModel";
import { File64, getFileTo64 } from "Utils/File";
import { UploadNoteDocumentEndpoint } from "../../Endpoints/UploadNoteDocument";
import { GetCustomerNoteWithRelatedByIdEndpoint } from "../../Endpoints/GetCustomerNoteWithRelatedById";
import { DeleteCustomerNoteDocumentByIdEndpoint } from "../../Endpoints/DeleteCustomerNoteDocumentById";
import { ModalType } from "Application/Models/Domain/AddEdditModalType";
import { SubmitType } from "Application/Models/Domain/Forms";
import { GenericNotesModel, GenericNotesModelValidator } from "Models/Notes/GenericNotesModel";
import { IDocumentUploaded } from "Models/Documents/IDocumentUploaded";
import { IDocumentType } from "Models/Documents/DocumentType";
import { ICustomerNote } from "Views/Directory/Customers/Common/ICustomerNote";
import { DocumentDownloaderEndpoint, DocumentDownloaderRequest } from "Endpoint/DocumentDownloader";
import { INotesViewModel } from "Components/Modals/IAddNoteViewModel";

export class CustomerNotesViewModel extends ViewModelBase<GenericNotesModel> implements INotesViewModel {
    public apiClient = new APIClient();
    errorStore = container.resolve(ErrorStore);
    public spinnerText: string = "";
    public isProcessing: boolean = false;
    public areYouSureModelOpen: boolean = false;
    public noteToDelete: Guid = "";
    public modalType: ModalType = ModalType.Add;
    public documentTypes: IObservableArray<IDocumentType> = observable([]);

    public showNewNoteModal: boolean = false;
    public customerId: Guid = "";
    public customerNotes: IObservableArray<ICustomerNote> = observable([]);
    public showDocumentUploader: boolean = false;
    public isAreYouSureModalOpen: boolean = false;

    public documentsToUpload: CustomerNoteDocumentItemViewModel[] = [];
    public uploadedDocuments: IObservableArray<IDocumentUploaded> = observable([]);

    public validateDocumentsToUpload: boolean = false;

    public noteCountCallBack: (newCount: number) => void;

    constructor(customerId: Guid, noteCountCallBack: (newCount: number) => void) {
        super(new GenericNotesModel());
        this.customerId = customerId;
        this.noteCountCallBack = noteCountCallBack;
        this.setValue("sourceId", customerId);
        this.setValidator(new GenericNotesModelValidator());
        makeObservable(this, {
            showNewNoteModal: observable,
            isProcessing: observable,
            areYouSureModelOpen: observable,
            noteToDelete: observable,
            documentsToUpload: observable,
            validateDocumentsToUpload: observable,
            showDocumentUploader: observable,
            customerId: observable,
            isAreYouSureModalOpen: observable,

            addDocumentFileAndNote: action,
            addFiles: action,
            clear: action,
            clearNote: action,
            // ICommand closeAddNoteModal: action,
            closeAreYouSureModal: action,
            confirmDeleteNote: action,
            cancelDelete: action,
            deleteDocument: action,
            deleteNote: action,
            deleteNoteByIdCommand: action,
            deleteNoteCheck: action,
            downloadDocumentCommand: action,
            downloadDocumentAsync: action,
            getNoteAndRelatedById: action,
            getNotes: action,
            openEditNoteModal: action,
            upsertNote: action,
            updateCustomerId: action,
            updateNotesForEditing: action,

            getDocTypesForDropdown: computed,
            getNotesForTable: computed,
        });
    }

    public clear = () => {
        this.spinnerText = "";
        this.isProcessing = false;
        this.areYouSureModelOpen = false;
        this.noteToDelete = "";
        this.modalType = ModalType.Add;
        // this.documentTypes.clear();
        // this.showNewNoteModal = false;
        // this.customerId = "";
        this.customerNotes.clear();
        this.showDocumentUploader = false;
        this.documentsToUpload = [];
        this.uploadedDocuments.clear();
        this.validateDocumentsToUpload = false;
        this.model.clear();
    };

    /// Clear note, only clear the things we need to clean for opening the model
    public clearNote = () => {
        this.areYouSureModelOpen = false;
        this.noteToDelete = "";
        this.modalType = ModalType.Add;
        this.showNewNoteModal = false;
        // this.customerNotes.clear();
        this.showDocumentUploader = false;
        this.documentsToUpload = [];
        // this.uploadedDocuments.clear();
        this.validateDocumentsToUpload = false;
        this.model.clear();
    };

    public openAddNoteModal: ICommand = new RelayCommand(() => {
        this.clearNote();
        this.setValue("id", null);
        this.setValue("sourceId", this.customerId);
        this.modalType = ModalType.Add;
        this.showDocumentUploader = false;
        this.showNewNoteModal = true;
    });

    public openEditNoteModal = (e: any, id: string) => {
        this.setValue("id", id);
        this.setValue("sourceId", this.customerId);
        this.getNoteAndRelatedById(id);
        let note: ICustomerNote[] = this.customerNotes.filter((Note) => {
            return Note.id === id;
        });
        this.setValue("note", note[0].note);
        this.modalType = ModalType.Edit;
        this.showNewNoteModal = true;
    };

    public closeAddNoteModal: ICommand = new RelayCommand(() => {
        this.showNewNoteModal = false;
        this.documentsToUpload = [];
        if (!this.isModelValid()) {
            CoreStoreInstance.HideInfoBar();
            this.setError("note", "");
        }
    });

    public updateNotesForEditing = (notes: ICustomerNote[]) => {
        this.customerNotes.replace(notes);
    };

    public updateCustomerId(CustomerId: Guid) {
        this.customerId = CustomerId;
        this.setValue("sourceId", CustomerId);
    }

    public async getNotes(): Promise<void> {
        if (this.isProcessing === false) {
            this.isProcessing = true;

            if (this.getValue("sourceId") !== "") {
                const endpoint = new GetCustomerNotesFromCustomerIdEndpoint();
                await this.apiClient.sendAsync(endpoint, this.model);

                if (this.apiClient.IsRequestSuccessful) {
                    runInAction(() => {
                        const response = this.apiClient.Response();
                        this.customerNotes.replace(response);
                        this.noteCountCallBack(response.length);
                        this.isProcessing = false;
                    });
                } else {
                    runInAction(() => {
                        this.isProcessing = false;
                    });
                    this.errorStore.setHeaderText("Notes");
                    this.errorStore.setButtonText("Close");
                    this.errorStore.setErrorMessageOne("Failed to load notes.  Please try again later.");
                    this.errorStore.setErrorMessageTwo(this.apiClient.ValidationMessage);
                    this.errorStore.setErrorModalOpen(true);
                }
            }
        }
    }

    public openAreYouSureModal: ICommand = new RelayCommand((id: Guid) => {
        this.noteToDelete = id;
        this.isAreYouSureModalOpen = true;
    });

    public cancelDelete = () => {
        this.isAreYouSureModalOpen = false;
        this.noteToDelete = "";
    };

    public confirmDelete = () => {
        this.deleteNoteByIdCommand();
        this.isAreYouSureModalOpen = false;
    };

    public deleteNoteByIdCommand = () => {
        this.deleteNote(this.noteToDelete);
    };

    public async deleteNote(id: Guid): Promise<void> {
        if (this.isProcessing === false) {
            this.isProcessing = true;
            const endpoint = new DeleteCustomerNoteByIdEndpoint();
            await this.apiClient.sendAsync(endpoint, id);

            if (this.apiClient.IsRequestSuccessful) {
                runInAction(async () => {
                    this.isProcessing = false;
                    await this.getNotes();
                });
            } // else show error message
            else {
                runInAction(() => {
                    this.isProcessing = false;
                });
                this.errorStore.setHeaderText("Notes");
                this.errorStore.setButtonText("Close");
                this.errorStore.setErrorMessageOne("Failed to delete note.  Please try again later.");
                this.errorStore.setErrorMessageTwo(this.apiClient.ValidationMessage);
                this.errorStore.setErrorModalOpen(true);
            }
        }
    }

    public setNote: ICommand = new RelayCommand((value: string) => {
        this.setValue("note", value);
    });

    public submitNote = (submitType: SubmitType) => {
        this.upsertNote(submitType);
    };

    public async upsertNote(submitType: SubmitType): Promise<void> {
        if (this.isModelValid()) {
            this.spinnerText = "Saving...";
            this.isProcessing = true;
            let sourceId: string = this.getValue("sourceId");
            const endpoint = new UpsertCustomerNoteEndpoint();
            await this.apiClient.sendAsync(endpoint, this.model);

            if (this.apiClient.IsRequestSuccessful) {
                runInAction(() => {
                    this.clear();
                    let response = this.apiClient.Response();
                    this.setValue("note", response.note.note);
                    this.setValue("id", response.note.id);
                    this.setValue("rowVersion", response.note.rowVersion);
                    this.setValue("sourceId", sourceId);

                    this.customerNotes.replace(response.noteList);
                    this.noteCountCallBack(response.noteList.length);

                    if (submitType === SubmitType.SaveAndExit) {
                        this.showNewNoteModal = false;
                    } else if (submitType === SubmitType.SaveAndContinue) {
                        this.isProcessing = false;
                        this.showDocumentUploader = true;
                    }
                    this.isProcessing = false;
                    this.spinnerText = "Loading...";
                    CoreStoreInstance.HideInfoBar();
                });
            } else {
                runInAction(() => {
                    this.isProcessing = false;
                    this.spinnerText = "Loading...";
                });
                this.errorStore.setHeaderText("Notes");
                this.errorStore.setButtonText("Close");
                this.errorStore.setErrorMessageOne("Failed to add note.  Please try again later.");
                this.errorStore.setErrorMessageTwo(this.apiClient.ValidationMessage);
                this.errorStore.setErrorModalOpen(true);
            }
        }
    }

    public get getNotesForTable() {
        return this.customerNotes.slice();
    }

    public deleteNoteCheck = (e: any, id: string): void => {
        this.noteToDelete = id;
        this.areYouSureModelOpen = true;
    };

    public closeAreYouSureModal = () => {
        this.areYouSureModelOpen = false;
    };

    public confirmDeleteNote = () => {
        this.deleteNote(this.noteToDelete);
        this.areYouSureModelOpen = false;
    };

    // Files - Add files to list in modal, ready for uploading
    public addFiles = (acceptedFiles: File[]) => {
        acceptedFiles.map((file: File) => {
            const allowedFileTypes = [".doc", ".docx", ".pdf", ".xls", ".xlsx", ".csv", ".jpg", ".png"];
            const fileType = file.name.substring(file.name.lastIndexOf(".")).toLowerCase();
            if (allowedFileTypes.includes(fileType)) {
                const viewModel = new CustomerNoteDocumentItemViewModel();
                viewModel.setValue("filename", file.name);
                viewModel.setValue("documentCategoryId", 0);
                viewModel.setValue("file", file);

                this.documentsToUpload.push(viewModel);
                this.validateDocumentsToUpload = false;
            } else {
                this.errorStore.setHeaderText("Invalid Files");
                this.errorStore.setButtonText("Close");
                this.errorStore.setErrorMessageOne(`Invalid file type: ${fileType}. Only .doc, .pdf, .xls, .csv, .jpg, .png are allowed.`);
                this.errorStore.setErrorModalOpen(true);
            }
        });
    };

    public get getDocTypesForDropdown(): KeyValuePair[] {
        let retVal: KeyValuePair[] = [];

        this.documentTypes.map((type) => {
            retVal.push({ key: type.id, text: type.categoryName });
        });

        return retVal;
    }

    // Files - remove file from list in modal
    public removeFileFromModalList: ICommand = new RelayCommand((index: number) => {
        const copy = [...this.documentsToUpload];
        copy.splice(index, 1);
        this.documentsToUpload = copy;
    });

    public addDocumentFileAndNote = async (submitType: SubmitType) => {
        this.validateDocumentsToUpload = true;
        // TODO check validation before proceeding

        this.isProcessing = true;
        this.apiClient.setAPITimeout(240000);

        if (this.documentsToUpload.length > 0 && this.getValue("id") !== "" && this.getValue("id") !== null) {
            for (let i = 0; i < this.documentsToUpload.length; i++) {
                // this.spinnerText = "Uploading document " + (i + 1) + " of " + this.documentsToUpload.length;
                let uploadedFile: File64 = await getFileTo64(this.documentsToUpload[i].getValue("file"));
                this.documentsToUpload[i].setValue("fileBase64", uploadedFile.base64StringFile);
                this.documentsToUpload[i].setValue("fileType", uploadedFile.fileType);
                this.documentsToUpload[i].setValue("noteId", this.getValue("id"));

                let endpoint: UploadNoteDocumentEndpoint = new UploadNoteDocumentEndpoint(this.documentsToUpload[i].model);

                let _ = await this.apiClient.sendAsync(endpoint);
                if (this.apiClient.IsRequestSuccessful) {
                    await runInAction(() => {
                        this.isProcessing = false;
                        let response: IDocumentUploaded[] = this.apiClient.Response();
                        this.uploadedDocuments.replace(response);
                    });
                } else {
                    runInAction(() => {
                        this.isProcessing = false;
                    });
                    this.errorStore.setHeaderText("Notes");
                    this.errorStore.setButtonText("Close");
                    this.errorStore.setErrorMessageOne("Failed to upload the document.  Please correct the file and try again");
                    this.errorStore.setErrorMessageTwo(this.apiClient.ValidationMessage);
                    this.errorStore.setErrorModalOpen(true);
                }
            }
            this.upsertNote(submitType);
            // this.showAddDocumentModal = false;
            this.documentsToUpload = [];
        }
    };

    public async getNoteAndRelatedById(Id: Guid): Promise<void> {
        // this.spinnerText = "Saving...";
        // this.isProcessing = true;
        if (Id !== null) {
            const endpoint = new GetCustomerNoteWithRelatedByIdEndpoint(Id);
            await this.apiClient.sendAsync(endpoint);

            if (this.apiClient.IsRequestSuccessful) {
                runInAction(() => {
                    let response = this.apiClient.Response();
                    this.model.fromResponse(response);
                    this.uploadedDocuments.replace(response.documents);
                    this.showDocumentUploader = true;

                    this.isProcessing = false;
                    // this.spinnerText = "Loading...";
                });
            } // else show error message
            else {
                runInAction(() => {
                    this.isProcessing = false;
                });
                this.errorStore.setHeaderText("Notes");
                this.errorStore.setButtonText("Close");
                this.errorStore.setErrorMessageOne("Failed to load notes.  Please try again later.");
                this.errorStore.setErrorMessageTwo(this.apiClient.ValidationMessage);
                this.errorStore.setErrorModalOpen(true);
            }
        }
    }

    public deleteDocumentByIdCommand: ICommand = new RelayCommand((Id: Guid) => {
        this.deleteDocument(Id, this.getValue("id"));
    });

    public async deleteDocument(Id: Guid, NoteId: Guid): Promise<void> {
        this.isProcessing = true;
        const endpoint = new DeleteCustomerNoteDocumentByIdEndpoint(Id, NoteId);
        await this.apiClient.sendAsync(endpoint);

        if (this.apiClient.IsRequestSuccessful) {
            runInAction(async () => {
                const response: IDocumentUploaded[] = this.apiClient.Response();

                this.uploadedDocuments.replace(response);

                this.isProcessing = false;
                await this.getNotes();
            });
        } // else show error message
        else {
            runInAction(() => {
                this.isProcessing = false;
            });
            this.errorStore.setHeaderText("Documents");
            this.errorStore.setButtonText("Close");
            this.errorStore.setErrorMessageOne("Failed to delete document.  Please try again later.");
            this.errorStore.setErrorMessageTwo(this.apiClient.ValidationMessage);
            this.errorStore.setErrorModalOpen(true);
        }
    }

    public downloadDocumentCommand = (e: any, id: string): void => {
        let documentToDownload = new DocumentDownloaderRequest();

        for (let i = 0; i < this.uploadedDocuments.length; i++) {
            if (this.uploadedDocuments[i].id == id) {
                documentToDownload.id = this.uploadedDocuments[i].id;
                documentToDownload.fileName = this.uploadedDocuments[i].fileName;
                documentToDownload.blobName = this.uploadedDocuments[i].blobName;
                documentToDownload.fileType = this.uploadedDocuments[i].fileType;
                documentToDownload.docType = this.uploadedDocuments[i].docType;
            }
        }
        this.downloadDocumentAsync(documentToDownload);
    };

    public async downloadDocumentAsync(documentToDownload: DocumentDownloaderRequest): Promise<void> {
        if (documentToDownload !== undefined) {
            const endpoint = new DocumentDownloaderEndpoint();

            await this.apiClient.sendAsync(endpoint, documentToDownload);
            if (this.apiClient.IsRequestSuccessful) {
                runInAction(() => {
                    // this.isProcessing = false;
                });
            } // else show error message
            else {
                runInAction(() => {
                    // this.isProcessing = false;
                    this.errorStore.setHeaderText("Documents");
                    this.errorStore.setButtonText("Close");
                    this.errorStore.setErrorMessageOne("Failed to download document.  Please try again later.");
                    this.errorStore.setErrorMessageTwo(this.apiClient.ValidationMessage);
                    this.errorStore.setErrorModalOpen(true);
                });
            }
        }
    }
}
