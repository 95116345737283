// Importing the styled-components library for styling components
import styled from "@emotion/styled";
import { Box, theme } from "Application";

// Styled-components for layout and design
export const ColouredBox = styled(Box)`
    background-color: ${theme.palette.common.grey};
    height: 375px;

    &.multisite {
        background-color: ${theme.palette.common.multisitebackground};
    }

    &.masterproject {
        background-color: ${theme.palette.common.masterprojectbackground};
    }

    &.projectquote {
        background-color: ${theme.palette.common.projectquotebackground};
    }
`;

export const TopBox = styled(Box)`
    min-height: 87px;
    padding: 9px;
`;

export const ScrollBox = styled(Box)`
    background-color: white;
    overflow-y: auto;
    height: 286px;
    margin: 2px;
`;
