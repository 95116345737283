import React, { useEffect, useState } from "react";
import { observer } from "mobx-react-lite";
import { Box, Button, EditSelect, ICommand, RadioButton, RelayCommand, ThemedLoader } from "Application";
import { NewEditPropertyViewModel } from "./NewEditPropertyViewModel";
import { SubHeaderView, subHeadingButton } from "Views/Header/SubHeader";
import { useNavigate, useParams } from "react-router";
import { AppUrlIndex, AppUrls } from "AppUrls";
import { BaseWrapper } from "Components/BaseWrapper";
import { H2, P } from "Components/Primitives/TextElements/TextElements";
import { HRDashedGrey } from "Components/HorizontalRules";
import { AddressGeneric } from "Components/AddressGeneric/AddressGeneric";
import { ScrollingListWithSearch } from "Components/ScrollingListWithSearch/ScrollingListWithSearch";
import { SelectedItem } from "Components/ScrollingListWithSearch/SelectedItem";
import AddIcon from "Assets/Icons/AddSquareIcon.svg";
import { CreateCustomFooter } from "Components/CommonPageComponents";
import tick from "Assets/Icons/SmallTickCircleIcon.svg";
import { ModalType } from "Application/Models/Domain/AddEdditModalType";
import useCallout from "Application/Hooks/UseCallout";
import { ContactInfoModalView } from "Components/ReusableModals/ContactInfoModelView";
import { BackButtonNavigation } from "Components/BackButtonNavigation";
import styled from "@emotion/styled";

interface INewEditPropertyView {
    addOrEdit: ModalType;
}

export const RadioWrapper = styled(Box)`
    label {
        width: 150px;
    }
`;

export const NewEditPropertyView: React.FC<INewEditPropertyView> = observer((props) => {
    const navToPropertyView = (id: Guid) => {
        navigate(AppUrls.Client.Directory.PropertyView + id + "/details");
    };

    const [viewModel] = useState(new NewEditPropertyViewModel(navToPropertyView, props.addOrEdit));
    const { calloutState, infoCalloutId, setCalloutStateCommand, closeCalloutStateCommand } = useCallout();

    const navigate = useNavigate();
    let params = useParams();
    useEffect(() => {
        if (props.addOrEdit === ModalType.Add) {
            viewModel.getOccupierListAndRelatedAsync();
        } else if (props.addOrEdit === ModalType.Edit) {
            if (params.id !== undefined) {
                viewModel.getPropertyAsync(params.id);
            }
        }
        return () => {
            // Clean up after yourself
            //viewModel.clear();
        };
    }, []);

    const openInfoModal = (e: any, id: string | Guid): void => {
        setCalloutStateCommand.execute(e.currentTarget.id, id);
    };

    const handleSubHeadingButtonClicks: ICommand = new RelayCommand((value: subHeadingButton) => {
        switch (value) {
            case subHeadingButton.Back:
                if (props.addOrEdit === ModalType.Edit) {
                    // Back to Property View
                    //navigate(AppUrls.Client.Directory.PropertyView);
                    navigate(BackButtonNavigation(AppUrls.Client.Directory.PropertyView));
                } else {
                    // Back to list
                    navigate(AppUrls.Client.Directory.Properties[AppUrlIndex.Long]);
                }
                break;

            default:
            // code block
        }
    });

    return (
        <>
            <SubHeaderView buttonClick={handleSubHeadingButtonClicks} backButtonText={props.addOrEdit === ModalType.Edit ? "Back to property" : "Back to properties"} />

            {calloutState.isCalloutVisible && infoCalloutId && (
                <ContactInfoModalView onDismissCommand={closeCalloutStateCommand} sourceId={infoCalloutId} targetId={calloutState.targetId} />
            )}

            <BaseWrapper>
                {viewModel.isProcessing ? (
                    <ThemedLoader isOpen={true} />
                ) : (
                    <>
                        <H2 pt="40px" pb="10px">
                            {props.addOrEdit === "edit" ? "Edit property" : "New property"}
                        </H2>

                        <AddressGeneric addressViewModel={viewModel.addressViewModel} showAddressFields={true} />

                        <HRDashedGrey />

                        <Box grid dc="1fr 1fr">
                            <ScrollingListWithSearch
                                title="Add occupier"
                                list={viewModel.getOccupiersWithoutSelected}
                                searchInputPlaceholderText="Type to filter the list of occupiers"
                                addItemCommand={viewModel.chooseOccupierCommand}
                                showInfoCommand={setCalloutStateCommand}
                            />
                            <Box>
                                <P pb="10px">
                                    <strong>Occupier</strong>
                                </P>
                                {viewModel.model.occupiers.length > 0 ? (
                                    <Box pb="30px">
                                        {viewModel.model.occupiers.map((occupier) => {
                                            return (
                                                <SelectedItem
                                                    // key={generateID()}
                                                    data={viewModel.getSelectedOccupier(occupier)}
                                                    removeItemCommand={viewModel.removeSelectedOccupierCommand}
                                                    showInfoCommand={setCalloutStateCommand}
                                                    marginBottom="10px"
                                                    showRemoveButton={true}
                                                />
                                            );
                                        })}
                                    </Box>
                                ) : (
                                    <Box flexBox minHeight="30px" pb="30px">
                                        <P pr="10px">There are currently no Occupiers added to this project. Please select an occupier from the list and click the</P>
                                        <img src={AddIcon} />
                                        <P pl="10px">button</P>
                                    </Box>
                                )}
                                <Box grid dc="1fr 1fr">
                                    <Box>
                                        <RadioWrapper>
                                            <RadioButton
                                                key="sisrb"
                                                command={viewModel.setIsSurveyed}
                                                displayName="Is Surveyed?"
                                                options={[
                                                    { key: true, text: "Yes" },
                                                    { key: false, text: "No" },
                                                ]}
                                                value={() => viewModel.getValue("isSurveyedByTsm")}
                                                uniqueId="isSurveyedByTsm"
                                            />
                                            <RadioButton
                                                key="isrb"
                                                command={viewModel.setSiteInspection}
                                                displayName="Site Inspection?"
                                                options={[
                                                    { key: true, text: "Yes" },
                                                    { key: false, text: "No" },
                                                ]}
                                                value={() => viewModel.getValue("siteInspection")}
                                                uniqueId="siteInspection"
                                            />
                                        </RadioWrapper>
                                        <Box p="15px 0px">
                                            <EditSelect
                                                displayName="Roof Substrate"
                                                command={viewModel.setRoofSubstrate}
                                                options={viewModel.getRoofSubstrates}
                                                value={() => viewModel.getValue("roofSubstrate")}
                                            />
                                        </Box>
                                        <P pb="15px">
                                            <strong>End of lease (if known)</strong>
                                        </P>
                                        <Box grid dc="1fr 1fr">
                                            <EditSelect
                                                displayName="Month"
                                                command={viewModel.setEndOfLeaseMonth}
                                                options={viewModel.getMonths}
                                                value={() => viewModel.getValue("endOfLeaseMonth")}
                                            />
                                            <EditSelect
                                                displayName="Year"
                                                command={viewModel.setEndOfLeaseYear}
                                                options={viewModel.getYears}
                                                value={() => viewModel.getValue("endOfLeaseYear")}
                                            />
                                        </Box>
                                    </Box>
                                </Box>
                            </Box>
                        </Box>
                    </>
                )}
            </BaseWrapper>
            <CreateCustomFooter>
                <Button
                    displayName={props.addOrEdit === ModalType.Add ? " Create property" : " Save property"}
                    style={{ marginRight: "10px" }}
                    command={viewModel.submitNewProperty}
                    value={props.addOrEdit}
                    icon={<img src={tick} />}
                    paletteColor="ButtonGreen"
                    boxShadow={true}
                />
            </CreateCustomFooter>
        </>
    );
});
