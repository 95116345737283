import React, { useEffect, useState } from "react";
import { observer } from "mobx-react-lite";
import { Box, ThemedLoader, formatDate } from "Application";
import { createColumnHelper } from "@tanstack/react-table";
import { CenteredCell, CenteredTextCell } from "Utils/CellFormatComponents";
import { SharmansTable, SortOrderDirection } from "Components/Primitives/DataTable/SharmansTable";
import { H5, P } from "Components/Primitives/TextElements/TextElements";
import { ButtonUnder } from "Components/Primitives/Buttons/ButtonUnder";
import AddNoteIcon from "Assets/Icons/editButtonIcon.svg";
import { DeleteButton } from "Components/Primitives/Buttons/DeleteButton";
import { AreYouSureModal } from "Components/AreYouSureModal/AreYouSure";
import { ComplaintDocumentsViewModel } from "./ComplaintDocumentsViewModel";
import { DownloadButton } from "Components/Primitives/Buttons/DownloadButton";
import { AddDocumentModal } from "Components/Modals/AddDocumentModal";
import { IDocumentType } from "Models/Documents/DocumentType";
import { ComplaintTypeEnum } from "Models/Complaints/ComplaintTypeEnum";
import { ViewDocumentPreviewButton } from "Components/Primitives/Buttons/ViewDocumentPreviewButton";
import { PageTabWrapper } from "Styles/BaseStyles";

interface IDocuments {
    id?: string;
    documentTypes: IDocumentType[];

    documentCountCallBack: (newCount: number) => void;
}

interface IDocuments {
    complaintId: Guid;
    complaintType: ComplaintTypeEnum;
    documentTypes: IDocumentType[];

    documentCountCallBack: (newCount: number) => void;
}

export const DocumentsView: React.FC<IDocuments> = observer(({ complaintId, complaintType, documentTypes, documentCountCallBack }) => {
    const [viewModel] = useState(new ComplaintDocumentsViewModel());
    useEffect(() => {
        viewModel.init(complaintId, complaintType, documentCountCallBack);
        if (complaintType !== ComplaintTypeEnum.Unknown) {
            viewModel.getDocumentsByComplaintId();
        }
        return () => {
            // Clean up after yourself
            //viewModel.clear();
        };
    }, []);

    useEffect(() => {
        viewModel.init(complaintId, complaintType, documentCountCallBack);
        if (complaintType !== ComplaintTypeEnum.Unknown) {
            viewModel.getDocumentsByComplaintId();
        }
    }, [complaintType, documentTypes]);

    const updateSorting = (columnName: string, orderBy: SortOrderDirection) => {
        viewModel.setValue("columnName", columnName);
        viewModel.setValue("orderBy", orderBy);
    };

    const columnHelper = createColumnHelper<any>();

    const columns = [
        columnHelper.accessor("dateCreated", {
            size: 20,
            header: () => "Created date",
            cell: (info) => <CenteredTextCell greenText={false} value={info.getValue() !== null ? formatDate(new Date(info.getValue())) : ""} />,
        }),
        columnHelper.accessor("fileName", {
            size: 700,
            header: () => "File name",
            cell: (info) => info.getValue(),
        }),
        columnHelper.accessor("uploadedBy", {
            header: () => "Added by",
            cell: (info) => info.renderValue(),
        }),

        columnHelper.accessor("id", {
            header: () => "Actions",
            cell: (info) => {
                return (
                    <CenteredCell style={{ display: "flex", flexDirection: "row", justifyContent: "space-evenly" }}>
                        <ViewDocumentPreviewButton
                            id={info.getValue().toString()}
                            fileName={info.row.original.fileName}
                            blobName={info.row.original.blobName}
                            fileType={info.row.original.fileType}
                            docType={info.row.original.docType}
                            onClick={viewModel.downloadDocumentCommand}
                        />
                        <DownloadButton id={info.getValue().toString()} onClick={viewModel.downloadDocumentCommand} />
                        <DeleteButton id={info.getValue().toString()} onClick={viewModel.deleteDocumentCheck} />
                    </CenteredCell>
                );
            },
            enableSorting: false,
        }),
    ];

    return (
        <>
            {viewModel.isProcessing === true ? (
                <ThemedLoader spinnerText="Loading..." isOpen={true} />
            ) : (
                <>
                    <PageTabWrapper>
                        {viewModel.showAddDocumentModal === true && <AddDocumentModal parentViewModel={viewModel} documentTypes={documentTypes} />}
                        {viewModel.areYouSureModalOpen === true && (
                            <AreYouSureModal
                                title="Delete Document"
                                message="Are you sure you want to delete this document?"
                                isOpen={viewModel.areYouSureModalOpen}
                                onCancel={viewModel.closeAreYouSureModal}
                                onConfirm={viewModel.confirmDeleteDocument}
                            />
                        )}

                        <Box className="tabheader" flexBox style={{ justifyContent: "space-between" }}>
                            <H5 pt="15px">Documents</H5>
                            <ButtonUnder
                                displayName=" Add Document"
                                paletteColor="ButtonBlue" // TODO CMR WHY DOES THIS NOT COMPILE{viewModel.isProcessing === true ? "ButtonInactive" : }
                                icon={<img src={AddNoteIcon} />}
                                boxShadow={true}
                                command={viewModel.openNewDocumentModal}
                            />
                        </Box>
                        <Box>
                            <SharmansTable columns={columns} manualSorting={false} data={viewModel.getDocuments} onSortChange={updateSorting} />
                            {viewModel.uploadedDocuments.length === 0 && (
                                <P pt="40px" style={{ textAlign: "center" }}>
                                    This customer has no documents
                                </P>
                            )}
                        </Box>
                    </PageTabWrapper>
                </>
            )}
        </>
    );
});
