import { DownloadMasterProjectComplaintPDFEndpoint } from "../Endpoints/DownloadMasterProjectComplaintPDF";
import { makeObservable, action, computed, observable, runInAction } from "mobx";
import { ViewModelBase } from "@shoothill/core";
import { APIClient, EndpointWithoutRequest, ICommand, RelayCommand } from "Application";
import { ErrorStore } from "Stores/Domain/ErrorStore";
import { container } from "tsyringe";
import { ComplaintsListModel, ComplaintsListModelValidator } from "./ComplaintsModel";
import { KeyValuePairExtended } from "Models/KeyValuePairExtended";
import { ComplaintGetForListEndpoint, ComplaintGetForListRequest, ComplaintGetForListResponse } from "../Endpoints/ComplaintGetForList";
import { DateTime } from "luxon";
import { ComplaintListItem } from "Models/Complaints/ComplaintListItem";
import { DownloadProjectQuoteComplaintPDFEndpoint } from "../Endpoints/DownloadProjectQuoteComplaintPDF";
import { ComplaintSourceEnum } from "Models/Complaints/ComplaintSourceEnum";
import { SortOrderDirection } from "Components/Primitives/DataTable/SharmansTable";
import { DefaultPageSize } from "Globals/GlobalSettings";

export class ComplaintsListViewModel extends ViewModelBase<ComplaintsListModel> {
    // Singleton Patten - Uses instance and a private static variable to ensure only one instance is created and a private Constructor
    private static _instance: ComplaintsListViewModel;
    public static get Instance() {
        return this._instance || (this._instance = new this());
    }
    public apiClient = new APIClient();
    errorStore = container.resolve(ErrorStore);

    public isProcessing: boolean = false;
    public debounceTimer: NodeJS.Timeout | null = null;

    // Sorting

    public orderBy: SortOrderDirection = SortOrderDirection.ASC;
    public columnName: string = "referenceId";

    // Pagination

    private constructor() {
        super(new ComplaintsListModel());
        this.setValidator(new ComplaintsListModelValidator());
        makeObservable(this, {
            columnName: observable,
            isProcessing: observable,
            orderBy: observable,

            assignedSelectAll: action,
            assignedSelectNone: action,
            clear: action,
            natureSelectAll: action,
            natureSelectNone: action,
            changePageNumber: action,

            getCanExecute: computed,
            getComplaintList: computed,
            getFilteredNature: computed,
            getAssignedUsers: computed,
            getSearchValue: computed,
            getTableDateFrom: computed,
            getTableDateTo: computed,
        });
    }

    public clear = () => {
        this.isProcessing = false;
        this.model.clear();
    };

    public reset = () => {};

    public get getCanExecute(): boolean {
        return !this.isProcessing;
    }

    public get IsBusy(): boolean {
        return this.apiClient.IsBusy;
    }

    public get getFilteredNature(): KeyValuePairExtended<any>[] {
        const items = this.model.nature.slice();
        let retVal: KeyValuePairExtended<any>[] = [];

        for (let i: number = 0; i < items.length; i++) {
            retVal.push({
                key: items[i].id.toString(),
                text: items[i].nature,
                class: "",
            });
        }

        return retVal;
    }

    public get getAssignedUsers(): KeyValuePairExtended<any>[] {
        const items = this.model.assignedUsers.slice();

        let retVal: KeyValuePairExtended<any>[] = [];

        for (let i: number = 0; i < items.length; i++) {
            retVal.push({
                key: items[i].id.toString(),
                text: items[i].displayName,
                class: "",
            });
        }

        return retVal;
    }

    /* Table */
    public get getSearchValue(): string {
        return this.model.tableSearchCommand;
    }

    public executeSearch = (): void => {
        this.loadComplaintList();
    };

    public get getTableComplaintStatus(): string {
        return this.model.tableComplaintStatus;
    }

    public setTableComplaintStatus: ICommand = new RelayCommand(
        (value: string) => {
            this.setValue("tableComplaintStatus", value);
            this.model.pageNumber = 1;
            let promise = this.loadComplaintList();
        },
        () => {
            return this.getCanExecute;
        },
    );

    public searchCommand: ICommand = new RelayCommand(
        (value: string) => {
            this.setValue("tableSearchCommand", value);

            if (this.debounceTimer) {
                clearTimeout(this.debounceTimer);
            }

            this.debounceTimer = setTimeout(() => {
                this.model.pageNumber = 1;
                let promise = this.loadComplaintList();
            }, 600);
        },
        () => {
            return this.getCanExecute;
        },
    );

    public setTableDateFrom: ICommand = new RelayCommand((value: moment.Moment) => {
        // Convert to luxon
        let temp: string = value.toISOString();
        this.setValue("tableStartDate", DateTime.fromISO(temp));
        this.model.pageNumber = 1;
        let promise = this.loadComplaintList();
    });

    public get getTableDateFrom(): Date {
        return new Date(this.model.tableStartDate.toString());
    }

    public setTableDateTo: ICommand = new RelayCommand(
        (value: moment.Moment) => {
            // Convert to luxon
            let temp: string = value.toISOString();
            this.setValue("tableEndDate", DateTime.fromISO(temp));
            this.model.pageNumber = 1;
            let promise = this.loadComplaintList();
        },
        () => {
            return this.getCanExecute;
        },
    );

    public get getTableDateTo(): Date {
        return new Date(this.model.tableEndDate.toString());
    }

    public setTableDateAll: ICommand = new RelayCommand(
        () => {
            this.model.tableSetAll();
            this.model.pageNumber = 1;
            let promise = this.loadComplaintList();
        },
        () => {
            return this.getCanExecute;
        },
    );

    public setTableDateMonth: ICommand = new RelayCommand(
        () => {
            this.model.tableSetMonth();
            this.model.pageNumber = 1;
            let promise = this.loadComplaintList();
        },
        () => {
            return this.getCanExecute;
        },
    );

    public setTableDateYear: ICommand = new RelayCommand(
        () => {
            this.model.tableSetYear();
            this.model.pageNumber = 1;
            let promise = this.loadComplaintList();
        },
        () => {
            return this.getCanExecute;
        },
    );

    public updateAssignedUserCommand: ICommand = new RelayCommand(
        (value: KeyValuePairExtended[]) => {
            this.setValue(
                "filterAssignedUsers",
                value.map((item) => item.key),
            );

            if (this.debounceTimer) {
                clearTimeout(this.debounceTimer);
            }

            this.debounceTimer = setTimeout(() => {
                this.model.pageNumber = 1;
                let promise = this.loadComplaintList();
            }, 600);
        },
        () => {
            return this.getCanExecute;
        },
    );

    public updateFilterNatureCommand: ICommand = new RelayCommand(
        (value: KeyValuePairExtended[]) => {
            this.setValue(
                "filterNature",
                value.map((item) => item.key),
            );
            if (this.debounceTimer) {
                clearTimeout(this.debounceTimer);
            }

            this.debounceTimer = setTimeout(() => {
                this.model.pageNumber = 1;
                let promise = this.loadComplaintList();
            }, 600);
        },
        () => {
            return this.getCanExecute;
        },
    );

    public loadComplaintList = async (): Promise<ComplaintGetForListResponse> => {
        const endpoint = new ComplaintGetForListEndpoint();
        let retVal: ComplaintGetForListResponse = new ComplaintGetForListResponse();

        if (this.isProcessing === false && this.apiClient.IsBusy === false) {
            this.isProcessing = true;

            let request: ComplaintGetForListRequest = this.model.getRequest();
            await this.apiClient.sendAsync(endpoint, request);

            if (this.apiClient.IsRequestSuccessful) {
                runInAction(() => {
                    this.isProcessing = false;
                    let response: ComplaintGetForListResponse = this.apiClient.Response();
                    this.model.fromListResponse(response);
                });
            } else {
                runInAction(() => {
                    this.isProcessing = false;
                });
                this.errorStore.setHeaderText("Complaints");
                this.errorStore.setButtonText("Close");
                this.errorStore.setErrorMessageOne("Failed to get the complaint list.  Please try again later.");
                this.errorStore.setErrorMessageTwo(this.apiClient.ValidationMessage);
                this.errorStore.setErrorModalOpen(true);
            }
        } else {
        }
        return retVal;
    };

    public downloadPDF = async (id: string): Promise<any> => {
        const complaint = this.model.complaints.find((a) => a.id === id);

        if (complaint !== null && complaint !== undefined) {
            let endpoint: EndpointWithoutRequest<any> = new DownloadProjectQuoteComplaintPDFEndpoint(id);

            if (complaint.complaintSource === ComplaintSourceEnum.MasterProject) {
                endpoint = new DownloadMasterProjectComplaintPDFEndpoint(id);
            }

            if (this.isProcessing === false && this.apiClient.IsBusy === false) {
                // Taken out as was stopping the table ordering from working
                //this.isProcessing = true;

                await this.apiClient.sendAsync(endpoint);

                if (this.apiClient.IsRequestSuccessful) {
                    runInAction(() => {
                        this.isProcessing = false;
                    });
                } else {
                    runInAction(() => {
                        this.isProcessing = false;
                    });
                    this.errorStore.setHeaderText("Complaint list");
                    this.errorStore.setButtonText("Close");
                    this.errorStore.setErrorMessageOne("Failed to download the pdf.  Please try again later.");
                    this.errorStore.setErrorMessageTwo(this.apiClient.ValidationMessage);
                    this.errorStore.setErrorModalOpen(true);
                }
            } else {
            }
        }
    };

    public assignedSelectAll = async () => {
        this.model.assignedSelectAll();
        let promise = this.loadComplaintList();
    };

    public assignedSelectNone = async () => {
        this.model.assignedSelectNone();
        let promise = this.loadComplaintList();
    };

    public natureSelectAll = async () => {
        this.model.natureSelectAll();
        let promise = this.loadComplaintList();
    };

    public natureSelectNone = async () => {
        this.model.natureSelectNone();
        let promise = this.loadComplaintList();
    };

    public get getComplaintList(): ComplaintListItem[] {
        return this.model.complaints.slice();
    }

    public changePageNumber = (pageNumber: number) => {
        this.model.pageNumber = pageNumber;
        this.loadComplaintList();
    };

    public clearFiltering: ICommand = new RelayCommand(
        () => {
            this.loadComplaintList();
        },
        () => {
            return this.getCanExecute;
        },
    );
}
