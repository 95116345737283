import React, { useEffect, useState } from "react";
import { observer } from "mobx-react-lite";
import { Box, EditDate, EditSelect, ICommand, Input, RelayCommand } from "Application";

import spyScope from "Assets/Icons/magnifyingGlass.svg";
import { FilteringTopSectionGenericViewModel } from "./FilteringTopSectionGenericViewModel";
import { MultiEditSelect } from "./Primitives/Selects/MultiEditSelect";
import { filterApiResultBasicErrors } from "@shoothill/core";

export interface IFiltering {
    searchCommand?: ICommand<any>;
    searchExecute?: () => void;
    searchValue: string;

    timescaleCommand?: ICommand<any>;
    timescaleOptions?: any[];
    timescaleValue: string;

    dateFromCommand?: ICommand<any>;
    dateFromValue?: Date;

    dateToCommand?: ICommand<any>;
    dateToValue?: Date;

    visibleHiddenCommand?: ICommand<any>;
    visibleValue?: string[];
    visibleHiddenSelectAll?: () => void;
    visibleHiddenSelectNone?: () => void;
    displayName?: string;

    isDisabled?: boolean;
}

export const FilteringTopSectionGenericView: React.FC<IFiltering> = observer((props) => {
    const [viewModel] = useState(() => new FilteringTopSectionGenericViewModel());
    let debounceTimer: NodeJS.Timeout | null = null;

    useEffect(() => {
        if (props.searchValue) {
            viewModel.setSearchString(props.searchValue);
        }
        return () => {
            // Clean up after yourself
            //    viewModel.clear();
        };
    }, []);

    const debounce: ICommand = new RelayCommand(
        (keyword: string) => {
            viewModel.setSearchString(keyword);
            if (debounceTimer) {
                clearTimeout(debounceTimer);
            }

            debounceTimer = setTimeout(() => {
                if (props.searchCommand) {
                    props.searchCommand.execute(keyword);
                }
            }, 600);
        },
        // () => {
        //     let retVal: boolean = props.searchCommand === undefined ? true : props.searchCommand!.canExecute();
        //     return retVal;
        // },
    );

    return (
        <>
            <Box grid dc={"2fr 1fr 0.5fr 0.5fr 1fr"}>
                {props.searchCommand && (
                    <Input
                        command={debounce}
                        placeholder={props.displayName ? props.displayName : "Start typing to search by name, number etc..."}
                        prefix={<img src={spyScope} style={{ cursor: "pointer" }} />}
                        displayName="Keyword search"
                        value={() => viewModel.searchString}
                    />
                )}

                {props.timescaleCommand && (
                    <EditSelect
                        command={props.timescaleCommand}
                        displayName="Timescale"
                        options={props.timescaleOptions!}
                        value={() => props.timescaleValue}
                        isDisabled={props.timescaleCommand === undefined ? false : !props.timescaleCommand!.canExecute()}
                    />
                )}

                {props.dateFromCommand && <EditDate command={props.dateFromCommand} value={props.dateFromValue} displayName="Show from" />}

                {props.dateToCommand && <EditDate command={props.dateToCommand} value={props.dateToValue} displayName="Show to" />}

                {props.visibleHiddenCommand && props.visibleValue !== undefined && (
                    <MultiEditSelect
                        command={props.visibleHiddenCommand}
                        displayName="Show All/Visible/Hidden"
                        options={visibleHiddenOptions}
                        value={() => (props.visibleValue !== undefined ? props.visibleValue : [])}
                        showSelectAllNone={true}
                        selectAll={props.visibleHiddenSelectAll}
                        selectNone={props.visibleHiddenSelectNone}
                        isDisabled={props.visibleHiddenCommand === undefined ? false : !props.visibleHiddenCommand!.canExecute()}
                    />
                )}
            </Box>
        </>
    );
});

const visibleHiddenOptions = [
    { key: "true", text: "Show visible" },
    { key: "false", text: "Show hidden" },
];
