import styled from "@emotion/styled";
import { Box, theme } from "Application";

export interface IBoxColour {
    color: string;
}

export const ContactStatusTableBox = styled(Box)<IBoxColour>`
    color: white;
    background-color: ${(props) => (props.color ? props.color : "pink")};
    border-radius: 10px;
    width: 100%;
    height: 24px;
    display: flex;
    flex: 1 0 0;
    vertical-align: middle;
    text-align: center;
    justify-content: center;
    letter-spacing: -0.15px;
    line-height: 24px;
    font-size: ${theme.fontStyles.tableRow.fontSize};
    padding-left: 5px;
    padding-right: 5px;
`;
