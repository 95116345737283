import React, { useCallback, useEffect } from "react";
import { observer } from "mobx-react-lite";
import { Box, Button, CheckBox, EditDate, EditSelect, ICommand, Input, RadioButton, RelayCommand } from "Application";
import { ModalGenericWithHeader } from "Components/ModalGenericWithHeader/ModalGenericWithHeader";
import { HRSolidGrey } from "Components/HorizontalRules";
import tick from "Assets/Icons/SmallTickCircleIcon.svg";
import ContactIcon from "Assets/Icons/ContactIcon.svg";
import { ThemedLoaderSmaller } from "Components/Primitives/Loaders/ThemedLoaderSmaller";
import { useDropzone } from "react-dropzone";
import FileIcon from "Assets/Icons/FileIcon.svg";
import { IObservableArray } from "mobx";
import { FieldType, KeyValuePair } from "@shoothill/core/dist/Models";
import { DeleteButton, DocumentToUploadBox, NoteBox, UploadImageBox } from "Components/CommonPageComponents";
import { ModalType } from "Application/Models/Domain/AddEdditModalType";
import { SubmitType } from "Application/Models/Domain/Forms";
import { IDocumentType } from "Models/Documents/DocumentType";
import { ContactStatusDropdownItem } from "Models/Contacts/ContactStatusDropdownItem";
import { P } from "Components/Primitives/TextElements/TextElements";
import DocLogo from "Assets/Icons/DocLogo.svg";
import { ContactDocumentItemViewModel } from "../CustomerContacts/ContactDocumentItemViewModel";
import { IDocumentUploaded } from "Models/Documents/IDocumentUploaded";
import CloseIcon from "Assets/close_icon_black.svg";
import { DatePickerBox } from "Components/Primitives/DatePickerBox";
import styled from "@emotion/styled";
import { DownloadButton } from "Components/Primitives/Buttons/DownloadButton";

export const RadioBox = styled.div`
    label {
        width: 40%;
    }
`;

export const TextAreaBox = styled(Box)`
    textarea {
        width: calc(100% - 24px) !important;
        white-space: normal !important;
        overflow: auto;
    }
`;

interface IParentViewModel {
    getStatusTypesForDropdown: KeyValuePair<any>[];
    getDocTypesForDropdown?: KeyValuePair<any>[];
}
interface IAddContactModal {
    isOpen: boolean;
    closeModal: ICommand<any>;
    viewModel: IContactModalViewModel;
    parentViewModel: IParentViewModel;
    addOrEdit?: ModalType;
    contactStatusTypes?: ContactStatusDropdownItem[];
    documentTypes?: IDocumentType[];
    enableCustomerDropdown?: boolean;
}

interface IContactModalViewModel {
    statusTypes: IObservableArray<ContactStatusDropdownItem>;
    documentTypes: IObservableArray<IDocumentType>;
    uploadedDocuments: IDocumentUploaded[];
    deleteDocumentByIdCommand: ICommand<any>;
    showDocumentsSection: boolean;
    addFiles: (acceptedFiles: File[]) => void;
    validateAndSubmitContact: ICommand<any>;
    isProcessing: boolean;
    setValue<TR>(fieldName: keyof FieldType, value: TR): void;
    getValue<TR>(fieldName: keyof FieldType): TR;
    getError(fieldName: keyof FieldType): string;
    documentsToUpload: ContactDocumentItemViewModel[];
    submitDocuments: ICommand;

    setCustomerType?: (type: number) => void;
    executeCustomerSearch?: (searchString: string) => void;
    selectCustomer?: ICommand<any>;
    getCustomers?: (searchString: string) => void;
    isCustomerTypeSelected?: () => boolean;
    getCustomerTypesForAddModal?: () => void;
    getCustomerTypesForModalDropdown?: KeyValuePair<any>[];
    downloadDocumentCommand?: (e: any, id: string) => void;
}

export const AddContactModal: React.FC<IAddContactModal> = observer((props) => {
    const setFirstName: ICommand = new RelayCommand((value: string) => {
        props.viewModel.setValue("firstName", value);
    });

    const setLastName: ICommand = new RelayCommand((value: string) => {
        props.viewModel.setValue("lastName", value);
    });

    const setPhoneNumber: ICommand = new RelayCommand((value: string) => {
        props.viewModel.setValue("phone", value);
    });

    const setPhoneNumber2: ICommand = new RelayCommand((value: string) => {
        props.viewModel.setValue("phone2", value);
    });

    const setEmail: ICommand = new RelayCommand((value: string) => {
        props.viewModel.setValue("email", value);
    });

    const setJobTitle: ICommand = new RelayCommand((value: string) => {
        props.viewModel.setValue("jobTitle", value);
    });

    const setPrimaryContact: ICommand = new RelayCommand((value: string) => {
        props.viewModel.setValue("primaryContact", value);
    });

    const setMarketingConsent: ICommand = new RelayCommand((value: string) => {
        props.viewModel.setValue("marketingConsent", value);
    });

    const setPlygeneTrained: ICommand = new RelayCommand((value: string) => {
        let trueOrFalse = false;
        if (value === "true") {
            trueOrFalse = true;
        } else {
            props.viewModel.setValue("datePlygeneTrained", null);
            trueOrFalse = false;
        }
        props.viewModel.setValue("plygeneTrained", trueOrFalse);
    });

    const setPlygeneDateTrained: ICommand = new RelayCommand((date: Date) => {
        props.viewModel.setValue("datePlygeneTrained", date);
    });

    const setCoatingsTrained: ICommand = new RelayCommand((value: string) => {
        let trueOrFalse = false;
        if (value === "true") {
            trueOrFalse = true;
        } else {
            props.viewModel.setValue("dateCoatingsTrained", null);
            trueOrFalse = false;
        }
        props.viewModel.setValue("coatingsTrained", trueOrFalse);
    });

    const setCoatingsDateTrained: ICommand = new RelayCommand((date: Date) => {
        props.viewModel.setValue("dateCoatingsTrained", date);
    });

    //CPD

    const setPlygeneCPD: ICommand = new RelayCommand((value: string) => {
        let trueOrFalse = false;
        if (value === "true") {
            trueOrFalse = true;
        } else {
            props.viewModel.setValue("datePlygeneCPD", null);
            trueOrFalse = false;
        }
        props.viewModel.setValue("plygeneCPD", trueOrFalse);
    });

    const setPlygeneDateCPD: ICommand = new RelayCommand((date: Date) => {
        props.viewModel.setValue("datePlygeneCPD", date);
    });

    const setCoatingsCPD: ICommand = new RelayCommand((value: string) => {
        let trueOrFalse = false;
        if (value === "true") {
            trueOrFalse = true;
        } else {
            props.viewModel.setValue("dateCoatingsCPD", null);
            trueOrFalse = false;
        }
        props.viewModel.setValue("coatingsCPD", trueOrFalse);
    });

    const setCoatingsDateCPD: ICommand = new RelayCommand((date: Date) => {
        props.viewModel.setValue("dateCoatingsCPD", date);
    });

    //end

    const setContactStatus: ICommand = new RelayCommand((value: any) => {
        props.viewModel.setValue("status", value.key);
    });

    const setNote: ICommand = new RelayCommand((value: string) => {
        props.viewModel.setValue("note", value);
    });

    const GetPlygeneTrained = () => {
        if (props.viewModel.getValue("plygeneTrained") === true) {
            return "true";
        } else {
            return "false";
        }
    };

    const GetCoatingsTrained = () => {
        if (props.viewModel.getValue("coatingsTrained") === true) {
            return "true";
        } else {
            return "false";
        }
    };

    const GetPlygeneCPD = () => {
        if (props.viewModel.getValue("plygeneCPD") === true) {
            return "true";
        } else {
            return "false";
        }
    };

    const GetCoatingsCPD = () => {
        if (props.viewModel.getValue("coatingsCPD") === true) {
            return "true";
        } else {
            return "false";
        }
    };

    const getCustomersForDropdown = () => {
        let customers: any = props.viewModel.getValue("customers");

        return customers.map((customer: any) => {
            return { key: customer.id, text: customer.displayName };
        });
    };

    const setCustomerType: ICommand = new RelayCommand((value: KeyValuePair) => {
        if (props.viewModel.setCustomerType) {
            props.viewModel.setCustomerType(Number(value.key));
        }
    });

    useEffect(() => {
        if (props.contactStatusTypes !== undefined) {
            props.viewModel.statusTypes.replace(props.contactStatusTypes);
        }

        if (props.documentTypes !== undefined) {
            props.viewModel.documentTypes.replace(props.documentTypes);
        }
        if (props.addOrEdit === ModalType.Edit) {
            props.viewModel.showDocumentsSection = true;
        }
        if (props.viewModel.getCustomerTypesForAddModal) {
            props.viewModel.getCustomerTypesForAddModal();
        }
        return () => {
            // Clean up after yourself
            //viewModel.clear();
        };
    }, []);

    function MyDropzone() {
        const onDrop = useCallback((acceptedFiles: File[]) => {
            // Do something with the files
            props.viewModel.addFiles(acceptedFiles);
        }, []);
        const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop, noDragEventsBubbling: true, multiple: false });

        return (
            <UploadImageBox>
                <div {...getRootProps()}>
                    <img src={FileIcon} />
                    <input {...getInputProps()} />
                    {isDragActive ? <p>Drop the files here ...</p> : <p>Click or drag files to attach (.doc .pdf .xls .csv .jpg .png etc.)</p>}
                </div>
            </UploadImageBox>
        );
    }

    return (
        <ModalGenericWithHeader
            isOpen={props.isOpen}
            headerText={props.addOrEdit === ModalType.Edit ? "Edit contact" : "Add contact"}
            headerIcon={ContactIcon}
            stickyBar={
                <>
                    <Button
                        displayName={props.addOrEdit === ModalType.Edit ? " Save contact & exit" : " Add contact & exit"}
                        paletteColor="ButtonGreen"
                        value={SubmitType.SaveAndExit}
                        command={props.viewModel.validateAndSubmitContact}
                        icon={<img src={tick} />}
                        mr="10px"
                        boxShadow={true}
                    />

                    <Button
                        displayName={props.addOrEdit === ModalType.Edit ? " Save contact & continue" : " Add contact & continue"}
                        paletteColor="ButtonGreen"
                        value={SubmitType.SaveAndContinue}
                        command={props.viewModel.validateAndSubmitContact}
                        icon={<img src={tick} />}
                        mr="10px"
                        boxShadow={true}
                    />
                    <Button displayName=" Cancel" paletteColor="ButtonCancel" command={props.closeModal} icon={<img src={tick} />} boxShadow={true} />
                </>
            }
            closeModalCommand={props.closeModal}
        >
            {props.viewModel.isProcessing ? (
                <ThemedLoaderSmaller isOpen={true} />
            ) : (
                <>
                    {props.enableCustomerDropdown && props.addOrEdit === ModalType.Add && props.viewModel.getCustomerTypesForModalDropdown && (
                        <Box>
                            <Box pb="15px">
                                <EditSelect
                                    displayName="Select customer type *"
                                    command={setCustomerType}
                                    options={props.viewModel.getCustomerTypesForModalDropdown}
                                    value={() => props.viewModel.getValue("customerType")}
                                    validationMessage={() => props.viewModel.getError("customerType")}
                                    errorIconRight={false}
                                />
                            </Box>
                            {props.viewModel.selectCustomer &&
                                props.viewModel.getCustomers &&
                                props.viewModel.isCustomerTypeSelected &&
                                props.viewModel.isCustomerTypeSelected() === true && (
                                    <EditSelect
                                        displayName="Select customer *"
                                        placeholder="Enter customer name"
                                        command={props.viewModel.selectCustomer}
                                        options={getCustomersForDropdown()}
                                        value={() => props.viewModel.getValue("searchString")}
                                        onInputChange={(e) => {
                                            props.viewModel.getCustomers && props.viewModel.getCustomers(e);
                                        }}
                                        validationMessage={() => props.viewModel.getError("customerId")}
                                        errorIconRight={false}
                                    />
                                )}
                            {/* } */}
                        </Box>
                    )}
                    <Box grid dc="1fr 1fr" minWidth="658px" p="12px 0px">
                        <Input
                            // command={props.viewModel.setFirstName}
                            command={setFirstName}
                            displayName="First name *"
                            placeholder="First name"
                            value={() => props.viewModel.getValue("firstName")}
                            validationMessage={() => props.viewModel.getError("firstName")}
                            autoFill={false}
                        ></Input>
                        <Input
                            command={setLastName}
                            displayName="Last name *"
                            placeholder="Last name"
                            value={() => props.viewModel.getValue("lastName")}
                            validationMessage={() => props.viewModel.getError("lastName")}
                            autoFill={false}
                        ></Input>
                        <Input
                            command={setPhoneNumber}
                            displayName="Contact phone number *"
                            placeholder="Phone number"
                            value={() => props.viewModel.getValue("phone")}
                            validationMessage={() => props.viewModel.getError("phone")}
                            autoFill={false}
                        ></Input>
                        <Input
                            command={setPhoneNumber2}
                            displayName="Additional phone number information"
                            placeholder="Additional number information"
                            value={() => props.viewModel.getValue("phone2")}
                            validationMessage={() => props.viewModel.getError("phone2")}
                            autoFill={false}
                        ></Input>
                        <Input
                            command={setEmail}
                            displayName="Contact email address *"
                            placeholder="Email address"
                            value={() => props.viewModel.getValue("email")}
                            validationMessage={() => props.viewModel.getError("email")}
                            autoFill={false}
                        ></Input>
                        <Input command={setJobTitle} displayName="Job title" placeholder="Job title" value={() => props.viewModel.getValue("jobTitle")} autoFill={false}></Input>
                        <CheckBox command={setPrimaryContact} displayName="Primary contact" value={() => props.viewModel.getValue("primaryContact")} />
                        <CheckBox command={setMarketingConsent} displayName="Marketing consent" value={() => props.viewModel.getValue("marketingConsent")} />
                    </Box>
                    <HRSolidGrey />
                    <Box grid dc="1fr 1fr" p="10px 0px">
                        <RadioBox>
                            <RadioButton
                                className="RadioBoxInner"
                                key="ptrb"
                                command={setPlygeneTrained}
                                displayName="Plygene Trained"
                                options={[
                                    { key: "true", text: "Yes" },
                                    { key: "false", text: "No" },
                                ]}
                                value={() => GetPlygeneTrained()}
                                uniqueId="plygene"
                            />
                        </RadioBox>
                        {props.viewModel.getValue("plygeneTrained") === true ? (
                            <DatePickerBox style={{ maxWidth: "182px" }}>
                                <EditDate
                                    command={setPlygeneDateTrained}
                                    value={props.viewModel.getValue("datePlygeneTrained")}
                                    displayName="Date trained"
                                    placeholder="Date trained"
                                    validationMessage={() => props.viewModel.getError("datePlygeneTrained")}
                                />
                            </DatePickerBox>
                        ) : (
                            <Box minHeight="60px" />
                        )}
                        <RadioBox>
                            <RadioButton
                                key="ctrb"
                                command={setCoatingsTrained}
                                displayName="Coatings Trained"
                                options={[
                                    { key: "true", text: "Yes" },
                                    { key: "false", text: "No" },
                                ]}
                                value={() => GetCoatingsTrained()}
                                uniqueId="coating"
                            />
                        </RadioBox>
                        {props.viewModel.getValue("coatingsTrained") === true ? (
                            <DatePickerBox style={{ maxWidth: "182px" }}>
                                <EditDate
                                    command={setCoatingsDateTrained}
                                    value={props.viewModel.getValue("dateCoatingsTrained")}
                                    displayName="Date trained"
                                    placeholder="Date trained"
                                    validationMessage={() => props.viewModel.getError("dateCoatingsTrained")}
                                />
                            </DatePickerBox>
                        ) : (
                            <Box minHeight="60px" />
                        )}
                    </Box>

                    <Box grid dc="1fr 1fr" p="10px 0px">
                        <RadioBox>
                            <RadioButton
                                key="pcrb"
                                command={setPlygeneCPD}
                                displayName="Plygene CPD"
                                options={[
                                    { key: "true", text: "Yes" },
                                    { key: "false", text: "No" },
                                ]}
                                value={() => GetPlygeneCPD()}
                                uniqueId="plygenecpd"
                            />
                        </RadioBox>
                        {props.viewModel.getValue("plygeneCPD") === true ? (
                            <DatePickerBox style={{ maxWidth: "182px" }}>
                                <EditDate
                                    command={setPlygeneDateCPD}
                                    value={props.viewModel.getValue("datePlygeneCPD")}
                                    displayName="Date CPD"
                                    placeholder="Date CPD"
                                    validationMessage={() => props.viewModel.getError("datePlygeneCPD")}
                                />
                            </DatePickerBox>
                        ) : (
                            <Box minHeight="60px" />
                        )}
                        <RadioBox>
                            <RadioButton
                                key="ccrb"
                                command={setCoatingsCPD}
                                displayName="Coatings CPD"
                                options={[
                                    { key: "true", text: "Yes" },
                                    { key: "false", text: "No" },
                                ]}
                                value={() => GetCoatingsCPD()}
                                uniqueId="coatingcpd"
                            />
                        </RadioBox>
                        {props.viewModel.getValue("coatingsCPD") === true ? (
                            <DatePickerBox style={{ maxWidth: "182px" }}>
                                <EditDate
                                    command={setCoatingsDateCPD}
                                    value={props.viewModel.getValue("dateCoatingsCPD")}
                                    displayName="Date CPD"
                                    placeholder="Date CPD"
                                    validationMessage={() => props.viewModel.getError("dateCoatingsCPD")}
                                />
                            </DatePickerBox>
                        ) : (
                            <Box minHeight="60px" />
                        )}
                    </Box>

                    <HRSolidGrey />
                    <Box pt="12px">
                        <TextAreaBox>
                            <Input
                                displayName="Note"
                                placeholder="Your note"
                                command={setNote}
                                value={() => props.viewModel.getValue("note")}
                                multiline={true}
                                rows={4}
                                autoFill={false}
                            />
                        </TextAreaBox>
                    </Box>
                    {props.viewModel.showDocumentsSection && (
                        <>
                            <Box p="10px 0px">
                                <P pb="15px">
                                    <strong>Documents added</strong>
                                </P>
                                {props.viewModel.uploadedDocuments.length < 1 && <P pb="15px">There are currently no documents added to this contact</P>}
                                {props.viewModel.uploadedDocuments.map((document) => {
                                    return (
                                        <>
                                            <NoteBox key={document.id}>
                                                <Box flexBox>
                                                    <img src={FileIcon} height="15.3px"></img>
                                                    <P pl="10px">{document.fileName}</P>
                                                </Box>
                                                <Box style={{ display: "flex", gap: "5px" }}>
                                                    <DownloadButton id={document.id} onClick={props.viewModel.downloadDocumentCommand!} />

                                                    <DeleteButton
                                                        command={props.viewModel.deleteDocumentByIdCommand}
                                                        value={String(document.id)}
                                                        style={{ cursor: "pointer" }}
                                                        icon={<img src={CloseIcon} />}
                                                    />
                                                </Box>
                                            </NoteBox>
                                        </>
                                    );
                                })}
                            </Box>

                            <Box minWidth="658px" p="12px 0px">
                                <MyDropzone />
                            </Box>
                            {props.viewModel.documentsToUpload.map((document, index) => {
                                return (
                                    <>
                                        <DocumentToUploadBox key={document.model.KEY + index}>
                                            <Box flexBox gap={0} style={{ alignItems: "center" }}>
                                                <img src={DocLogo} height="18px" />
                                                <P pl="10px">{document.getValue("filename")}</P>
                                            </Box>
                                            <Box flexBox style={{ justifyContent: "space-between", alignItems: "center" }}>
                                                {props.parentViewModel.getDocTypesForDropdown && (
                                                    <EditSelect
                                                        command={document.setDocumentType}
                                                        options={props.parentViewModel.getDocTypesForDropdown}
                                                        value={() => document.getValue("documentCategoryId")}
                                                        validationMessage={() => document.getError("documentCategoryId")}
                                                        errorIconRight={true}
                                                        noHeader={true}
                                                    />
                                                )}
                                            </Box>
                                        </DocumentToUploadBox>
                                    </>
                                );
                            })}
                            {props.viewModel.documentsToUpload.length > 0 && (
                                <Box flexBox justifyContent="right">
                                    <Button displayName="Attach files" boxShadow={true} paletteColor="ButtonGreen" command={props.viewModel.submitDocuments} />
                                </Box>
                            )}
                        </>
                    )}
                </>
            )}
        </ModalGenericWithHeader>
    );
});
