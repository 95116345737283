import { Box } from "Application";
import styled from "@emotion/styled";

export const ButtonUnderBoxDocUploader = styled(Box)`
    margin-top: -15px;
`;

export const PreviewButtonImg = styled.img`
    cursor: pointer;
`;

export const ExcelTableWrapper = styled.div`
    table,
    th,
    td {
        border-collapse: collapse;
        border: 1px solid lightgray;
    }

    td {
        padding: 0px 5px;
    }
`;

export const DocPreviewPaddingBox = styled(Box)`
    padding: 16px;
    background-color: lightgray;
`;

export const DocPreviewBox = styled(Box)`
    padding: 16px;
    background-color: white;

    img {
        width: 100%;
    }
`;
