import { makeObservable, action, observable, IObservableArray, computed } from "mobx";
import { KeyValuePair, ViewModelBase } from "@shoothill/core";
import { APIClient, ICommand, RelayCommand } from "Application";
import { ErrorStore } from "Stores/Domain/ErrorStore";
import { container } from "tsyringe";
import { TasksModel, TasksModelValidator } from "Models/Tasks/TasksModel";
import { ITasksAddEditViewModel } from "./ITasksAddEditViewModel";
import { TaskTypeDropdownItem } from "../../../Models/Tasks/TaskTypeDropdownItem";
import { SubTaskTypeDropdownItem } from "../../../Models/Tasks/SubTaskTypeDropdownItem";
import { ITasksRelatedResponse } from "Models/Tasks/ITasksRelatedResponse";
import { ITaskWithRelatedResponse } from "Models/Tasks/TaskWithRelatedResponse";

export abstract class AddEditBaseViewModel extends ViewModelBase<TasksModel> implements ITasksAddEditViewModel {
    public apiClient = new APIClient();
    errorStore = container.resolve(ErrorStore);

    public isProcessing: boolean = false;
    public showActionSection: boolean = false;

    public taskType: IObservableArray<TaskTypeDropdownItem> = observable([]);
    public subTaskType: IObservableArray<SubTaskTypeDropdownItem> = observable([]);

    public constructor() {
        super(new TasksModel());
        this.setValidator(new TasksModelValidator());
        makeObservable(this, {
            clear: action,
            isProcessing: observable,
            showActionSection: observable,
            getTaskTypeDropdownItems: computed,
            getSubTaskTypeDropdownItems: computed,
            getIsProcessing: computed,
        });
    }

    public clear = () => {
        this.isProcessing = false;
        this.model.clear();
    };

    public setShowActionSection(value: boolean): void {
        this.showActionSection = value;
    }

    public abstract upsertAsync(projectId: string): Promise<any>;

    public abstract getTasks(complaintId: string): Promise<any>;

    public abstract getTasksRelatedForProject(projectId: string): Promise<any>;

    public get getIsProcessing(): boolean {
        return this.isProcessing;
    }

    public setTaskType: ICommand = new RelayCommand((value: KeyValuePair) => {
        this.model.subTaskTypeId = value.key;
    });

    public get getTaskTypeDropdownItems(): KeyValuePair[] {
        return this.taskType.map((x) => {
            return { key: x.id, text: x.displayName };
        });
    }

    public setSubTaskType: ICommand = new RelayCommand((value: KeyValuePair) => {
        this.model.assignedUserId = value.key;
    });

    public get getSubTaskTypeDropdownItems(): KeyValuePair[] {
        return this.subTaskType.map((x) => {
            return { key: x.id, text: x.userName };
        });
    }

    public updatedDescriptionCommand: ICommand = new RelayCommand((value: string) => {
        this.model.description = value;
    });

    public updateStartDateCommand = new RelayCommand((value: Date) => {
        this.model.startDateTime = value;
    });

    public updateDueDateCommand = new RelayCommand((value: string) => {
        this.model.dueDateTime = value;
    });

    public updateIsUrgentCommand: ICommand = new RelayCommand((value: boolean) => {
        this.model.isUrgent = value;
    });

    public updateIsCompleteCommand: ICommand = new RelayCommand((value: boolean) => {
        this.model.isComplete = value;
    });

    public processRelatedResponse = (response: ITasksRelatedResponse) => {
        this.taskType.clear();
        this.subTaskType.clear();
        this.taskType.replace(response.taskSubTypeDropdownItem);
        this.subTaskType.replace(response.userDropdownItem);
    };

    public processTaskWithRelatedResponse = (response: ITaskWithRelatedResponse) => {
        this.model.fromResponse(response.sharmanTask);
        this.processRelatedResponse(response);
    };
}
