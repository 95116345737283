import { IObservableArray, action, computed, makeObservable, observable, runInAction } from "mobx";
import { KeyValuePair, ViewModelBase } from "@shoothill/core";
import { APIClient, ICommand, RelayCommand } from "Application";
import { TasksListModel, TasksListModelValidator } from "./TasksListModel";
import { ErrorStore } from "Stores/Domain/ErrorStore";
import { container } from "tsyringe";
import { DownloadTasksCSVEndpoint } from "./Endpoints/DownloadTasksCSVEndpoint";
import { SortOrderDirection } from "Components/Primitives/DataTable/SharmansTable";
import { DefaultPageSize, StartOfTime } from "Globals/GlobalSettings";
import { GetTasksListAndRelatedEndpoint, TasksListAndRelatedResponse } from "./Endpoints/GetTasksListAndRelatedEndpoint";
import { TasksListItemModel } from "./TasksListItemModel";
import { KeyValuePairExtended } from "Models/KeyValuePairExtended";
import { UrgencyHelpers } from "./UrgencyEnum";
import { Timescale } from "Models/Timescale";
import { GetComplaintTaskDetailsByIdEndpoint } from "Views/Aftersales/Complaints/View/Tasks/Endpoints/GetComplaintTaskDetailsByIdEndpoint";
import { TaskListUpsertEndpoint } from "./Endpoints/TaskListUpsertEndpoint";
import { AccountStore } from "Stores/Domain/AccountStores";

export class TasksListViewModel extends ViewModelBase<TasksListModel> {
    // Singleton Patten - Uses instance and a private static variable to ensure only one instance is created and a private Constructor
    private static _instance: TasksListViewModel;
    public static get Instance() {
        return this._instance || (this._instance = new this());
    }

    public apiClient = new APIClient();
    errorStore = container.resolve(ErrorStore);
    accountStore = container.resolve(AccountStore);
    public lastLogin: string = "";
    public isProcessing: boolean = false;
    public initialLoad: boolean = true;
    public showViewTaskModal: boolean = false;
    public isNewUser: boolean = false;

    // Filtering top section

    public keywordSearch: string = "";
    public timescale: Timescale = Timescale.All;
    public showFrom: Date = new Date(StartOfTime);
    public showTo: Date = new Date();

    public filterByTasksType: string[] = [];
    public filterByCategory: string[] = [];
    public filterByAssignedTo: string[] = [];
    public filterByUrgency: string[] = [];
    public filterByCompleted: string[] = [];

    // Filtering bottom section

    public task: string = "";
    public category: string = "";
    public assignedTo: string = "";
    public urgency: string = "";

    // Table ordering

    public orderBy: SortOrderDirection = SortOrderDirection.ASC;
    public columnName: string = "displayName";

    // Pagination

    public tasksCount: number = 0;
    public pageSize: number = DefaultPageSize;
    public pageCount: number = 0;
    public pageNumber: number = 1;

    public filterTaskType: IObservableArray<KeyValuePairExtended> = observable([]);
    public filterCategory: IObservableArray<KeyValuePairExtended> = observable([]);
    public filterAssignedTo: IObservableArray<KeyValuePairExtended> = observable([]);

    private constructor() {
        super(new TasksListModel());
        this.setValidator(new TasksListModelValidator());
        this.loadNoCompletedSelected();
        makeObservable(this, {
            isProcessing: observable,
            keywordSearch: observable,
            timescale: observable,
            showFrom: observable,
            showTo: observable,
            task: observable,
            category: observable,
            assignedTo: observable,
            urgency: observable,
            orderBy: observable,
            columnName: observable,
            tasksCount: observable,
            pageSize: observable,
            pageNumber: observable,
            showViewTaskModal: observable,

            filterByTasksType: observable,
            filterByCategory: observable,
            filterByAssignedTo: observable,
            filterByUrgency: observable,
            initialLoad: observable,
            lastLogin: observable,
            isNewUser: observable,

            filterByCompleted: observable,

            updateTasksTypeFiltering: action,
            tasksTypeSelectAll: action,
            tasksTypeSelectNone: action,

            updateCategoryFiltering: action,
            categorySelectAll: action,
            categorySelectNone: action,

            updateAssignedToFiltering: action,
            assignedToSelectAll: action,
            assignedToSelectNone: action,

            updateUrgencyFiltering: action,
            urgencySelectAll: action,
            urgencySelectNone: action,

            updateCompletedFiltering: action,
            completedSelectAll: action,
            completedSelectNone: action,

            downloadCSVAsync: action,
            openViewTasksModal: action,
            getTasksDetailsById: action,

            loadTasksAsync: action,

            getCanExecute: computed,
            getTasks: computed,
            getTaskType: computed,
            getTasksFilter: computed,
            getCategory: computed,
            getCategoryFilter: computed,
            getAssignedTo: computed,
            getAssignedToFilter: computed,
            getUrgencyFilter: computed,
            getCompletedFilter: computed,
        });
    }

    public clear = () => {
        this.isProcessing = false;
        this.showViewTaskModal = false;
        this.keywordSearch = "";
        this.orderBy = SortOrderDirection.ASC;
        this.columnName = "displayName";
        this.tasksCount = 0;
        this.pageSize = DefaultPageSize;
        this.pageCount = 0;
        this.pageNumber = 1;
    };

    public reset = () => {
        this.keywordSearch = "";
        this.timescale = Timescale.All;
        this.showFrom = new Date(StartOfTime);
        this.showTo = new Date();
        this.tasksTypeSelectAll();
        this.categorySelectAll();
        // this.assignedToSelectAll();
        this.urgencySelectAll();
        this.loadNoCompletedSelected();
    };

    public openViewTasksModal = (Id: any) => {
        this.getTasksDetailsById(Id!);
        this.showViewTaskModal = true;
    };

    public updateKeywordSearch: ICommand = new RelayCommand(
        (keyword: string) => {
            this.keywordSearch = keyword;
            this.loadTasksAsync();
        },
        () => {
            return this.getCanExecute;
        },
    );

    public closeViewTaskModal: ICommand = new RelayCommand(() => {
        this.showViewTaskModal = false;
    });

    public updateIsCompleteCommand: ICommand = new RelayCommand((value: boolean) => {
        this.model.isComplete = value;
    });

    public addEditCommand: ICommand = new RelayCommand(() => {
        let promise = this.upsertAsync();
        promise.then((kv) => {
            this.loadTasksAsync();
        });
    });

    public get getCanExecute(): boolean {
        return !this.isProcessing;
    }

    public get getTasks(): TasksListItemModel[] {
        return this.model.tasks.slice();
    }

    public get getTimescale(): string {
        return this.timescale.toString();
    }

    //FilteringTopSection

    public setShowFromDate(date: Date) {
        this.showFrom = date;
    }

    public get getShowFrom(): Date {
        return this.showFrom;
    }

    public setShowToDate(date: Date) {
        this.showTo = date;
    }

    public get getShowTo(): Date {
        return this.showTo;
    }

    public setTimeScale = (timescale: KeyValuePair) => {
        this.timescale = parseInt(timescale.key) as Timescale;
        this.updateShowFromTo();
    };

    public updateShowFromTo = () => {
        let showFrom: Date = new Date();
        showFrom.setHours(0, 0, 0, 0);
        this.showTo = new Date();
        this.showTo.setHours(23, 59, 59, 59);

        switch (this.timescale) {
            case Timescale.OneMonth: {
                showFrom = new Date(showFrom.setMonth(showFrom.getMonth() - 1));
                break;
            }
            case Timescale.OneYear: {
                showFrom = new Date(showFrom.setFullYear(showFrom.getFullYear() - 1));
                break;
            }
            case Timescale.OneWeek: {
                showFrom = new Date(showFrom.setDate(showFrom.getDate() - 7));
                break;
            }
            case Timescale.ThreeMonths: {
                showFrom = new Date(showFrom.setMonth(showFrom.getMonth() - 3));
                break;
            }
            case Timescale.ThreeYears: {
                showFrom = new Date(showFrom.setFullYear(showFrom.getFullYear() - 3));
                break;
            }

            case Timescale.All: {
                showFrom = new Date(StartOfTime);
                break;
            }

            case Timescale.Today:
            default: {
                break;
            }
        }
        this.showFrom = showFrom;
    };

    //End FilteringTopSection

    // Tasks Type

    public get getTaskType(): KeyValuePairExtended[] {
        return this.filterTaskType.slice();
    }

    public get getTasksFilter(): string | string[] {
        return this.filterByTasksType.slice();
    }

    public updateTasksTypeFiltering = (values: string[]) => {
        this.filterByTasksType = values;
    };

    public tasksTypeSelectAll = () => {
        this.filterByTasksType = this.filterTaskType.map((item) => {
            return item.key;
        });
    };

    public tasksTypeSelectNone = () => {
        this.filterByTasksType = [];
    };

    // End Tasks

    // Category

    public get getCategory(): KeyValuePairExtended[] {
        return this.filterCategory.slice();
    }

    public get getCategoryFilter(): string | string[] {
        return this.filterByCategory.slice();
    }

    public updateCategoryFiltering = (values: string[]) => {
        this.filterByCategory = values;
    };

    public categorySelectAll = () => {
        this.filterByCategory = this.filterCategory.map((item) => {
            return item.key;
        });
    };

    public categorySelectNone = () => {
        this.filterByCategory = [];
    };

    //end Category

    // Assigned to

    public get getAssignedTo(): KeyValuePairExtended[] {
        return this.filterAssignedTo.slice();
    }

    public get getAssignedToFilter(): string | string[] {
        return this.filterByAssignedTo.slice();
    }

    public updateAssignedToFiltering = (values: string[]) => {
        this.filterByAssignedTo = values;
    };

    public assignedToSelectAll = () => {
        this.filterByAssignedTo = this.filterAssignedTo.map((item) => {
            return item.key;
        });
    };

    public assignedToSelectNone = () => {
        this.filterByAssignedTo = [];
    };

    // end Assigned to

    //Urgency

    public get getUrgencyFilter(): string | string[] {
        return this.filterByUrgency.slice();
    }

    public updateUrgencyFiltering = (values: string[]) => {
        this.filterByUrgency = values;
    };

    public urgencySelectAll = () => {
        this.filterByUrgency = UrgencyHelpers.getUrgencyForDropdown().map((item) => {
            return item.key;
        });
    };

    public urgencySelectNone = () => {
        this.filterByUrgency = [];
    };

    // end Urgency

    //completed

    public get getCompletedFilter(): string[] {
        return this.filterByCompleted.slice();
    }

    public get getCompletedDropdown() {
        let retVal: KeyValuePair[] = [
            { key: true, text: "Completed" },
            { key: false, text: "Not Completed" },
        ];

        return retVal;
    }

    public updateCompletedFiltering = (values: string[]) => {
        this.filterByCompleted = values;
    };

    public completedSelectAll = () => {
        this.filterByCompleted = this.getCompletedDropdown.map((item) => {
            return item.key;
        });
    };

    public completedSelectNone = () => {
        this.filterByCompleted = [];
    };

    public loadNoCompletedSelected = () => {
        const noCompletedSelected: string[] = this.getCompletedDropdown.filter((item) => item.text === "Not Completed").map((item) => item.key);
        this.filterByCompleted = noCompletedSelected;
    };

    // End completed

    public get IsBusy(): boolean {
        return this.apiClient.IsBusy;
    }

    public get IsAdmin(): boolean {
        return this.accountStore.isInRole("ADMIN") ? true : false;
    }

    public loadTasksAsync = async (): Promise<TasksListAndRelatedResponse> => {
        const endpoint = new GetTasksListAndRelatedEndpoint();
        let retVal: TasksListAndRelatedResponse = new TasksListAndRelatedResponse();

        const lastLoggedInUserId = this.lastLogin;
        const currentLoggedInUserId = this.accountStore.UserName;
        if (this.isProcessing === false && this.apiClient.IsBusy === false) {
            this.isProcessing = true;

            if (lastLoggedInUserId != currentLoggedInUserId) {
                this.isNewUser = true;
            } else {
                this.isNewUser = false;
            }

            this.lastLogin = this.accountStore.UserName;

            if (this.isNewUser) {
                this.filterByTasksType = [];
                this.filterByCategory = [];
                this.filterByAssignedTo = [];
                this.filterByUrgency = [];
                this.initialLoad = true;
            }

            await this.apiClient.sendAsync(endpoint, this);

            if (this.apiClient.IsRequestSuccessful) {
                runInAction(() => {
                    this.model.tasks.clear();
                    let response: TasksListAndRelatedResponse = this.apiClient.Response();
                    this.model.tasks.replace(response.tasks);
                    this.tasksCount = response.totalCount;
                    this.pageCount = Math.ceil(this.tasksCount / this.pageSize);
                    this.isProcessing = false;
                    if (this.initialLoad === true) {
                        this.filterTaskType.clear();
                        this.filterCategory.clear();
                        this.filterAssignedTo.clear();

                        this.filterTaskType.replace(
                            response.taskTypeDropdown.map((item) => {
                                return { key: item.id.toString(), text: item.displayName, class: "" };
                            }),
                        );

                        this.filterCategory.replace(
                            response.categoryDropdown.map((item) => {
                                return { key: item.id.toString(), text: item.displayName, class: "" };
                            }),
                        );

                        this.filterAssignedTo.replace(
                            response.assignedDropdown.map((item) => {
                                return { key: item.id.toString(), text: item.userName, class: "" };
                            }),
                        );

                        const tempTaskType: string[] = response.taskTypeDropdown.map((item) => {
                            return item.id.toString();
                        });

                        const tempCategory: string[] = response.categoryDropdown.map((item) => {
                            return item.id.toString();
                        });

                        const tempAssignedTo: string[] = response.assignedDropdown
                            .filter((item) => item.id.toString() === this.accountStore.UserName)
                            .map((item) => item.id.toString());

                        this.filterByAssignedTo = tempAssignedTo;
                        this.filterByTasksType = tempTaskType;
                        this.filterByCategory = tempCategory;

                        // Reset the filters to ALL
                        this.tasksTypeSelectAll();
                        this.categorySelectAll();
                        this.urgencySelectAll();

                        this.initialLoad = false;

                        if (!this.isNewUser) {
                            this.initialLoad = false;
                        }
                    }
                });
            } else {
                runInAction(() => {
                    this.isProcessing = false;
                });
                this.errorStore.setHeaderText("Tasks list");
                this.errorStore.setButtonText("Close");
                this.errorStore.setErrorMessageOne("Failed to get the tasks list.  Please try again later.");
                this.errorStore.setErrorMessageTwo(this.apiClient.ValidationMessage);
                this.errorStore.setErrorModalOpen(true);
            }
        }
        return retVal;
    };

    public async downloadCSVAsync(): Promise<void> {
        const endpoint = new DownloadTasksCSVEndpoint();
        await this.apiClient.sendAsync(endpoint, this);
        this.isProcessing = true;

        if (this.apiClient.IsRequestSuccessful) {
            runInAction(() => {
                this.isProcessing = false;
            });
        } else {
            runInAction(() => {
                this.isProcessing = false;
            });
            this.errorStore.setHeaderText("Download tasks CSV");
            this.errorStore.setButtonText("Close");
            this.errorStore.setErrorMessageOne("Failed to download tasks CSV.  Please try again later.");
            this.errorStore.setErrorMessageTwo(this.apiClient.ValidationMessage);
            this.errorStore.setErrorModalOpen(true);
        }
    }

    public upsertAsync = async (): Promise<any> => {
        let retVal: TasksListModel = this.model;

        if (this.isProcessing === false && this.apiClient.IsBusy === false) {
            {
                let endpoint: TaskListUpsertEndpoint = new TaskListUpsertEndpoint();
                this.isProcessing = true;
                this.setValue("sourceId", this.model.sourceId);
                this.setValue("taskTypeId", this.model.enumId);
                let _ = await this.apiClient.sendAsync(endpoint, this.model);
                if (this.apiClient.IsRequestSuccessful) {
                    runInAction(() => {
                        this.isProcessing = false;
                        let response: TasksListModel = this.apiClient.Response();
                        this.model.fromResponse(response);
                        this.showViewTaskModal = false;
                    });
                } else {
                    runInAction(() => {
                        this.isProcessing = false;
                    });
                    this.errorStore.setHeaderText("Task List");
                    this.errorStore.setButtonText("Close");
                    this.errorStore.setErrorMessageOne("Failed to upsert task.  Please try again later.");
                    this.errorStore.setErrorMessageTwo(this.apiClient.ValidationMessage);
                    this.errorStore.setErrorModalOpen(true);
                }
            }
        }
        return retVal;
    };

    public async getTasksDetailsById(Id: Guid): Promise<void> {
        this.isProcessing = true;
        if (Id !== null) {
            await this.apiClient.sendAsync(new GetComplaintTaskDetailsByIdEndpoint(Id));
            if (this.apiClient.IsRequestSuccessful) {
                runInAction(() => {
                    let response = this.apiClient.Response();
                    this.model.fromResponse(response.sharmanTask);
                    this.isProcessing = false;
                });
            } else {
                runInAction(() => {
                    this.isProcessing = false;
                });
                this.errorStore.setHeaderText("Tasks Details");
                this.errorStore.setButtonText("Close");
                this.errorStore.setErrorMessageOne("Failed to load task details.  Please try again later.");
                this.errorStore.setErrorMessageTwo(this.apiClient.ValidationMessage);
                this.errorStore.setErrorModalOpen(true);
            }
        }
    }

    public clearFiltering: ICommand = new RelayCommand(
        () => {
            this.filterByTasksType = [];
            this.filterByCategory = [];
            this.filterByAssignedTo = [];
            this.filterByUrgency = [];

            this.filterByCompleted = [];
            this.initialLoad = true;
            this.loadTasksAsync();
        },
        () => {
            return this.getCanExecute;
        },
    );
}
