import { IObservableArray, action, computed, makeObservable, observable, runInAction } from "mobx";
import { KeyValuePair, ViewModelBase } from "@shoothill/core";
import { APIClient, ICommand, RelayCommand } from "Application";
import { ErrorStore } from "Stores/Domain/ErrorStore";
import { container } from "tsyringe";
import { NewCustomerModel, NewCustomerModelValidator } from "./NewCustomerModel";
import { IAddress } from "Components/AddressGeneric/AddressGenericModel";
import { CoreStoreInstance } from "@shoothill/core";
import { GetNewEditRelatedEndpoint, GetNewEditRelatedResponse } from "../Endpoints/GetNewEditRelated";
import { UpsertCustomerEndpoint } from "../Endpoints/UpsertCustomer";
import { AppUrlIndex, AppUrls } from "AppUrls";
import { AddressGenericViewModel } from "Components/AddressGeneric/AddressGenericViewModel";
import { CustomerDetailsForEdit } from "../ViewCustomer/Details/DetailsModel";
import { DropdownItem } from "Models/DropdownItem";
import { ModalType } from "Application/Models/Domain/AddEdditModalType";
import { CustomerTypeDropdownItem } from "Application/Models/Domain/Customer/CustomerTypeDropdownItem";
import { SubmitType } from "Application/Models/Domain/Forms";
import { IDocumentType } from "Models/Documents/DocumentType";
import { ContactStatusDropdownItem } from "Models/Contacts/ContactStatusDropdownItem";
import { ICustomerNote } from "../Common/ICustomerNote";
import { IDocumentUploaded } from "Models/Documents/IDocumentUploaded";
import { ContactListItem } from "./CustomerContacts/CustomerContactsModel";
import { GetCustomerDetailsForEditByIdEndpoint } from "../Endpoints/GetCustomerDetailsForEditById";
import { CustomerStore } from "Stores/Domain";

export class NewCustomerViewModel extends ViewModelBase<NewCustomerModel> {
    customerStore = container.resolve(CustomerStore);
    public apiClient = new APIClient();
    errorStore = container.resolve(ErrorStore);
    public isProcessing: boolean = false;
    public spinnerText: string = "Loading...";
    public addOrEdit: ModalType;
    // For bringing them in in Edit View
    public customerNotes: IObservableArray<ICustomerNote> = observable([]);
    public uploadedDocuments: IObservableArray<IDocumentUploaded> = observable([]);
    public contacts: IObservableArray<ContactListItem> = observable([]);

    public validateAddressForSubmission: boolean = false;
    public isAddressValid: boolean = false;
    public trigger: number = 0;

    public showAttachmentsSection: boolean = false; // Show attachments for dev

    public addressViewModel: AddressGenericViewModel = new AddressGenericViewModel();

    // Related - Main form

    public customerTypes: IObservableArray<CustomerTypeDropdownItem> = observable([]);
    public tsms: IObservableArray<DropdownItem> = observable([]);
    public bdms: IObservableArray<DropdownItem> = observable([]);

    // Related - Documents

    public documentTypes: IObservableArray<IDocumentType> = observable([]);

    // Related - Contacts

    public contactStatusTypes: IObservableArray<ContactStatusDropdownItem> = observable([]);

    constructor(addOrEdit: ModalType) {
        super(new NewCustomerModel());
        this.addOrEdit = addOrEdit;
        this.setValidator(new NewCustomerModelValidator(this.customerStore));
        makeObservable(this, {
            isProcessing: observable,
            validateAddressForSubmission: observable,
            isAddressValid: observable,
            trigger: observable,
            showAttachmentsSection: observable,
            spinnerText: observable,
            addressViewModel: observable,
            addOrEdit: observable,

            checkIfAddressIsValid: action,
            checkIfModelIsValid: action,
            clear: action,
            getCustomerDetails: action,
            getRelated: action,
            handleUpdatedAddress: action,
            // setLeadTSM: action,
            setShowAttachmentsSectionTrue: action,
            upsertCustomer: action,

            getBDM: computed,
            getBdmsForDropdown: computed,
            getDocTypesForDropdown: computed,
            getEORINumber: computed,
            getLeadTsm: computed,
            getStatusTypesForDropdown: computed,
            getTssNumber: computed,
            getTypesForDropdown: computed,
            getSageRef: computed,
            getTsmsForDropdown: computed,
        });
    }

    public clear = () => {
        this.isProcessing = false;
        this.spinnerText = "Loading...";
        this.addOrEdit = ModalType.Add;
        this.validateAddressForSubmission = false;
        this.isAddressValid = false;
        this.trigger = 0;
        this.showAttachmentsSection = false; // Show attachments for dev
        this.addressViewModel = new AddressGenericViewModel();
        // Related - Main form
        this.customerTypes.clear();
        this.tsms.clear();
        this.bdms.clear();
        // Related - Documents
        this.documentTypes.clear();
        // Related - Contacts
        this.contactStatusTypes.clear();
        this.model.clear();
    };

    // First form section
    public setCompanyName: ICommand = new RelayCommand((value: string) => {
        this.model.name = value;
    });

    public setCustomerType: ICommand = new RelayCommand(
        (value: KeyValuePair) => {
            this.model.customerTypeId = value.key;
        },
        () => this.addOrEdit === ModalType.Add,
    );

    public get getTypesForDropdown() {
        let retVal: KeyValuePair[] = [];

        retVal = this.customerTypes.slice().map((type) => {
            return { key: type.id, text: type.name };
        });

        return retVal;
    }

    public setSageRef: ICommand = new RelayCommand((value: string) => {
        this.model.sageRef = value;
    });

    public get getSageRef() {
        if (this.model.sageRef !== undefined) {
            return String(this.model.sageRef);
        } else {
            return "";
        }
    }

    public get getTsmsForDropdown() {
        let retVal: KeyValuePair[] = [];

        retVal = this.tsms.slice().map((tsm: DropdownItem) => {
            return { key: tsm.id, text: tsm.displayName };
        });

        return retVal;
    }

    public setLeadTSM: ICommand = new RelayCommand((value: KeyValuePair) => {
        this.model.leadTsmId = value.key;
    });

    public get getLeadTsm() {
        if (this.model.leadTsmId !== undefined) {
            return this.model.leadTsmId;
        } else {
            return "";
        }
    }

    public get getBdmsForDropdown() {
        let retVal: KeyValuePair[] = [];

        retVal = this.bdms.slice().map((bdm: DropdownItem) => {
            return { key: bdm.id, text: bdm.displayName };
        });

        return retVal;
    }

    public setBDM: ICommand = new RelayCommand((value: KeyValuePair) => {
        this.model.bdmId = value.key;
    });

    public get getBDM() {
        if (this.model.bdmId !== undefined) {
            return this.model.bdmId;
        } else {
            return "";
        }
    }

    public setEORINumber: ICommand = new RelayCommand((value: string) => {
        this.model.eORINumber = value;
    });

    public get getEORINumber() {
        if (this.model.eORINumber !== undefined) {
            return String(this.model.eORINumber);
        } else {
            return "";
        }
    }

    public setTSSNumber: ICommand = new RelayCommand((value: string) => {
        this.model.tSSNumber = value;
    });

    public setReferral: ICommand = new RelayCommand((value: boolean) => {
        this.model.referral = value;
    });

    public get getTssNumber() {
        if (this.model.tSSNumber !== undefined) {
            return String(this.model.tSSNumber);
        } else {
            return "";
        }
    }

    // Second form section

    public setCompanyContactEmail: ICommand = new RelayCommand((value: string) => {
        this.model.email = value;
    });

    public setCompanyContactPhone: ICommand = new RelayCommand((value: string) => {
        this.model.phone = value;
    });

    public setCompanyContactPhone2: ICommand = new RelayCommand((value: string) => {
        this.model.phone2 = value;
    });

    public setCompanyWebsiteAddress: ICommand = new RelayCommand((value: string) => {
        this.model.websiteAddress = value;
    });

    // Handle returned address
    public handleUpdatedAddress = (address: IAddress) => {
        this.model.address = address;
    };

    // Check if address model is valid
    public checkIfAddressIsValid = (isValid: boolean) => {
        this.validateAddressForSubmission = true;
        this.isAddressValid = isValid;
    };

    public checkIfModelIsValid = () => {
        this.validateAddressForSubmission = true;
        if (this.isModelValid() === true && this.isAddressValid === true) {
            CoreStoreInstance.HideInfoBar();
            return true;
        } else {
            return false;
        }
    };

    public submitNewCustomer: ICommand = new RelayCommand((submitType: SubmitType) => {
        if (
            this.addressViewModel.hasValidLatLong === false &&
            this.addressViewModel.model.postcode !== undefined &&
            this.addressViewModel.model.postcode !== null &&
            this.addressViewModel.model.postcode !== ""
        ) {
            let promise = this.addressViewModel.populateLatLongFromPostcode();

            promise.then(() => {
                this.addEditCustomer(submitType);
            });
        } else {
            this.addEditCustomer(submitType);
        }
    });

    private async addEditCustomer(submitType: SubmitType): Promise<void> {
        // Makes validator check address fields - turned off when searching by postcode,
        // so that validator only checks if postcode is filled in
        this.addressViewModel.turnOnAddressFieldsValidation();
        const thisModelValid: boolean = this.isModelValid();
        const addressModelValid: boolean = this.addressViewModel.isModelValid();

        if (thisModelValid && addressModelValid) {
            this.model.address = {
                postcode: this.addressViewModel.getValue("postcode"),
                latitude: this.addressViewModel.getValue("latitude"),
                longitude: this.addressViewModel.getValue("longitude"),
                addressLineOne: this.addressViewModel.getValue("addressLineOne"),
                addressLineTwo: this.addressViewModel.getValue("addressLineTwo"),
                townCity: this.addressViewModel.getValue("townCity"),
                county: this.addressViewModel.getValue("county"),
                country: this.addressViewModel.getValue("country"),
                countryId: this.addressViewModel.getValue("countryId"),
            };

            this.upsertCustomer(submitType);
        } else if (addressModelValid == false) {
            this.addressViewModel.setAddressFieldsPanelOpen();
        }
    }

    public async upsertCustomer(submitType: SubmitType): Promise<void> {
        this.spinnerText = "Saving...";

        if (this.isProcessing === false) {
            this.isProcessing = true;

            const endpoint = new UpsertCustomerEndpoint();
            await this.apiClient.sendAsync(endpoint, this.model);

            if (this.apiClient.IsRequestSuccessful) {
                runInAction(() => {
                    let response = this.apiClient.Response();

                    CoreStoreInstance.HideInfoBar();
                    if (submitType === SubmitType.SaveAndExit) {
                        this.history.push(AppUrls.Client.Directory.Customers[AppUrlIndex.Long]);
                    } else if (submitType === SubmitType.SaveAndContinue) {
                        this.setValue("id", response.id);
                        this.model = response;
                        this.isProcessing = false;
                        this.showAttachmentsSection = true;
                        // this.scrollToAttachmentSection(); // TODO at some point
                    }
                    this.isProcessing = false;
                    this.spinnerText = "Loading...";
                });
            } // else show error message
            else {
                runInAction(() => {
                    this.isProcessing = false;
                });
                this.errorStore.setHeaderText("New/edit customer");
                this.errorStore.setButtonText("Close");
                this.errorStore.setErrorMessageOne("Failed to save customer.  Please try again later.");
                this.errorStore.setErrorMessageTwo(this.apiClient.ValidationMessage);
                this.errorStore.setErrorModalOpen(true);
            }
        }
    }

    public async getRelated(): Promise<void> {
        if (this.isProcessing === false) {
            this.isProcessing = true;
            const endpoint = new GetNewEditRelatedEndpoint();
            await this.apiClient.sendAsync(endpoint);

            if (this.apiClient.IsRequestSuccessful) {
                runInAction(() => {
                    let response: GetNewEditRelatedResponse = this.apiClient.Response();
                    this.customerTypes.clear();
                    this.tsms.clear();
                    this.bdms.clear();
                    this.documentTypes.clear();
                    this.contactStatusTypes.clear();
                    this.customerTypes.replace(response.customerType);
                    this.tsms.replace(response.customerTsm);
                    this.bdms.replace(response.customerBdm);
                    this.documentTypes.replace(response.documentType);
                    this.contactStatusTypes.replace(response.contactStatusType);
                    this.model.customerTypeId = 1;

                    this.isProcessing = false;
                });
            } // else show error message
            else {
                runInAction(() => {
                    this.isProcessing = false;
                });
                this.errorStore.setHeaderText("New/edit customer");
                this.errorStore.setButtonText("Close");
                this.errorStore.setErrorMessageOne("Failed to get the dropdown lists.  Please try again later.");
                this.errorStore.setErrorMessageTwo(this.apiClient.ValidationMessage);
                this.errorStore.setErrorModalOpen(true);
            }
        }
    }

    public get getStatusTypesForDropdown() {
        let types: KeyValuePair[] = [];

        this.contactStatusTypes.map((type) => {
            types.push({ key: type.id, text: type.statusType });
        });
        return types;
    }

    public get getDocTypesForDropdown() {
        let types: KeyValuePair[] = [];

        this.documentTypes.map((type) => {
            types.push({ key: type.id, text: type.categoryName });
        });

        return types;
    }

    // For editing customer details

    public setShowAttachmentsSectionTrue() {
        this.showAttachmentsSection = true;
    }

    public async getCustomerDetails(Id: any): Promise<void> {
        if (this.isProcessing === false) {
            this.isProcessing = true;
            const endpoint: GetCustomerDetailsForEditByIdEndpoint = new GetCustomerDetailsForEditByIdEndpoint(Id);
            await this.apiClient.sendAsync(endpoint);

            if (this.apiClient.IsRequestSuccessful) {
                runInAction(() => {
                    let response: CustomerDetailsForEdit = this.apiClient.Response();
                    this.model.fromResponse(response);
                    this.addressViewModel.setValue("latitude", response.locationLatitude);
                    this.addressViewModel.setValue("longitude", response.locationLongitude);
                    this.addressViewModel.setValue("addressLineOne", response.addressLine1);
                    this.addressViewModel.setValue("addressLineTwo", response.addressLine2);
                    this.addressViewModel.setValue("townCity", response.townCity);
                    this.addressViewModel.setValue("county", response.county);
                    this.addressViewModel.setValue("country", response.country);
                    this.addressViewModel.setValue("countryId", response.countryId);
                    this.addressViewModel.setValue("postcode", response.postCode);
                    this.customerNotes.replace(response.notes);
                    this.uploadedDocuments.replace(response.documents);
                    this.contacts.replace(response.contacts);

                    this.isProcessing = false;
                });
            } // else show error message
            else {
                runInAction(() => {
                    this.isProcessing = false;
                });
                this.errorStore.setHeaderText("Customer");
                this.errorStore.setButtonText("Close");
                this.errorStore.setErrorMessageOne("Failed to get the customer details.  Please try again later.");
                this.errorStore.setErrorMessageTwo(this.apiClient.ValidationMessage);
                this.errorStore.setErrorModalOpen(true);
            }
        }
    }
}
