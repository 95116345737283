import React from "react";
import { observer } from "mobx-react-lite";
import { Box, Button, CheckBox, ICommand, Label, formatDate } from "Application";
import { ModalGenericWithHeader } from "Components/ModalGenericWithHeader/ModalGenericWithHeader";
import tick from "Assets/Icons/SmallTickCircleIcon.svg";
import { hyphenIfNoValue } from "Utils/Formats";
import ContactIcon from "Assets/Icons/ContactIcon.svg";
import styled from "@emotion/styled";
import { BooleanTickOrCrossCell } from "Utils/CellFormatComponents";

interface IViewcontactModal {
    isOpen: boolean;
    closeModal: ICommand<any>;
    customerId?: string;
    viewModel: any; // TODO TARUN use an interface
}

export const FooterBtn = styled(Box)`
    display: flex;
    align-items: center;
    gap: 10px;
    button {
        display: flex;
        gap: 8px;
        align-items: center;
    }
`;

export const ViewTasksModal: React.FC<IViewcontactModal> = observer((props) => {
    return (
        <>
            <ModalGenericWithHeader
                isOpen={props.isOpen}
                headerText={hyphenIfNoValue(props.viewModel.getValue("taskTypeName"))}
                headerIcon={ContactIcon}
                stickyBar={
                    <FooterBtn>
                        <Button displayName="Update" paletteColor="ButtonGreen" command={props.viewModel.addEditCommand} icon={<img src={tick} />} boxShadow={true} />
                        <Button displayName="Close" paletteColor="ButtonCancel" command={props.closeModal} icon={<img src={tick} />} boxShadow={true} />
                    </FooterBtn>
                }
                closeModalCommand={props.closeModal}
            >
                <Box width="658px" p="12px 0px">
                    <Box gridTemplateColumns={"1fr 1fr"} display={"grid"} mb={20} pb={20} style={{ gap: "20px", borderBottom: "1px dashed #ddd" }}>
                        <Box>
                            <Label fontStyle="h5">Task type:</Label>
                            <Label>{hyphenIfNoValue(props.viewModel.getValue("taskTypeName"))}</Label>
                        </Box>
                        <Box>
                            <Label fontStyle="h5">Assign this task to :</Label>
                            <Label>{hyphenIfNoValue(props.viewModel.getValue("assignedUserName"))}</Label>
                        </Box>
                        <Box gridColumn={"1/3"}>
                            <Label fontStyle="h5">Task detail:</Label>
                            <Label>{hyphenIfNoValue(props.viewModel.getValue("description"))}</Label>
                        </Box>

                        <Box gridColumn={"1/3"}>
                            <Label fontStyle="h5">{props.viewModel.getValue("customerType") ? props.viewModel.getValue("customerType") : "Project"}:</Label>
                            <Label>{props.viewModel.getValue("customerType") ? props.viewModel.getValue("customerName") : props.viewModel.getValue("projectName")}</Label>
                        </Box>
                    </Box>

                    <Box gridTemplateColumns={"1fr 1fr 1fr"} display={"grid"} mb={20} pb={20} style={{ gap: "20px" }}>
                        <Box>
                            <Label fontStyle="h5">Date set:</Label>
                            <Label>{formatDate(props.viewModel.getValue("startDateTime"))}</Label>
                        </Box>
                        <Box>
                            <Label fontStyle="h5">Due date:</Label>
                            <Label>{props.viewModel.getValue("dueDateTime") ? formatDate(props.viewModel.getValue("dueDateTime")) : "-"}</Label>
                        </Box>
                        <Box>
                            <Label fontStyle="h5">Completed Date:</Label>
                            <Label>{props.viewModel.getValue("completedDateTime") ? formatDate(props.viewModel.getValue("completedDateTime")) : "-"}</Label>
                        </Box>
                        <Box>
                            <Label fontStyle="h5">Is this task urgent?</Label>
                            <Label style={{ alignItems: "center", display: "flex" }}>
                                <BooleanTickOrCrossCell value={props.viewModel.getValue("isUrgent")} alignLeft={true} />
                            </Label>
                        </Box>
                    </Box>
                    <Box gridTemplateColumns={"1fr"} display={"grid"} pb={20} style={{ gap: "20px" }}>
                        <Box>
                            <Box>
                                <Label fontStyle="h5" mb={"8px"}>
                                    Is this task completed?:
                                </Label>
                                <CheckBox
                                    displayName={"Is this task completed?:"}
                                    command={props.viewModel.updateIsCompleteCommand}
                                    value={() => props.viewModel.getValue("isComplete")}
                                    paletteColor={"secondary"}
                                />
                            </Box>
                        </Box>
                    </Box>
                </Box>
            </ModalGenericWithHeader>
        </>
    );
});
