import { ModelBase } from "@shoothill/core";
import { makeObservable, action, computed, observable } from "mobx";
import { Validator } from "Application/Validation";

export class PreviewUploadedDocumentItemModel extends ModelBase<PreviewUploadedDocumentItemModel> {
    //public id: string = "";
    // public id: string = "";
    documentCategoryId: number = 0;
    filename: string = "";
    // uploadedBy: Guid = "";
    // dateCreated: Date = new Date();
    // isDeleted: number = 0;
    // deletedBy: Guid = "";
    blobName: string = "";
    file: File | undefined = undefined;
    fileBase64: string = "";
    fileType: string = "";

    constructor() {
        super();
        makeObservable(this, {
            //id: observable
            filename: observable,
            blobName: observable,
            fileType: observable,
            documentCategoryId: observable,

            clear: action,
        });
    }

    public clear = () => {
        //this.id = "";
    };
}

export class PreviewUploadedDocumentItemModelValidator extends Validator<PreviewUploadedDocumentItemModel> {
    constructor() {
        super();
        //this.ruleFor("id").notEmpty();
    }
}
