import { IObservableArray, action, computed, makeObservable, observable, runInAction } from "mobx";
import { KeyValuePair, ViewModelBase } from "@shoothill/core";
import { APIClient, ICommand, RelayCommand } from "Application";
import { ErrorStore } from "Stores/Domain/ErrorStore";
import { container } from "tsyringe";
import { NewEditPropertyModel, NewEditPropertyModelValidator } from "./NewEditPropertyModel";
import { AddressGenericViewModel } from "Components/AddressGeneric/AddressGenericViewModel";
import { GetOccupierListAndRelatedEndpoint } from "../EndPoints/GetOccupierListAndRelatedEndpoint";
import { PropertyUpsertEndpoint } from "../EndPoints/PropertyUpsert";
import { GetPropertyByIdWithRelatedEndpoint } from "../EndPoints/GetPropertyByIdWithRelated";
import { ModalType } from "Application/Models/Domain/AddEdditModalType";
import { DeleteOccupierByIdEndpoint } from "../EndPoints/DeleteOccupierById";
import { AddPropertyOccupierEndpoint } from "../EndPoints/AddPropertyOccupier";
import { RoofSubstrateHelper } from "Models/Property/RoofSubstrateEnum";
import { OccupierListItem } from "./OccupierListItem";

export class NewEditPropertyViewModel extends ViewModelBase<NewEditPropertyModel> {
    public apiClient = new APIClient();
    errorStore = container.resolve(ErrorStore);
    public isProcessing: boolean = false;
    public allOccupiers: IObservableArray<OccupierListItem> = observable([]);

    public addressViewModel: AddressGenericViewModel = new AddressGenericViewModel();

    // Occupier info modal

    public occupierModalOpen: boolean = false;
    public selectedOccupierIdForInfo: string = "";

    public navToPropertyView: (id: Guid) => void;
    private addOrEdit: ModalType = ModalType.Add;

    constructor(navToPropertyView: (id: Guid) => void, addOrEdit: ModalType) {
        super(new NewEditPropertyModel());
        this.navToPropertyView = navToPropertyView;
        this.setValidator(new NewEditPropertyModelValidator());
        makeObservable(this, {
            isProcessing: observable,
            addressViewModel: observable,
            occupierModalOpen: observable,
            selectedOccupierIdForInfo: observable,

            addOccupierAsync: action,
            getOccupierListAndRelatedAsync: action,

            getOccupiersWithoutSelected: computed,
        });
        this.addOrEdit = addOrEdit;
    }

    //region properties

    public get isModalAddType(): boolean {
        return this.addOrEdit === ModalType.Add;
    }

    public get isModalEditType(): boolean {
        return this.addOrEdit === ModalType.Edit;
    }

    //endregion properties

    public clear = () => {
        this.model.clear();
    };

    public chooseOccupierCommand: ICommand = new RelayCommand((id: string) => {
        if (this.isModalAddType) {
            this.model.occupiers.push(id);
        } else if (this.isModalEditType) {
            if (this.model.id && id) {
                this.addOccupierAsync(id, this.model.id);
            }
        }
    });

    public showOccupierInfoModalCommand = new RelayCommand((id: string): void => {
        this.selectedOccupierIdForInfo = id;
        this.occupierModalOpen = true;
    });

    public showOccupierInfoModal = (id: string): void => {
        this.selectedOccupierIdForInfo = id;
        this.occupierModalOpen = true;
    };

    public closeOccupierInfoModal: ICommand = new RelayCommand(() => {
        this.occupierModalOpen = false;
    });

    public async getOccupierListAndRelatedAsync(): Promise<void> {
        const endpoint = new GetOccupierListAndRelatedEndpoint();
        this.isProcessing = true;
        await this.apiClient.sendAsync(endpoint);

        if (this.apiClient.IsRequestSuccessful) {
            runInAction(() => {
                this.isProcessing = false;
                let response = this.apiClient.Response();
                this.allOccupiers.replace(response.occupiers);
            });
        } else {
            runInAction(() => {
                this.isProcessing = false;
            });
            this.errorStore.setHeaderText("Property");
            this.errorStore.setButtonText("Close");
            this.errorStore.setErrorMessageOne("Failed to load occupier list.  Please try again later.");
            this.errorStore.setErrorMessageTwo(this.apiClient.ValidationMessage);
            this.errorStore.setErrorModalOpen(true);
        }
    }

    public getSelectedOccupier(id: Guid) {
        const item = this.allOccupiers.find((item) => item.id === id);
        if (item) {
            return { id: item.id, displayName: item.displayName };
        }

        return { id: "", displayName: "" };
    }

    public removeSelectedOccupierCommand = new RelayCommand((occupierToRemove: Guid, type: ModalType): void => {
        if (this.isModalAddType === true) {
            let retVal = this.model.occupiers.filter((occupier) => {
                return occupier !== occupierToRemove;
            });
            this.model.occupiers.replace(retVal);
        } else if (this.isModalEditType === true) {
            this.deleteOccupierByIdAsync(occupierToRemove, this.model.id);
        }
    });

    public setIsSurveyed: ICommand = new RelayCommand((value: boolean) => {
        this.setValue("isSurveyedByTsm", value);
    });

    public setSiteInspection: ICommand = new RelayCommand((value: boolean) => {
        this.setValue("siteInspection", value);
    });

    public setRoofSubstrate: ICommand = new RelayCommand((value: KeyValuePair) => {
        this.setValue("roofSubstrate", value.key);
    });

    public setEndOfLeaseMonth: ICommand = new RelayCommand((value: KeyValuePair) => {
        this.setValue("endOfLeaseMonth", value.key);
    });

    public setEndOfLeaseYear: ICommand = new RelayCommand((value: KeyValuePair) => {
        this.setValue("endOfLeaseYear", value.key);
    });

    public get getRoofSubstrates() {
        return RoofSubstrateHelper.getRoofSubstrateOptions();
    }

    public get getMonths() {
        let retVal: KeyValuePair[] = [];

        retVal = [
            { key: 1, text: "January" },
            { key: 2, text: "February" },
            { key: 3, text: "March" },
            { key: 4, text: "April" },
            { key: 5, text: "May" },
            { key: 6, text: "June" },
            { key: 7, text: "July" },
            { key: 8, text: "August" },
            { key: 9, text: "September" },
            { key: 10, text: "October" },
            { key: 11, text: "November" },
            { key: 12, text: "December" },
        ];

        return retVal;
    }

    public get getYears() {
        let retVal: KeyValuePair[] = [];

        let currentYear = new Date().getFullYear();
        let howManyYears = 25;

        for (let i = 0; i < howManyYears; i++) {
            retVal.push({ key: currentYear + i, text: String(currentYear + i) });
        }
        return retVal;
    }

    public submitNewProperty: ICommand = new RelayCommand(() => {
        // Makes validator check address fields - turned off when searching by postcode,
        // so that validator only checks if postcode is filled in
        this.addressViewModel.turnOnAddressFieldsValidation();

        if (this.addressViewModel.canShowMapPin === false) {
            let promise = this.addressViewModel.populateLatLongFromPostcode();

            promise.then(() => {
                if (this.addressViewModel.isModelValid() && this.isModelValid()) {
                    this.upsertAsync();
                }
            });
        } else {
            if (this.addressViewModel.isModelValid() && this.isModelValid()) {
                this.upsertAsync();
            }
        }
    });

    public async upsertAsync(): Promise<void> {
        if (this.isProcessing === false) {
            const endpoint = new PropertyUpsertEndpoint(this);
            this.isProcessing = true;
            await this.apiClient.sendAsync(endpoint);

            if (this.apiClient.IsRequestSuccessful) {
                runInAction(() => {
                    this.isProcessing = false;
                    let response = this.apiClient.Response();
                    this.navToPropertyView(response.id);
                });
            } else {
                runInAction(() => {
                    this.isProcessing = false;
                });
                this.errorStore.setHeaderText("Property");
                this.errorStore.setButtonText("Close");
                this.errorStore.setErrorMessageOne("Failed to save property.  Please try again later.");
                this.errorStore.setErrorMessageTwo(this.apiClient.ValidationMessage);
                this.errorStore.setErrorModalOpen(true);
            }
        }
    }
    public async getPropertyAsync(id: Guid): Promise<void> {
        const endpoint = new GetPropertyByIdWithRelatedEndpoint(id);
        this.isProcessing = true;
        await this.apiClient.sendAsync(endpoint);

        if (this.apiClient.IsRequestSuccessful) {
            runInAction(() => {
                this.isProcessing = false;
                let response = this.apiClient.Response();
                this.model.fromResponse(response.property);
                this.allOccupiers.replace(response.occupiers);
                this.model.occupiers = response.occupiersLinkedToProperty;
                this.addressViewModel.model.addressLineOne = response.property.addressLine1;
                this.addressViewModel.model.addressLineTwo = response.property.addressLine2;
                this.addressViewModel.model.townCity = response.property.townCity;
                this.addressViewModel.model.county = response.property.county;
                this.addressViewModel.model.country = response.property.country;
                this.addressViewModel.model.countryId = response.property.countryId;
                this.addressViewModel.model.postcode = response.property.postcode;
                this.addressViewModel.model.latitude = response.property.locationLatitude;
                this.addressViewModel.model.longitude = response.property.locationLongitude;
            });
        } else {
            runInAction(() => {
                this.isProcessing = false;
            });
            this.errorStore.setHeaderText("Property");
            this.errorStore.setButtonText("Close");
            this.errorStore.setErrorMessageOne("Failed to load property.  Please try again later.");
            this.errorStore.setErrorMessageTwo(this.apiClient.ValidationMessage);
            this.errorStore.setErrorModalOpen(true);
        }
    }

    public async deleteOccupierByIdAsync(occupierId: Guid, propertyId: Guid): Promise<void> {
        const endpoint = new DeleteOccupierByIdEndpoint(occupierId, propertyId);
        this.isProcessing = true;
        await this.apiClient.sendAsync(endpoint);

        if (this.apiClient.IsRequestSuccessful) {
            runInAction(() => {
                this.isProcessing = false;
                let response = this.apiClient.Response();
                this.model.occupiers = response;

                // Hack to get the list to regenerate
                let temp = this.allOccupiers.slice();
                this.allOccupiers.clear();
                this.allOccupiers.replace(temp);
            });
        } else {
            runInAction(() => {
                this.isProcessing = false;
            });

            this.errorStore.setHeaderText("Occupier");
            this.errorStore.setButtonText("Close");
            this.errorStore.setErrorMessageOne("Failed to delete occupier.  Please try again later.");
            this.errorStore.setErrorMessageTwo(this.apiClient.ValidationMessage);
            this.errorStore.setErrorModalOpen(true);
        }
    }

    public async addOccupierAsync(occupierId: Guid, propertyId: Guid): Promise<void> {
        let allowUpsert: boolean = this.model.allowAddingOcupier(occupierId, propertyId);

        if (allowUpsert == true) {
            const endpoint = new AddPropertyOccupierEndpoint(occupierId, propertyId);
            this.isProcessing = true;
            await this.apiClient.sendAsync(endpoint);

            if (this.apiClient.IsRequestSuccessful) {
                runInAction(() => {
                    let response = this.apiClient.Response();
                    this.model.occupiers = response;

                    // Hack to get the list to regenerate
                    let temp = this.allOccupiers.slice();
                    this.allOccupiers.clear();
                    this.allOccupiers.replace(temp);

                    this.isProcessing = false;
                });
            } else {
                runInAction(() => {
                    this.isProcessing = false;
                });
                this.errorStore.setHeaderText("Occupier");
                this.errorStore.setButtonText("Close");
                this.errorStore.setErrorMessageOne("Failed to add occupier.  Please try again later.");
                this.errorStore.setErrorMessageTwo(this.apiClient.ValidationMessage);
                this.errorStore.setErrorModalOpen(true);
            }
        }
    }

    public get getOccupiersWithoutSelected() {
        let retVal: OccupierListItem[] = [];

        retVal = this.allOccupiers.filter((item) => {
            return !this.model.occupiers.includes(item.id);
        });

        return retVal;
    }
}
