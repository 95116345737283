import { ModelBase } from "@shoothill/core";
import { IObservableArray, makeObservable, observable } from "mobx";
import { Validator } from "Application/Validation";
import { TasksListItemModel } from "./TasksListItemModel";

export class TasksListModel extends ModelBase<TasksListModel> {
    public id: string | null = null;
    public sourceId: string | null = null;
    public taskTypeId: string = "";
    public taskTypeName: string = "";
    public subTaskTypeId: string = "";
    public subTaskTypeName: string = "";
    public assignedUserId: string = "";
    public description: string = "";
    public startDateTime: Date = new Date();
    public dueDateTime: Date = new Date();
    public isUrgent: boolean = false;
    public isComplete: boolean = false;
    public enumId: number = 0;
    public tasks: IObservableArray<TasksListItemModel> = observable([]);
    public assignedUserName: string = "";
    public completedDateTime: string | null = null;
    public projectName: string | null = "";
    public customerName: string | null = null;
    public customerType: string | null = null;

    constructor() {
        super();
        makeObservable(this, {
            sourceId: observable,
            taskTypeName: observable,
            taskTypeId: observable,
            subTaskTypeId: observable,
            subTaskTypeName: observable,
            assignedUserId: observable,
            description: observable,
            startDateTime: observable,
            dueDateTime: observable,
            isUrgent: observable,
            isComplete: observable,
            enumId: observable,
            assignedUserName: observable,
            completedDateTime: observable,
            projectName: observable,
            customerName: observable,
            customerType: observable,
        });
    }
}

export class TasksListModelValidator extends Validator<TasksListModel> {
    constructor() {
        super();
    }
}
