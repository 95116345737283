import { IObservableArray, action, computed, makeObservable, observable, runInAction } from "mobx";
import { KeyValuePair, ViewModelBase } from "@shoothill/core";
import { APIClient, ICommand, RelayCommand } from "Application";
import { ErrorStore } from "Stores/Domain/ErrorStore";
import { container } from "tsyringe";
import { ContactListItem, CustomerContactsModel, CustomerContactsModelValidator } from "./CustomerContactsModel";
import { UpsertContactEndpoint } from "../../Endpoints/UpsertContact";
import { GetContactsByCustomerIdEndpoint } from "../../Endpoints/GetContactsByCustomerId";
import { GetContactByIdEndpoint } from "../../Endpoints/GetContactById";
import { DeleteContactByIdEndpoint } from "../../Endpoints/DeleteContactById";
import { GetContactDetailsModalByIdEndpoint } from "../../Endpoints/GetContactDetailsModalById";
import { File64, getFileTo64 } from "Utils/File";
import { UploadContactDocumentEndpoint } from "../../Endpoints/UploadContactDocument";
import { ContactDocumentItemViewModel } from "./ContactDocumentItemViewModel";
import { DeleteContactDocumentByIdEndpoint } from "../../Endpoints/DeleteContactDocumentById";
import { ModalType } from "Application/Models/Domain/AddEdditModalType";
import { SubmitType } from "Application/Models/Domain/Forms";
import { IDocumentType } from "Models/Documents/DocumentType";
import { IDocumentUploaded } from "Models/Documents/IDocumentUploaded";
import { ContactStatusDropdownItem } from "Models/Contacts/ContactStatusDropdownItem";

import { CoreStoreInstance } from "@shoothill/core";
export class CustomerContactsViewModel extends ViewModelBase<CustomerContactsModel> {
    public apiClient = new APIClient();
    errorStore = container.resolve(ErrorStore);

    public isProcessing: boolean = false;
    public modalType: ModalType = ModalType.Add;
    public areYouSureModelOpen: boolean = false;
    public contactToDeleteId: Guid = "";
    public contactId: Guid = "";
    public contactIdToView: string = "";

    // Attachments
    public documentsToUpload: ContactDocumentItemViewModel[] = [];
    public validateDocumentsToUpload: boolean = false;
    public documentTypes: IObservableArray<IDocumentType> = observable([]);

    public uploadedDocuments: IObservableArray<IDocumentUploaded> = observable([]);

    // Modals
    public showAddContactModal: boolean = false;
    public showViewContactModal: boolean = false;

    public statusTypes: IObservableArray<ContactStatusDropdownItem> = observable([]);

    public showDocumentsSection: boolean = false;

    public contacts: IObservableArray<ContactListItem> = observable([]);

    private contactCountCallBack: (newCount: number) => void;

    constructor(contactCountCallBack: (newCount: number) => void, customerId?: any) {
        super(new CustomerContactsModel());
        this.setValue("customerId", customerId);
        this.contactCountCallBack = contactCountCallBack;
        this.setValidator(new CustomerContactsModelValidator());
        makeObservable(this, {
            isProcessing: observable,
            modalType: observable,
            showAddContactModal: observable,
            showDocumentsSection: observable,
            areYouSureModelOpen: observable,
            contactToDeleteId: observable,
            showViewContactModal: observable,
            documentsToUpload: observable,
            contactId: observable,
            contactIdToView: observable,

            addDocumentFile: action,
            addFiles: action,
            closeAreYouSureModal: action,
            confirmDeleteContact: action,
            deleteContactCheck: action,
            deleteContactById: action,
            deleteDocument: action,
            getContactById: action,
            getContactDetailsModalById: action,
            openEditContactModal: action,
            openViewContactModal: action,
            setCustomerIdFromParent: action,
            SortContacts: action,
            upsertContact: action,
            updateContactsForEditing: action,

            getcontacts: computed,
            getDocTypesForDropdown: computed,
            getStatusTypesForDropdown: computed,
        });
    }

    public clear = () => {
        this.isProcessing = false;
        this.modalType = ModalType.Add;
        this.areYouSureModelOpen = false;
        this.contactToDeleteId = "";
        this.contactId = "";

        // Attachments
        this.documentsToUpload = [];
        this.validateDocumentsToUpload = false;
        this.documentTypes.clear();

        this.uploadedDocuments.clear();

        // Modals
        this.showAddContactModal = false;
        this.showViewContactModal = false;

        this.statusTypes.clear();
        this.showDocumentsSection = false;
        this.contacts.clear();
        this.model.clear();
    };

    // Modals

    public openEditContactModal = (e: any, id: string) => {
        this.getContactById(id);
        this.modalType = ModalType.Edit;
        this.showDocumentsSection = true;
        this.showAddContactModal = true;
    };

    public openAddNewContactModal: ICommand = new RelayCommand(() => {
        this.modalType = ModalType.Add;
        this.showDocumentsSection = false;
        this.showAddContactModal = true;
    });

    public closeAddNewContactModal: ICommand = new RelayCommand(() => {
        this.model.clear();
        this.documentsToUpload = [];
        this.showAddContactModal = false;
        if (!this.isModelValid()) {
            CoreStoreInstance.HideInfoBar();
            this.setError("firstName", "");
            this.setError("lastName", "");
            this.setError("email", "");
            this.setError("phone", "");
            this.setError("phone2", "");
        }
    });

    public openViewContactModal = (Id: string) => {
        this.contactIdToView = Id;
        this.showViewContactModal = true;
    };

    public closeViewContactModal: ICommand = new RelayCommand(() => {
        this.model.clear();
        this.showViewContactModal = false;
    });
    //

    // For Editing

    public updateContactsForEditing = (contacts: ContactListItem[]) => {
        this.contacts.replace(contacts);
    };

    public get getStatusTypesForDropdown() {
        let types: KeyValuePair[] = [];

        this.statusTypes.map((type) => {
            types.push({ key: type.id, text: type.statusType });
        });
        return types;
    }

    public get GetPlygeneTrained(): string {
        if (this.getValue("plygeneTrained") === true) {
            return "true";
        } else {
            return "false";
        }
    }
    public get GetCoatingsTrained(): string {
        if (this.getValue("coatingsTrained") === true) {
            return "true";
        } else {
            return "false";
        }
    }

    public setFirstName: ICommand = new RelayCommand((value: string) => {
        this.setValue("firstName", value);
    });

    public setLastName: ICommand = new RelayCommand((value: string) => {
        this.setValue("lastName", value);
    });

    public setPhoneNumber: ICommand = new RelayCommand((value: string) => {
        this.setValue("phone", value);
    });

    public setEmail: ICommand = new RelayCommand((value: string) => {
        this.setValue("email", value);
    });

    public setJobTitle: ICommand = new RelayCommand((value: string) => {
        this.setValue("jobTitle", value);
    });

    public setPrimaryContact: ICommand = new RelayCommand((value: string) => {
        this.setValue("primaryContact", value);
    });

    public setMarketingConsent: ICommand = new RelayCommand((value: string) => {
        this.setValue("marketingConsent", value);
    });

    public setPlygeneTrained: ICommand = new RelayCommand((value: string) => {
        let trueOrFalse = false;
        if (value === "true") {
            trueOrFalse = true;
        } else {
            this.setValue("datePlygeneTrained", null);
        }
        this.setValue("plygeneTrained", trueOrFalse);
    });

    public setPlygeneDateTrained: ICommand = new RelayCommand((date: Date) => {
        this.setValue("datePlygeneTrained", date);
    });

    public setCoatingsTrained: ICommand = new RelayCommand((value: string) => {
        let trueOrFalse = false;
        if (value === "true") {
            trueOrFalse = true;
        } else {
            this.setValue("dateCoatingsTrained", null);
        }
        this.setValue("coatingsTrained", trueOrFalse);
    });

    public setCoatingsDateTrained: ICommand = new RelayCommand((date: Date) => {
        this.setValue("dateCoatingsTrained", date);
    });

    public setContactStatus: ICommand = new RelayCommand((value: any) => {
        this.setValue("status", value.key);
    });

    public setNote: ICommand = new RelayCommand((value: string) => {
        this.setValue("note", value);
    });

    public validateAndSubmitContact: ICommand = new RelayCommand((submitType: SubmitType) => {
        this.upsertContact(submitType);
    });

    public async upsertContact(submitType: SubmitType): Promise<void> {
        // this.spinnerText = "Saving...";
        // this.isProcessing = true;
        if (this.isModelValid()) {
            const endpoint = new UpsertContactEndpoint(this.model);
            await this.apiClient.sendAsync(endpoint);

            if (this.apiClient.IsRequestSuccessful) {
                runInAction(() => {
                    CoreStoreInstance.HideInfoBar();
                    let response = this.apiClient.Response();

                    this.contacts.replace(response);
                    this.contactCountCallBack(this.contacts.length);

                    if (submitType === SubmitType.SaveAndExit) {
                        this.showAddContactModal = false;
                        this.model.clear();
                        this.uploadedDocuments.clear();
                        this.documentsToUpload = [];
                    } else if (submitType === SubmitType.SaveAndContinue) {
                        this.setValue("id", response[0].id);
                        this.showDocumentsSection = true;
                    }
                    // this.isProcessing = false;
                    // this.spinnerText = "Loading...";
                });
            } // else show error message
            else {
                runInAction(() => {
                    // this.isProcessing = false;
                });
                this.errorStore.setHeaderText("Add contact");
                this.errorStore.setButtonText("Close");
                this.errorStore.setErrorMessageOne("Failed to add contact.  Please try again later.");
                this.errorStore.setErrorMessageTwo(this.apiClient.ValidationMessage);
                this.errorStore.setErrorModalOpen(true);
            }
        }
    }

    public async SortContacts(): Promise<void> {
        // this.spinnerText = "Saving...";
        // this.isProcessing = true;

        const endpoint = new GetContactsByCustomerIdEndpoint(this.model);
        await this.apiClient.sendAsync(endpoint);

        if (this.apiClient.IsRequestSuccessful) {
            runInAction(() => {
                let response = this.apiClient.Response();
                this.contacts.replace(response);
                // this.isProcessing = false;
            });
        } // else show error message
        else {
            runInAction(() => {
                // this.isProcessing = false;
            });
            this.errorStore.setHeaderText("Sort Contacts");
            this.errorStore.setButtonText("Close");
            this.errorStore.setErrorMessageOne("Failed to sort contacts.  Please try again later.");
            this.errorStore.setErrorMessageTwo(this.apiClient.ValidationMessage);
            this.errorStore.setErrorModalOpen(true);
        }
    }

    public async getContactById(Id: Guid): Promise<void> {
        // this.spinnerText = "Saving...";
        this.isProcessing = true;
        if (Id !== null) {
            const endpoint = new GetContactByIdEndpoint(Id);
            await this.apiClient.sendAsync(endpoint);

            if (this.apiClient.IsRequestSuccessful) {
                runInAction(() => {
                    let response = this.apiClient.Response();
                    this.model.fromResponse(response);
                    this.uploadedDocuments.replace(response.documents);

                    this.isProcessing = false;
                    // this.spinnerText = "Loading...";
                });
            } // else show error message
            else {
                runInAction(() => {
                    this.isProcessing = false;
                });
                this.errorStore.setHeaderText("Contact");
                this.errorStore.setButtonText("Close");
                this.errorStore.setErrorMessageOne("Failed to load contact.  Please try again later.");
                this.errorStore.setErrorMessageTwo(this.apiClient.ValidationMessage);
                this.errorStore.setErrorModalOpen(true);
            }
        }
    }

    public async getContactDetailsModalById(Id: Guid): Promise<void> {
        // this.spinnerText = "Saving...";
        this.isProcessing = true;
        if (Id !== null) {
            const endpoint = new GetContactDetailsModalByIdEndpoint(Id);
            await this.apiClient.sendAsync(endpoint);

            if (this.apiClient.IsRequestSuccessful) {
                runInAction(() => {
                    let response = this.apiClient.Response();
                    this.model.fromResponse(response);
                    this.uploadedDocuments.replace(response.documents);

                    this.isProcessing = false;
                    // this.spinnerText = "Loading...";
                });
            } // else show error message
            else {
                runInAction(() => {
                    this.isProcessing = false;
                });
                this.errorStore.setHeaderText("Contact");
                this.errorStore.setButtonText("Close");
                this.errorStore.setErrorMessageOne("Failed to load contact.  Please try again later.");
                this.errorStore.setErrorMessageTwo(this.apiClient.ValidationMessage);
                this.errorStore.setErrorModalOpen(true);
            }
        }
    }

    public deleteContactCheck = (e: any, id: string): void => {
        this.contactToDeleteId = id;
        this.areYouSureModelOpen = true;
    };

    public closeAreYouSureModal = () => {
        this.areYouSureModelOpen = false;
    };

    public confirmDeleteContact = () => {
        this.deleteContactById(this.contactToDeleteId, this.getValue("customerId"));
        this.areYouSureModelOpen = false;
    };

    public async deleteContactById(Id: Guid, CustomerId: Guid): Promise<void> {
        // this.spinnerText = "Saving...";
        this.isProcessing = true;

        const endpoint = new DeleteContactByIdEndpoint(Id, CustomerId);
        await this.apiClient.sendAsync(endpoint);

        if (this.apiClient.IsRequestSuccessful) {
            runInAction(() => {
                let response = this.apiClient.Response();
                this.contacts.replace(response);
                this.contactCountCallBack(this.contacts.length);
                this.isProcessing = false;
            });
        } // else show error message
        else {
            runInAction(() => {
                this.isProcessing = false;
            });
            this.errorStore.setHeaderText("Delete Contact");
            this.errorStore.setButtonText("Close");
            this.errorStore.setErrorMessageOne("Failed to delete contact.  Please try again later.");
            this.errorStore.setErrorMessageTwo(this.apiClient.ValidationMessage);
            this.errorStore.setErrorModalOpen(true);
        }
    }

    public get getcontacts(): ContactListItem[] {
        return this.contacts.slice();
    }

    // Attachments * * * * * * * * * * * * * * * * * * * * * //

    // Files - Add files to list in modal, ready for uploading
    public addFiles = (acceptedFiles: File[]) => {
        acceptedFiles.map((file: File) => {
            const allowedFileTypes = [".doc", ".docx", ".pdf", ".xls", ".xlsx", ".csv", ".jpg", ".png"];
            const fileType = file.name.substring(file.name.lastIndexOf(".")).toLowerCase();
            if (allowedFileTypes.includes(fileType)) {
                const viewModel = new ContactDocumentItemViewModel();
                viewModel.setValue("filename", file.name);
                viewModel.setValue("documentCategoryId", 0);
                viewModel.setValue("file", file);

                this.documentsToUpload.push(viewModel);
                this.validateDocumentsToUpload = false;
            } else {
                this.errorStore.setHeaderText("Invalid Files");
                this.errorStore.setButtonText("Close");
                this.errorStore.setErrorMessageOne(`Invalid file type: ${fileType}. Only .doc, .pdf, .xls, .csv, .jpg, .png are allowed.`);
                this.errorStore.setErrorModalOpen(true);
            }
        });
    };

    // Files - remove file from list in modal
    public removeFileFromModalList: ICommand = new RelayCommand((index: number) => {
        const copy = [...this.documentsToUpload];
        copy.splice(index, 1);
        this.documentsToUpload = copy;
    });

    // Files - validate, then submit to server for blob storage and database
    public submitDocuments: ICommand = new RelayCommand(() => {
        let areViewModelsValid: boolean = true;

        for (let i = 0; i < this.documentsToUpload.length; i++) {
            if (this.documentsToUpload[i].isModelValid() === false) {
                areViewModelsValid = false;
            }
        }

        if (areViewModelsValid === true) {
            this.addDocumentFile();
        }
    });

    public get getDocTypesForDropdown() {
        let types: KeyValuePair[] = [];

        this.documentTypes.map((type) => {
            types.push({ key: type.id, text: type.categoryName });
        });

        return types;
    }

    public addDocumentFile = async () => {
        this.validateDocumentsToUpload = true;
        // TODO check validation before proceeding

        this.isProcessing = true;
        this.apiClient.setAPITimeout(240000);

        if (this.documentsToUpload.length > 0) {
            for (let i = 0; i < this.documentsToUpload.length; i++) {
                // this.spinnerText = "Uploading document " + (i + 1) + " of " + this.documentsToUpload.length;
                let uploadedFile: File64 = await getFileTo64(this.documentsToUpload[i].getValue("file"));
                this.documentsToUpload[i].setValue("fileBase64", uploadedFile.base64StringFile);
                this.documentsToUpload[i].setValue("fileType", uploadedFile.fileType);
                this.documentsToUpload[i].setValue("contactId", this.getValue("id"));

                let endpoint: UploadContactDocumentEndpoint = new UploadContactDocumentEndpoint(this.documentsToUpload[i].model);

                let _ = await this.apiClient.sendAsync(endpoint);
                if (this.apiClient.IsRequestSuccessful) {
                    runInAction(() => {
                        this.isProcessing = false;
                        let response: IDocumentUploaded[] = this.apiClient.Response();
                        this.uploadedDocuments.replace(response);
                        // this.showAddDocumentModal = false;
                        this.documentsToUpload = [];
                    });
                } else {
                    runInAction(() => {
                        this.isProcessing = false;
                    });
                    this.errorStore.setHeaderText("Documents");
                    this.errorStore.setButtonText("Close");
                    this.errorStore.setErrorMessageOne("Failed to upload the document.  Please correct the file and try again");
                    this.errorStore.setErrorMessageTwo(this.apiClient.ValidationMessage);
                    this.errorStore.setErrorModalOpen(true);
                }
            }
        }
    };

    public deleteDocumentByIdCommand: ICommand = new RelayCommand((Id: Guid) => {
        this.deleteDocument(Id, this.getValue("id"));
    });

    public async deleteDocument(Id: Guid, ContactId: Guid): Promise<void> {
        this.isProcessing = true;
        const endpoint = new DeleteContactDocumentByIdEndpoint(Id, ContactId);
        await this.apiClient.sendAsync(endpoint);

        if (this.apiClient.IsRequestSuccessful) {
            runInAction(() => {
                const response: IDocumentUploaded[] = this.apiClient.Response();

                this.uploadedDocuments.replace(response);

                this.isProcessing = false;
            });
        } // else show error message
        else {
            runInAction(() => {
                this.isProcessing = false;
            });
            this.errorStore.setHeaderText("Documents");
            this.errorStore.setButtonText("Close");
            this.errorStore.setErrorMessageOne("Failed to delete document.  Please try again later.");
            this.errorStore.setErrorMessageTwo(this.apiClient.ValidationMessage);
            this.errorStore.setErrorModalOpen(true);
        }
    }

    public setCustomerIdFromParent = (id: Guid) => {
        this.model.customerId = id;
    };
}
