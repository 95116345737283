import React, { useEffect, useState } from "react";
import { observer } from "mobx-react-lite";
import { PaginationDirection, SharmansTable, SortOrderDirection } from "Components/Primitives/DataTable/SharmansTable";
import { Box, ICommand, RelayCommand } from "Application";
import { createColumnHelper } from "@tanstack/react-table";
import { MultiEditSelect } from "Components/Primitives/Selects/MultiEditSelect";
import { KeyValuePair, formatCurrency } from "@shoothill/core";
import { LinkedMasterViewModel } from "./LinkedMasterViewModel";
import { H5 } from "Components/Primitives/TextElements/TextElements";
import moment from "moment";
import { ProjectStatusBox } from "Views/Work/Common/ProjectsList.styles";
import { FilteringBottomSectionBox } from "Components/FilteringBottomSectionBox";

import { useNavigate } from "react-router";
import { AppUrlIndex, AppUrls } from "AppUrls";
interface ILinkedMaster {
    id?: string;
    masterProjectCountCallBack: (newCount: number) => void;
}

export const LinkedMasterView: React.FC<ILinkedMaster> = observer((props) => {
    const [viewModel] = useState(() => new LinkedMasterViewModel(props.id!, props.masterProjectCountCallBack));
    let debounceTimer: NodeJS.Timeout | null = null;
    const [isFiltering, setIsFiltering] = useState(false);
    let navigate = useNavigate();
    useEffect(() => {
        if (props.id) {
            viewModel.getLinkedMaster();
        }
        return () => {
            viewModel.clear();
        };
    }, []);

    useEffect(() => {
        if (isFiltering) {
            debounceFilter();
        }
    }, [isFiltering, viewModel]);

    const columnHelper = createColumnHelper<any>();

    const columns = [
        columnHelper.accessor("number", {
            size: 60,
            header: () => "Prj no.",
            cell: (info) => info.renderValue(),
        }),

        columnHelper.accessor("projectName", {
            size: 250,
            header: () => "Project name",
            cell: (info) => info.renderValue(),
        }),

        columnHelper.accessor("bdm", {
            size: 250,
            header: () => "BDM",
            cell: (info) => info.renderValue(),
        }),

        columnHelper.accessor("createdDate", {
            header: () => "Created date",
            cell: (info) => {
                return moment.utc(info.getValue()).format("DD/MM/yyyy");
            },
        }),

        columnHelper.accessor("netValue", {
            header: () => "Net value",
            cell: (info) => {
                return formatCurrency(info.renderValue());
            },
        }),

        columnHelper.accessor("wonValue", {
            header: () => "Won value",
            cell: (info) => {
                return formatCurrency(info.renderValue());
            },
        }),

        columnHelper.accessor("openValue", {
            header: () => "Open value",
            cell: (info) => {
                return formatCurrency(info.renderValue());
            },
        }),

        columnHelper.accessor("lostValue", {
            header: () => "Lost value",
            cell: (info) => {
                return formatCurrency(info.renderValue());
            },
        }),

        columnHelper.accessor("status", {
            header: () => "Status",
            cell: (info) => {
                const status = viewModel.statusDropdownItems.find((a) => a.statusId === info.getValue());
                if (status !== undefined) {
                    return (
                        <ProjectStatusBox className={status.className}>
                            <div>{status.displayName}</div>
                        </ProjectStatusBox>
                    );
                } else {
                    return "";
                }
            },
        }),
    ];

    const updateSorting = (columnName: string, orderBy: SortOrderDirection) => {
        viewModel.columnName = columnName;
        viewModel.orderBy = orderBy;
        viewModel.getLinkedMaster();
    };

    const onChangePage = (change: PaginationDirection) => {
        switch (change) {
            case PaginationDirection.NEXT:
                viewModel.pageNumber < viewModel.pageCount ? viewModel.pageNumber++ : null;
                viewModel.getLinkedMaster();
                break;
            case PaginationDirection.BACK:
                viewModel.pageNumber > 1 ? viewModel.pageNumber-- : null;
                viewModel.getLinkedMaster();

                break;
            case PaginationDirection.START:
                viewModel.pageNumber = 1;
                viewModel.getLinkedMaster();

                break;
            case PaginationDirection.END:
                viewModel.pageNumber = viewModel.pageCount;
                viewModel.getLinkedMaster();

                break;
            default:
        }
    };

    const debounceFilter = () => {
        if (debounceTimer) {
            clearTimeout(debounceTimer);
        }
        debounceTimer = setTimeout(() => {
            viewModel.getLinkedMaster();
            setIsFiltering(false);
        }, 1200);
    };

    //Status filtering
    const updateStatusFiltering: ICommand = new RelayCommand((values: KeyValuePair[]) => {
        viewModel.updateStatusFiltering(
            values.map((item) => {
                return item.key;
            }),
        );
        debounceFilter();
    });

    const statusSelectAll = (): void => {
        viewModel.statusSelectAll();
        viewModel.getLinkedMaster();
    };

    const statusSelectNone = (): void => {
        viewModel.statusSelectNone();
        viewModel.getLinkedMaster();
    };

    const FilteringBottomSection = (
        <FilteringBottomSectionBox grid dc={"1fr 1fr 1fr 1fr 1fr"}>
            <MultiEditSelect
                command={updateStatusFiltering}
                displayName="Filter by status"
                options={viewModel.getStatuses}
                value={() => viewModel.getStatusFilter}
                showSelectAllNone={true}
                selectAll={statusSelectAll}
                selectNone={statusSelectNone}
                isDisabled={viewModel.isProcessing}
            />
        </FilteringBottomSectionBox>
    );

    const onChangeRowPerPage = (rowsPerPage: number) => {
        viewModel.pageSize = rowsPerPage;
        viewModel.pageNumber = 1;
        viewModel.getLinkedMaster();
    };

    function onRowClick(rowId: string | number): void {
        const path: string = AppUrls.Client.Work.Master.View[AppUrlIndex.Long].replace(":masterprojectid", rowId.toString());
        navigate(path);
    }

    return (
        <Box>
            <H5 pt="12px" pb="12px">
                <strong>Master projects linked to this customer</strong>
            </H5>
            <SharmansTable
                columns={columns}
                data={viewModel.getLinkedProjectListItem}
                filteringElementsBottomSection={FilteringBottomSection}
                tableHeaderColour="#D6E4F1"
                tableFilteringColour="#EEF4F9"
                onChangeRowPerPage={onChangeRowPerPage}
                onChangePage={onChangePage}
                onChangePageNumber={viewModel.changePageNumber}
                onSortChange={updateSorting}
                showPagination={true}
                totalRowCount={viewModel.totalCount}
                totalPageCount={viewModel.pageCount}
                pageNumber={viewModel.pageNumber}
                pageSize={viewModel.pageSize}
                clearFilteringCommand={viewModel.clearFiltering}
                onRowClick={onRowClick}
            />
        </Box>
    );
});
