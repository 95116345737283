import { Logger } from "index";
import { Endpoint, Http } from "Application/Helpers/BaseEndpoint";
import { NewEditPropertyViewModel } from "../NewEditProperty/NewEditPropertyViewModel";
import { RoofSubstrateEnum } from "Models/Property/RoofSubstrateEnum";
import { AppUrls } from "AppUrls";

class PropertyUpsertRequest {
    id: string | null = null;
    addressLine1: string = "";
    addressLine2: string = "";
    townCity: string = "";
    county: string = "";
    country: string = "";
    countryId: number = -1;
    postcode: string = "";
    locationLatitude: number | null = null;
    locationLongitude: number | null = null;
    isSurveyedByTsm: boolean | null = null;
    siteInspection: boolean | null = null;
    roofSubstrate: RoofSubstrateEnum | null = null;
    endOfLeaseMonth: number | null = null;
    endOfLeaseYear: number | null = null;
    occupiers: Guid[] = [];
}

class PropertyUpsertResponse {
    id: string | null = null;
    addressLine1: string = "";
    addressLine2: string = "";
    townCity: string = "";
    county: string = "";
    country: string = "";
    countryId: number = -1;
    postcode: string = "";
    locationLatitude: number | null = null;
    locationLongitude: number | null = null;
    isSurveyedByTsm: boolean | null = null;
    siteInspection: boolean | null = null;
    roofSubstrate: RoofSubstrateEnum | null = null;
    endOfLeaseMonth: number | null = null;
    endOfLeaseYear: number | null = null;
    occupiers: Guid[] = [];
}

export class PropertyUpsertEndpoint extends Endpoint<PropertyUpsertRequest, PropertyUpsertResponse> {
    public viewModel: NewEditPropertyViewModel;

    constructor(viewModel: NewEditPropertyViewModel) {
        super();
        this.viewModel = viewModel;
        this.verb(Http.Put);
        this.path(AppUrls.Server.Property.Upsert);
    }

    public async HandleRequestAsync(model: any): Promise<any> {
        let request = new PropertyUpsertRequest();
        request.id = this.viewModel.model.id;
        request.addressLine1 = this.viewModel.addressViewModel.model.addressLineOne;
        request.addressLine2 = this.viewModel.addressViewModel.model.addressLineTwo;
        request.townCity = this.viewModel.addressViewModel.model.townCity;
        request.county = this.viewModel.addressViewModel.model.county;
        request.country = this.viewModel.addressViewModel.model.country;
        request.countryId = this.viewModel.addressViewModel.model.countryId;
        request.postcode = this.viewModel.addressViewModel.model.postcode;
        request.locationLatitude = this.viewModel.addressViewModel.model.latitude;
        request.locationLongitude = this.viewModel.addressViewModel.model.longitude;
        request.isSurveyedByTsm = this.viewModel.model.isSurveyedByTsm;
        request.siteInspection = this.viewModel.model.siteInspection;
        request.roofSubstrate = this.viewModel.model.roofSubstrate;
        request.endOfLeaseMonth = this.viewModel.model.endOfLeaseMonth;
        request.endOfLeaseYear = this.viewModel.model.endOfLeaseYear;
        request.occupiers = this.viewModel.model.occupiers;
        return request;
    }

    public async HandleResponseAsync(response: PropertyUpsertResponse): Promise<any> {
        Logger.logInformation("Response", response);

        //Return your model
        return response;
    }
}
