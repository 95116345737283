export const StartOfTime: string = "2018-03-01";

// Sharman's Table defaults
export const DefaultPageSize: number = 100;

// Condition Report
export const A4Width: number = 794;
export const A4Height: number = 1123;
export const DefaultImageSize: number = 350; // Was 250 in origianl document
export const DefaultImageGap: number = 22; // Original was 85.  This is deprecated
