import styled from "@emotion/styled";
import { Box, theme } from "Application";

export const AreYouSureModalWrapper = styled(Box)``;

export const AreYouSureModalHeader = styled(Box)`
    height: 50px;
    padding: 15px;
    background-color: ${theme.palette.common.sharmansBlue};
    display: flex;
    justify-content: space-between;
`;

export const AreYouSureModalBody = styled(Box)`
    padding: 15px;
`;

export const HeaderBox = styled(Box)`
    display: flex;
    align-items: center;
`;

export const ButtonRow = styled(Box)`
    display: flex;
    align-items: center;
    justify-content: center;
`;

export const ButtonBox = styled(Box)`
    display: flex;
    align-items: center;
    justify-content: center;

    .last-button {
        margin-left: 15px;
    }
`;
