import { IObservableArray, action, computed, makeObservable, observable, runInAction } from "mobx";
import { KeyValuePair, ViewModelBase, generateID, isEmptyOrWhitespace } from "@shoothill/core";
import { APIClient, ICommand, RelayCommand } from "Application";
import { ErrorStore } from "Stores/Domain/ErrorStore";
import { container } from "tsyringe";
import { AddressGenericModel, AddressGenericModelValidator, MatchingAddress, IAddress } from "./AddressGenericModel";
import { Client } from "getaddress-api";
import { GetCountriesForDropdownEndpoint, GetCountriesForDropdownEndpointResponse } from "./Endpoints/GetCountriesForDropdownEndpoint";
import { isValidNumber } from "Utils/Compare";

export class AddressGenericViewModel extends ViewModelBase<AddressGenericModel> {
    private addressApiClient = new Client("sltYkKHMOkaQ-7Tal-HLRw36070");

    public apiClient: APIClient = new APIClient();
    public isProcessing: boolean = false;

    errorStore = container.resolve(ErrorStore);
    public showAddressFieldsPanel: boolean = false;
    public showLatLongPanel: boolean = false;

    public countries: IObservableArray<KeyValuePair<any>> = observable([]);

    constructor() {
        super(new AddressGenericModel());

        this.setValidator(new AddressGenericModelValidator());
        makeObservable(this, {
            isProcessing: observable,
            showAddressFieldsPanel: observable,
            showLatLongPanel: observable,

            addresses: computed,
            canShowMapPin: computed,
            hasValidLatLong: computed,
            canShowLocationModal: computed,

            getCountries: action,
            setAddressFieldsPanelOpen: action,
            findAddressesCommand: action,
            checkIfModelValid: action,
            setLatLongPanelState: action,
        });
    }

    public setLatLongPanelState = () => {
        this.showLatLongPanel = !this.showLatLongPanel;
    };

    public get canShowMapPin(): boolean {
        let retVal: boolean = true;
        if (this.hasValidLatLong === false && this.model.postcode !== undefined && this.model.postcode !== null && this.model.postcode !== "") {
            retVal = false;
        }

        return retVal;
    }

    public clear = () => {
        this.isProcessing = false;
        this.countries.clear();
    };

    public get canShowLocationModal(): boolean {
        let retVal: boolean = false;

        if (this.model.postcode !== null && this.model.postcode !== undefined && this.model.postcode.length >= 5 && this.model.postcode.length <= 8) {
            retVal = true;
        }

        return retVal;
    }

    public setAddressFieldsPanelOpen = () => {
        this.showAddressFieldsPanel = true;
    };

    public setAddressFieldsPanelClosed = () => {
        this.showAddressFieldsPanel = false;
    };

    public turnOnAddressFieldsValidation = () => {
        this.setValue("validateFields", true);
    };

    public setLatitude: ICommand = new RelayCommand((value: string) => {
        if (value === "" || isValidNumber(value) === false) {
            this.model.latitude = 0;
        } else {
            this.model.latitude = parseFloat(value);
        }
    });

    public setLongitude: ICommand = new RelayCommand((value: string) => {
        if (value === "" || isValidNumber(value) === false) {
            this.model.longitude = 0;
        } else {
            this.model.longitude = parseFloat(value);
        }
    });

    public setAddressOne: ICommand = new RelayCommand((value: string) => {
        this.model.addressLineOne = value;
    });

    public updateCountryId: ICommand = new RelayCommand((value: KeyValuePair) => {
        this.model.countryId = parseInt(value.key);
        const name: string | undefined = this.countries.slice().find((c) => c.key === value.key)?.text;
        this.model.country = name === undefined ? "United Kingdom" : name;
    });

    public setAddressTwo: ICommand = new RelayCommand((value: string) => {
        this.model.addressLineTwo = value;
    });

    public setTownCity: ICommand = new RelayCommand((value: string) => {
        this.model.townCity = value;
    });

    public setCounty: ICommand = new RelayCommand((value: string) => {
        this.model.county = value;
    });

    public setCountry: ICommand = new RelayCommand((value: string) => {
        this.model.country = value;
    });

    public setPostcodeCommand = new RelayCommand((postcode: string) => {
        this.setValue("postcode", postcode);
    });

    // Command to find addresses from postcode
    public findAddressesCommand = async () => {
        this.model.validateFields = false;
        if (this.isModelValid() === true) {
            const response = await this.addressApiClient.find(this.model.postcode);

            if (response.isSuccess) {
                const success = response.toSuccess();

                runInAction(() => {
                    if (success.addresses.addresses.length > 0) {
                        this.model.postcode = success.addresses.postcode;
                        this.model.latitude = success.addresses.latitude;
                        this.model.longitude = success.addresses.longitude;
                        this.model.addresses.replace(
                            success.addresses.addresses.map<MatchingAddress>((address: any) => {
                                return {
                                    id: generateID(),
                                    address: address,
                                };
                            }),
                        );
                        this.model.selectedAddress = this.addresses[0];
                        this.model.addresses.sort((a: any, b: any) => a?.address.building_number - b?.address.building_number);
                    } else {
                        this.errorStore.setHeaderText("Address search");
                        this.errorStore.setButtonText("Close");
                        this.errorStore.setErrorMessageOne("Cannot find postcode.  Please try another postcode.");
                        this.errorStore.setErrorModalOpen(true);
                    }
                });
            } else {
                const failed = response.toFailed();

                this.errorStore.setHeaderText("Address search");
                this.errorStore.setButtonText("Close");
                this.errorStore.setErrorMessageOne("Search failed. Please try again later. Ensure UK postcode");
                this.errorStore.setErrorModalOpen(true);
                this.model.addresses.replace([]);
            }
        }
    };

    public populateLatLongFromPostcode = async (): Promise<void> => {
        this.model.validateFields = false;
        if (this.isModelValid() === true) {
            const response = await this.addressApiClient.find(this.model.postcode);

            if (response.isSuccess) {
                const success = response.toSuccess();

                runInAction(() => {
                    if (success.addresses.addresses.length > 0) {
                        this.model.latitude = success.addresses.latitude;
                        this.model.longitude = success.addresses.longitude;
                    } else {
                        this.errorStore.setHeaderText("Address search");
                        this.errorStore.setButtonText("Close");
                        this.errorStore.setErrorMessageOne("Cannot find postcode. Please try another postcode.");
                        this.errorStore.setErrorModalOpen(true);
                    }
                });
            } else {
                const failed = response.toFailed();
                this.errorStore.setHeaderText("Address search");
                this.errorStore.setButtonText("Close");
                this.errorStore.setErrorMessageOne("Search failed.  Please try again later.");
                this.errorStore.setErrorModalOpen(true);
                this.model.latitude = 0;
                this.model.longitude = 0;
                this.model.addresses.replace([]);
            }
        }
    };

    public get addresses() {
        return this.model.addresses.slice().map((item: MatchingAddress, index: number) => {
            return {
                key: item.id,
                text: (item.address as any).formatted_address.filter((part: string) => !isEmptyOrWhitespace(part)).join(", "),
            };
        });
    }

    public get hasValidLatLong(): boolean {
        let retVal: boolean = false;

        if (
            this.model.latitude !== undefined &&
            this.model.latitude !== null &&
            this.model.longitude !== undefined &&
            this.model.longitude !== null &&
            (this.model.latitude != 0 || this.model.longitude != 0)
        ) {
            retVal = true;
        }

        return retVal;
    }

    // Set address when clicked on in the editselect
    public setAddress: ICommand = new RelayCommand((value: KeyValuePair) => {
        //keyvalue pair for editselect
        this.model.selectedAddress = value;

        //Update address fields
        let chosenAddress: MatchingAddress | undefined = this.model.addresses.slice().find((address) => {
            return address.id === this.model.selectedAddress.key;
        });
        this.model.setAddress(chosenAddress?.address, this.countries.slice());
        this.showAddressFieldsPanel = true;
    });

    public setAddressFromIncoming = (address: IAddress) => {
        (this.model.postcode = address.postcode),
            (this.model.latitude = address.latitude),
            (this.model.longitude = address.longitude),
            (this.model.addressLineOne = address.addressLineOne),
            (this.model.addressLineTwo = address.addressLineTwo),
            (this.model.townCity = address.townCity),
            (this.model.county = address.county),
            (this.model.country = address.country);
    };

    // Get selected address for editselect
    public getAddress: ICommand = new RelayCommand(() => {
        return this.model.selectedAddress;
    });

    public returnTheAddress = () => {
        let addressToReturn: IAddress = {
            postcode: this.model.postcode,
            latitude: this.model.latitude,
            longitude: this.model.longitude,
            addressLineOne: this.model.addressLineOne,
            addressLineTwo: this.model.addressLineTwo,
            townCity: this.model.townCity,
            county: this.model.county,
            country: this.model.country,
            countryId: this.model.countryId,
        };

        return addressToReturn;
    };

    public checkIfModelValid = () => {
        this.model.validateFields = true;
        if (this.isModelValid() === true) {
            return true;
        } else {
            this.showAddressFieldsPanel = true;
            return false;
        }
    };

    public onBlurCommand = () => {
        if (this.isModelValid()) {
            //
        }
    };

    public getCountries = async () => {
        if (this.isProcessing === false) {
            this.isProcessing = true;

            const endpoint: GetCountriesForDropdownEndpoint = new GetCountriesForDropdownEndpoint();

            await this.apiClient.sendAsync(endpoint);

            if (this.apiClient.IsRequestSuccessful) {
                runInAction(() => {
                    this.isProcessing = false;
                    let response: GetCountriesForDropdownEndpointResponse[] = this.apiClient.Response();

                    for (let i = 0; i < response.length; i++) {
                        this.countries.push({
                            key: response[i].key,
                            text: response[i].name,
                        });
                    }
                });
            } else {
                runInAction(() => {
                    this.isProcessing = false;
                });
                this.errorStore.setHeaderText("Address");
                this.errorStore.setButtonText("Close");
                this.errorStore.setErrorMessageOne("Failed to get the country data.  Please try again later.");
                this.errorStore.setErrorMessageTwo(this.apiClient.ValidationMessage);
                this.errorStore.setErrorModalOpen(true);
            }
        }
    };
}
