import React, { useCallback, useEffect, useState } from "react";
import { observer } from "mobx-react-lite";
import { Box, EditSelect, ICommand, RelayCommand } from "Application";
import { IDocumentType } from "Models/Documents/DocumentType";
import { useDropzone } from "react-dropzone";
import { DeleteButton, DocumentToUploadBox, PStyledDocuments, UploadImageBox } from "Components/CommonPageComponents";
import FileIcon from "Assets/Icons/FileIcon.svg";
import { ThemedLoaderSmaller } from "Components/Primitives/Loaders/ThemedLoaderSmaller";
import { P } from "Components/Primitives/TextElements/TextElements";
import DocLogo from "Assets/Icons/DocLogo.svg";
import CloseIcon from "Assets/close_icon_black.svg";
import { IDocumentUploaderViewModel } from "./IDocumentUploaderViewModel";
import spyScope from "Assets/Icons/magnifyingGlass.svg";
import { PreviewButtonImg } from "./DocumentUploader.styles";
import { PreviewView } from "./PreviewView/PreviewView";
import { checkIsAcceptedFile } from "Utils/File";

interface IProps {
    // parentViewModel: IAddDocumentModalViewModel;
    parentViewModel: IDocumentUploaderViewModel;
    documentTypes: IDocumentType[];
}

export const DocumentUploader: React.FC<IProps> = observer((props) => {
    const [isPreviewPanelVisible, setIsPreviewPanelVisible] = useState<boolean>(false);
    const [previewFileIndex, setPreviewFileIndex] = useState<number>(0);

    function MyDropzone() {
        const onDrop = useCallback((acceptedFiles: File[]) => {
            // Do something with the files
            props.parentViewModel.addFiles(acceptedFiles);
        }, []);
        const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop, noDragEventsBubbling: true, multiple: false });

        return (
            <UploadImageBox>
                <div {...getRootProps()}>
                    <img src={FileIcon} />
                    <input {...getInputProps()} />
                    {isDragActive ? <p>Drop the files here ...</p> : <p>Click or drag files to attach (.doc .pdf .xls .csv .jpg .png etc.)</p>}
                </div>
            </UploadImageBox>
        );
    }

    const truncateString = (str: string): string => {
        let maxLength = 60;
        if (str.length <= maxLength) {
            return str; // Return the original string if it's already shorter than maxLength
        }

        const truncatedLength = maxLength - 5; // Length of the string without " ..."
        const firstHalfLength = Math.ceil(truncatedLength / 2);
        const secondHalfLength = truncatedLength - firstHalfLength;

        const firstHalf = str.substr(0, firstHalfLength);
        const secondHalf = str.substr(-secondHalfLength);

        return `${firstHalf}  ...  ${secondHalf}`;
    };

    // Previewing document

    const showPreviewPanel = (documentIndex: number) => {
        setPreviewFileIndex(documentIndex);
        setIsPreviewPanelVisible(true);
    };

    const navBackToDocumentUploader: ICommand = new RelayCommand(() => {
        setIsPreviewPanelVisible(false);
    });

    // ***************

    useEffect(() => {
        return () => {
            // Clean up after yourself
            //    viewModel.clear();
        };
    }, []);

    return (
        <>
            {isPreviewPanelVisible ? (
                <PreviewView documentList={props.parentViewModel.documentsToUpload} previewFileIndex={previewFileIndex} navBackToDocumentUploader={navBackToDocumentUploader} />
            ) : (
                <>
                    <Box minWidth="658px" p="12px 0px">
                        <MyDropzone />
                    </Box>

                    {props.parentViewModel.documentsToUpload.length > 0 && (
                        <Box style={{ display: "grid", gridTemplateColumns: "1.5fr 1fr" }}>
                            <PStyledDocuments p="10px 0px">Documents to upload</PStyledDocuments>
                            <PStyledDocuments p="10px 0px">Type</PStyledDocuments>
                        </Box>
                    )}

                    {props.parentViewModel.isProcessing ? (
                        <ThemedLoaderSmaller isOpen={true} spinnerText={props.parentViewModel.spinnerText} />
                    ) : (
                        <>
                            {props.parentViewModel.documentsToUpload.map((document, index) => {
                                return (
                                    <>
                                        <DocumentToUploadBox key={document.model.KEY + index}>
                                            <Box flexBox gap={0} style={{ alignItems: "center", justifyContent: "space-between" }}>
                                                <Box flexBox gap={0} style={{ alignItems: "center" }}>
                                                    <img src={DocLogo} height="18px" />
                                                    <P pl="10px">{truncateString(document.getValue("filename"))}</P>
                                                </Box>

                                                {checkIsAcceptedFile(document.model.file) && (
                                                    <Box pr={"10px"}>
                                                        <PreviewButtonImg src={spyScope} onClick={() => showPreviewPanel(index)} />
                                                    </Box>
                                                )}
                                            </Box>
                                            <Box flexBox style={{ justifyContent: "space-between", alignItems: "center" }}>
                                                <EditSelect
                                                    command={document.setDocumentType}
                                                    options={props.parentViewModel.getDocTypesForDropdown}
                                                    value={() => document.getValue("documentCategoryId")}
                                                    validationMessage={() => document.getError("documentCategoryId")}
                                                    errorIconRight={true}
                                                    noHeader={true}
                                                    menuPosition="fixed"
                                                />
                                                <DeleteButton
                                                    command={props.parentViewModel.removeFileFromModalList}
                                                    value={index}
                                                    style={{ cursor: "pointer" }}
                                                    icon={<img src={CloseIcon} />}
                                                />
                                            </Box>
                                        </DocumentToUploadBox>
                                    </>
                                );
                            })}
                        </>
                    )}
                </>
            )}
        </>
    );
});
