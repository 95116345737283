import { Endpoint, Http } from "Application/Helpers/BaseEndpoint";
import { AppUrls } from "AppUrls";
import { ComplaintTaskListItem } from "../TasksModel";
import { SharmanTaskTypeEnum } from "Models/Tasks/TaskTypeEnum";
import { DefaultPageSize } from "Globals/GlobalSettings";
import { TasksViewModel } from "../TasksViewModel";

class GetComplaintTaskRequest {
    public sourceId: Guid = "";
    public taskTypeId: number = 0;
    public orderBy: string = "ASC";
    public columnName: string = "";
    public pageSize: number = DefaultPageSize;
    public pageNumber: number = 1;
    public filterByCompleted: string[] = [];
}

class GetComplaintTasksListResponse {
    public taskList: ComplaintTaskListItem[] = [];
    public taskCount: number = 0;
}

export class GetComplaintTaskListEndpoint extends Endpoint<GetComplaintTaskRequest, GetComplaintTasksListResponse> {
    constructor() {
        super();
        this.verb(Http.Post);
        this.path(AppUrls.Server.MasterProject.GetAllTask);
    }

    public async HandleRequestAsync(viewModel: TasksViewModel): Promise<any> {
        let request = new GetComplaintTaskRequest();
        request.orderBy = viewModel.orderBy;
        request.columnName = viewModel.columnName;
        request.pageNumber = viewModel.pageNumber;
        request.pageSize = viewModel.pageSize;
        request.sourceId = viewModel.complaintId;
        request.taskTypeId = SharmanTaskTypeEnum.ProjectQuoteComplaint;
        request.filterByCompleted = viewModel.filterByCompleted;
        return request;
    }

    public async HandleResponseAsync(response: GetComplaintTasksListResponse): Promise<any> {
        return response;
    }
}
