import { Logger } from "index";
import { Endpoint, Http } from "Application/Helpers/BaseEndpoint";
import { CustomerContactsViewModel } from "../NewCustomer/CustomerContacts/CustomerContactsViewModel";
import { CustomerContactsModel } from "../NewCustomer/CustomerContacts/CustomerContactsModel";
import { AppUrls } from "AppUrls";

class GetContactsByCustomerIdRequest {
    customerId: Guid = "";
    orderBy: string = "";
    columnName: string = "";
}

class GetContactsByCustomerIdResponse {}

export class GetContactsByCustomerIdEndpoint extends Endpoint<GetContactsByCustomerIdRequest, GetContactsByCustomerIdResponse> {
    private model: any;

    constructor(model: any) {
        super();
        this.model = model;
        this.verb(Http.Post);
        this.path(AppUrls.Server.Customer.GetContactsByCustomerId);
    }

    public async HandleRequestAsync(model: any): Promise<any> {
        let request = new GetContactsByCustomerIdRequest();
        request.customerId = this.model.customerId;
        request.orderBy = this.model.orderBy;
        request.columnName = this.model.columnName;
        return request;
    }

    public async HandleResponseAsync(response: GetContactsByCustomerIdResponse): Promise<any> {
        Logger.logInformation("Response", response);

        //Return your model
        return response;
    }
}
