import React, { useEffect, useState } from "react";
import { observer } from "mobx-react-lite";
import { Box } from "Application";
import LoadingSpinnerSVG from "Assets/Spinners/LoadingSpinner.svg";

interface ILoadingSpinner {
    heightOfSpinner?: any;
}

export const LoadingSpinner: React.FC<ILoadingSpinner> = observer((props) => {
    return (
        <>
            <Box flexBox justifyContent={"center"}>
                <img src={LoadingSpinnerSVG} height={props.heightOfSpinner ? props.heightOfSpinner : "40px"}></img>
            </Box>
        </>
    );
});
