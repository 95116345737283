import React from "react";
import { observer } from "mobx-react-lite";
import { Button, ICommand, RelayCommand } from "Application";
import { Modal } from "@fluentui/react";
import { ButtonBox, AreYouSureModalBody, AreYouSureModalHeader, AreYouSureModalWrapper, HeaderBox, ButtonRow } from "./AreYouSure.styles";
import { P } from "Components/Primitives/TextElements/TextElements";
import ErrorIcon from "Assets/Icons/warning_icon_1.svg";
import CloseIcon from "Assets/close_icon.svg";

export interface IAreYouSure {
    title: string;
    message: string;

    isOpen: boolean;

    onConfirm: () => void;
    onCancel: () => void;
}

export const AreYouSureModal: React.FC<IAreYouSure> = observer(({ title, message, onConfirm, onCancel, isOpen }) => {
    const handleConfirmButtonClick: ICommand = new RelayCommand(() => {
        onConfirm();
    });

    const handleCancelButtonClick: ICommand = new RelayCommand(() => {
        onCancel();
    });

    return (
        <>
            <Modal isOpen={isOpen}>
                <AreYouSureModalWrapper>
                    <AreYouSureModalHeader>
                        <HeaderBox>
                            <img src={ErrorIcon} height="20px" />
                            <P color="white" pl="10px" style={{ fontSize: "10px", fontWeight: 600 }}>
                                {title}
                            </P>
                        </HeaderBox>
                        <HeaderBox style={{ cursor: "pointer" }} onClick={onCancel}>
                            <img src={CloseIcon} />
                        </HeaderBox>
                    </AreYouSureModalHeader>

                    <AreYouSureModalBody>
                        <P>{message}</P>
                        <ButtonRow>
                            <ButtonBox pt="30px">
                                <Button command={handleConfirmButtonClick} displayName="Confirm" boxShadow={true} paletteColor="ButtonGreen" />
                                <Button className="last-button" command={handleCancelButtonClick} displayName="Cancel" boxShadow={true} paletteColor="ButtonBlue" />
                            </ButtonBox>
                        </ButtonRow>
                    </AreYouSureModalBody>
                </AreYouSureModalWrapper>
            </Modal>
        </>
    );
});
