import { action, computed, makeObservable, observable, runInAction } from "mobx";
import { KeyValuePair, ViewModelBase } from "@shoothill/core";
import { APIClient, ICommand, RelayCommand } from "Application";
import { PropertiesListModel, PropertiesListModelValidator } from "./PropertiesListModel";
import { container } from "tsyringe";
import { ErrorStore } from "Stores/Domain/ErrorStore";
import { SortOrderDirection } from "Components/Primitives/DataTable/SharmansTable";
import { PropertiesListItemModel } from "./PropertiesListItemModel";
import { GetPropertyListAndRelatedEndpoint, PropertyListAndRelatedResponse } from "../EndPoints/GetPropertyListAndRelatedEndpoint";
import { DownloadPropertyCSVEndpoint } from "../EndPoints/DownloadPropertyCSVEndpoint";
import { DefaultPageSize } from "Globals/GlobalSettings";
import { KeyValuePairExtended } from "Models/KeyValuePairExtended";

export class PropertiesListViewModel extends ViewModelBase<PropertiesListModel> {
    // Singleton Patten - Uses instance and a private static variable to ensure only one instance is created and a private Constructor
    private static _instance: PropertiesListViewModel;
    public static get Instance() {
        return this._instance || (this._instance = new this());
    }

    public apiClient = new APIClient();
    errorStore = container.resolve(ErrorStore);
    public isProcessing: boolean = false;
    public initialLoad: boolean = true;
    // Filtering top section

    public searchString: string = "";
    public filterBySurveyed: string[] = [];
    public filterBySiteVisited: string[] = [];

    // Table ordering

    public orderBy: SortOrderDirection = SortOrderDirection.ASC;
    public columnName: string = "address";

    // Pagination

    public propertyCount: number = 0;
    public pageSize: number = DefaultPageSize;
    public pageCount: number = 0;
    public pageNumber: number = 1;

    private constructor() {
        super(new PropertiesListModel());
        this.setValidator(new PropertiesListModelValidator());
        makeObservable(this, {
            isProcessing: observable,
            searchString: observable,
            orderBy: observable,
            columnName: observable,
            pageSize: observable,
            pageNumber: observable,
            propertyCount: observable,
            filterBySurveyed: observable,
            filterBySiteVisited: observable,

            clear: action,
            downloadCSVAsync: action,
            loadPropertiesAsync: action,
            setIsProcessing: action,

            getProperties: computed,

            getCanExecute: computed,
            getsurveyedFilter: computed,
            surveyedSelectAll: action,
            surveyedSelectNone: action,
            updatesurveyedFiltering: action,

            getSitevisitedFilter: computed,
            siteVisitedSelectAll: action,
            siteVisitedSelectNone: action,
            updateSiteVisitedFiltering: action,
        });
    }

    public clear = () => {
        this.isProcessing = false;

        // Filtering top section

        this.searchString = "";
        this.filterBySurveyed = [];
        this.filterBySiteVisited = [];

        // Table ordering

        this.orderBy = SortOrderDirection.ASC;
        this.columnName = "address";

        // Pagination

        this.propertyCount = 0;
        this.pageSize = DefaultPageSize;
        this.pageCount = 0;
        this.pageNumber = 1;
    };

    public reset = () => {
        this.searchString = "";
        this.surveyedSelectAll();
        this.siteVisitedSelectAll();
    };

    public get getCanExecute(): boolean {
        return !this.isProcessing;
    }

    public get IsBusy(): boolean {
        return this.apiClient.IsBusy;
    }

    public updateKeywordSearch: ICommand = new RelayCommand(
        (keyword: string) => {
            this.searchString = keyword;
            this.pageNumber = 1;
            this.loadPropertiesAsync();
        },
        () => {
            return this.getCanExecute;
        },
    );

    public updateFiltering: ICommand = new RelayCommand(
        () => {},
        () => {
            return this.getCanExecute;
        },
    );

    public get getsurveyedFilter(): string | string[] {
        return this.filterBySurveyed.slice();
    }

    public get getSitevisitedFilter(): string | string[] {
        return this.filterBySiteVisited.slice();
    }

    public updatesurveyedFiltering = (values: string[]) => {
        this.filterBySurveyed = values;
    };

    public surveyedSelectAll = () => {
        this.filterBySurveyed = this.getBooleanOptionsForDropdown.map((item) => {
            return item.key;
        });
    };

    public surveyedSelectNone = () => {
        this.filterBySurveyed = [];
    };

    public updateSiteVisitedFiltering = (values: string[]) => {
        this.filterBySiteVisited = values;
    };

    public siteVisitedSelectAll = () => {
        this.filterBySiteVisited = this.getBooleanOptionsForDropdown.map((item) => {
            return item.key;
        });
    };

    public siteVisitedSelectNone = () => {
        this.filterBySiteVisited = [];
    };

    public loadPropertiesAsync = async (): Promise<PropertyListAndRelatedResponse> => {
        const endpoint = new GetPropertyListAndRelatedEndpoint();
        let retVal: PropertyListAndRelatedResponse = new PropertyListAndRelatedResponse();

        if (this.isProcessing === false && this.apiClient.IsBusy === false) {
            this.setIsProcessing(true);
            await this.apiClient.sendAsync(endpoint, this);

            if (this.apiClient.IsRequestSuccessful) {
                runInAction(() => {
                    this.model.Properties.clear();

                    let response: PropertyListAndRelatedResponse = this.apiClient.Response();
                    this.model.Properties.replace(response.properties);
                    this.propertyCount = response.propertyCount;
                    this.pageCount = Math.ceil(this.propertyCount / this.pageSize);
                    this.setIsProcessing(false);
                    if (this.initialLoad === true) {
                        // Set the intial filter state
                        this.surveyedSelectAll();
                        this.siteVisitedSelectAll();

                        this.initialLoad = false;
                    }
                });
            } else {
                runInAction(() => {
                    this.setIsProcessing(false);
                });
                this.errorStore.setHeaderText("Property list");
                this.errorStore.setButtonText("Close");
                this.errorStore.setErrorMessageOne("Failed to get the property list.  Please try again later.");
                this.errorStore.setErrorMessageTwo(this.apiClient.ValidationMessage);
                this.errorStore.setErrorModalOpen(true);
            }
        }
        return retVal;
    };

    public setIsProcessing = (value: boolean) => {
        this.isProcessing = value;
    };

    public get getProperties(): PropertiesListItemModel[] {
        return this.model.Properties.slice();
    }

    public get getBooleanOptionsForDropdown() {
        let retVal: KeyValuePairExtended[] = [
            // { key: null, text: "All" },
            { key: true, text: "Yes" },
            { key: false, text: "No" },
        ];

        return retVal;
    }

    public setFilterBySurveyed: ICommand = new RelayCommand(
        (value: KeyValuePair) => {
            this.filterBySurveyed = value.key;
            this.pageNumber = 1;
            this.loadPropertiesAsync();
        },
        () => {
            return this.getCanExecute;
        },
    );

    public setFilterSiteVisited: ICommand = new RelayCommand(
        (value: KeyValuePair) => {
            this.filterBySiteVisited = value.key;
            this.pageNumber = 1;
            this.loadPropertiesAsync();
        },
        () => {
            return this.getCanExecute;
        },
    );

    public async downloadCSVAsync(): Promise<void> {
        const endpoint = new DownloadPropertyCSVEndpoint(this);
        await this.apiClient.sendAsync(endpoint);
        this.isProcessing = true;

        if (this.apiClient.IsRequestSuccessful) {
            runInAction(() => {
                this.isProcessing = false;
            });
        } else {
            runInAction(() => {
                this.isProcessing = false;
            });
            this.errorStore.setHeaderText("Download property CSV");
            this.errorStore.setButtonText("Close");
            this.errorStore.setErrorMessageOne("Failed to download property CSV.  Please try again later.");
            this.errorStore.setErrorMessageTwo(this.apiClient.ValidationMessage);
            this.errorStore.setErrorModalOpen(true);
        }
    }

    public clearFiltering: ICommand = new RelayCommand(
        () => {
            this.filterBySurveyed = [];
            this.filterBySiteVisited = [];
            this.initialLoad = true;
            this.loadPropertiesAsync();
        },
        () => {
            return this.getCanExecute;
        },
    );

    public changePageNumber = (pageNumber: number) => {
        this.pageNumber = pageNumber;
        this.loadPropertiesAsync();
    };
}
