import { AppUrls } from "AppUrls";
import { Endpoint, Http } from "Application";
import { PropertiesListItemModel } from "../List/PropertiesListItemModel";
import { PropertiesListViewModel } from "../List/PropertiesListViewModel";

class Request {
    orderBy: string = "";
    columnName: string = "";
    pageSize: number = 0;
    pageNumber: number = 1;
    filterBySurveyed: string[] = [];
    filterBySiteVisited: string[] = [];
    searchString: string = "";
    public initialLoad: boolean = true;
}

export class PropertyListAndRelatedResponse {
    properties: PropertiesListItemModel[] = [];
    propertyCount: number = 0;
}

export class GetPropertyListAndRelatedEndpoint extends Endpoint<Request, PropertyListAndRelatedResponse> {
    constructor() {
        super();
        this.verb(Http.Post);
        this.path(AppUrls.Server.Property.GetPropertyListAndRelated);
    }

    public async HandleRequestAsync(viewModel: PropertiesListViewModel): Promise<any> {
        let request = new Request();
        request.filterBySurveyed = [];
        request.columnName = viewModel.columnName;
        request.orderBy = viewModel.orderBy;
        request.pageSize = viewModel.pageSize;
        request.pageNumber = viewModel.pageNumber;
        // request.userRolesForFiltering = this.viewModel.userRolesForFiltering;
        request.searchString = viewModel.searchString;

        request.filterBySurveyed = viewModel.filterBySurveyed.map((item) => {
            return item;
        });

        request.filterBySiteVisited = viewModel.filterBySiteVisited.map((item) => {
            return item;
        });

        request.initialLoad = viewModel.initialLoad;

        return request;
    }

    public async HandleResponseAsync(response: PropertyListAndRelatedResponse): Promise<PropertyListAndRelatedResponse> {
        return response;
    }
}
