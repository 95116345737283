import { makeObservable, action, computed, observable, runInAction } from "mobx";
import { ViewModelBase } from "@shoothill/core";
import { APIClient } from "Application";
import { ErrorStore } from "Stores/Domain/ErrorStore";
import { container } from "tsyringe";
import { PreviewUploadedDocumentModalModel, PreviewUploadedDocumentModalModelValidator } from "./PreviewUploadedDocumentModalModel";
import { DocumentDownloaderPreviewEndpoint } from "Endpoint/DocumentDownloaderPreview";
import { DocumentDownloaderRequest } from "Endpoint/DocumentDownloader";
import { IAddedDocumentViewModel } from "Models/Documents/IAddedDocumentViewModel";
import { PreviewUploadedDocumentItemViewModel } from "./PreviewUploadedDocumentItemViewModel";

export class PreviewUploadedDocumentViewModel extends ViewModelBase<PreviewUploadedDocumentModalModel> {
    public apiClient = new APIClient();
    errorStore = container.resolve(ErrorStore);
    public documentToPreview: PreviewUploadedDocumentItemViewModel[] = [];
    public isProcessing: boolean = false;

    constructor() {
        super(new PreviewUploadedDocumentModalModel());
        this.setValidator(new PreviewUploadedDocumentModalModelValidator());
        makeObservable(this, {
            documentToPreview: observable,
            isProcessing: observable,

            loadDocument: action,
            clear: action,
        });
    }

    public clear = () => {
        this.model.clear();
    };

    public downloadDocumentForPreviewCommand = (id: string, fileName: string, blobName: string, fileType: string, docType: string): void => {
        let documentToDownload = new DocumentDownloaderRequest();

        documentToDownload.id = id;
        documentToDownload.fileName = fileName;
        documentToDownload.blobName = blobName;
        documentToDownload.fileType = fileType;
        documentToDownload.docType = docType;

        this.loadDocument(documentToDownload);
    };

    public loadDocument = async (documentToDownload: DocumentDownloaderRequest) => {
        let retVal: any = null;
        this.isProcessing = true;
        let endpoint: DocumentDownloaderPreviewEndpoint = new DocumentDownloaderPreviewEndpoint();
        await this.apiClient.sendAsync(endpoint, documentToDownload);

        if (this.apiClient.IsRequestSuccessful === true) {
            retVal = this.apiClient.Response<any>();
            runInAction(() => {
                let document = new PreviewUploadedDocumentItemViewModel();
                document.model.file = retVal;
                document.model.filename = documentToDownload.fileName;
                document.model.blobName = documentToDownload.blobName;
                document.model.fileType = documentToDownload.fileType;
                // this.documentToPreview[0].model.documentCategoryId = documentToDownload.docType;
                this.documentToPreview.push(document);
                this.isProcessing = false;
            });
        } else {
            runInAction(() => {
                this.isProcessing = false;
            });
            this.errorStore.setHeaderText("Document Preview");
            this.errorStore.setButtonText("Close");
            this.errorStore.setErrorMessageOne("Failed to download the document.  Please try again later.");
            this.errorStore.setErrorMessageTwo(this.apiClient.ValidationMessage);
            this.errorStore.setErrorModalOpen(true);
        }

        return retVal;
    };
}
