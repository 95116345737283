import React, { useEffect, useState } from "react";
import { observer } from "mobx-react-lite";
import { ICommand, RelayCommand } from "Application";
import { createColumnHelper } from "@tanstack/react-table";
import { CenteredCell, CenteredTextCell } from "Utils/CellFormatComponents";
import { formatDate } from "Utils/Formats";
import { PaginationDirection, SharmansTable, SortOrderDirection } from "Components/Primitives/DataTable/SharmansTable";
import { ProjectQuoteViewModel } from "./ProjectQuoteViewModel";
import { ProjectStatusBox, ProjectStatusWrapper } from "Views/Work/Common/ProjectsList.styles";
import { ProjectStatusEnumHelper } from "Models/Project/ProjectStatusEnum";
import { DeleteButton } from "Components/Primitives/Buttons/DeleteButton";
import { AreYouSureModal } from "Components/AreYouSureModal/AreYouSure";
import { MultiEditSelect } from "Components/Primitives/Selects/MultiEditSelect";
import { KeyValuePair, formatCurrency } from "@shoothill/core";
import { ProductTypeSelectWrapper } from "Styles/BaseStyles";
import { useNavigate } from "react-router";
import { AppUrlIndex, AppUrls } from "AppUrls";
import { FilteringBottomSectionBox } from "Components/FilteringBottomSectionBox";
import { H2 } from "Components/Primitives/TextElements/TextElements";

export interface IProjectQuotesTableProps {
    propertyId: string;
    projectQuotesCountCallBack: (newCount: number) => void;
}

export const ProjectQuotesTable: React.FC<IProjectQuotesTableProps> = observer((props) => {
    const [viewModel] = useState(() => new ProjectQuoteViewModel(props.propertyId!, props.projectQuotesCountCallBack));
    const [isFiltering, setIsFiltering] = useState(false);
    const columnHelper = createColumnHelper<any>();
    let navigate = useNavigate();
    let debounceTimer: NodeJS.Timeout | null = null;

    useEffect(() => {
        if (props?.propertyId) {
            viewModel.getLinkedProjectsQuotes();
        }
        return () => {
            // Clean up after yourself
            viewModel.clear();
        };
    }, []);

    useEffect(() => {
        if (isFiltering) {
            debounceFilter();
        }
    }, [isFiltering, viewModel]);

    const columns = [
        columnHelper.accessor("number", {
            header: () => "Prj no.",
            cell: (info) => info.renderValue(),
        }),

        columnHelper.accessor("projectName", {
            size: 400,
            header: () => "Project name",
            cell: (info) => info.renderValue(),
        }),

        columnHelper.accessor("projectProduct", {
            header: () => "Product",
            cell: (info) => info.renderValue(),
        }),

        columnHelper.accessor("createdDate", {
            header: () => "Created date",
            cell: (info) => <CenteredTextCell value={formatDate(new Date(info.renderValue()))} />,
        }),

        columnHelper.accessor("netValue", {
            header: () => "Net value",
            cell: (info) => formatCurrency(info.renderValue()),
        }),

        columnHelper.accessor("wonValue", {
            header: () => "Won value",
            cell: (info) => formatCurrency(info.renderValue()),
        }),

        columnHelper.accessor("openValue", {
            header: () => "Open value",
            cell: (info) => formatCurrency(info.renderValue()),
        }),

        columnHelper.accessor("lostValue", {
            header: () => "Lost value",
            cell: (info) => formatCurrency(info.renderValue()),
        }),

        columnHelper.accessor("overallStatus", {
            header: () => "Status",
            cell: (info) => {
                if (info.getValue() !== undefined) {
                    return (
                        <ProjectStatusBox className={ProjectStatusEnumHelper.getClassName(info.getValue())}>
                            <div>{ProjectStatusEnumHelper.getText(info.getValue())}</div>
                        </ProjectStatusBox>
                    );
                } else {
                    return "";
                }
            },
        }),

        columnHelper.accessor("canDelete", {
            header: () => "Actions",
            cell: (info) => {
                if (info.getValue() === true) {
                    return (
                        <CenteredCell>
                            <DeleteButton id={info.row.original.id.toString()} onClick={viewModel.deletePropertyCheck} />
                        </CenteredCell>
                    );
                } else {
                    return "";
                }
            },
            enableSorting: false,
        }),
    ];

    const updateSorting = (columnName: string, orderBy: SortOrderDirection) => {
        viewModel.columnName = columnName;
        viewModel.orderBy = orderBy;
        viewModel.getLinkedProjectsQuotes();
    };

    const onChangePage = (change: PaginationDirection) => {
        switch (change) {
            case PaginationDirection.NEXT:
                viewModel.pageNumber < viewModel.pageCount ? viewModel.pageNumber++ : null;
                viewModel.getLinkedProjectsQuotes();
                break;
            case PaginationDirection.BACK:
                viewModel.pageNumber > 1 ? viewModel.pageNumber-- : null;
                viewModel.getLinkedProjectsQuotes();

                break;
            case PaginationDirection.START:
                viewModel.pageNumber = 1;
                viewModel.getLinkedProjectsQuotes();

                break;
            case PaginationDirection.END:
                viewModel.pageNumber = viewModel.pageCount;
                viewModel.getLinkedProjectsQuotes();

                break;
            default:
        }
    };

    const onChangeRowPerPage = (rowsPerPage: number) => {
        viewModel.pageSize = rowsPerPage;
        viewModel.pageNumber = 1;
        viewModel.getLinkedProjectsQuotes();
    };

    const debounceFilter = () => {
        if (debounceTimer) {
            clearTimeout(debounceTimer);
        }
        debounceTimer = setTimeout(() => {
            viewModel.getLinkedProjectsQuotes();
            setIsFiltering(false);
        }, 1200);
    };

    // Filter by status
    const updateStatusFiltering: ICommand = new RelayCommand((values: KeyValuePair[]) => {
        viewModel.updateStatusFiltering(
            values.map((item) => {
                return item.key;
            }),
        );
        debounceFilter();
    });

    const statusSelectAll = (): void => {
        viewModel.statusSelectAll();
        viewModel.getLinkedProjectsQuotes();
    };

    const statusSelectNone = (): void => {
        viewModel.statusSelectNone();
        viewModel.getLinkedProjectsQuotes();
    };

    // Category
    const updateCategoryFiltering: ICommand = new RelayCommand((values: KeyValuePair[]) => {
        viewModel.updateProductFiltering(
            values.map((item) => {
                return item.key;
            }),
        );
        debounceFilter();
    });

    const productSelectAll = (): void => {
        viewModel.productSelectAll();
        viewModel.getLinkedProjectsQuotes();
    };

    const productSelectNone = (): void => {
        viewModel.productSelectNone();
        viewModel.getLinkedProjectsQuotes();
    };

    const formatOptionLabel = ({ value, label, cls }: any) => <p className={getClassName(value)}>{label}</p>;

    const getClassName = (value: number) => {
        let retVal = "";
        let type = viewModel.projectProducts.find((productType) => productType.id === value);

        if (type?.parentId === 0) {
            retVal = "parent";
        } else if (type?.parentId !== 0) {
            retVal = "child";
        }
        return retVal;
    };

    const FilteringBottomSection = (
        <FilteringBottomSectionBox grid dc={"1fr 1fr 1fr 1fr 1fr"}>
            <ProductTypeSelectWrapper>
                <MultiEditSelect
                    command={updateCategoryFiltering}
                    displayName="Category"
                    options={viewModel.getProductTypesForDropdown}
                    value={() => viewModel.getProductFilter}
                    showSelectAllNone={true}
                    selectAll={productSelectAll}
                    selectNone={productSelectNone}
                    formatOptionLabel={formatOptionLabel}
                    isOptionDisabled={(option) => option.class === "parent"}
                    isDisabled={viewModel.isProcessing}
                />
            </ProductTypeSelectWrapper>
            <ProjectStatusWrapper>
                <MultiEditSelect
                    command={updateStatusFiltering}
                    displayName="Filter by status"
                    options={viewModel.getStatuses}
                    value={() => viewModel.getStatusFilter}
                    showSelectAllNone={true}
                    selectAll={statusSelectAll}
                    selectNone={statusSelectNone}
                    isDisabled={viewModel.isProcessing}
                />
            </ProjectStatusWrapper>
        </FilteringBottomSectionBox>
    );

    function onRowClick(rowId: string | number): void {
        const path: string = AppUrls.Client.Work.Quotes.View[AppUrlIndex.Long].replace(":projectquoteid", rowId.toString()) + "/details";
        navigate(path);
    }

    return (
        <>
            {viewModel.areYouSureModelOpen && (
                <AreYouSureModal
                    title="Delete Project Quote"
                    message="Are you sure you want to delete this project quote?"
                    isOpen={viewModel.areYouSureModelOpen}
                    onCancel={viewModel.closeAreYouSureModal}
                    onConfirm={viewModel.confirmDeleteProperty}
                />
            )}

            <H2 p={"28px 0 10px 0"}> Project quotes linked to this property</H2>

            <SharmansTable
                columns={columns}
                data={viewModel.getProjects}
                tableHeaderColour="#D4D4D4"
                filteringElementsBottomSection={FilteringBottomSection}
                totalRowCount={viewModel.customerCount}
                onSortChange={updateSorting}
                onChangePage={onChangePage}
                onChangePageNumber={viewModel.changePageNumber}
                onChangeRowPerPage={onChangeRowPerPage}
                showPagination={true}
                totalPageCount={viewModel.pageCount}
                pageNumber={viewModel.pageNumber}
                pageSize={viewModel.pageSize}
                clearFilteringCommand={viewModel.clearFiltering}
                onRowClick={onRowClick}
            />
        </>
    );
});
