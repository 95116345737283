import React, { useEffect, useState } from "react";
import { observer } from "mobx-react-lite";
import { Box, Button, EditDate, Input, RadioButton, formatDate } from "Application";
import { createColumnHelper } from "@tanstack/react-table";
import {
    ComplaintTableDateWrapper,
    ComplaintsFilterRow,
    ComplaintsFilterSearchRow,
    ComplaintsFilterWrapper,
    ComplaintsListWrapper,
    ComplaintsReportRadio,
} from "../ComplaintsList.styles";
import { H2 } from "Components/Primitives/TextElements/TextElements";
import { PaginationDirection, SharmansTable, SortOrderDirection } from "Components/Primitives/DataTable/SharmansTable";
import { ComplaintsListViewModel } from "./ComplaintsListViewModel";
import { AppUrlIndex, AppUrls } from "AppUrls";
import { useNavigate } from "react-router";
import spyScope from "Assets/Icons/magnifyingGlass.svg";
import { MultiEditSelect } from "Components/Primitives/Selects/MultiEditSelect";
import { CenteredCell, CenteredTextCell } from "Utils/CellFormatComponents";
import { DownloadButton } from "Components/Primitives/Buttons/DownloadButton";
import styled from "@emotion/styled";
import { DatePickerBox } from "Components/Primitives/DatePickerBox";

export const SmallDataTable = styled(Box)`
    tr {
        td:first-child,
        th:first-child {
            max-width: 45px;
        }
    }
    & [style="width: 60px;"] {
        max-width: 60px;
    }
`;

export const ComplaintsListView: React.FC = observer(() => {
    const [viewModel] = useState(ComplaintsListViewModel.Instance);
    const columnHelper = createColumnHelper<any>();
    let navigate = useNavigate();

    useEffect(() => {
        viewModel.loadComplaintList().then(() => {});
        return () => {
            // Clean up after yourself
            // Don't clear, we want to keep this since using a singleton viewModel.clear();
            // viewModel.reset();
        };
    }, []);

    const columns = [
        columnHelper.accessor("referenceId", {
            size: 60,
            header: () => "Ref No.",
            cell: (info) => info.getValue(),
        }),

        columnHelper.accessor("projectName", {
            header: () => "Project",
            cell: (info) => info.renderValue(),
        }),

        columnHelper.accessor("address", {
            header: () => "Address",
            cell: (info) => info.renderValue(),
        }),

        columnHelper.accessor("postcode", {
            size: 50,
            header: () => "Postcode",
            cell: (info) => <CenteredTextCell value={info.renderValue()} />,
        }),

        columnHelper.accessor("installationContractor", {
            header: () => "Installer",
            cell: (info) => info.renderValue(),
        }),

        columnHelper.accessor("complaintAssignee", {
            header: () => "Assigned",
            cell: (info) => info.renderValue(),
        }),

        columnHelper.accessor("nature", {
            header: () => "Nature",
            cell: (info) => info.renderValue(),
        }),

        columnHelper.accessor("createdDate", {
            size: 60,
            header: () => "Opened",
            cell: (info) => <CenteredTextCell value={info.getValue() !== null ? formatDate(new Date(info.getValue())) : " - "} />,
        }),

        columnHelper.accessor("resolvedDate", {
            size: 60,
            header: () => "Resolved date",
            cell: (info) => <CenteredTextCell value={info.getValue() !== null ? formatDate(new Date(info.getValue())) : " - "} />,
        }),

        columnHelper.accessor("id", {
            size: 60,
            header: () => "Actions",
            cell: (info) => (
                <CenteredCell>
                    <DownloadButton id={info.getValue().toString()} onClick={downloadPdfClick} />
                </CenteredCell>
            ),
            enableSorting: false,
        }),
    ];

    const downloadPdfClick = (e: any, id: string) => {
        e.stopPropagation();
        let promise = viewModel.downloadPDF(id);
        promise.then((result: any) => {});
    };

    function onRowClick(rowId: string | number): void {
        const path: string = AppUrls.Client.Aftersales.ComplaintsView[AppUrlIndex.Long].replace(":complaintid", rowId.toString());
        navigate(path);
    }

    const updateSorting = (columnName: string, orderBy: SortOrderDirection) => {
        viewModel.model.columnName = columnName;
        viewModel.model.orderBy = orderBy;
        viewModel.loadComplaintList();
    };

    const onChangePage = (change: PaginationDirection) => {
        switch (change) {
            case PaginationDirection.NEXT:
                viewModel.model.pageNumber < viewModel.model.pageCount ? viewModel.model.pageNumber++ : null;
                viewModel.loadComplaintList();
                break;
            case PaginationDirection.BACK:
                viewModel.model.pageNumber > 1 ? viewModel.model.pageNumber-- : null;
                viewModel.loadComplaintList();

                break;
            case PaginationDirection.START:
                viewModel.model.pageNumber = 1;
                viewModel.loadComplaintList();

                break;
            case PaginationDirection.END:
                viewModel.model.pageNumber = viewModel.model.pageCount;
                viewModel.loadComplaintList();
                break;
            default:
        }
    };

    const onChangeRowPerPage = (rowsPerPage: number) => {
        viewModel.model.pageSize = rowsPerPage;
        viewModel.model.pageNumber = 1;
        viewModel.loadComplaintList();
    };

    return (
        <>
            <ComplaintsListWrapper>
                <H2 pb="15px">Complaint List</H2>
                <ComplaintsFilterWrapper>
                    <ComplaintsFilterSearchRow>
                        <Input
                            command={viewModel.searchCommand}
                            placeholder="Start typing to search by reference no, project name, address, assigned etc..."
                            prefix={<img src={spyScope} onClick={(e) => viewModel.executeSearch()} style={{ cursor: "pointer" }} />}
                            displayName="Search"
                            value={() => viewModel.getSearchValue}
                        />
                        <div>&nbsp;</div>
                    </ComplaintsFilterSearchRow>
                    <ComplaintsFilterRow>
                        <ComplaintsReportRadio>
                            <RadioButton
                                key="rbcrr"
                                className="complaintRadioButton"
                                horizontal={false}
                                command={viewModel.setTableComplaintStatus}
                                displayName="Complaint Status:"
                                options={[
                                    { key: "1", text: "Unresolved" },
                                    { key: "2", text: "Resolved" },
                                    { key: "3", text: "Both" },
                                ]}
                                value={() => viewModel.getTableComplaintStatus}
                                uniqueId="tableComplaintStatus"
                            />
                        </ComplaintsReportRadio>

                        <ComplaintTableDateWrapper>
                            <DatePickerBox>
                                <EditDate
                                    command={viewModel.setTableDateFrom}
                                    value={viewModel.getTableDateFrom}
                                    displayName="Show resolved from: "
                                    style={{ marginRight: "10px" }}
                                />
                            </DatePickerBox>
                            <DatePickerBox>
                                <EditDate command={viewModel.setTableDateTo} value={viewModel.getTableDateTo} displayName="Show resolved to:" style={{ marginRight: "10px" }} />
                            </DatePickerBox>
                            <Button
                                command={viewModel.setTableDateAll}
                                paletteColor={viewModel.isProcessing === true ? "ButtonInactive" : "ButtonBlue"}
                                displayName={"All resolved"}
                                style={{ marginTop: "17px", padding: "5px 15px", maxHeight: "30px", marginRight: "10px" }}
                                isButtonDisabled={viewModel.isProcessing}
                            />
                            <Button
                                command={viewModel.setTableDateMonth}
                                paletteColor={viewModel.isProcessing === true ? "ButtonInactive" : "ButtonBlue"}
                                displayName={"Last month resolved"}
                                style={{ marginTop: "17px", padding: "5px 15px", maxHeight: "30px", marginRight: "10px" }}
                                isButtonDisabled={viewModel.isProcessing}
                            />
                            <Button
                                command={viewModel.setTableDateYear}
                                paletteColor={viewModel.isProcessing === true ? "ButtonInactive" : "ButtonBlue"}
                                displayName={"last year resolved"}
                                style={{ marginTop: "17px", padding: "5px 15px", maxHeight: "30px", marginRight: "10px" }}
                                isButtonDisabled={viewModel.isProcessing}
                            />

                            <Box>
                                <MultiEditSelect
                                    command={viewModel.updateAssignedUserCommand}
                                    isMulti={true}
                                    displayName="Assigned User"
                                    options={viewModel.getAssignedUsers}
                                    value={() => viewModel.getValue("filterAssignedUsers")}
                                    showSelectAllNone={true}
                                    selectAll={viewModel.assignedSelectAll}
                                    selectNone={viewModel.assignedSelectNone}
                                    isDisabled={viewModel.isProcessing}
                                />
                            </Box>
                            <Box>
                                <MultiEditSelect
                                    command={viewModel.updateFilterNatureCommand}
                                    isMulti={true}
                                    displayName="Nature"
                                    options={viewModel.getFilteredNature}
                                    value={() => viewModel.getValue("filterNature")}
                                    showSelectAllNone={true}
                                    selectAll={viewModel.natureSelectAll}
                                    selectNone={viewModel.natureSelectNone}
                                    isDisabled={viewModel.isProcessing}
                                />
                            </Box>
                        </ComplaintTableDateWrapper>
                    </ComplaintsFilterRow>
                </ComplaintsFilterWrapper>
                <SmallDataTable>
                    <SharmansTable
                        columns={columns}
                        data={viewModel.getComplaintList}
                        onSortChange={updateSorting}
                        onRowClick={onRowClick}
                        showPagination={true}
                        totalRowCount={viewModel.model.totalCount}
                        onChangePage={onChangePage}
                        onChangePageNumber={viewModel.changePageNumber}
                        onChangeRowPerPage={onChangeRowPerPage}
                        totalPageCount={viewModel.model.pageCount}
                        pageNumber={viewModel.model.pageNumber}
                        pageSize={viewModel.model.pageSize}
                        isProcessing={viewModel.isProcessing}
                        stickyTop={70}
                    />
                </SmallDataTable>
            </ComplaintsListWrapper>
        </>
    );
});
