import React, { ReactNode, useEffect, useState } from "react";
import { observer } from "mobx-react-lite";
import { Box, Button, ICommand } from "Application";
import { Modal } from "@fluentui/react";
import { HeaderBox, HeaderBoxCloseButton, ModalBody, ModalHeader, ModalWrapper, StickyBar } from "./ModalGeneric.styles";
import { P } from "Components/Primitives/TextElements/TextElements";
import CloseIcon from "Assets/close_icon.svg";

interface IModal {
    isOpen?: boolean;
    headerIcon?: string;
    headerText?: string;
    headerSubText?: string;
    closeModalCommand: ICommand<any>;
    children?: ReactNode;
    stickyBar?: ReactNode;
    noBodyMargin?: boolean;
}

export const ModalGenericWithHeader: React.FC<IModal> = observer((props) => {
    useEffect(() => {
        return () => {
            // Clean up after yourself
            //viewModel.clear();
        };
    }, []);

    return (
        <>
            <Modal isOpen={props.isOpen} styles={{ scrollableContent: { overflowY: "visible !important" } }}>
                <ModalWrapper>
                    <ModalHeader>
                        <HeaderBox>
                            <img src={props.headerIcon} height="20px" />
                            <Box>
                                <P color="white" pl="10px" style={{ fontSize: "10px", fontWeight: 600 }}>
                                    {props.headerText}
                                </P>
                                <P color="white" pl="10px" style={{ fontSize: "10px" }}>
                                    {props.headerSubText}
                                </P>
                            </Box>
                        </HeaderBox>
                        <HeaderBoxCloseButton style={{ cursor: "pointer" }} icon={<img src={CloseIcon} />} command={props.closeModalCommand}></HeaderBoxCloseButton>
                    </ModalHeader>

                    <ModalBody noPadding={props.noBodyMargin}>{props.children}</ModalBody>
                </ModalWrapper>

                <StickyBar>{props.stickyBar}</StickyBar>
            </Modal>
        </>
    );
});
