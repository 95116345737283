import { ComplaintDocumentsGetByComplaintIdRequest, MasterProjectComplaintDocumentsGetByComplaintIdEndpoint } from "./../Endpoints/MasterProjectComplaintDocumentsGetByComplaintId";
import { makeObservable, action, computed, observable, runInAction, IObservableArray } from "mobx";
import { KeyValuePair, ViewModelBase } from "@shoothill/core";
import { APIClient, ICommand, RelayCommand } from "Application";
import { ErrorStore } from "Stores/Domain/ErrorStore";
import { container } from "tsyringe";
import { ComplaintDocumentsModel, ComplaintDocumentsModelValidator } from "./ComplaintDocumentsModel";
import { IAddDocumentModalViewModel } from "Models/Documents/IAddDocumentModalViewModel";
import { IDocumentUploaded } from "Models/Documents/IDocumentUploaded";
import { DocumentDownloaderEndpoint, DocumentDownloaderRequest } from "Endpoint/DocumentDownloader";
import { File64, getFileTo64 } from "Utils/File";
import { IDocumentType } from "Models/Documents/DocumentType";
import { ComplaintTypeEnum } from "Models/Complaints/ComplaintTypeEnum";
import { ProjectQuoteComplaintDocumentsGetByComplaintIdEndpoint } from "../Endpoints/ProjectQuoteComplaintDocumentsGetByComplaintId";
import { ProjectQuoteComplaintDocumentDeleteByIdEndpoint } from "../Endpoints/ProjectQuoteComplaintDocumentDeleteById";
import { ComplaintDocumentDeleteByIdRequest, MasterProjectComplaintDocumentDeleteByIdEndpoint } from "../Endpoints/MasterProjectComplaintDocumentDeleteById";
import { ProjectQuoteComplaintDocumentUpsertEndpoint } from "../Endpoints/ProjectQuoteComplaintDocumentUpsert";
import { MasterProjectComplaintDocumentUpsertEndpoint } from "../Endpoints/MasterProjectComplaintDocumentUpsert";
import { ComplaintDocumentItemViewModel } from "./ComplaintDocumentItemViewModel";

export class ComplaintDocumentsViewModel extends ViewModelBase<ComplaintDocumentsModel> implements IAddDocumentModalViewModel {
    public apiClient = new APIClient();
    errorStore = container.resolve(ErrorStore);

    public isProcessing: boolean = false;
    public spinnerText: string = "Loading...";
    public areYouSureModalOpen: boolean = false;

    public complaintId: Guid = null;
    public complaintType: ComplaintTypeEnum = ComplaintTypeEnum.ProjectQuote;

    public showAddDocumentModal: boolean = false;

    public documentTypes: IDocumentType[] = [];

    public documentsToUpload: ComplaintDocumentItemViewModel[] = [];
    public validateDocumentsToUpload: boolean = false;
    public currentDocumentUploadingIndex: number = 0;

    public uploadedDocuments: IObservableArray<IDocumentUploaded> = observable([]);

    public documentFile: File | undefined = undefined;
    public documentToDeleteId: string | null = null;

    public countCallBack: ((newCount: number) => void) | undefined = undefined;

    constructor() {
        super(new ComplaintDocumentsModel());
        this.setValidator(new ComplaintDocumentsModelValidator());

        makeObservable(this, {
            complaintId: observable,
            isProcessing: observable,
            spinnerText: observable,
            showAddDocumentModal: observable,
            documentsToUpload: observable,
            documentTypes: observable,
            validateDocumentsToUpload: observable,
            currentDocumentUploadingIndex: observable,
            areYouSureModalOpen: observable,
            documentToDeleteId: observable,

            addDocumentFile: action,
            addFiles: action,
            clear: action,
            closeAreYouSureModal: action,
            confirmDeleteDocument: action,
            deleteDocument: action,
            deleteDocumentCheck: action,
            downloadDocumentAsync: action,
            downloadDocumentCommand: action,
            getDocumentsByComplaintId: action,
            init: action,
            updateDocumentListForEditing: action,

            getDocuments: computed,
            getDocTypesForDropdown: computed,
        });
    }

    public init(complaintId: Guid, complaintType: ComplaintTypeEnum, countCallBack: (newCount: number) => void) {
        this.complaintId = complaintId;
        this.countCallBack = countCallBack;
        this.complaintType = complaintType;
    }

    public clear = () => {
        this.isProcessing = false;
        this.spinnerText = "Loading...";
        this.areYouSureModalOpen = false;
        this.complaintId = "";
        this.showAddDocumentModal = false;
        this.documentTypes = [];
        this.documentsToUpload = [];
        this.validateDocumentsToUpload = false;
        this.currentDocumentUploadingIndex = 0;
        this.uploadedDocuments.clear();
        this.documentFile = undefined;
        this.documentToDeleteId = null;
        this.model.clear();
    };

    public openNewDocumentModal: ICommand = new RelayCommand(() => {
        this.showAddDocumentModal = true;
    });

    public closeNewDocumentModal: ICommand = new RelayCommand(() => {
        this.showAddDocumentModal = false;
        this.documentsToUpload = [];
    });

    // Files - Add files to list in modal, ready for uploading
    public addFiles = (acceptedFiles: File[]) => {
        acceptedFiles.map((file: File) => {
            const allowedFileTypes = [".doc", ".docx", ".pdf", ".xls", ".xlsx", ".csv", ".jpg", ".png"];
            const fileType = file.name.substring(file.name.lastIndexOf(".")).toLowerCase();
            if (allowedFileTypes.includes(fileType)) {
                const viewModel = new ComplaintDocumentItemViewModel();
                viewModel.setValue("filename", file.name);
                viewModel.setValue("documentCategoryId", 0);
                viewModel.setValue("file", file);

                this.documentsToUpload.push(viewModel);
                this.validateDocumentsToUpload = false;
            } else {
                this.errorStore.setHeaderText("Invalid Files");
                this.errorStore.setButtonText("Close");
                this.errorStore.setErrorMessageOne(`Invalid file type: ${fileType}. Only .doc, .pdf, .xls, .csv, .jpg, .png are allowed.`);
                this.errorStore.setErrorModalOpen(true);
            }
        });
    };

    // Files - remove file from list in modal
    public removeFileFromModalList: ICommand = new RelayCommand((index: number) => {
        const copy = [...this.documentsToUpload];
        copy.splice(index, 1);
        this.documentsToUpload = copy;
    });

    // Files - validate, then submit to server for blob storage and database
    public submitDocuments: ICommand = new RelayCommand(() => {
        let areViewModelsValid: boolean = true;

        for (let i = 0; i < this.documentsToUpload.length; i++) {
            if (this.documentsToUpload[i].isModelValid() === false) {
                areViewModelsValid = false;
            }
        }

        if (areViewModelsValid === true && this.documentsToUpload.length > 0) {
            this.addDocumentFile();
        }
    });

    public addDocumentFile = async () => {
        this.validateDocumentsToUpload = true;

        if (this.isProcessing === false) {
            this.isProcessing = true;
            this.apiClient.setAPITimeout(240000);

            if (this.documentsToUpload.length > 0) {
                for (let i = 0; i < this.documentsToUpload.length; i++) {
                    this.spinnerText = "Uploading document " + (i + 1) + " of " + this.documentsToUpload.length;
                    let uploadedFile: File64 = await getFileTo64(this.documentsToUpload[i].getValue("file"));
                    this.documentsToUpload[i].setValue("fileBase64", uploadedFile.base64StringFile);
                    this.documentsToUpload[i].setValue("fileType", uploadedFile.fileType);
                    this.documentsToUpload[i].setValue("complaintId", this.complaintId);

                    let endpoint: any = new ProjectQuoteComplaintDocumentUpsertEndpoint();

                    if (this.complaintType === ComplaintTypeEnum.MasterProject) {
                        endpoint = new MasterProjectComplaintDocumentUpsertEndpoint();
                    }

                    let _ = await this.apiClient.sendAsync(endpoint, this.documentsToUpload[i].model);
                    if (this.apiClient.IsRequestSuccessful) {
                        runInAction(() => {
                            this.isProcessing = false;
                            let response: IDocumentUploaded[] = this.apiClient.Response();
                            this.uploadedDocuments.replace(response);

                            if (this.countCallBack !== undefined) {
                                this.countCallBack(response.length);
                            }
                            this.showAddDocumentModal = false;
                            this.documentsToUpload = [];
                        });
                    } else {
                        runInAction(() => {
                            this.isProcessing = false;
                        });
                        this.errorStore.setHeaderText("Complaint documents");
                        this.errorStore.setButtonText("Close");
                        this.errorStore.setErrorMessageOne("Failed to upload the document.  Please correct the file and try again");
                        this.errorStore.setErrorMessageTwo(this.apiClient.ValidationMessage);
                        this.errorStore.setErrorModalOpen(true);
                    }
                }
                this.showAddDocumentModal = false;
                this.documentsToUpload = [];
            }
        }
    };

    public get getDocuments() {
        return this.uploadedDocuments.slice();
    }

    public deleteDocumentByIdCommand: ICommand = new RelayCommand((Id: Guid) => {
        this.deleteDocument(Id, this.complaintId);
    });

    public async deleteDocument(id: Guid, complaintid: Guid): Promise<void> {
        if (this.isProcessing === false) {
            this.isProcessing = true;
            let endpoint: any = new ProjectQuoteComplaintDocumentDeleteByIdEndpoint();

            if (this.complaintType === ComplaintTypeEnum.MasterProject) {
                endpoint = new MasterProjectComplaintDocumentDeleteByIdEndpoint();
            }
            const request: ComplaintDocumentDeleteByIdRequest = new ComplaintDocumentDeleteByIdRequest();
            request.id = id;
            request.complaintId = complaintid;

            await this.apiClient.sendAsync(endpoint, request);

            if (this.apiClient.IsRequestSuccessful) {
                runInAction(() => {
                    const response: IDocumentUploaded[] = this.apiClient.Response();

                    this.uploadedDocuments.replace(response);
                    if (this.countCallBack !== undefined) {
                        this.countCallBack(response.length);
                    }
                    this.isProcessing = false;
                });
            } // else show error message
            else {
                runInAction(() => {
                    this.isProcessing = false;
                });
                this.errorStore.setHeaderText("Complaint documents"); // Note - Change this
                this.errorStore.setButtonText("Close");
                this.errorStore.setErrorMessageOne("Failed to delete document.  Please try again later.");
                this.errorStore.setErrorMessageTwo(this.apiClient.ValidationMessage);
                this.errorStore.setErrorModalOpen(true);
            }
        }
    }

    public async getDocumentsByComplaintId(): Promise<void> {
        if (this.isProcessing === false) {
            this.isProcessing = true;
            this.setValue("complaintId", this.complaintId);
            if (this.complaintId !== "") {
                let endpoint: any = new ProjectQuoteComplaintDocumentsGetByComplaintIdEndpoint();

                if (this.complaintType === ComplaintTypeEnum.MasterProject) {
                    endpoint = new MasterProjectComplaintDocumentsGetByComplaintIdEndpoint();
                }

                let request = new ComplaintDocumentsGetByComplaintIdRequest();
                request.complaintId = this.complaintId!;
                request.columnName = this.model.columnName;
                request.orderBy = this.model.orderBy;

                await this.apiClient.sendAsync(endpoint, request);

                if (this.apiClient.IsRequestSuccessful) {
                    runInAction(() => {
                        const response = this.apiClient.Response();
                        this.uploadedDocuments.replace(response);
                        if (this.countCallBack !== undefined) {
                            this.countCallBack(response.length);
                        }
                        this.isProcessing = false;
                    });
                } // else show error message
                else {
                    runInAction(() => {
                        this.isProcessing = false;
                    });
                    this.errorStore.setHeaderText("Complaint documents"); // Note - Change this
                    this.errorStore.setButtonText("Close");
                    this.errorStore.setErrorMessageOne("Failed to get the documents.  Please try again later.");
                    this.errorStore.setErrorMessageTwo(this.apiClient.ValidationMessage);
                    this.errorStore.setErrorModalOpen(true);
                }
            }
        }
    }

    public deleteDocumentCheck = (e: any, id: string): void => {
        this.documentToDeleteId = id;
        this.areYouSureModalOpen = true;
    };

    public downloadDocumentCommand = (e: any, id: string): void => {
        let foundDoc: IDocumentUploaded | undefined = this.uploadedDocuments.find((a) => a.id === id);

        if (foundDoc !== undefined) {
            let documentToDownload = new DocumentDownloaderRequest();
            documentToDownload.id = foundDoc.id;
            documentToDownload.fileName = foundDoc.fileName;
            documentToDownload.blobName = foundDoc.blobName;
            documentToDownload.fileType = foundDoc.fileType;
            documentToDownload.docType = foundDoc.docType;

            this.downloadDocumentAsync(documentToDownload);
        }
    };

    public async downloadDocumentAsync(documentToDownload: DocumentDownloaderRequest): Promise<void> {
        if (documentToDownload !== undefined) {
            const endpoint = new DocumentDownloaderEndpoint();

            await this.apiClient.sendAsync(endpoint, documentToDownload);
            if (this.apiClient.IsRequestSuccessful) {
                runInAction(() => {
                    // this.isProcessing = false;
                    let response = this.apiClient.Response();
                });
            } // else show error message
            else {
                runInAction(() => {
                    // this.isProcessing = false;
                });
                this.errorStore.setHeaderText("Complaint documents"); // Note - Change this
                this.errorStore.setButtonText("Close");
                this.errorStore.setErrorMessageOne("Failed to download the file.  Please try again later.");
                this.errorStore.setErrorMessageTwo(this.apiClient.ValidationMessage);
                this.errorStore.setErrorModalOpen(true);
            }
        }
    }

    public openAreYouSureModal: ICommand = new RelayCommand((id: Guid) => {
        this.documentToDeleteId = id;
        this.areYouSureModalOpen = true;
    });

    public closeAreYouSureModal = () => {
        this.areYouSureModalOpen = false;
    };

    public confirmDeleteDocument = () => {
        this.deleteDocument(this.documentToDeleteId, this.complaintId);
        this.areYouSureModalOpen = false;
    };

    public get getDocTypesForDropdown(): KeyValuePair<number>[] {
        let retVal: KeyValuePair<number>[] = [];

        this.documentTypes.map((type) => {
            retVal.push({ key: type.id, text: type.categoryName });
        });

        return retVal;
    }

    public updateDocumentListForEditing = (documents: IDocumentUploaded[]) => {
        this.uploadedDocuments.replace(documents);
    };
}
