import { DateTime } from "luxon";
import { ModelBase } from "@shoothill/core";
import { makeObservable, action, computed, observable, IObservableArray } from "mobx";
import { Validator } from "Application/Validation";
import { ComplaintStatusEnum, ComplaintStatusEnumHelpers } from "Models/Complaints/ComplaintStatusEnum";
import { AssignedUserDropdownItem } from "Models/Complaints/AssignedUserDropdownItem";
import { ComplaintNatureDropdownItem } from "Models/Complaints/ComplaintNatureDropdownItem";
import { DefaultPageSize } from "Globals/GlobalSettings";
import { ComplaintGetForListRequest, ComplaintGetForListResponse } from "../Endpoints/ComplaintGetForList";
import { SortOrderDirection } from "Components/Primitives/DataTable/SharmansTable";
import { ComplaintListItem } from "Models/Complaints/ComplaintListItem";

export class ComplaintsListModel extends ModelBase<ComplaintsListModel> {
    //public id: string = "";

    // Table ordering
    public orderBy: SortOrderDirection = SortOrderDirection.ASC;
    public columnName: string = "displayName";

    public pageSize: number = DefaultPageSize;
    public pageCount: number = 0;
    public pageNumber: number = 1;

    public totalCount: number = 0;

    // Complaint Status 1 = unresolved, 2 = resolved , 3 = both
    public tableComplaintStatus: string = ComplaintStatusEnum.UnResolved.toString();
    public tableSearchCommand: string = "";
    public tableStartDate: DateTime = DateTime.local().minus({ month: 1 }).startOf("day");
    public tableEndDate: DateTime = DateTime.local().endOf("day");

    // filters
    public filterAssignedUsers: string[] = [];
    public filterNature: string[] = [];

    public assignedUsers: AssignedUserDropdownItem[] = [];
    public nature: ComplaintNatureDropdownItem[] = [];

    public initialLoad: boolean = true;

    public complaints: IObservableArray<ComplaintListItem> = observable([]);

    constructor() {
        super();
        makeObservable(this, {
            assignedUsers: observable,
            filterAssignedUsers: observable,
            filterNature: observable,
            columnName: observable,
            orderBy: observable,
            initialLoad: observable,
            nature: observable,
            tableSearchCommand: observable,
            tableComplaintStatus: observable,
            tableEndDate: observable,
            tableStartDate: observable,
            totalCount: observable,
            pageSize: observable,
            pageNumber: observable,

            assignedSelectAll: action,
            assignedSelectNone: action,
            clear: action,
            fromListResponse: action,
            natureSelectAll: action,
            natureSelectNone: action,
            tableSetAll: action,
            tableSetMonth: action,
            tableSetYear: action,
        });
    }

    public clear = () => {
        this.id = "";

        this.tableComplaintStatus = ComplaintStatusEnum.UnResolved.toString();
        this.tableSearchCommand = "";
        this.tableStartDate = DateTime.local().minus({ month: 1 }).startOf("day");
        this.tableEndDate = DateTime.local().endOf("day");

        this.filterAssignedUsers = [];
        this.filterNature = [];
        this.initialLoad = true;
        this.assignedUsers = [];
        this.nature = [];

        this.totalCount = 0;
        this.pageCount = 0;
        this.pageNumber = 1;
        this.complaints.clear();
    };

    public assignedSelectAll = () => {
        this.filterAssignedUsers = this.assignedUsers.map((item) => {
            return item.id.toString();
        });
    };
    public assignedSelectNone = () => {
        this.filterAssignedUsers = [];
    };
    public natureSelectAll = () => {
        this.filterNature = this.nature.map((item) => {
            return item.id.toString();
        });
    };
    public natureSelectNone = () => {
        this.filterNature = [];
    };

    public tableSetYear = () => {
        this.tableStartDate = DateTime.local().minus({ year: 1 }).startOf("day");
        this.tableEndDate = DateTime.local().endOf("day");
        this.tableComplaintStatus = ComplaintStatusEnum.Resolved.toString();
    };
    public tableSetMonth = () => {
        this.tableStartDate = DateTime.local().minus({ month: 1 }).startOf("day");
        this.tableEndDate = DateTime.local().endOf("day");
        this.tableComplaintStatus = ComplaintStatusEnum.Resolved.toString();
    };
    public tableSetAll = () => {
        this.tableStartDate = DateTime.local().set({ year: 2018, month: 3, day: 1 }).startOf("day");
        this.tableEndDate = DateTime.local().endOf("day");
        this.tableComplaintStatus = ComplaintStatusEnum.Resolved.toString();
    };

    public getRequest = (): ComplaintGetForListRequest => {
        let retVal: ComplaintGetForListRequest = new ComplaintGetForListRequest();

        retVal.filterByAssigned = [];
        retVal.filterByNature = [];

        retVal.orderBy = this.orderBy;
        retVal.columnName = this.columnName;
        retVal.pageSize = this.pageSize;
        retVal.pageNumber = this.pageNumber;

        retVal.searchString = this.tableSearchCommand;

        retVal.showFrom = this.tableStartDate.toISODate()!;
        retVal.showTo = this.tableEndDate.toISODate()!;
        retVal.complaintStatus = ComplaintStatusEnumHelpers.fromString(this.tableComplaintStatus);

        retVal.filterByAssigned = this.filterAssignedUsers.slice().map((item) => parseInt(item));
        retVal.filterByNature = this.filterNature.slice().map((item) => parseInt(item));

        retVal.initialLoad = this.initialLoad;

        return retVal;
    };

    public fromListResponse(response: ComplaintGetForListResponse) {
        this.totalCount = response.totalCount;
        this.pageCount = Math.ceil(this.totalCount / this.pageSize);

        this.complaints.clear();
        this.complaints.replace(response.complaints);

        if (this.initialLoad === true) {
            this.assignedUsers = [];
            this.assignedUsers = response.complaintAssignees;

            this.filterAssignedUsers = response.complaintAssignees.map((item) => {
                return item.id.toString();
            });

            this.nature = [];
            this.nature = response.complaintNatures;
            this.filterNature = response.complaintNatures.map((item) => {
                return item.id.toString();
            });

            this.initialLoad = false;
        }
    }
}

export class ComplaintsListModelValidator extends Validator<ComplaintsListModel> {
    constructor() {
        super();
        //this.ruleFor("id").notEmpty();
    }
}
