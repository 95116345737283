import { IObservableArray, action, computed, makeObservable, observable, runInAction } from "mobx";
import { ViewModelBase } from "@shoothill/core";
import { APIClient, ICommand, RelayCommand } from "Application";
import { ErrorStore } from "Stores/Domain/ErrorStore";
import { container } from "tsyringe";
import { LinkedMultiSiteModel, LinkedMultiSiteModelValidator } from "./LinkedMultiSiteModel";
import { SortOrderDirection } from "Components/Primitives/DataTable/SharmansTable";
import { DefaultPageSize } from "Globals/GlobalSettings";
import { KeyValuePairExtended } from "Models/KeyValuePairExtended";
import { StatusEnum, StatusEnumHelper } from "Models/Project/StatusEnum";
import { StatusDropdownItem } from "Models/DropdownItem";
import { GetLinkedMultiSiteAndRelatedEndpoint, LinkedMultiSiteAndRelatedResponse } from "./Endpoints/GetLinkedMultiSiteAndRelatedEndpoint";
import { LinkedMultiSiteListItem } from "./LinkedMultiSiteListItem";

export class LinkedMultiSiteViewModel extends ViewModelBase<LinkedMultiSiteModel> {
    public apiClient = new APIClient();
    errorStore = container.resolve(ErrorStore);

    public isProcessing: boolean = false;

    public initialLoad: boolean = true;

    public filterStatuses: IObservableArray<KeyValuePairExtended> = observable([]);
    public statusDropdownItems: StatusDropdownItem[] = StatusEnumHelper.getStatusDropdownOptions();

    // Table ordering

    public orderBy: SortOrderDirection = SortOrderDirection.ASC;
    public columnName: string = "displayName";

    // Pagination
    public customerCount: number = 0;
    public pageSize: number = DefaultPageSize;
    public pageCount: number = 0;
    public pageNumber: number = 1;
    public totalCount: number = 0;

    // Filtering top section

    public filterByBdm: string[] = [];
    public filterByTsm: string[] = [];
    public filterByStatus: string[] = [];
    private multiSiteCountCallBack: (newCount: number) => void;
    constructor(customerId: string | undefined, multiSiteCountCallBack: (newCount: number) => void) {
        super(new LinkedMultiSiteModel());
        this.multiSiteCountCallBack = multiSiteCountCallBack;
        this.model.customerId = customerId!;
        this.setValidator(new LinkedMultiSiteModelValidator());
        this.filterStatuses.replace(StatusEnumHelper.getStatusOptionsForListView(StatusEnum.Open));
        this.statusSelectAll();
        makeObservable(this, {
            filterByStatus: observable,
            isProcessing: observable,
            pageSize: observable,
            pageNumber: observable,
            totalCount: observable,
            filterStatuses: observable,
            statusDropdownItems: observable,
            filterByBdm: observable,
            filterByTsm: observable,
            clear: action,
            updateStatusFiltering: action,
            statusSelectAll: action,
            statusSelectNone: action,

            getStatuses: computed,
            getStatusFilter: computed,
            getLinkedMultiSiteList: computed,
        });
    }

    public clear() {
        this.isProcessing = false;
        this.orderBy = SortOrderDirection.ASC;
        this.columnName = "displayName";
        this.pageSize = 10;
        this.pageNumber = 1;
        this.totalCount = 0;
    }

    // Status

    public updateStatusFiltering(values: string[]) {
        this.filterByStatus = values;
    }

    public statusSelectAll() {
        this.filterByStatus = this.filterStatuses.map((item) => {
            return item.key.toString();
        });
    }

    public statusSelectNone() {
        this.filterByStatus = [];
    }

    public get getStatuses(): KeyValuePairExtended[] {
        return this.filterStatuses.slice();
    }

    public get getStatusFilter(): string | string[] {
        return this.filterByStatus.slice();
    }

    // End Status

    // Data Table

    public get getLinkedMultiSiteList(): LinkedMultiSiteListItem[] {
        return this.model.linkedMultiSiteList.slice();
    }

    // end Data Table

    public get IsBusy(): boolean {
        return this.apiClient.IsBusy;
    }

    public loadLinkedMultiSiteList(linkedMultiSiteList: LinkedMultiSiteListItem[]) {
        this.model.linkedMultiSiteList.clear();
        this.customerCount = 0;
        this.model.linkedMultiSiteList.replace(linkedMultiSiteList);
    }

    public getLinkedMultiSite = async (): Promise<LinkedMultiSiteAndRelatedResponse> => {
        const endpoint = new GetLinkedMultiSiteAndRelatedEndpoint();
        let retVal: LinkedMultiSiteAndRelatedResponse = new LinkedMultiSiteAndRelatedResponse();

        if (this.isProcessing === false && this.apiClient.IsBusy === false) {
            this.isProcessing = true;
            await this.apiClient.sendAsync(endpoint, this);

            if (this.apiClient.IsRequestSuccessful) {
                runInAction(() => {
                    this.model.linkedMultiSiteList.clear();
                    this.isProcessing = false;
                    let response: LinkedMultiSiteAndRelatedResponse = this.apiClient.Response();
                    this.loadLinkedMultiSiteList(response.customerMultiSiteProjectItemList);
                    this.multiSiteCountCallBack(this.model.linkedMultiSiteList.length);
                    this.totalCount = response.customerCount;
                    this.pageCount = Math.ceil(this.totalCount / this.pageSize);
                    if (this.initialLoad === true) {
                        this.initialLoad = false;
                    }
                });
            } else {
                runInAction(() => {
                    this.isProcessing = false;
                });
                this.errorStore.setHeaderText("View Linked MultiSite");
                this.errorStore.setButtonText("Close");
                this.errorStore.setErrorMessageOne("Failed to get the Linked MultiSite.  Please try again later.");
                this.errorStore.setErrorMessageTwo(this.apiClient.ValidationMessage);
                this.errorStore.setErrorModalOpen(true);
            }
        }
        return retVal;
    };

    public clearFiltering: ICommand = new RelayCommand(() => {
        this.filterByStatus = [];
        this.initialLoad = true;
        this.filterStatuses.replace(StatusEnumHelper.getStatusOptionsForListView(StatusEnum.Open));
        this.statusSelectAll();
        this.getLinkedMultiSite();
    });

    public changePageNumber = (pageNumber: number) => {
        this.pageNumber = pageNumber;
        this.getLinkedMultiSite();
    };
}
